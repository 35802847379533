import React, { useCallback } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

export interface ITextWithLinksProps {
  html: string;
  onLinkClick?: (id: string) => void;
  hidden?: boolean;
}

export const TextWithLinksComponent = (props: ITextWithLinksProps) => {
  const onNodeClick = useCallback((evt) => {
    let target = evt.target;
    let linkFound = target?.nodeName === 'A';

    if (!linkFound) {
      while (!target?.getAttribute('data-text-with-links') && !linkFound) {
        target = target.parentNode;
        linkFound = target?.nodeName === 'A';
      }
    }

    if (linkFound) {
      const linkId = target.getAttribute('id') || target.getAttribute('data-link-id');

      evt.stopPropagation();
      evt.preventDefault();
      props.onLinkClick?.(linkId);
    }
  }, []);

  if (props.hidden || !props.html) {
    return null;
  }

  // The component will inject simple HTML.
  const { html, ...rest } = omit(props, ['onLinkClick', 'children']);

  return <span data-text-with-links="true" onClick={onNodeClick} {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
};

TextWithLinksComponent.propTypes = {
  html: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func,
  hidden: PropTypes.bool
};

TextWithLinksComponent.displayName = 'TextWithLinks';
