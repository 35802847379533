import React from 'react';

import objectFitImages from 'object-fit-images';

export class MDFImage extends React.Component<any, any> {
  componentDidMount() {
    // MS Browser polyfill below:
    objectFitImages();
  }

  render() {
    return (
      <img src={this.props.src} className={'carousel-img'} role="presentation" />
    );
  }
}
