// my tests in ie11/chrome/FF indicate that keyDown repeats
// at about 35ms+/- 5ms after an initial 500ms delay. callback fires on the leading edge
export function repeater(callback) {
  let id;
  const cancel = () => clearInterval(id);

  id = setInterval(() => {
    cancel();
    id = setInterval(() => callback(), 35);
    // fire after everything in case the user cancels on the first call
    callback();
  }, 500);

  return cancel;
}
