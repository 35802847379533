import React from 'react';

export interface ICSSLayoutProps {
  // Selects the autoflow method: 'row' or 'dense' (default). 'row' uses a sparse layout method, where 'dense' will attempt to fill any empty space.
  autoFlow?: 'dense' | 'row';
  // The base row height to use in order to keep all items in a row the same height. Defaults to 240.
  baseRowHeight?: number;
  // The number of columns to use in the grid. Defaults to 3.
  columns?: number;
  // The children of this component.
  children: any;
}

export const CSSLayout = (props: ICSSLayoutProps) => {
  const styles = {
    gridAutoRows: `minmax(${props.baseRowHeight}px, auto)`,
    gridAutoFlow: props.autoFlow
  };

  const className = `mdf-css-layout grid grid-cols-${props.columns} gap-2`;

  return (
    <div className={className} style={styles}>
      {props.children}
    </div>
  );
};

CSSLayout.displayName = 'CSSLayout';
CSSLayout.defaultProps = {
  autoFlow: 'dense',
  baseRowHeight: 240,
  columns: 3
};

export interface ICSSLayoutItemProps {
  // The number of columns this item should span in the layout. Defaults to 1.
  colSpan?: number;
  // The specific column in which to put this item. Columns are 1-based.
  gridColumn?: string;
  // The specific row in which to put this item. Rows are 1-based.
  gridRow?: string;
  // The number of rows this item should span in the layout. Defaults to 1.
  rowSpan?: number;
  // The children of this component.
  children: any;
}

export const CSSLayoutItem = (props: ICSSLayoutItemProps) => {
  const classNames = `mdf-css-layout-item flex col-span-${props.colSpan} row-span-${props.rowSpan}`;

  const styles: any = {};

  if (props.gridColumn) {
    styles.gridColumn = props.gridColumn;
  }

  if (props.gridRow) {
    styles.gridRow = props.gridRow;
  }

  return (
    <div className={classNames} style={styles}>
      {props.children}
    </div>
  );
};

CSSLayoutItem.displayName = 'CSSLayoutItem';
CSSLayoutItem.defaultProps = {
  colSpan: 1,
  rowSpan: 1
};
