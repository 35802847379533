import React, { useState } from 'react';
import { IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellMMLevel3Item } from './WFNMockShellMMLevel3Item';
import { IWFNMockShellMMLevel1Item, IWFNMockShellMMLevel2Item } from '../types';
import { Checkbox } from '@synerg/vdl-react-components';
import { WFNMockShellMMFeaturesSelectedDetail } from './WFNMockShellMMFeaturesSelectedDetail';
import { WFNMockShellMMLevel2TitleBar } from './WFNMockShellMMLevel2TitleBar';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellMMNavCollapedLevel2Items {
  title: string;
  setExpanded: any;
  level1ItemIndex: number;
  level1Item: IWFNMockShellMMLevel1Item;
  childList: IWFNMockShellMMLevel2Item[];
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string;
}

export const WFNMockShellMMNavCollapsedLevel2Items = ({ setExpanded, level1ItemIndex, childList, onWFNShellChange, level1Item, editOptionsLabel }: IWFNMockShellMMNavCollapedLevel2Items) => {
  const [activeLevel2ItemIndex, setActiveLevel2ItemIndex] = useState(-1);

  const handleLevel2ItemMouseEnter = (subItem, i) => {
    if (activeLevel2ItemIndex !== -1) {
      childList[activeLevel2ItemIndex].active = false;
    }

    setActiveLevel2ItemIndex(i);
    subItem.active = true;
  };

  const handleLevel2ItemListHover = (level2Active) => {
    if (childList[activeLevel2ItemIndex]) {
      childList[activeLevel2ItemIndex].active = level2Active;
    }
  };

  const handleLevel2ItemChange = (level2Item, value) => {
    onWFNShellChange({
      item: {
        oid: level2Item.oid,
        name: level2Item.name
      },
      type: 'menuLevel2Checkbox',
      value
    });
  };

  const renderLevel2Items = () => {
    return childList.map((level2Item, i) => (
      <div
        key={level2Item.oid}
        onMouseEnter={handleLevel2ItemMouseEnter.bind(null, level2Item, i)}
        className={`wfnmockshell-sub-item ${level2Item.active ? 'active-sub' : ''}`}
      >
        <button className="wfnmockshell-sub-item--button">
          <div className="wfnmockshell-sub-item--button-content">
            {level2Item.renderCheckbox && <Checkbox onChange={handleLevel2ItemChange.bind(null, level2Item)} checked={level2Item.isSelected} disabled={level2Item.disableCheckbox} />}
            <div className="wfnmockshell-sub-item--button-content-text">
              <div className="wfnmockshell-sub-item-button-content-title" title={level2Item.name}>{level2Item.name}</div>
              {<WFNMockShellMMFeaturesSelectedDetail totalChildren={level2Item.totalChildren} selectedChildren={level2Item.selectedChildren} renderFeatureSelection={level2Item.renderFeatureSelection} editOptionsLabel={editOptionsLabel} featuresSelectedLabel={FormatHelper.formatMessage('@@FeaturesSelected')} />}
            </div>
          </div>
        </button>
        <div className="wfnmockshell-sub-item--button-dart"/>
      </div>
    ));
  };

  const renderLevel3Items = () => {
    if (activeLevel2ItemIndex === -1 || !childList[activeLevel2ItemIndex]?.childList) {
      return null;
    }

    return childList[activeLevel2ItemIndex].childList.map((level3Item) => <WFNMockShellMMLevel3Item key={level3Item.oid} onWFNShellChange={onWFNShellChange} {...level3Item} editOptionsLabel={editOptionsLabel} />);
  };

  return (
    <div className="wfnmockshell-top-item--wrapper wfnmockshell-collapsed">
      <div className="wfnmockshell-top-item--wrapper-dart"/>
      <div
        onMouseEnter={handleLevel2ItemListHover.bind(null, true)}
        onMouseLeave={handleLevel2ItemListHover.bind(null, false)}
        className={`wfnmockshell-sub mdf-mock-shell-wfnmockshell-slide-down has-active-level3-item expand-nav ${level1ItemIndex > 4 ? ' wfnmockshell-sub-right-align' : ''}`}
      >
        <WFNMockShellMMLevel2TitleBar level1Item={level1Item} onWFNShellChange={onWFNShellChange} />
        <div className="wfnmockshell-sub-container">
          <div className="wfnmockshell-sub-items">
            {renderLevel2Items()}
          </div>
          <div className="wfnmockshell-level3-item active-level3-item">
            <div className="wfnmockshell-level3-item-list">
              {renderLevel3Items()}
            </div>
          </div>
        </div>
        <div className="wfnmockshell-sub-expand" onClick={setExpanded.bind(null, true)}>
          <i className="wfnmockshell-sub-expand--icon fa fa-expand"/>
          <div className="wfnmockshell-sub-expand--title">{FormatHelper.formatMessage('@@label_expand_menu')}</div>
        </div>
      </div>
    </div>
  );
};

WFNMockShellMMNavCollapsedLevel2Items.displayName = 'WFNMockShellMMNavCollapsedLevel2Items';
