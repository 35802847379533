import React from 'react';
import { IWFNXWizardStepProps, WFNXWizardStep } from './WFNXWizardStep';
import classNames from 'classnames';

interface IWizardStepBarProps {
  wizardStepData: IWFNXWizardStepProps[];
  onStepClick: () => void;
  onViewClick: () => void;
  onDelegateBtnClick: () => void;
  onContainerCollapse: () => void;
  handleStepBarTabClick: (isCollpased: boolean) => void;
  wizardCollapsed: boolean;
}

interface IWizardStepBarState {
  wizardCollapsed: boolean;
}

export class WFNXWizardStepBar extends React.Component<IWizardStepBarProps, IWizardStepBarState> {
  constructor(props) {
    super(props);

    this.state = {
      wizardCollapsed: this.props.wizardCollapsed
    };
  }

  static defaultProps = {
    wizardCollapsed: false
  };

  private renderSteps = () => {
    const { wizardStepData, onStepClick, onViewClick, onDelegateBtnClick } = this.props;

    return wizardStepData.map((wizardStepProps, index) => {
      if (wizardStepProps.render === false) {
        return null;
      }

      const passDownProps = { ...wizardStepProps, onStepClick, onViewClick, onDelegateBtnClick };

      return (
        <WFNXWizardStep key={index} {...passDownProps} />
      );
    });
  };

  handleCollapseClick = (event: any) => {
    event.stopPropagation();

    this.setState({
      wizardCollapsed: !this.state.wizardCollapsed
    }, () => this.props.handleStepBarTabClick && this.props.handleStepBarTabClick(this.state.wizardCollapsed));
  };

  renderCollapseControl = () => (<div className={classNames('collapse-control', !this.state.wizardCollapsed ? 'collapse-control--open' : 'collapse-control--closed')} onClick={this.handleCollapseClick}><i className={classNames('fa', !this.state.wizardCollapsed ? 'fa-chevron-left' : 'fa-chevron-right')} /></div>);

  render() {
    return (
      <div className="wizard-stepbar">
        {this.renderCollapseControl()}
        <div className={this.state.wizardCollapsed ? 'wizard-steps-container wizard-collapsed' : 'wizard-steps-container wizard-expand'}>
          {this.renderSteps()}
        </div>
      </div>
    );
  }
}
