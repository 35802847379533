export interface IExternalHosts {
  dit?: string;
  fit?: string;
  iat?: string;
  production: string;
}

export type URLMapper = Record<string, IExternalHosts>;

export class ExternalComponents {
  private static urlMapper: URLMapper = {};

  private static discernEnvironment = (): string => {
    const hostname = window?.location.hostname || 'workforcenow.adp.com';

    if (hostname.includes('dit')) {
      return 'dit';
    }
    else if (hostname.includes('fit')) {
      return 'fit';
    }
    else if (hostname.includes('iat')) {
      return 'iat';
    }
    else {
      return 'production';
    }
  };

  private static isScriptLoaded = (url): boolean => {
    const scriptTags = document.getElementsByTagName('script');

    for (const script of Array.from(scriptTags)) {
      const href = script.src;

      if (href.includes(url)) {
        return true;
      }
    }

    return false;
  };

  static addExternalHosts = (application: string, configuration: IExternalHosts) => {
    this.urlMapper[application] = configuration;
  };

  static getHost = (application: string) => {
    const env = this.discernEnvironment();
    return this.urlMapper[application][env] || this.urlMapper[application].production;
  };

  static loadScript = (application: string, uri: string, options: any = {}) => {
    const host = this.getHost(application);

    return new Promise((resolve, reject) => {
      if (host) {
        if (this.isScriptLoaded(uri)) {
          resolve(`Script ${uri} already loaded.`);
        }
        else {
          const script = document.createElement('script');

          script.src = `//${host}${uri}`;
          script.onload = (e) => resolve(e);
          script.onerror = (e) => reject(e);

          for (const option in options) {
            if (options.hasOwnProperty(option)) {
              script[option] = options[option];
            }
          }

          document.head.appendChild(script);
        }
      }
      else {
        // This shouldn't happen, but it might.
        reject(`No hostname found for application ${application}.`);
      }
    });
  };
}
