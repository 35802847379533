import React, { useContext, useState } from 'react';
import { MDFSelectBox } from '../MDFSelectBox';
import { MDFRequiredField } from '../MDFRequiredField';
import { MDFDropdownList } from '../MDFDropdownList';
import { MDFButton2 } from '../MDFButton2';
import { ILabelValuePair, IRuleCellValue, IRuleColumn, RuleCell } from './RuleCell';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { RuleCardContext, RuleRowContext } from '../MDFContext';
import { RulesetActions } from './RuleSet';
import classNames from 'classnames';
import { MDFBadge } from '../MDFBadge';
import { SdfFocusPane } from '@waypoint/react-components';

export interface IRuleRowProps extends IRuleRow {
  // set current card index
  cardIndex: number;

  // set current row index
  rowIndex: number;

  // To enable/disable the component
  disabled?: boolean;

  // To enable/disable an individual option in dropdown list
  disabledItems?: any;

  // show card row join operator label
  showJoinOperator?: boolean;

  // show card row join operator dropdown list
  showJoinOperatorComponent?: boolean;

  // The items to display in the field Names dropdown.
  fieldNames?: IRuleColumn[];

  // The items to display in the operator dropdown.
  operatorOptions?: ILabelValuePair<string>[];

  // The items to display in the join dropdown.
  joinOperatorOptions?: any;

  rowJoinOperator: string;

  // to render join operator on the row
  renderRowJoinOperator: boolean

  // to render delete button on the row
  renderDelete: boolean;
}

export interface IRuleRow {
  field?: string;
  operator?: string;
  values?: IRuleCellValue[];
  joinOperator?: string;
}

const MDFValidatedSelectBox = MDFRequiredField(MDFSelectBox);
const MDFValidatedDropdownList = MDFRequiredField(MDFDropdownList);

export const RuleRow = (props: IRuleRowProps) => {
  // use RuleCardContext context to update the state
  const context: any = useContext(RuleCardContext);
  const [showModal, setShowModal] = useState(false);

  const joinOperatorOptions = [
    {
      label: FormatHelper.formatMessage('@@match_all_statements'),
      valueLabel: FormatHelper.formatMessage('@@and').toUpperCase(),
      value: 'and'
    },
    {
      label: FormatHelper.formatMessage('@@match_any_statement'),
      valueLabel: FormatHelper.formatMessage('@@or').toUpperCase(),
      value: 'or'
    }
  ];

  const updateState = (ruleRows: IRuleRow[], changeType: string) => {
    if (context?.onChange) {
      context.onChange(props.cardIndex, { ruleRows: ruleRows }, changeType);
    }
  };

  const handleFieldNameChange = (e) => {
    const field = e?.value;
    const defaultOperator = e?.defaultOperator;
    const ruleRows = [...context.ruleRows];

    // update the state only if it changed.
    if (field !== ruleRows[props.rowIndex].field) {
      ruleRows[props.rowIndex].field = field;
      ruleRows[props.rowIndex].operator = defaultOperator ?? null;
      ruleRows[props.rowIndex].values = [];

      updateState(ruleRows, RulesetActions.FIELD_NAME_CHANGE);
    }
  };

  const handleOperatorChange = (e) => {
    const operator = e?.value;
    const ruleRows = [...context.ruleRows];

    // update the state only if it changed.
    if (operator !== ruleRows[props.rowIndex].operator) {
      ruleRows[props.rowIndex].operator = operator;
      ruleRows[props.rowIndex].values = [];

      updateState(ruleRows, RulesetActions.OPERATOR_CHANGE);
    }
  };

  const handleFieldValueChange = (values) => {
    const ruleRows = [...context.ruleRows];
    ruleRows[props.rowIndex].values = values;

    updateState(ruleRows, RulesetActions.FIELD_VALUE_CHANGE);
  };

  const handleJoinOperatorChange = (e) => {
    const joinOperator = e?.value;
    const ruleRows = [...context.ruleRows];

    // update the state only if it changed.
    if (joinOperator !== ruleRows[props.rowIndex].joinOperator) {
      ruleRows[props.rowIndex].joinOperator = joinOperator;

      updateState(ruleRows, RulesetActions.FIELD_JOIN_OPERATOR_CHANGE);
    }
  };

  const handleFetchValueOptions = (selectValue, prevOptions) => {
    if (context?.onFetchValueOptions) {
      return context.onFetchValueOptions(props.field, selectValue, prevOptions);
    }
  };

  const resetModal = () => {
    setShowModal(false);
  };

  const deleteRow = () => {
    // Remove row from array
    if (context) {
      const ruleRows = [...context.ruleRows];
      ruleRows.splice(props.rowIndex, 1);

      updateState(ruleRows, RulesetActions.DELETE_ROW);
    }

    resetModal();
  };

  const deleteRuleRow = () => {
    if (!props.field && !props.operator) {
      deleteRow();
    }
    else {
      setShowModal(true);
    }
  };

  const field = props.fieldNames?.find((column) => {
    return column.value === props.field;
  });
  const showJoinOperator = props.showJoinOperator ?? true;
  const showJoinOperatorComponent = props.showJoinOperatorComponent ?? true;
  const hasOperator = field?.hasOperator ?? true;
  const labelIndex = props.rowIndex + 1;
  const operatorClasses = classNames(
    {
      'rule-row-field': true,
      operator: showJoinOperator && showJoinOperatorComponent
    }
  );

  return (
    <React.Fragment>
      <RuleRowContext.Provider value={{
        cardIndex: props.cardIndex,
        rowIndex: props.rowIndex,
        onFieldValueChange: handleFieldValueChange,
        onFetchValueOptions: handleFetchValueOptions
      }}>
        <div id={`rule-row-${props.cardIndex}-${props.rowIndex}`} className="rule-row" role="group" aria-label={FormatHelper.formatMessage('@@Statement') + ' ' + labelIndex}>
          <div className="rule-row-label">
            <MDFBadge>{labelIndex}</MDFBadge>
          </div>
          <div className="rule-row-field fieldname">
            <MDFValidatedSelectBox
              id={`rule_${props.cardIndex}_row_${props.rowIndex}_fieldName`}
              name={`rule_${props.cardIndex}_row_${props.rowIndex}_fieldName`}
              disabled={props.disabled}
              required={true}
              optionsList={props.fieldNames}
              value={props.field || ''}
              onChange={handleFieldNameChange}
              noOptionsMessage={() => FormatHelper.formatMessage('@@noOptions')}
              placeholder={FormatHelper.formatMessage('@@Select_Attribute')}
              aria-label={FormatHelper.formatMessage('@@Select_Attribute')}
            />
          </div>
          {hasOperator &&
            <>
              <div className={operatorClasses}>
                <MDFValidatedSelectBox
                  id={`rule_${props.cardIndex}_row_${props.rowIndex}_operatorOption`}
                  name={`rule_${props.cardIndex}_row_${props.rowIndex}_operatorOption`}
                  disabled={props.disabled}
                  required={true}
                  optionsList={props.operatorOptions}
                  value={props.operator || ''}
                  onChange={handleOperatorChange}
                  noOptionsMessage={() => FormatHelper.formatMessage('@@noOptions')}
                  placeholder={props.field ? FormatHelper.formatMessage('@@Select_Operator') : FormatHelper.formatMessage('@@Operator')}
                  aria-label={props.field ? FormatHelper.formatMessage('@@Select_Operator') : FormatHelper.formatMessage('@@Operator')}
                />
              </div>
              <div className="rule-row-field fieldvalue">
                <RuleCell
                  {...field}
                  disabled={props.disabled}
                  disabledItems={props.disabledItems}
                  operator={props.operator}
                  values={props.values}
                />
              </div>
            </>
          }
          {showJoinOperator && showJoinOperatorComponent && <div className="rule-row-field join-operator">
            {props.renderRowJoinOperator && <MDFValidatedDropdownList
              id={`rule_${props.cardIndex}_row_${props.rowIndex}_operator`}
              name={`rule_${props.cardIndex}_row_${props.rowIndex}_operator`}
              required={true}
              data={props.joinOperatorOptions || joinOperatorOptions}
              textField={'valueLabel'}
              valueField={'value'}
              value={props.joinOperator}
              onChange={handleJoinOperatorChange}
            />
            }
          </div>

          }
          {showJoinOperator && (!showJoinOperatorComponent) &&
            <div className="rule-row-field join-operator-label">
              {props.renderRowJoinOperator && <span className="operator-label">
                {props.rowJoinOperator}
              </span>}
            </div>
          }
          <div className="rule-row-field removebtn">
            {props.renderDelete && <MDFButton2
              id={`rule-field-removebtn-${props.rowIndex}`}
              variant={'text'}
              buttonStyle="link"
              disabled={props.disabled}
              iconClass="action-delete"
              aria-label={FormatHelper.formatMessage('@@Delete')}
              onClick={deleteRuleRow}>
            </MDFButton2>
            }
          </div>
        </div>
        {showModal &&
          <SdfFocusPane
            pane-type="floating"
            accept-label={FormatHelper.formatMessage('@@Yes')}
            dismiss-label={FormatHelper.formatMessage('@@No')}
            heading={FormatHelper.formatMessage('@@Delete_Rule_Statement_Modal_Header')}
            spacing="normal"
            closeable={true}
            visible={showModal}
            onSdfAccept={deleteRow}
            onSdfDismiss={resetModal}>
            {FormatHelper.formatMessage('@@Delete_Rule_Statement_Modal_Body')}
          </SdfFocusPane>
        }
      </RuleRowContext.Provider>
    </React.Fragment>
  );
};

RuleRow.defaultProps = {
  showJoinOperator: true,
  operatorOptions: []
};

RuleRow.displayName = 'RuleRow';
