import React from 'react';
import { SdfIcon } from '@waypoint/react-components';
// legacy icons
import LegacyCheckIcon from 'adp-react-icons/lib/adp/pay';
import LegacyHomeIcon from 'adp-react-icons/lib/fa/home';

export const CheckIcon = () => {
  const isADPUnified = !window['isLegacyAppShell'];

  if (isADPUnified) {
    return <SdfIcon icon="brand-payments" />;
  }
  else {
    return <LegacyCheckIcon />;
  }
};

export const HomeIcon = () => {
  const isADPUnified = !window['isLegacyAppShell'];

  if (isADPUnified) {
    return <SdfIcon icon="home" />;
  }
  else {
    return <LegacyHomeIcon />;
  }
};
