// The flag names are given to us from outside, so we can't enforce our lint rules on them.
export const flagTranslations = {
  'en-ca': {
    country_US: 'USA - United States',
    country_CA: 'CAN - Canada',
    country_AD: 'AND - Andorra',
    country_AE: 'ARE - United Arab Emirates',
    country_AF: 'AFG - Afghanistan',
    country_AG: 'ATG - Antigua and Barbuda',
    country_AI: 'AIA - Anguilla',
    country_AL: 'ALB - Albania',
    country_AM: 'ARM - Armenia',
    country_AN: 'ANT - Netherlands Antilles',
    country_AO: 'AGO - Angola',
    country_AQ: 'ATA - Antarctica',
    country_AR: 'ARG - Argentina',
    country_AS: 'ASM - American Samoa',
    country_AT: 'AUT - Austria',
    country_AU: 'AUS - Australia',
    country_AW: 'ABW - Aruba',
    country_AX: 'ALA - Aland Islands',
    country_AZ: 'AZE - Azerbaijan',
    country_BA: 'BIH - Bosnia and Herzegovina',
    country_BB: 'BRB - Barbados',
    country_BD: 'BGD - Bangladesh',
    country_BE: 'BEL - Belgium',
    country_BF: 'BFA - Burkina Faso',
    country_BG: 'BGR - Bulgaria',
    country_BH: 'BHR - Bahrain',
    country_BI: 'BDI - Burundi',
    country_BJ: 'BEN - Benin',
    country_BM: 'BMU - Bermuda',
    country_BN: 'BRN - Brunei Darussalam',
    country_BO: 'BOL - Bolivia',
    country_BR: 'BRA - Brazil',
    country_BS: 'BHS - Bahamas',
    country_BT: 'BTN - Bhutan',
    country_BV: 'BVT - Bouvet Island',
    country_BW: 'BWA - Botswana',
    country_BY: 'BLR - Belarus',
    country_BZ: 'BLZ - Belize',
    country_CC: 'CCK - Cocos (Keeling) Islands',
    country_CD: 'COG - Congo the Democratic Rep',
    country_CF: 'RCA - Central African Republic',
    country_CG: 'COG - Congo',
    country_CH: 'CHE - Switzerland',
    country_CI: 'CIV - Cote D\'ivoire',
    country_CK: 'COK - Cook Islands',
    country_CL: 'CHL - Chile',
    country_CM: 'CMR - Cameroon',
    country_CN: 'CHN - China',
    country_CO: 'COL - Colombia',
    country_CR: 'CRI - Costa Rica',
    country_CU: 'CUB - Cuba',
    country_CV: 'CPV - Cape Verde',
    country_CX: 'CXR - Christmas Island',
    country_CY: 'CYP - Cyprus',
    country_CZ: 'CZE - Czech Republic',
    country_DE: 'DEU - Germany',
    country_DJ: 'DJI - Djibouti',
    country_DK: 'DNK - Denmark',
    country_DM: 'DMA - Dominica',
    country_DO: 'DOM - Dominican Republic',
    country_DZ: 'DZA - Algeria',
    country_EC: 'ECU - Ecuador',
    country_EE: 'EST - Estonia',
    country_EG: 'EGY - Egypt',
    country_EH: 'ESH - Western Sahara',
    country_ER: 'ERI - Eritrea',
    country_ES: 'ESP - Spain',
    country_ET: 'ETH - Ethiopia',
    country_FI: 'FIN - Finland',
    country_FJ: 'FJI - Fiji',
    country_FK: 'FLK - Falkland Islands (Malvinas)',
    country_FM: 'FSM - Micronesia',
    country_FO: 'FRO - Faroe Islands',
    country_FR: 'FRA - France',
    country_GA: 'GAB - Gabon',
    country_GB: 'GBR - United Kingdom',
    country_GD: 'GRD - Grenada',
    country_GE: 'GEO - Georgia',
    country_GF: 'GUF - French Guiana',
    country_GH: 'GHA - Ghana',
    country_GI: 'GIB - Gibraltar',
    country_GL: 'GRL - Greenland',
    country_GM: 'WAG - Gambia',
    country_GN: 'GIN - Guinea',
    country_GP: 'GLP - Guadeloupe',
    country_GQ: 'GNQ - Equatorial Guinea',
    country_GR: 'GRC - Greece',
    country_GT: 'GTM - Guatemala',
    country_GU: 'GUM - Guam',
    country_GW: 'GNB - Guinea-Bissau',
    country_GY: 'GUY - Guyana',
    country_HK: 'HKG - Hong Kong',
    country_HM: 'HMD - Heard Island McDonald Islands',
    country_HN: 'HND - Honduras',
    country_HR: 'HRV - Croatia',
    country_HT: 'HTI - Haiti',
    country_HU: 'HUN - Hungary',
    country_ID: 'IDN - Indonesia',
    country_IE: 'IRL - Ireland',
    country_IL: 'ISR - Israel',
    country_IM: 'IMN - Isle of Man',
    country_IN: 'IND - India',
    country_IO: 'IOT - British Indian Ocean Terr',
    country_IQ: 'IRQ - Iraq',
    country_IR: 'IRN - Iran (Islamic Republic Of)',
    country_IS: 'ISL - Iceland',
    country_IT: 'ITA - Italy',
    country_JE: 'JEY - Jersey',
    country_JM: 'JAM - Jamaica',
    country_JO: 'JOR - Jordan',
    country_JP: 'JPN - Japan',
    country_KE: 'KEN - Kenya',
    country_KG: 'KGZ - Kyrgyzstan',
    country_KH: 'KHM - Cambodia',
    country_KI: 'KIR - Kiribati',
    country_KM: 'COM - Comoros',
    country_KN: 'KNA - Saint Kitts and Nevis',
    country_KP: 'PRK - N. Korea',
    country_KR: 'KOR - Korea, Republic of',
    country_KW: 'KWT - Kuwait',
    country_KY: 'CYM - Cayman Islands',
    country_KZ: 'KAZ - Kazakhstan',
    country_LA: 'LAO - Laos',
    country_LB: 'LBN - Lebanon',
    country_LC: 'LCA - St. Lucia',
    country_LI: 'LIE - Liechtenstein',
    country_LK: 'LKA - Sri Lanka',
    country_LR: 'LBR - Liberia',
    country_LS: 'LSO - Lesotho',
    country_LT: 'LTU - Lithuania',
    country_LU: 'LUX - Luxembourg',
    country_LV: 'LVA - Latvia',
    country_LY: 'LBY - Libya',
    country_MA: 'MAR - Morocco',
    country_MC: 'MCO - Monaco',
    country_MD: 'MDA - Moldova',
    country_ME: 'MNE - Montenegro',
    country_MG: 'MDG - Madagascar',
    country_MH: 'MHL - Marshall Islands',
    country_MK: 'MKD - Macedonia',
    country_ML: 'MLI - Mali',
    country_MM: 'MMR - Myanmar',
    country_MN: 'MNG - Mongolia',
    country_MO: 'MAC - Macao',
    country_MP: 'MNP - Northern Mariana Islands',
    country_MQ: 'MTQ - Martinique',
    country_MR: 'MRT - Mauritania',
    country_MS: 'MSR - Montserrat',
    country_MT: 'MLT - Malta',
    country_MU: 'MUS - Mauritius',
    country_MV: 'MDV - Maldives',
    country_MW: 'MWI - Malawi',
    country_MX: 'MEX - Mexico',
    country_MY: 'MYS - Malaysia',
    country_MZ: 'MOZ - Mozambique',
    country_NA: 'NAM - Namibia',
    country_NC: 'NCL - New Caledonia',
    country_NE: 'NER - Niger',
    country_NF: 'NFK - Norfolk Island',
    country_NG: 'NGA - Nigeria',
    country_NI: 'NIC - Nicaragua',
    country_NL: 'NLD - Netherlands',
    country_NO: 'NOR - Norway',
    country_NP: 'NPL - Nepal',
    country_NR: 'NRU - Nauru',
    country_NU: 'NIU - Niue',
    country_NZ: 'NZL - New Zealand',
    country_OM: 'OMN - Oman',
    country_PA: 'PAN - Panama',
    country_PE: 'PER - Peru',
    country_PF: 'PYF - French Polynesia',
    country_PG: 'PNG - Papua New Guinea',
    country_PH: 'PHL - Philippines',
    country_PK: 'PAK - Pakistan',
    country_PL: 'POL - Poland',
    country_PM: 'SPM - ST. Pierre and Miquelon',
    country_PN: 'PCN - Pitcairn',
    country_PR: 'PRI - Puerto Rico',
    country_PT: 'PRT - Portugal',
    country_PW: 'PLW - Palau',
    country_PY: 'PRY - Paraguay',
    country_QA: 'QAT - Qatar',
    country_RE: 'REU - Reunion',
    country_RO: 'ROU - Romania',
    country_RS: 'SRB - Serbia',
    country_RU: 'RUS - Russian Federation',
    country_RW: 'RWA - Rwanda',
    country_SA: 'SAU - Saudi Arabia',
    country_SB: 'SLB - Solomon Islands',
    country_SD: 'SDN - Sudan',
    country_SE: 'SWE - Sweden',
    country_SG: 'SGP - Singapore',
    country_SH: 'SHN - St. Helena',
    country_SI: 'SVN - Slovenia',
    country_SJ: 'SJM - Svalbard and Jan Mayen',
    country_SK: 'SVK - Slovakia',
    country_SL: 'SLE - Sierra Leone',
    country_SM: 'SMR - San Marino',
    country_SN: 'SEN - Senegal',
    country_SO: 'SOM - Somalia',
    country_SR: 'SUR - Suriname',
    country_ST: 'STP - Sao Tome and Principe',
    country_SV: 'SLV - EL Salvador',
    country_SY: 'SYR - Syria',
    country_SZ: 'SWZ - Swaziland',
    country_TC: 'TCA - Turks and Caicos Islands',
    country_TD: 'TCD - Chad',
    country_TF: 'ATF - French Southern Territories',
    country_TG: 'TGO - Togo',
    country_TH: 'THA - Thailand',
    country_TJ: 'TJK - Tajikistan',
    country_TK: 'TKL - Tokelau',
    country_TL: 'TLS - Timor-Leste',
    country_TM: 'TMN - Turkmenistan',
    country_TN: 'TUN - Tunisia',
    country_TO: 'TON - Tonga',
    country_TR: 'TUR - Turkey',
    country_TT: 'TTO - Trinidad and Tobago',
    country_TV: 'TUV - Tuvalu',
    country_TW: 'TWN - Taiwan',
    country_TZ: 'TZA - Tanzania',
    country_UA: 'UKR - Ukraine',
    country_UG: 'UGA - Uganda',
    country_UM: 'UMI - US Minor Outlying Islands',
    country_UY: 'URY - Uruguay',
    country_UZ: 'UZB - Uzbekistan',
    country_VA: 'VAT - Vatican City',
    country_VC: 'VCT - ST. Vincent and the Grenadines',
    country_VE: 'VEN - Venezuela',
    country_VG: 'VGB - Virgin Islands (British)',
    country_VI: 'VIR - Virgin Islands, U.S.',
    country_VN: 'VNM - Viet nam',
    country_WF: 'WLF - Wallis and Futuna',
    country_WS: 'WSM - Samoa',
    country_YE: 'YEM - Yemen',
    country_YT: 'MYT - Mayotte',
    country_ZA: 'ZAF - South Africa',
    country_ZM: 'ZMB - Zambia',
    country_ZW: 'ZWE - Zimbabwe'
  },
  'en-us': {
    country_US: 'USA - United States',
    country_CA: 'CAN - Canada',
    country_AD: 'AND - Andorra',
    country_AE: 'ARE - United Arab Emirates',
    country_AF: 'AFG - Afghanistan',
    country_AG: 'ATG - Antigua and Barbuda',
    country_AI: 'AIA - Anguilla',
    country_AL: 'ALB - Albania',
    country_AM: 'ARM - Armenia',
    country_AN: 'ANT - Netherlands Antilles',
    country_AO: 'AGO - Angola',
    country_AQ: 'ATA - Antarctica',
    country_AR: 'ARG - Argentina',
    country_AS: 'ASM - American Samoa',
    country_AT: 'AUT - Austria',
    country_AU: 'AUS - Australia',
    country_AW: 'ABW - Aruba',
    country_AX: 'ALA - Aland Islands',
    country_AZ: 'AZE - Azerbaijan',
    country_BA: 'BIH - Bosnia and Herzegovina',
    country_BB: 'BRB - Barbados',
    country_BD: 'BGD - Bangladesh',
    country_BE: 'BEL - Belgium',
    country_BF: 'BFA - Burkina Faso',
    country_BG: 'BGR - Bulgaria',
    country_BH: 'BHR - Bahrain',
    country_BI: 'BDI - Burundi',
    country_BJ: 'BEN - Benin',
    country_BM: 'BMU - Bermuda',
    country_BN: 'BRN - Brunei Darussalam',
    country_BO: 'BOL - Bolivia',
    country_BR: 'BRA - Brazil',
    country_BS: 'BHS - Bahamas',
    country_BT: 'BTN - Bhutan',
    country_BV: 'BVT - Bouvet Island',
    country_BW: 'BWA - Botswana',
    country_BY: 'BLR - Belarus',
    country_BZ: 'BLZ - Belize',
    country_CC: 'CCK - Cocos (Keeling) Islands',
    country_CD: 'COG - Congo the Democratic Rep',
    country_CF: 'RCA - Central African Republic',
    country_CG: 'COG - Congo',
    country_CH: 'CHE - Switzerland',
    country_CI: 'CIV - Cote D\'ivoire',
    country_CK: 'COK - Cook Islands',
    country_CL: 'CHL - Chile',
    country_CM: 'CMR - Cameroon',
    country_CN: 'CHN - China',
    country_CO: 'COL - Colombia',
    country_CR: 'CRI - Costa Rica',
    country_CU: 'CUB - Cuba',
    country_CV: 'CPV - Cape Verde',
    country_CX: 'CXR - Christmas Island',
    country_CY: 'CYP - Cyprus',
    country_CZ: 'CZE - Czech Republic',
    country_DE: 'DEU - Germany',
    country_DJ: 'DJI - Djibouti',
    country_DK: 'DNK - Denmark',
    country_DM: 'DMA - Dominica',
    country_DO: 'DOM - Dominican Republic',
    country_DZ: 'DZA - Algeria',
    country_EC: 'ECU - Ecuador',
    country_EE: 'EST - Estonia',
    country_EG: 'EGY - Egypt',
    country_EH: 'ESH - Western Sahara',
    country_ER: 'ERI - Eritrea',
    country_ES: 'ESP - Spain',
    country_ET: 'ETH - Ethiopia',
    country_FI: 'FIN - Finland',
    country_FJ: 'FJI - Fiji',
    country_FK: 'FLK - Falkland Islands (Malvinas)',
    country_FM: 'FSM - Micronesia',
    country_FO: 'FRO - Faroe Islands',
    country_FR: 'FRA - France',
    country_GA: 'GAB - Gabon',
    country_GB: 'GBR - United Kingdom',
    country_GD: 'GRD - Grenada',
    country_GE: 'GEO - Georgia',
    country_GF: 'GUF - French Guiana',
    country_GH: 'GHA - Ghana',
    country_GI: 'GIB - Gibraltar',
    country_GL: 'GRL - Greenland',
    country_GM: 'WAG - Gambia',
    country_GN: 'GIN - Guinea',
    country_GP: 'GLP - Guadeloupe',
    country_GQ: 'GNQ - Equatorial Guinea',
    country_GR: 'GRC - Greece',
    country_GT: 'GTM - Guatemala',
    country_GU: 'GUM - Guam',
    country_GW: 'GNB - Guinea-Bissau',
    country_GY: 'GUY - Guyana',
    country_HK: 'HKG - Hong Kong',
    country_HM: 'HMD - Heard Island McDonald Islands',
    country_HN: 'HND - Honduras',
    country_HR: 'HRV - Croatia',
    country_HT: 'HTI - Haiti',
    country_HU: 'HUN - Hungary',
    country_ID: 'IDN - Indonesia',
    country_IE: 'IRL - Ireland',
    country_IL: 'ISR - Israel',
    country_IM: 'IMN - Isle of Man',
    country_IN: 'IND - India',
    country_IO: 'IOT - British Indian Ocean Terr',
    country_IQ: 'IRQ - Iraq',
    country_IR: 'IRN - Iran (Islamic Republic Of)',
    country_IS: 'ISL - Iceland',
    country_IT: 'ITA - Italy',
    country_JE: 'JEY - Jersey',
    country_JM: 'JAM - Jamaica',
    country_JO: 'JOR - Jordan',
    country_JP: 'JPN - Japan',
    country_KE: 'KEN - Kenya',
    country_KG: 'KGZ - Kyrgyzstan',
    country_KH: 'KHM - Cambodia',
    country_KI: 'KIR - Kiribati',
    country_KM: 'COM - Comoros',
    country_KN: 'KNA - Saint Kitts and Nevis',
    country_KP: 'PRK - N. Korea',
    country_KR: 'KOR - Korea, Republic of',
    country_KW: 'KWT - Kuwait',
    country_KY: 'CYM - Cayman Islands',
    country_KZ: 'KAZ - Kazakhstan',
    country_LA: 'LAO - Laos',
    country_LB: 'LBN - Lebanon',
    country_LC: 'LCA - St. Lucia',
    country_LI: 'LIE - Liechtenstein',
    country_LK: 'LKA - Sri Lanka',
    country_LR: 'LBR - Liberia',
    country_LS: 'LSO - Lesotho',
    country_LT: 'LTU - Lithuania',
    country_LU: 'LUX - Luxembourg',
    country_LV: 'LVA - Latvia',
    country_LY: 'LBY - Libya',
    country_MA: 'MAR - Morocco',
    country_MC: 'MCO - Monaco',
    country_MD: 'MDA - Moldova',
    country_ME: 'MNE - Montenegro',
    country_MG: 'MDG - Madagascar',
    country_MH: 'MHL - Marshall Islands',
    country_MK: 'MKD - Macedonia',
    country_ML: 'MLI - Mali',
    country_MM: 'MMR - Myanmar',
    country_MN: 'MNG - Mongolia',
    country_MO: 'MAC - Macao',
    country_MP: 'MNP - Northern Mariana Islands',
    country_MQ: 'MTQ - Martinique',
    country_MR: 'MRT - Mauritania',
    country_MS: 'MSR - Montserrat',
    country_MT: 'MLT - Malta',
    country_MU: 'MUS - Mauritius',
    country_MV: 'MDV - Maldives',
    country_MW: 'MWI - Malawi',
    country_MX: 'MEX - Mexico',
    country_MY: 'MYS - Malaysia',
    country_MZ: 'MOZ - Mozambique',
    country_NA: 'NAM - Namibia',
    country_NC: 'NCL - New Caledonia',
    country_NE: 'NER - Niger',
    country_NF: 'NFK - Norfolk Island',
    country_NG: 'NGA - Nigeria',
    country_NI: 'NIC - Nicaragua',
    country_NL: 'NLD - Netherlands',
    country_NO: 'NOR - Norway',
    country_NP: 'NPL - Nepal',
    country_NR: 'NRU - Nauru',
    country_NU: 'NIU - Niue',
    country_NZ: 'NZL - New Zealand',
    country_OM: 'OMN - Oman',
    country_PA: 'PAN - Panama',
    country_PE: 'PER - Peru',
    country_PF: 'PYF - French Polynesia',
    country_PG: 'PNG - Papua New Guinea',
    country_PH: 'PHL - Philippines',
    country_PK: 'PAK - Pakistan',
    country_PL: 'POL - Poland',
    country_PM: 'SPM - ST. Pierre and Miquelon',
    country_PN: 'PCN - Pitcairn',
    country_PR: 'PRI - Puerto Rico',
    country_PT: 'PRT - Portugal',
    country_PW: 'PLW - Palau',
    country_PY: 'PRY - Paraguay',
    country_QA: 'QAT - Qatar',
    country_RE: 'REU - Reunion',
    country_RO: 'ROU - Romania',
    country_RS: 'SRB - Serbia',
    country_RU: 'RUS - Russian Federation',
    country_RW: 'RWA - Rwanda',
    country_SA: 'SAU - Saudi Arabia',
    country_SB: 'SLB - Solomon Islands',
    country_SD: 'SDN - Sudan',
    country_SE: 'SWE - Sweden',
    country_SG: 'SGP - Singapore',
    country_SH: 'SHN - St. Helena',
    country_SI: 'SVN - Slovenia',
    country_SJ: 'SJM - Svalbard and Jan Mayen',
    country_SK: 'SVK - Slovakia',
    country_SL: 'SLE - Sierra Leone',
    country_SM: 'SMR - San Marino',
    country_SN: 'SEN - Senegal',
    country_SO: 'SOM - Somalia',
    country_SR: 'SUR - Suriname',
    country_ST: 'STP - Sao Tome and Principe',
    country_SV: 'SLV - EL Salvador',
    country_SY: 'SYR - Syria',
    country_SZ: 'SWZ - Swaziland',
    country_TC: 'TCA - Turks and Caicos Islands',
    country_TD: 'TCD - Chad',
    country_TF: 'ATF - French Southern Territories',
    country_TG: 'TGO - Togo',
    country_TH: 'THA - Thailand',
    country_TJ: 'TJK - Tajikistan',
    country_TK: 'TKL - Tokelau',
    country_TL: 'TLS - Timor-Leste',
    country_TM: 'TMN - Turkmenistan',
    country_TN: 'TUN - Tunisia',
    country_TO: 'TON - Tonga',
    country_TR: 'TUR - Turkey',
    country_TT: 'TTO - Trinidad and Tobago',
    country_TV: 'TUV - Tuvalu',
    country_TW: 'TWN - Taiwan',
    country_TZ: 'TZA - Tanzania',
    country_UA: 'UKR - Ukraine',
    country_UG: 'UGA - Uganda',
    country_UM: 'UMI - US Minor Outlying Islands',
    country_UY: 'URY - Uruguay',
    country_UZ: 'UZB - Uzbekistan',
    country_VA: 'VAT - Vatican City',
    country_VC: 'VCT - ST. Vincent and the Grenadines',
    country_VE: 'VEN - Venezuela',
    country_VG: 'VGB - Virgin Islands (British)',
    country_VI: 'VIR - Virgin Islands, U.S.',
    country_VN: 'VNM - Viet nam',
    country_WF: 'WLF - Wallis and Futuna',
    country_WS: 'WSM - Samoa',
    country_YE: 'YEM - Yemen',
    country_YT: 'MYT - Mayotte',
    country_ZA: 'ZAF - South Africa',
    country_ZM: 'ZMB - Zambia',
    country_ZW: 'ZWE - Zimbabwe'
  },
  'es-us': {
    country_US: 'USA - EE. UU.',
    country_CA: 'CAN - Canadá',
    country_AD: 'AND - Andorra',
    country_AE: 'ARE - Emiratos Árabes Unidos',
    country_AF: 'AFG - Afganistán',
    country_AG: 'ATG - Antigua y Barbuda',
    country_AI: 'AIA - Anguilla',
    country_AL: 'ALB - Albania',
    country_AM: 'ARM - Armenia',
    country_AN: 'ANT - Antillas Neerlandesas',
    country_AO: 'AGO - Angola',
    country_AQ: 'ATA - Antártida',
    country_AR: 'ARG - Argentina',
    country_AS: 'ASM - Samoa Americana',
    country_AT: 'AUT - Austria',
    country_AU: 'AUS - Australia',
    country_AW: 'ABW - Aruba',
    country_AX: 'ALA - Aland Islands',
    country_AZ: 'AZE - Azerbaiyán',
    country_BA: 'BIH - Bosnia y Herzegovina',
    country_BB: 'BRB - Barbados',
    country_BD: 'BGD - Bangladesh',
    country_BE: 'BEL - Bélgica',
    country_BF: 'BFA - Burkina Faso',
    country_BG: 'BGR - Bulgaria',
    country_BH: 'BHR - Bahrein',
    country_BI: 'BDI - Burundi',
    country_BJ: 'BEN - Benín',
    country_BM: 'BMU - Bermudas',
    country_BN: 'BRN - Brunei Darussalam',
    country_BO: 'BOL - Bolivia',
    country_BR: 'BRA - Brasil',
    country_BS: 'BHS - Bahamas',
    country_BT: 'BTN - Bután',
    country_BV: 'BVT - Isla Bouvet',
    country_BW: 'BWA - Botswana',
    country_BY: 'BLR - Bielorrusia',
    country_BZ: 'BLZ - Belice',
    country_CC: 'CCK - Islas Cocos (Keeling)',
    country_CD: 'COG - Congo (República Democrática del)',
    country_CF: 'RCA - República Centroafricana',
    country_CG: 'COG - Congo',
    country_CH: 'CHE - Suiza',
    country_CI: 'CIV - Costa de Marfil',
    country_CK: 'COK - Islas Cook',
    country_CL: 'CHL - Chile',
    country_CM: 'CMR - Camerún',
    country_CN: 'CHN - China',
    country_CO: 'COL - Colombia',
    country_CR: 'CRI - Costa Rica',
    country_CU: 'CUB - Cuba',
    country_CV: 'CPV - Cabo Verde',
    country_CX: 'CXR - Isla Christmas',
    country_CY: 'CYP - Chipre',
    country_CZ: 'CZE - República Checa',
    country_DE: 'DEU - Alemania',
    country_DJ: 'DJI - Yibuti',
    country_DK: 'DNK - Dinamarca',
    country_DM: 'DMA - Dominica',
    country_DO: 'DOM - República Dominicana',
    country_DZ: 'DZA - Argelia',
    country_EC: 'ECU - Ecuador',
    country_EE: 'EST - Estonia',
    country_EG: 'EGY - Egipto',
    country_EH: 'ESH - Sahara Occidental',
    country_ER: 'ERI - Eritrea',
    country_ES: 'ESP - España',
    country_ET: 'ETH - Etiopía',
    country_FI: 'FIN - Finlandia',
    country_FJ: 'FJI - Fiji',
    country_FK: 'FLK - Islas Malvinas',
    country_FM: 'FSM - Micronesia (Estados Federados de)',
    country_FO: 'FRO - Islas Feroe',
    country_FR: 'FRA - Francia',
    country_GA: 'GAB - Gabón',
    country_GB: 'GBR - Reino Unido',
    country_GD: 'GRD - Granada',
    country_GE: 'GEO - Georgia',
    country_GF: 'GUF - Guayana Francesa',
    country_GH: 'GHA - Gana',
    country_GI: 'GIB - Gibraltar',
    country_GL: 'GRL - Groenlandia',
    country_GM: 'WAG - Gambia',
    country_GN: 'GIN - Guinea',
    country_GP: 'GLP - Guadalupe',
    country_GQ: 'GNQ - Guinea Ecuatorial',
    country_GR: 'GRC - Grecia',
    country_GT: 'GTM - Guatemala',
    country_GU: 'GUM - Guam',
    country_GW: 'GNB - Guinea-Bissau',
    country_GY: 'GUY - Guayana',
    country_HK: 'HKG - Hong Kong',
    country_HM: 'HMD - Isla Heard e Islas McDonald',
    country_HN: 'HND - Honduras',
    country_HR: 'HRV - Croacia',
    country_HT: 'HTI - Haití',
    country_HU: 'HUN - Hungría',
    country_ID: 'IDN - Indonesia',
    country_IE: 'IRL - Irlanda',
    country_IL: 'ISR - Israel',
    country_IM: 'IMN - Isle of Man',
    country_IN: 'IND - India',
    country_IO: 'IOT - Territorio Británico del Océano Índico',
    country_IQ: 'IRQ - Iraq',
    country_IR: 'IRN - Irán (República Islámica de)',
    country_IS: 'ISL - Islandia',
    country_IT: 'ITA - Italia',
    country_JE: 'JEY - Jersey',
    country_JM: 'JAM - Jamaica',
    country_JO: 'JOR - Jordania',
    country_JP: 'JPN - Japón',
    country_KE: 'KEN - Kenia',
    country_KG: 'KGZ - Kirguizistán',
    country_KH: 'KHM - Camboya',
    country_KI: 'KIR - Kiribati',
    country_KM: 'COM - Comoras',
    country_KN: 'KNA - San Cristóbal y Nieves',
    country_KP: 'PRK - Corea (República Popular Democrática de)',
    country_KR: 'KOR - Corea (República de)',
    country_KW: 'KWT - Kuwait',
    country_KY: 'CYM - Islas Caimán',
    country_KZ: 'KAZ - Kazajstán',
    country_LA: 'LAO - República Democrática Popular Lao',
    country_LB: 'LBN - Líbano',
    country_LC: 'LCA - Santa Lucía',
    country_LI: 'LIE - Liechtenstein',
    country_LK: 'LKA - Sri Lanka',
    country_LR: 'LBR - Liberia',
    country_LS: 'LSO - Lesoto',
    country_LT: 'LTU - Lituania',
    country_LU: 'LUX - Luxemburgo',
    country_LV: 'LVA - Letonia',
    country_LY: 'LBY - Yamahiriya Árabe Libia',
    country_MA: 'MAR - Marruecos',
    country_MC: 'MCO - Mónaco',
    country_MD: 'MDA - Moldavia',
    country_ME: 'MNE - Montenegro',
    country_MG: 'MDG - Madagascar',
    country_MH: 'MHL - Islas Marshall',
    country_MK: 'MKD - Macedonia (Antigua República Yugoslava de)',
    country_ML: 'MLI - Malí',
    country_MM: 'MMR - Myanmar',
    country_MN: 'MNG - Mongolia',
    country_MO: 'MAC - Macao',
    country_MP: 'MNP - Islas Marianas del Norte',
    country_MQ: 'MTQ - Martinica',
    country_MR: 'MRT - Mauritania',
    country_MS: 'MSR - Montserrat',
    country_MT: 'MLT - Malta',
    country_MU: 'MUS - Mauricio',
    country_MV: 'MDV - Maldivas',
    country_MW: 'MWI - Malawi',
    country_MX: 'MEX - México',
    country_MY: 'MYS - Malasia',
    country_MZ: 'MOZ - Mozambique',
    country_NA: 'NAM - Namibia',
    country_NC: 'NCL - Nueva Caledonia',
    country_NE: 'NER - Níger',
    country_NF: 'NFK - Isla Norfolk',
    country_NG: 'NGA - Nigeria',
    country_NI: 'NIC - Nicaragua',
    country_NL: 'NLD - Países Bajos',
    country_NO: 'NOR - Noruega',
    country_NP: 'NPL - Nepal',
    country_NR: 'NRU - Nauru',
    country_NU: 'NIU - Niue',
    country_NZ: 'NZL - Nueva Zelanda',
    country_OM: 'OMN - Omán',
    country_PA: 'PAN - Panamá',
    country_PE: 'PER - Perú',
    country_PF: 'PYF - Polinesia Francesa',
    country_PG: 'PNG - Papúa Nueva Guinea',
    country_PH: 'PHL - Filipinas',
    country_PK: 'PAK - Pakistán',
    country_PL: 'POL - Polonia',
    country_PM: 'SPM - San Pedro y Miquelón',
    country_PN: 'PCN - Pitcairn',
    country_PR: 'PRI - Puerto Rico',
    country_PT: 'PRT - Portugal',
    country_PW: 'PLW - Palau',
    country_PY: 'PRY - Paraguay',
    country_QA: 'QAT - Qatar',
    country_RE: 'REU - Reunión',
    country_RO: 'ROU - Rumania',
    country_RS: 'SRB - Serbia',
    country_RU: 'RUS - Federación Rusa',
    country_RW: 'RWA - Ruanda',
    country_SA: 'SAU - Arabia Saudí',
    country_SB: 'SLB - Islas Salomón',
    country_SD: 'SDN - Sudán',
    country_SE: 'SWE - Suecia',
    country_SG: 'SGP - Singapur',
    country_SH: 'SHN - Santa Helena',
    country_SI: 'SVN - Eslovenia',
    country_SJ: 'SJM - Svalbard y Jan Mayen',
    country_SK: 'SVK - Slovakia',
    country_SL: 'SLE - Sierra Leona',
    country_SM: 'SMR - San Marino',
    country_SN: 'SEN - Senegal',
    country_SO: 'SOM - Somalia',
    country_SR: 'SUR - Surinam',
    country_ST: 'STP - Santo Tomé y Príncipe',
    country_SV: 'SLV - El Salvador',
    country_SY: 'SYR - República Árabe Siria',
    country_SZ: 'SWZ - Suazilandia',
    country_TC: 'TCA - Islas Turcas y Caicos',
    country_TD: 'TCD - Chad',
    country_TF: 'ATF - Territorios Australes Franceses',
    country_TG: 'TGO - Togo',
    country_TH: 'THA - Tailandia',
    country_TJ: 'TJK - Tayikistán',
    country_TK: 'TKL - Tokelau',
    country_TL: 'TLS - Timor Oriental',
    country_TM: 'TMN - Turkmenistán',
    country_TN: 'TUN - Túnez',
    country_TO: 'TON - Tonga',
    country_TR: 'TUR - Turquía',
    country_TT: 'TTO - Trinidad y Tobago',
    country_TV: 'TUV - Tuvalu',
    country_TW: 'TWN - Taiwán (Provincia de China)',
    country_TZ: 'TZA - Tanzania (República Unida de)',
    country_UA: 'UKR - Ucrania',
    country_UG: 'UGA - Uganda',
    country_UM: 'UMI - Islas Ultramarinas Menores de Estados Unidos',
    country_UY: 'URY - Uruguay',
    country_UZ: 'UZB - Uzbekistán',
    country_VA: 'VAT - Santa Sede (Estado de la Ciudad del Vaticano)',
    country_VC: 'VCT - San Vicente y las Granadinas',
    country_VE: 'VEN - Venezuela',
    country_VG: 'VGB - Islas Vírgenes',
    country_VI: 'VIR - Virgin Islands, U.S.',
    country_VN: 'VNM - Viet nam',
    country_WF: 'WLF - Wallis y Futuna',
    country_WS: 'WSM - Samoa',
    country_YE: 'YEM - Yemen',
    country_YT: 'MYT - Mayotte',
    country_ZA: 'ZAF - Sudáfrica',
    country_ZM: 'ZMB - Zambia',
    country_ZW: 'ZWE - Zimbabue'
  },
  'fr-ca': {
    country_US: 'USA - États-Unis',
    country_CA: 'CAN - Canada',
    country_AD: 'AND - Andorre',
    country_AE: 'ARE - Émirats Arabes Unis',
    country_AF: 'AFG - Afghanistan',
    country_AG: 'ATG - Antigua Et Barbuda',
    country_AI: 'AIA - Anguilla',
    country_AL: 'ALB - Albanie',
    country_AM: 'ARM - Arménie',
    country_AN: 'ANT - Antilles Néerlandaises',
    country_AO: 'AGO - Angola',
    country_AQ: 'ATA - Antarctique',
    country_AR: 'ARG - Argentine',
    country_AS: 'ASM - Samoa Américaine',
    country_AT: 'AUT - Autriche',
    country_AU: 'AUS - Australie',
    country_AW: 'ABW - Aruba',
    country_AX: 'ALA - Aland Islands',
    country_AZ: 'AZE - Azerbaïdjan',
    country_BA: 'BIH - Bosnie-Herzégovine',
    country_BB: 'BRB - Barbade',
    country_BD: 'BGD - Bangladesh',
    country_BE: 'BEL - Belgique',
    country_BF: 'BFA - Burkina Faso',
    country_BG: 'BGR - Bulgarie',
    country_BH: 'BHR - Bahreïn',
    country_BI: 'BDI - Burundi',
    country_BJ: 'BEN - Bénin',
    country_BM: 'BMU - Bermudes',
    country_BN: 'BRN - Brunei Darussalam',
    country_BO: 'BOL - Bolivie',
    country_BR: 'BRA - Brésil',
    country_BS: 'BHS - Bahamas',
    country_BT: 'BTN - Bhoutan',
    country_BV: 'BVT - Île Bouvet',
    country_BW: 'BWA - Botswana',
    country_BY: 'BLR - Bélarus',
    country_BZ: 'BLZ - Belize',
    country_CC: 'CCK - Îles Cocos (Keeling)',
    country_CD: 'COG - Congo, République Démocratique Du',
    country_CF: 'RCA - République Centrafricaine',
    country_CG: 'COG - Congo',
    country_CH: 'CHE - Suisse',
    country_CI: 'CIV - Côte D\'Ivoire',
    country_CK: 'COK - Îles Cook',
    country_CL: 'CHL - Chili',
    country_CM: 'CMR - Cameroun',
    country_CN: 'CHN - Chine',
    country_CO: 'COL - Colombie',
    country_CR: 'CRI - Costa Rica',
    country_CU: 'CUB - Cuba',
    country_CV: 'CPV - Cap-Vert',
    country_CX: 'CXR - Île Christmas',
    country_CY: 'CYP - Chypre',
    country_CZ: 'CZE - République Tchèque',
    country_DE: 'DEU - Allemagne',
    country_DJ: 'DJI - Djibouti',
    country_DK: 'DNK - Danemark',
    country_DM: 'DMA - Dominique',
    country_DO: 'DOM - République Dominicaine',
    country_DZ: 'DZA - Algérie',
    country_EC: 'ECU - Équateur',
    country_EE: 'EST - Estonie',
    country_EG: 'EGY - Égypte',
    country_EH: 'ESH - Sahara Occidental',
    country_ER: 'ERI - Érythrée',
    country_ES: 'ESP - Espagne',
    country_ET: 'ETH - Éthiopie',
    country_FI: 'FIN - Finlande',
    country_FJ: 'FJI - Fidji',
    country_FK: 'FLK - Îles Malouines (Falkland)',
    country_FM: 'FSM - Micronésie, États Fédérés De',
    country_FO: 'FRO - Îles Féroé',
    country_FR: 'FRA - France',
    country_GA: 'GAB - Gabon',
    country_GB: 'GBR - Royaume-Uni',
    country_GD: 'GRD - Grenade',
    country_GE: 'GEO - Géorgie',
    country_GF: 'GUF - Guyane Française',
    country_GH: 'GHA - Ghana',
    country_GI: 'GIB - Gibraltar',
    country_GL: 'GRL - Groenland',
    country_GM: 'WAG - Gambie',
    country_GN: 'GIN - Guinée',
    country_GP: 'GLP - Guadeloupe',
    country_GQ: 'GNQ - Guinée Équatoriale',
    country_GR: 'GRC - Grèce',
    country_GT: 'GTM - Guatemala',
    country_GU: 'GUM - Guam',
    country_GW: 'GNB - Guinée-Bissau',
    country_GY: 'GUY - Guyana',
    country_HK: 'HKG - Hong Kong',
    country_HM: 'HMD - Îles Heard Et Mcdonald',
    country_HN: 'HND - Honduras',
    country_HR: 'HRV - Croatie',
    country_HT: 'HTI - Haïti',
    country_HU: 'HUN - Hongrie',
    country_ID: 'IDN - Indonésie',
    country_IE: 'IRL - Irlande',
    country_IL: 'ISR - Israël',
    country_IM: 'IMN - Isle of Man',
    country_IN: 'IND - Inde',
    country_IO: 'IOT - Territoire Britannique De L\'Océan Indien',
    country_IQ: 'IRQ - Iraq',
    country_IR: 'IRN - Iran (République Islamique)',
    country_IS: 'ISL - Islande',
    country_IT: 'ITA - Italie',
    country_JE: 'JEY - Jersey',
    country_JM: 'JAM - Jamaïque',
    country_JO: 'JOR - Jordanie',
    country_JP: 'JPN - Japon',
    country_KE: 'KEN - Kenya',
    country_KG: 'KGZ - Kirghizistan',
    country_KH: 'KHM - Cambodge',
    country_KI: 'KIR - Kiribati',
    country_KM: 'COM - Îles Comores',
    country_KN: 'KNA - Saint-Kitts-Et-Nevis',
    country_KP: 'PRK - Corée, République Populaire Démocratique De',
    country_KR: 'KOR - Corée, République De',
    country_KW: 'KWT - Koweït',
    country_KY: 'CYM - Îles Caïmans',
    country_KZ: 'KAZ - Kazakhstan',
    country_LA: 'LAO - République Démocratique Populaire Du Laos',
    country_LB: 'LBN - Liban',
    country_LC: 'LCA - Sainte-Lucie',
    country_LI: 'LIE - Liechtenstein',
    country_LK: 'LKA - Sri Lanka',
    country_LR: 'LBR - Libéria',
    country_LS: 'LSO - Lesotho',
    country_LT: 'LTU - Lituanie',
    country_LU: 'LUX - Luxembourg',
    country_LV: 'LVA - Lettonie',
    country_LY: 'LBY - Jamahiriya Arabe Libyenne',
    country_MA: 'MAR - Maroc',
    country_MC: 'MCO - Monaco',
    country_MD: 'MDA - Moldavie',
    country_ME: 'MNE - Monténégro',
    country_MG: 'MDG - Madagascar',
    country_MH: 'MHL - Îles Marshall',
    country_MK: 'MKD - Macédoine, Ex-République Yougoslave De',
    country_ML: 'MLI - Mali',
    country_MM: 'MMR - Myanmar',
    country_MN: 'MNG - Mongolie',
    country_MO: 'MAC - Macao',
    country_MP: 'MNP - Îles Mariannes Du Nord',
    country_MQ: 'MTQ - Martinique',
    country_MR: 'MRT - Mauritanie',
    country_MS: 'MSR - Montserrat',
    country_MT: 'MLT - Malte',
    country_MU: 'MUS - Maurice',
    country_MV: 'MDV - Maldives',
    country_MW: 'MWI - Malawi',
    country_MX: 'MEX - Mexique',
    country_MY: 'MYS - Malaisie',
    country_MZ: 'MOZ - Mozambique',
    country_NA: 'NAM - Namibie',
    country_NC: 'NCL - Nouvelle-Calédonie',
    country_NE: 'NER - Niger',
    country_NF: 'NFK - Île Norfolk',
    country_NG: 'NGA - Nigeria',
    country_NI: 'NIC - Nicaragua',
    country_NL: 'NLD - Pays-Bas',
    country_NO: 'NOR - Norvège',
    country_NP: 'NPL - Népal',
    country_NR: 'NRU - Nauru',
    country_NU: 'NIU - Nioue',
    country_NZ: 'NZL - Nouvelle-Zélande',
    country_OM: 'OMN - Oman',
    country_PA: 'PAN - Panama',
    country_PE: 'PER - Pérou',
    country_PF: 'PYF - Polynésie Française',
    country_PG: 'PNG - Papouasie-Nouvelle-Guinée',
    country_PH: 'PHL - Philippines',
    country_PK: 'PAK - Pakistan',
    country_PL: 'POL - Pologne',
    country_PM: 'SPM - Saint-Pierre Et Miquelon',
    country_PN: 'PCN - Pitcairn',
    country_PR: 'PRI - Puerto Rico',
    country_PT: 'PRT - Portugal',
    country_PW: 'PLW - Palaos',
    country_PY: 'PRY - Paraguay',
    country_QA: 'QAT - Qatar',
    country_RE: 'REU - Réunion',
    country_RO: 'ROU - Roumanie',
    country_RS: 'SRB - Serbie',
    country_RU: 'RUS - Fédération De Russie',
    country_RW: 'RWA - Rwanda',
    country_SA: 'SAU - Arabie Saoudite',
    country_SB: 'SLB - Îles Salomon',
    country_SD: 'SDN - Soudan',
    country_SE: 'SWE - Suède',
    country_SG: 'SGP - Singapour',
    country_SH: 'SHN - Sainte-Hélène',
    country_SI: 'SVN - Slovénie',
    country_SJ: 'SJM - Svalbard Et Jan Mayen',
    country_SK: 'SVK - Slovaquie',
    country_SL: 'SLE - Sierra Leone',
    country_SM: 'SMR - Saint-Marin',
    country_SN: 'SEN - Sénégal',
    country_SO: 'SOM - Somalie',
    country_SR: 'SUR - Suriname',
    country_ST: 'STP - Sao Tomé-Et-Principe',
    country_SV: 'SLV - El Salvador',
    country_SY: 'SYR - République Arabe Syrienne',
    country_SZ: 'SWZ - Swaziland',
    country_TC: 'TCA - Îles Turks Et Caicos',
    country_TD: 'TCD - Tchad',
    country_TF: 'ATF - Terres Australes Françaises',
    country_TG: 'TGO - Togo',
    country_TH: 'THA - Thaïlande',
    country_TJ: 'TJK - Tadjikistan',
    country_TK: 'TKL - Tokelau',
    country_TL: 'TLS - Timor Oriental',
    country_TM: 'TMN - Turkménistan',
    country_TN: 'TUN - Tunisie',
    country_TO: 'TON - Tonga',
    country_TR: 'TUR - Turquie',
    country_TT: 'TTO - Trinité-Et-Tobago',
    country_TV: 'TUV - Tuvalu',
    country_TW: 'TWN - Taïwan, Province Chinoise',
    country_TZ: 'TZA - Tanzanie, République Unie De',
    country_UA: 'UKR - Ukraine',
    country_UG: 'UGA - Ouganda',
    country_UM: 'UMI - Îles Mineures Éloignées Des États-Unis',
    country_UY: 'URY - Uruguay',
    country_UZ: 'UZB - Ouzbékistan',
    country_VA: 'VAT - Saint-Siège (État De La Cité Du Vatican)',
    country_VC: 'VCT - Saint-Vincent-Et-Les Grenadines',
    country_VE: 'VEN - Venezuela',
    country_VG: 'VGB - Îles Vierges',
    country_VI: 'VIR - Virgin Islands, U.S.',
    country_VN: 'VNM - Viêt Nam',
    country_WF: 'WLF - Wallis-Et-Futuna',
    country_WS: 'WSM - Samoa',
    country_YE: 'YEM - Yémen',
    country_YT: 'MYT - Mayotte',
    country_ZA: 'ZAF - Afrique Du Sud',
    country_ZM: 'ZMB - Zambie',
    country_ZW: 'ZWE - Zimbabwe'
  }
};
