import React from 'react';

export interface IMDFVersoViewProps {
  children: React.ReactNode;
  footerView?: React.ReactNode;
  headerView?: React.ReactNode;
  renderFooter?: boolean;
  renderHeader?: boolean;
  scrollToTop?: boolean;
  onScrollToTop?: () => void;
}

export class MDFVersoView extends React.Component<IMDFVersoViewProps, any> {
  private versoViewBody;

  constructor(props: any) {
    super(props);
  }

  // force the scroll to the top of the verso-view when scrollToTop property is set to true
  // To avoid being called all the time the application will have to set this property to false using onScrollToTop function.
  componentDidUpdate() {
    if (this.props.scrollToTop) {
      const scrollToContent = this.versoViewBody;

      if (scrollToContent?.classList.contains('mdf-verso-view-body')) {
        scrollToContent.scrollTop = 0;
        this.props?.onScrollToTop?.();
      }
    }
  }

  render() {
    const showHeader = this.props.headerView && (this.props.renderHeader !== false);
    const showFooter = this.props.footerView && (this.props.renderFooter !== false);

    return (
      <div className="mdf-verso-view">
        {showHeader && <div className="mdf-verso-view-header">
          {this.props.headerView}
        </div>
        }
        <div ref={(versoViewBody) => this.versoViewBody = versoViewBody} className="mdf-verso-view-body">
          {this.props.children}
        </div>
        {showFooter && <div className="mdf-verso-view-footer">
          {this.props.footerView}
        </div>
        }
      </div>
    );
  }
}
