import React from 'react';
import PropTypes from 'prop-types';
import vdlPrefix from '../util/vdlPrefix';
import classNames from 'classnames';

export interface IAlertNumberIndicatorProps {
  // Number displayed inside the alert indicator.
  alertNumber: number;

  // Custom CSS class applied to the root element of the alert indicator.
  className?: string;
}

export class AlertNumberIndicator extends React.Component<IAlertNumberIndicatorProps, {}> {
  static propTypes = {
    alertNumber: PropTypes.number,
    className: PropTypes.string
  };

  render() {
    const { className, alertNumber } = this.props;

    return (
      <div className={classNames(className, vdlPrefix('alert-number-indicator'))}>
        {alertNumber}
      </div>
    );
  }
}
