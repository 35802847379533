import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { SdfIcon, SdfLink } from '@waypoint/react-components';

export interface IMDFHelpIconProps {
  'aria-label'?: string;
  helpUrl?: string;
}

export function MDFHelpIcon(props: IMDFHelpIconProps) {
  const openHelpModal = () => {
    const parseHelpIdsFromUrl = () => {
      const url = new URL(props.helpUrl, location.origin);

      return {
        helpResourceId: url.searchParams.get('resource'),
        helpComponentId: url.searchParams.get('component')
      };
    };

    const { helpResourceId, helpComponentId } = parseHelpIdsFromUrl();

    window['WFNShell']?.showLegacyHelpDialog(helpResourceId, helpComponentId);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      openHelpModal();
    }
  };

  return (
    <SdfLink
      className="m-1"
      onClick={openHelpModal}
      onKeyDown={(e) => handleKeyDown(e)}
      title={props['aria-label'] || FormatHelper.formatMessage('@@HelpInformation')}
    >
      <SdfIcon icon={'action-help'}/>
    </SdfLink>
  );
}
