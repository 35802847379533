import React, { useEffect, useRef, useState } from 'react';
import { getStepKey, IMDFWizardStep, MDFWizardStep } from './MDFWizardStep';
import { ComponentManager } from '@adp-wfn/mdf-core';
import { MDFBusyIndicator } from '../MDFBusyIndicator';
import { MDFWizardFooter } from './MDFWizardFooter';
import { getParentContainer } from '../../util/DOMHelper';

export interface IMDFWizardContainerProps {
  // add or override any styles
  className?: string;
  // Accepts child of type MDFWizardFooter
  children?: React.ReactNode;
  // It represents active view. If applications are passing viewKey in each step to allow
  // common view for multiple steps then active step viewKey should be passed to currentView
  currentView: string;
  // disable/enable Next button
  isValid?: boolean;
  // Steps array
  steps: IMDFWizardStep[];
  // Expand/collapse left navigation
  hideNav?: boolean;
  // invoke the function when the navigation is collapse/expanded
  onHideNav?: (value) => void;
  // show or hide the collapse button
  showCollapsible?: boolean;
  // invoke the onStepChange function
  onStepChange?: (value) => void;
  // Event to set the step by the application before onStepChange
  onStepChangeRequested?: (value) => void;
  // to show/hide busy indicator on wizard
  isBusy?: boolean;
  // trigger on parent step click
  onExpand?: (value) => void;
}

export const MDFWizardContainer = (props: IMDFWizardContainerProps) => {
  const [content, setContent] = useState(null);
  const [activeStepKey, setActiveStepKey] = useState(null);
  const [isNavCollapsed, setNavCollapsed] = useState(props.hideNav || false);
  const [isLoading, setLoading] = useState(false);
  const [parentContainer, setParentContainer] = useState(null) as any;
  const elementRef = useRef<HTMLDivElement>(null);
  const views = [];
  let footer = null;

  // re-render only when the currentView changes.
  useEffect(() => {
    if (props.currentView) {
      const index = views.findIndex((e) => getStepKey(e) === props.currentView);

      // assign the current step data to the state
      if (index < views.length) {
        setContent(views[index]);
        setActiveStepKey(getStepKey(views[index]));
      }
    }
    else {
      console.error('MDFWizardContainer: There is no valid view name ' + props.currentView);
    }

  }, [props.currentView]);

  // Set parent container after initial rendering to find out if the parent is a slidein.
  useEffect(() => {
    setParentContainer(getParentContainer(elementRef.current));
  }, []);

  useEffect(() => {
    setNavCollapsed(props.hideNav);
  }, [props.hideNav]);

  useEffect(() => {
    setLoading(props.isBusy);
  }, [props.isBusy]);

  React.Children.forEach(props.children, (child: any): any => {
    if (child.type === MDFWizardFooter) {
      footer = child;
    }
    else {
      return null;
    }
  });

  const collectViewSteps = ((steps) => {
    steps.forEach((step) => {
      if (getStepKey(step)) {
        views.push(step);
      }

      if (step.subSteps) {
        collectViewSteps(step.subSteps);
      }
    });
  });

  const onStepClick = (clickedItem) => {
    setContent(clickedItem);
    setActiveStepKey(getStepKey(clickedItem));
  };

  const renderItem = (viewItem) => {
    const CustomView = ComponentManager.getComponent(viewItem);

    return <CustomView />;
  };

  const renderFooter = () => {
    return (
      <MDFWizardFooter
        {...footer.props}
        currentStep={content}
        slideinContainer={parentContainer}
        containerRef={elementRef}
        steps={views}
        isLastStep={activeStepKey === getStepKey(props.steps[props.steps.length - 1])}
        isFirstStep={activeStepKey === getStepKey(props.steps[0])}
        isValid={props.isValid}>
      </MDFWizardFooter>
    );
  };

  collectViewSteps(props.steps);

  return (
    <React.Fragment>
      {isLoading && <React.Fragment><div className="vdl-modal-backdrop fade in"></div> <MDFBusyIndicator /></React.Fragment>}
      <div className={`mdf-wizard-component--container ${props.className} mdf-leftNavContainer`} ref={elementRef}>
        <div className={`mdf-wizard-component--navigation ${isNavCollapsed ? 'mdf-leftNavContainer nav-collapsed' : 'mdf-leftNavContainer nav-expand'}`}>
          {props.steps.map((item, index) => (
            <MDFWizardStep
              key={index}
              {...item}
              stepClick={props.onStepChangeRequested || onStepClick}
              currentView={activeStepKey}
              isLastStep={(props && props.steps.length - 1) === index}
              onStepChange={props.onStepChange}
              onExpand={props.onExpand}>
            </MDFWizardStep>
          ))}
        </div>
        <div className={'mdf-wizard-component--container--content'}>
          {
            content?.pageTitle &&
            <div className="mdf-wizard-component--title">
              <h1>{content?.pageTitle}</h1>
            </div>
          }
          <div className="mdf-wizard-component--content">{content && renderItem(content.viewName)}</div>
        </div>
      </div>
      {renderFooter()}
    </React.Fragment>
  );
};

MDFWizardContainer.defaultProps = {
  showCollapsible: true
};

MDFWizardContainer.displayName = 'MDFWizardContainer';
