import React from 'react';
import PropTypes from 'prop-types';
import { WfnRadioButton } from '@adp-wfn/mdf-wc-react';

export interface IMDFRadioProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (value: any) => void;
  radioGroupValue?: any;
  tabIndex?: number;
  value?: any;
  id?: string;
}

export const MDFRadio = (props: IMDFRadioProps) => {
  const handleSdfChange = (event: CustomEvent) => {
    props?.onChange?.(event.detail.value);
  };

  return (
    <WfnRadioButton {...props} onSdfChange={handleSdfChange}/>
  );
};

MDFRadio.displayName = 'MDFRadio';

MDFRadio.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  radioGroupValue: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  tabIndex: PropTypes.number
};
