import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export type IBannerType = 'success' | 'info' | 'warning' | 'alert' | 'default';

const bannerIcons = {
  success: {
    icon: 'fa fa-check'
  },
  info: {
    icon: 'fa fa-info'
  },
  alert: {
    icon: 'fa fa-share'
  },
  warning: {
    icon: 'fa fa-exclamation'
  },
  defaultIcon: {
    icon: 'fa fa-flag-checkered'
  },
  default: {
    icon: null
  }
};

export interface IWFNXWFNXBannerProps {
  className?: string;
  type: IBannerType;
  icon?: string;
}

export class WFNXBanner extends React.Component<React.PropsWithChildren<IWFNXWFNXBannerProps>, any> {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'alert', 'default']),
    icon: PropTypes.string
  };

  render() {
    const icon = this.props.icon || bannerIcons[this.props.type as string].icon;
    const { className, type } = this.props;

    const bannerClasses = {
      ['wfnx-banner']: true,
      [`wfnx-banner--${type}`]: true
    };

    return (
      <div className={classNames(bannerClasses, className)}>
        <div className="wfnx-banner-container">
          {this.props.children}
          {
            icon &&
            <div className={'wfnx_bannercircle__icon'}>
              <i className={`banner-icon ${icon}`} />
            </div>
          }
        </div>
      </div>
    );
  }
}
