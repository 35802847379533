import { useEffect, useState } from 'react';

export function useMedia(queries, values, defaultValue, isHorizontal) {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mQuery) => mQuery.matches);

    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      if (!isHorizontal) {
        // Event listener callback.
        const handler = () => setValue(getValue);

        // Set a listener for each media query with above handler as callback.
        mediaQueryLists.forEach((mQuery) => mQuery.addListener(handler));

        // Remove listeners on cleanup
        return () => mediaQueryLists.forEach((mQuery) => mQuery.removeListener(handler));
      }
    }, [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
}
