import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import vdlPrefix from '../../util/vdlPrefix';
import classNames from 'classnames';
import { omit } from 'lodash';
import { notify } from '@synerg/vdl-react-components/src/util/widget-helpers';

export type ITabOrientation = 'horizontal' | 'vertical';

export interface ITabProps {
  dataItem?: any;
  selected?: boolean;
  focused?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  role?: string;
  orientation?: ITabOrientation;
  onNavigate?: (direction: string) => void;
  onSelect?: (dataItem: any) => void;
  index: number;
}

export const Tab = (props: ITabProps) => {
  const { className, children, focused, selected, disabled, role, orientation } = props;
  const otherProps = omit(props, Object.keys(Tab.propTypes));
  const tabClasses = {
    [vdlPrefix('tab')]: true,
    [vdlPrefix('tab--focus')]: focused,
    [vdlPrefix('tab--selected')]: selected,
    [vdlPrefix('tab--disabled')]: disabled,
    [vdlPrefix('tab--' + orientation)]: true
  };
  const el = useRef<HTMLLIElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && selected) {
      el.current?.focus();
    }
  }, [selected]);

  const onSelect = () => {
    if (!props.disabled) {
      notify(props.onSelect, props.dataItem);
    }
  };

  const handleArrowKeys = (evt: any) => {
    if (evt.keyCode === 37) {
      props.onNavigate?.('previous');
    }
    else if (evt.keyCode === 39) {
      props.onNavigate?.('next');
    }
  };

  return (
    <li
      ref={el}
      {...otherProps}
      role={role}
      tabIndex={(disabled || !selected) ? -1 : 0}
      data-disabled={disabled}
      aria-selected={!!selected}
      onKeyDown={handleArrowKeys}
      className={classNames(className, tabClasses)}
      onClick={onSelect}
    >
      <div className={vdlPrefix('tab__content')}>
        {children}
      </div>
    </li>
  );
};

Tab.displayName = 'Tab';

Tab.propTypes = {
  dataItem: PropTypes.any,
  selected: PropTypes.bool,
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  role: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  onSelect: PropTypes.func,
  index: PropTypes.number
};

Tab.defaultProps = {
  role: 'tab',
  orientation: 'horizontal',
  index: 0
};
