import React from 'react';

import { ModelHelper, RenderHelper } from '@adp-wfn/mdf-core';
import { Grid } from '../components/Grid/Grid';
import InfiniteGrid from '../components/Grid/InfiniteGrid';
import DraggableGrid from '../components/Grid/DraggableGrid';
import WindowScroll from '../components/Grid/WindowScroll';
import { Composer } from '../util/Composer';

export default function gridRenderer({ viewName, view, model }) {
  const columns = Array.isArray(view.columns) ? view.columns : ModelHelper.resolve(view.columns, model, viewName);
  const rows = Array.isArray(view.rows) ? view.rows : ModelHelper.resolve(view.rows, model, viewName);
  const props = ModelHelper.resolve(view.properties, model, viewName);
  props.columns = columns;
  props.rows = rows;
  props.model = model;
  props.itemVar = view.itemVar || 'item';

  if (view.events) {
    let viewEvents = ModelHelper.resolve(view.events, model, viewName);
    viewEvents = Array.isArray(viewEvents) ? viewEvents : [viewEvents];

    // Adding events to the properties
    RenderHelper.addEventsToProperties(props, model, viewEvents);
  }

  // Resolve the column HeaderLabel as it columns are out of properties object
  columns.forEach((column: any) => {
    column.HeaderLabel = column.properties ? ModelHelper.resolve(column.HeaderLabel, ModelHelper.resolve(column.properties, model), viewName) : ModelHelper.resolve(column.HeaderLabel, model, viewName);
    column.className = ModelHelper.resolve(column.className, model, viewName);
    column.visible = ModelHelper.resolve(column.visible, model, viewName);
    column.showSortingArrows = ModelHelper.resolve(column.showSortingArrows, model, viewName);
    column.sortDirection = ModelHelper.resolve(column.sortDirection, model, viewName);
    column.sortIconClassName = ModelHelper.resolve(column.sortIconClassName, model, viewName);
    column.alignHeader = ModelHelper.resolve(column.alignHeader, model, viewName);
    column.alignColumn = ModelHelper.resolve(column.alignColumn, model, viewName);
  });

  const getWrapperComponents = () => {
    // conditionally add wrapping components to this array as needed
    const components = [];

    if (props.draggable) {
      components.push(<DraggableGrid {...props} />);
    }

    if (props.fetchItems) {
      components.push(<InfiniteGrid {...props} />);
    }

    if (!props.enableScroll) {
      components.push(<WindowScroll {...props} />);
    }

    return components;
  };

  const wrappedCallback = () => (
    (wrappedProps) => <Grid {...wrappedProps} {...props} />
  );

  return (
    // below, we are using "functions as children" (AKA render props) - see https://reactjs.org/docs/render-props.html#using-props-other-than-render
    <Composer components={getWrapperComponents()}>
      {wrappedCallback()}
    </Composer>
  );
}
