import React from 'react';
import { WFNMockShellMMNavCollapsedLevel2Items } from './WFNMockShellMMNavCollapsedLevel2Items';
import { WFNMockShellMMNavExpandedLevel2Items } from './WFNMockShellMMNavExpandedLevel2Items';
import { IWFNMockShellMMLevel1Item } from '../types';
import { IWFNMockShellChange } from '../WFNMockShell';
import { Checkbox } from '@synerg/vdl-react-components';
import { WFNMockShellMMNavLevel2NoItems } from './WFNMockShellMMNavLevel2NoItems';

export interface IWFNMockShellMMNavLevel1ItemProps extends IWFNMockShellMMLevel1Item {
  active: boolean;
  handleTopItemClick: any;
  expanded: boolean;
  level1ItemIndex: number;
  hasSubItems: boolean;
  setExpanded: any;
  menuDisabled: boolean;
  additionalSubnavMargin: number;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string
}

export const WFNMockShellMMNavLevel1Item = (props: IWFNMockShellMMNavLevel1ItemProps) => {
  const { name, shortName, oid, active, isSelected, renderCheckbox, handleTopItemClick, expanded, setExpanded, hasSubItems, level1ItemIndex, childList, menuDisabled, onWFNShellChange, disableCheckbox, additionalSubnavMargin, editOptionsLabel } = props;
  const level1Item: IWFNMockShellMMLevel1Item = {
    oid,
    name,
    shortName,
    isSelected,
    childList,
    renderCheckbox,
    renderSelectAllUnselectAll: props.renderSelectAllUnselectAll,
    disableCheckbox: props.disableCheckbox,
    disableSelectAll: props.disableSelectAll,
    disableUnselectAll: props.disableUnselectAll
  };
  let topItemClassName = active ? 'wfnmockshell-top-item active-top' : 'wfnmockshell-top-item';

  topItemClassName = (renderCheckbox && !menuDisabled && name.length >= 9) ? name.length >= 15 ? `${topItemClassName} x-large-top-item` : `${topItemClassName} large-top-item` : topItemClassName;
  topItemClassName = (renderCheckbox && !menuDisabled) ? `${topItemClassName} has-checkbox` : topItemClassName;

  const handleLevel1ItemClick = (e) => {
    handleTopItemClick();
    e.stopPropagation();
  };

  const handleTopNavEnablement = (value) => {
    onWFNShellChange({
      item: {
        oid,
        name
      },
      type: 'menuLevel1Checkbox',
      value
    });
  };

  const renderSubItems = () => {
    if (menuDisabled) {
      return null;
    }

    if (!hasSubItems) {
      return <WFNMockShellMMNavLevel2NoItems name={name} additionalSubnavMargin={additionalSubnavMargin} level1ItemIndex={level1ItemIndex} />;
    }

    return expanded ?
      <WFNMockShellMMNavExpandedLevel2Items additionalSubnavMargin={additionalSubnavMargin} setExpanded={setExpanded} level1ItemIndex={level1ItemIndex} childList={childList} onWFNShellChange={onWFNShellChange} level1Item={level1Item} editOptionsLabel={editOptionsLabel} /> :
      <WFNMockShellMMNavCollapsedLevel2Items title={name} setExpanded={setExpanded} level1ItemIndex={level1ItemIndex} childList={childList} onWFNShellChange={onWFNShellChange} level1Item={level1Item} editOptionsLabel={editOptionsLabel} />;
  };

  return (
    <div className={topItemClassName} onClick={handleLevel1ItemClick}>
      <div className="wfnmockshell-top-label-wrapper">
        {(renderCheckbox && !menuDisabled) && <Checkbox onChange={handleTopNavEnablement} checked={isSelected} disabled={disableCheckbox}/>}
        <button className="wfnmockshell-top-item--button" title={name}><i className="wfnmockshell-top-item--icon"/>{shortName || name}</button>
      </div>
      {renderSubItems()}
    </div >
  );
};

WFNMockShellMMNavLevel1Item.displayName = 'WFNMockShellMMNavLevel1Item';
