import React from 'react';
import { DeviceHelper, FormatHelper, generateId, MDFCore } from '@adp-wfn/mdf-core';
import Select, { components, createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { findDOMNode } from 'react-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { uniqBy } from 'lodash';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';
import PropTypes from 'prop-types';

const createOption = (label: string) => ({
  label,
  value: label
});

const customInput = (props) => {
  const innerProps = {
    ...props.innerProps,
    role: 'combobox',
    'aria-expanded': props.selectProps.menuIsOpen,
    'aria-required': props.selectProps.ariaRequired
  };

  return <components.Input {...props} {...innerProps} />;
};

export class MobileSelectBox extends React.Component<any, any> {
  originalOptionsList: any[];

  onChange = (e) => {
    if (MDFCore.shouldLog()) {
      console.warn('MobileSelectBox.onChange(): Entering. this.props.onChange =', this.props.onChange);
    }

    if (this.props.onChange) {
      const targetObject = this.props.options && this.props.options[e.target.selectedOptions[0].getAttribute('data-index')];

      if (targetObject) {
        if (MDFCore.shouldLog()) {
          console.warn('MobileSelectBox.onChange(): Sending targetObject', targetObject);
        }

        this.props.onChange(targetObject);
      }
      else {
        if (MDFCore.shouldLog()) {
          console.warn('MobileSelectBox.onChange(): Sending e.target.value', e.target.value);
        }

        this.props.onChange(e.target.value);
      }
    }
  };

  // return boolean value if the given option exists in the disabledItems.
  isDisabledItem = (option: any, valueField?: string): boolean => {
    if (valueField) {
      return this.props.disabledItems.find((element) => element[valueField] === option[valueField]) !== null;
    }

    return this.props.disabledItems.find((element) => element === option) !== null;
  };

  render() {
    if (MDFCore.shouldLog()) {
      console.warn('MobileSelectBox.render(): Entering. this.props =', this.props);
    }

    let classNames = 'mdf-mobile-dropdownlist__input-container vdl-dropdown-list__input-container';

    if (this.props.disabled) {
      classNames += ' mdf-mobile-dropdownlist--disabled';
    }

    // set the selected value for dropdowns that have text/value field or with just option
    const setSelected = (option) => {
      let selectedValue = false;

      if (this.props.value === null || this.props.value === undefined || option === null || option === undefined) {
        return selectedValue;
      }

      const value = typeof this.props.value === 'string' ? this.props.value : this.props.value['value'];

      if (option['value']?.toLowerCase() === value.toLowerCase()) {
        selectedValue = true;
      }

      return selectedValue;
    };

    // constructing the list of options to appear
    const options = this.props.options.map((option, index) => (
      <option selected={setSelected(option)} key={index} data-index={index} disabled={this.props.disabledItems && this.isDisabledItem(option, 'value')} value={this.props.getOptionValue ? this.props.getOptionValue(option) : option['value']} className="vdl-list__option">
        {this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label}
      </option>
    ));

    return (
      <div className={classNames}>
        <select
          onChange={this.onChange}
          disabled={this.props.disabled}
        >
          {options}
        </select>
      </div>
    );
  }
}

export class MDFSelectBox extends React.Component<any, any> {
  private observer: any;
  private createdValue: any[];
  private menuObserver: any;
  private selectRef: any;
  originalOptionsList: any[];
  instanceId: string;
  useDevicePicker = false;

  static propTypes = Object.assign({
    adaptive: PropTypes.bool
  });

  constructor(props) {
    super(props);
    this.useDevicePicker = this.props.adaptive && DeviceHelper.isMobileDevice();
    // Start with empty state
    this.state = { selectValue: null, options: [], optionsLoaded: false, preventEscape: false, alignRight: false };
    // Copy option references into backup array, if we are not getting options asynchronously
    this.originalOptionsList = this.props.async ? [] : (this.props.optionsList ? this.props.optionsList.slice() : []);
    this.instanceId = (props.instanceId || generateId('instance'));
    this.selectRef = React.createRef();
  }

  static displayName = 'MDFSelectBox';

  componentDidMount() {
    // When async is true and there is a string value provided instead of an object,
    // we need to load the options in order to show the proper value.
    if (this.props.async && MDFSelectBox.isStringValue(this.state.selectValue) && this.props.loadOptions) {
      this.props.loadOptions(this.state.selectValue).then((options) => {
        // When we get the options, we need to set both the options and selectValue state so that
        // getDerivedStateFromProps (which still has the string value) will detect a different value
        // and re-select the right option. Otherwise, getDerivedStateFromProps won't know the state changed.
        const newValue = MDFSelectBox.findOptionByValue(options, this.state.selectValue);
        this.setState({ selectValue: newValue, options, optionsLoaded: true, preventEscape: false });
      });
    }

    // DE497186
    // This code implements dynamic loading of items. This feature is enabled by the use
    // of the onFetchItems property. We do not manage indexes or what items we are displaying,
    // all of that management is left up to the application's redux state to manage. We are
    // simply notifying you that the user has scrolled to the end of the list of options.
    // When an application receives this notification they can add/remove/update the array
    // of options via actions or Redux state and this component will re-render them.
    // When we call this.props.onFetchItems() we pass an object with the following:
    // 'scrollDirection' - UP or DOWN - This is an indicator the user has scrolled to the top
    // or bottom of the list.
    // 'item' - This is what the component thinks is the first item if scrollDirection is UP
    // or what the component thinks is the last item if the scrollDirection is DOWN.
    if (this.props.onFetchItems) {
      const componentNode = findDOMNode(this);
      const THRESHOLD_TOP = 5;
      const THRESHOLD_BOTTOM = 500;
      let scrollDirection = 'DOWN';
      let lastPosition = 0;

      this.observer = new MutationObserver((mutationsList: any) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            for (const node of mutation.addedNodes) {
              const element = node as Element;

              if (element.classList.contains('MDFSelectBox__menu')) {
                for (let i = 0; i < element.children.length; i++) {
                  const child = element.children.item(i);

                  if (child.classList.contains('MDFSelectBox__menu-list')) {
                    child.addEventListener('scroll', () => {

                      if (lastPosition > child.scrollTop) {
                        scrollDirection = 'UP';
                      }
                      else {
                        scrollDirection = 'DOWN';
                      }

                      if (scrollDirection === 'DOWN' && child.scrollTop >= (child.scrollHeight - THRESHOLD_BOTTOM)) {
                        this.props.onFetchItems({
                          direction: scrollDirection,
                          item: this.props.optionsList ? this.props.optionsList[this.props.optionsList.length - 1] : undefined
                        });
                      }
                      else if (scrollDirection === 'UP' && child.scrollTop <= THRESHOLD_TOP) {
                        this.props.onFetchItems({
                          direction: scrollDirection,
                          item: this.props.optionsList ? this.props.optionsList[0] : undefined
                        });
                      }

                      lastPosition = child.scrollTop;
                    });

                    break;
                  }
                }
              }
            }
          }
        }
      });

      this.observer.observe(componentNode, { childList: true });
    }
  }

  componentWillUnmount() {
    if (this.props.onFetchItems) {
      this.observer.disconnect();
    }
  }

  private static isStringValue(value: any) {
    return (typeof value === 'string') ||
      (typeof value === 'number') ||
      (Array.isArray(value) && value.some((item) => (typeof item === 'string' || typeof item === 'number')));
  }

  private static findOptionByValue(options: any[], value: any) {
    return options.filter((item) => item.value === value);
  }

  // Updating the state when the props are changed
  static getDerivedStateFromProps(nextProps: any, currentState: any) {
    const newState: any = {};

    if (nextProps.optionsList) {
      newState.options = nextProps.optionsList;
    }

    if (nextProps.value !== currentState.selectValue) {
      // Allow apps to use strings as values
      newState.selectValue = nextProps.value;

      // Let empty strings reset the value of the component
      if (newState.selectValue === '') {
        newState.selectValue = null;
      }

      // When the value is a string or number, we have to go find the item in the options list and convert the value to the option(s).
      // That process creates an array of the option(s) found that match the string or number.
      if (MDFSelectBox.isStringValue(newState.selectValue)) {
        const options = (newState.options && newState.options.length > 0) ? newState.options : currentState.options;
        let newValue: any[];

        if (Array.isArray(newState.selectValue)) {
          newValue = newState.selectValue
            .map((item) => MDFSelectBox.isStringValue(item) ? MDFSelectBox.findOptionByValue(options, item) : item)
            .reduce((finalArray, item) => {
              if (Array.isArray(item)) {
                item.forEach((subItem) => finalArray.push(subItem));
              }
              else {
                finalArray.push(item);
              }

              return finalArray;
            }, []);
        }
        else {
          newValue = MDFSelectBox.findOptionByValue(options, newState.selectValue);
        }

        // The new value will either be an empty array (which means there is no value),
        // an array of length 1 (a single value) or a longer array (multiple values).
        switch (newValue.length) {
          case 0:
            // Only treat this as no value when async is not true. Otherwise, allow the value to pass to componentDidMount.
            if (!nextProps.async) {
              delete newState.selectValue;
            }
            break;
          case 1:
            newState.selectValue = newValue[0];
            break;
          default:
            newState.selectValue = newValue;
            break;
        }
      }
    }

    // If we loaded options in componentDidMount, remove them. Otherwise, the dropdown ends up empty.
    if (currentState.optionsLoaded) {
      delete newState.options;
      newState.optionsLoaded = false;
    }

    if (Object.keys(newState).length > 0) {
      return newState;
    }
    else {
      return null;
    }
  }

  handleMenuOpen = () => {
    const observeOnscreen = (entries = []) => {
      const { boundingClientRect, intersectionRect } = entries[0];
      const isOnscreen = boundingClientRect.width <= intersectionRect.width;

      this.setState({
        alignRight: !isOnscreen
      });
    };

    setTimeout(() => {
      const menuList = this.selectRef.current?.menuListRef;
      this.menuObserver = new IntersectionObserver(observeOnscreen);

      this.menuObserver.observe(menuList);
    }, 10);
  };

  handleMenuClose = () => {
    this.setState({
      preventEscape: true,
      alignRight: false
    });

    this.menuObserver?.disconnect();
  };

  handleKeyUpCapture = (event: any) => {
    if (event.key === 'Escape' && this.state.preventEscape) {
      event.stopPropagation();

      this.setState({
        preventEscape: false
      });
    }
  };

  handleChange = (selectValue) => {
    if (MDFCore.shouldLog()) {
      console.log('MDFSelectBox.handleChange(): Entering. selectValue =', selectValue);
    }

    const newState: any = { selectValue, preventEscape: false };

    // For combo boxes, restore the options list to its original state since the Creatable adds newly typed values to the
    // options list, and we don't want that behavior.
    if (this.props.selectCombo) {
      newState.options = this.originalOptionsList.slice();
    }

    if (MDFCore.shouldLog()) {
      console.log('MDFSelectBox.handleChange(): Setting state. newState =', newState);
    }

    this.setState(newState);

    if (this.props.onChange) {
      if (MDFCore.shouldLog()) {
        console.log('MDFSelectBox.handleChange(): Calling props.onChange. selectValue =', selectValue);
      }

      this.props.onChange(selectValue);
    }
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });

    if (this.props.inputChange) {
      this.props.inputChange(inputValue);
    }
  };

  handleKeyDown = (event: any) => {
    let { selectValue } = this.state;
    const { inputValue } = this.state;

    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        // SelectValue should be an empty array if it is empty since there is a concat function internally inside the react-select library.
        if (!selectValue) {
          selectValue = [];
        }

        if (this.props.tagsSeparator) {
          // Create tags at a single click (Enter/Tab) for the provided tags separator list of input tags.
          this.createdValue = uniqBy([...selectValue, ...(inputValue.split(this.props.tagsSeparator).map((item) => createOption(item)))], 'label');
        }
        else {
          this.createdValue = [...selectValue, createOption(inputValue)];
        }

        this.setState(
          {
            inputValue: '',
            selectValue: this.createdValue,
            preventEscape: false
          },
          () => this.props.onChange?.(this.createdValue)
        );

        event.preventDefault();
      }
    }
  };

  // For combo boxes, this event handler is invoked whenever the user creates a new item instead of onChange.
  handleCreateOption = (inputValue) => {
    if (this.props.selectCombo && inputValue) {
      this.handleChange({ label: inputValue, value: inputValue });
    }
  };

  // There is an existing bug: FilterOptions prevent create action to CreatableSelect
  // hence ignoring matchFrom 'start' for selectCombo and isMultiSelectCombo
  customFilter = () => {
    const ignoreMatchFrom = (this.props.selectCombo || this.props.isMultiSelectCombo) ? 'any' : this.props.filterConfig.matchFrom;

    return createFilter({
      ignoreCase: this.props.filterConfig.ignoreCase,
      ignoreAccents: this.props.filterConfig.ignoreAccents,
      trim: this.props.filterConfig.trim,
      matchFrom: ignoreMatchFrom
    });
  };

  render() {
    const { placeholder, noResultsText, optionsList, async, selectCombo, promptTextCreator, value, isMultiHasTags, loadOptions, isMultiSelectCombo, id, ...compProps } = this.props;
    const SelectBoxComponent: any = this.useDevicePicker ? MobileSelectBox : Select;
    const placeholderText = placeholder ? placeholder : null;
    const emptyResultsText = noResultsText ? noResultsText : FormatHelper.formatMessage('@@mdfSelectBoxNoResultsText');
    const ariaLabel = resolveAriaProperty('MDFSelectBox', 'aria-label', 'ariaLabel', this.props);

    // When isMultiHasTags is set to true create a textbox with tags added to it instead of being a dropdown.
    const customTextBox = {
      DropdownIndicator: null
    };

    // Only position the dropdown to the right. Vertical position is taken care by the react select.
    const menuPortalStyle = {
      menu: (base) => ({
        ...base,
        ...(this.state.alignRight && { right: 0, position: 'absolute' })
      }),
      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
      noOptionsMessage: (base) => ({ ...base, color: '#262321' }) // gray-900, neutral-darkest
    };

    // Have to re-spread the compProps in order to alter them.
    const passThroughProps = { ...compProps };

    if (passThroughProps.hasOwnProperty('multi')) {
      passThroughProps.isMulti = passThroughProps.multi;
      console.error('The MDFSelectBox property "multi" has been renamed "isMulti". Please update your code now!');
      delete passThroughProps.multi;
    }

    if (passThroughProps.hasOwnProperty('disabled')) {
      passThroughProps.isDisabled = passThroughProps.disabled;
      delete passThroughProps.disabled;
    }

    if (async && !passThroughProps.hasOwnProperty('defaultOptions')) {
      passThroughProps.defaultOptions = true;
    }

    if (!passThroughProps.hasOwnProperty('isClearable')) {
      passThroughProps.isClearable = true;
    }

    if (!passThroughProps.hasOwnProperty('menuPlacement')) {
      passThroughProps.menuPlacement = 'auto';
    }

    passThroughProps.instanceId = this.instanceId;

    if (async && this.props.paginate) {
      return (
        // US1271955 - If async === true and this.props.paginate === true then we use the AsyncPaginate
        // component. This will allow filtering and if the user scrolls to the end of the list it will
        // load more data. Note the loadOptions function returns an object for this component NOT just
        // an array. See https://github.com/vtaits/react-select-async-paginate#usage for details.
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <AsyncPaginate
            {...passThroughProps}
            components={{ Input: customInput }}
            classNamePrefix="MDFSelectBox"
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            loadOptions={loadOptions}
            onChange={this.handleChange}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
            onMenuOpen = {this.handleMenuOpen}
            selectRef={this.selectRef}
          />
        </div>
      );
    }
    else if (async) {
      return (
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <AsyncSelect
            {...passThroughProps}
            components={{ Input: customInput }}
            classNamePrefix="MDFSelectBox"
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            loadOptions={loadOptions}
            onChange={this.handleChange}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
            onMenuOpen = {this.handleMenuOpen}
            ref={this.selectRef}
          />
        </div>
      );
    }
    else if (selectCombo) {
      return (
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <CreatableSelect
            {...passThroughProps}
            components={{ Input: customInput }}
            classNamePrefix="MDFSelectBox"
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            filterOption={this.props.filterConfig ? this.customFilter() : undefined}
            onChange={this.handleChange}
            onCreateOption={this.handleCreateOption}
            options={optionsList}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
          />
        </div>
      );
    }
    else if (isMultiHasTags) {
      return (
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <CreatableSelect
            {...passThroughProps}
            components={isMultiSelectCombo ? { Input: customInput } : customTextBox}
            inputValue={this.state.inputValue}
            isClearable
            isMulti
            menuIsOpen={isMultiSelectCombo ? undefined : false}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            onKeyDown={isMultiSelectCombo ? undefined : this.handleKeyDown}
            classNamePrefix="MDFSelectBox"
            filterOption={this.props.filterConfig ? this.customFilter() : undefined}
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            options={optionsList}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
          />
        </div>
      );
    }
    else if (selectCombo || this.props.isMulti) {
      return (
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <CreatableSelect
            {...passThroughProps}
            components={{ Input: customInput }}
            classNamePrefix="MDFSelectBox"
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            filterOption={this.props.filterConfig ? this.customFilter() : undefined}
            onChange={this.handleChange}
            onCreateOption={this.handleCreateOption}
            options={optionsList}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
            onMenuOpen = {this.handleMenuOpen}
            ref={this.selectRef}
          />
        </div>
      );
    }
    else {
      return (
        <div onKeyUpCapture = {(e) => this.handleKeyUpCapture(e)}>
          <SelectBoxComponent
            {...passThroughProps}
            components={{ Input: customInput }}
            classNamePrefix="MDFSelectBox"
            placeholder={placeholderText}
            noOptionsMessage={() => emptyResultsText}
            filterOption={this.props.filterConfig ? this.customFilter() : undefined}
            onChange={this.handleChange}
            options={optionsList}
            value={this.state.selectValue}
            aria-label={ariaLabel}
            inputId={this.props.id}
            menuPortalTarget={document.body}
            styles={menuPortalStyle}
            onMenuClose = {this.handleMenuClose}
            onMenuOpen = {this.handleMenuOpen}
            ref={this.selectRef}
          />
        </div>
      );
    }
  }
}
