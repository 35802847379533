import React from 'react';
import { StepType } from '../Wizard';

export interface IWFNXWizardViewInStep {
  name: string;
  isValid: boolean;
  isDelegated: boolean;
  isDisabled: boolean;
  labelText: string;
  isHidden: boolean;
}

export interface IWFNXWizardStepData {
  name: string;
  isValid: boolean;
  isDisabled: boolean;
  stepType: StepType;
  shouldRender: boolean;
  canJumpToStep: boolean;
  viewsInStep: IWFNXWizardViewInStep[];
  currentViewInStep: string;
}

export interface IWFNXWizardStepProps {
  isValid: boolean;
  isDisabled: boolean;
  onStepClick: (stepName) => void;
  stepLabel: string;
  stepType: StepType;
  stepIconClass: string;
  stepName: string;
  currentStep: IWFNXWizardStepData;
  canJumpToStep: boolean;
  canJumpToNextStep: boolean;
  showDelegate: boolean;
  viewName: string;
  viewsInStep: IWFNXWizardViewInStep[];
  render: boolean;
  onViewClick: (viewName) => void;
  onDelegateBtnClick: (stepName) => void;
}

/** Returns true if the value passed is a function, false otherwise. */
export const isFunc = (func: any): boolean =>
  typeof func === 'function';

export class WFNXWizardStep extends React.Component<IWFNXWizardStepProps, any> {
  // Check if the currentStep is the stepName and not the childstepName
  private isCurrentStep = (): boolean => this.props.currentStep.name === this.props.stepName;

  // render the StepArrow only for the current Step/ revisit
  private renderStepArrow = (): JSX.Element => this.isCurrentStep ? <div className="wizard-step-arrow" /> : null;

  // Do not render checkmark if the step is currentStep
  private renderCheckMark = (): JSX.Element => this.props.isValid && !this.isCurrentStep() ? <div className="fa fa-check step-checkmark" /> : this.props.isValid === false && <div className="fa fa-exclamation delegated-icon" />;

  private renderDelegateIcon = (): JSX.Element => !this.props.isValid && this.props.showDelegate ? <div className="fa fa-share delegate-icon" onClick={this.handleDelegateBtnClick}></div> : null;

  // Maintaining atleast one stepView and show vertical line
  private renderVerticalLine = (): JSX.Element => {
    // If childStep views are available then apply vertical-line
    const baseClassName = (this.isCurrentStep() && (this.getNumOfViews() <= 1)) ? 'wizard-vertical-line-viewless' : 'wizard-vertical-line';

    // added a prop to notify this is the last completed
    if (this.props.stepType === StepType.Complete) {
      return null;
    }
    else if (this.props.isValid) {
      return <div className={`${baseClassName} valid-vertical-line`} />;
    }
    else if (this.props.canJumpToNextStep && (this.props.canJumpToStep || this.isCurrentStep())) {
      return <div className={`${baseClassName} vertical-line-jumpable`} />;
    }

    return <div className={baseClassName} />;
  };

  private handleStepClassName = (): string => {
    if (this.isCurrentStep()) {
      return 'wizard-step current-step';
    }
    else if (this.props.isValid) {
      return 'wizard-step valid-step';
    }
    else if (this.props.canJumpToStep && !this.props.isDisabled) {
      return 'wizard-step jumpable-step';
    }

    return 'wizard-step';
  };

  private handleStepClick = (e): void => {
    e.preventDefault();

    if (this.isCurrentStep() || this.props.isDisabled || (!this.props.isValid && !this.props.canJumpToStep)) {
      return;
    }

    if (this.props.onStepClick) {
      this.props.onStepClick(this.props.stepName);
    }
  };

  private handleDelegateBtnClick = (e): void => {
    e.preventDefault();

    if (this.props.onDelegateBtnClick) {
      this.props.onDelegateBtnClick(this.props.stepName);
    }
  };

  private getViewInStepByViewName = (viewName): IWFNXWizardViewInStep => {
    for (const viewData of this.props.viewsInStep) {
      if (viewData.name !== viewName) {
        continue;
      }

      return viewData;
    }
  };

  private handleViewInStepIconClassName = (viewName): string => {
    const { isValid } = this.getViewInStepByViewName(viewName);

    const stepIconClassName = 'fa fa-check icon-hidden';

    if (isValid === true) {
      return 'fa fa-check valid';
    }

    if (isValid === false) {
      return 'fa fa-times invalid';
    }

    return stepIconClassName;
  };

  private getNumOfViews = (): number => (
    this.props.viewsInStep.reduce((total, view) => (view.isHidden ? total : total + 1), 0)
  );

  private handleViewInStepClassName = (viewName): string => {
    const isCurrentView = this.isViewCurrentView(viewName);
    const { isDisabled } = this.getViewInStepByViewName(viewName);
    const baseClassName = 'wizard-view-in-step';

    if (isCurrentView) {
      return `${baseClassName} current-view-in-step`;
    }

    if (isDisabled) {
      return `${baseClassName} disabled-view-in-step`;
    }

    return baseClassName;
  };

  private handleViewInStepClick = (viewName) => {
    if (isFunc(this.props.onViewClick)) {
      this.props.onViewClick(viewName);
    }
  };

  private renderViewsInStep = (): JSX.Element => {
    if (!this.isCurrentStep() || !Array.isArray(this.props.viewsInStep)) {
      return null;
    }

    // only show view in steps if there are more than one
    const totalViews = this.getNumOfViews();
    if (totalViews <= 1) {
      return null;
    }

    const viewsInStep = this.props.viewsInStep.map(({ name, labelText, isHidden }) => (
      isHidden ? null : (
        <div className={this.handleViewInStepClassName(name)} onClick={() => this.handleViewInStepClick(name)}>
          <span className={this.handleViewInStepIconClassName(name)} />
          {labelText}
        </div>
      )
    ));

    return <div className="wizard-views-in-step-wrapper">{viewsInStep}</div>;
  };

  private isViewCurrentView = (viewName) => this.props.currentStep.currentViewInStep === viewName;

  render() {
    return (
      <div className={this.handleStepClassName()} onClick={this.handleStepClick}>
        {this.renderVerticalLine()}
        <div className="wizard-step-label-icon-wrapper">
          {this.renderStepArrow()}
          <div className={`${this.props.stepIconClass} wizard-step-icon`}>
            {this.renderCheckMark()}
          </div>
          <div className="wizard-step-label">
            {this.props.stepLabel}
          </div>
          {this.renderDelegateIcon()}
        </div>
        {this.renderViewsInStep()}
      </div>
    );
  }
}
