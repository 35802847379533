const validateCard = (card, setIndex: { currentCardIndex?: number, currentGroupIndex?: number }) => {
  card.isValid = true;
  card.validationMessage = '';

  // validate for required values
  if (!card.ruleRows || card.ruleRows.some((row) => !row.field)) {
    card.isValid = false;
    card.validationMessage = 'Enter all mandatory fields for rule set ' + (setIndex.currentCardIndex);
  }
};

export const arrayMoveMutate = (array, from, to) => {
  array = [...array];
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;
    const [item] = array.splice(from, 1);

    array.splice(endIndex, 0, item);
  }

  return array;
};

export const arrangeDataSetWithGroupCardIndex = (dataset, setIndex: { currentCardIndex: number, currentGroupIndex: number }) => {
  dataset?.data?.forEach((cardOrGroup) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      cardOrGroup.groupIndex = setIndex.currentGroupIndex;
      setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
      arrangeDataSetWithGroupCardIndex(cardOrGroup, setIndex);
    }
    else {
      cardOrGroup.cardIndex = setIndex.currentCardIndex;
      setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
    }
  });
};

export const addNewGroupOrSet = (dataset, spliceIndex, card, setIndex: { currentCardIndex?: number, currentGroupIndex?: number }) => {
  return dataset?.data?.some((cardOrGroup, idx) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      if (setIndex.hasOwnProperty('currentGroupIndex')) {
        if (setIndex.currentGroupIndex === spliceIndex) {
          dataset.data.splice(idx + 1, 0, card);

          return true;
        }
        setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
      }

      return addNewGroupOrSet(cardOrGroup, spliceIndex, card, setIndex);
    }
    else {
      if (setIndex.hasOwnProperty('currentCardIndex')) {
        if (setIndex.currentCardIndex === spliceIndex) {
          dataset.data.splice(idx + 1, 0, card);

          return true;
        }
        setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
      }
    }

    return false;
  });
};

export const deleteGroupOrSet = (dataset, spliceIndex, card, setIndex: { currentCardIndex?: number, currentGroupIndex?: number }) => {
  return dataset?.data?.some((cardOrGroup, idx) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      if (setIndex.hasOwnProperty('currentGroupIndex')) {
        if (setIndex.currentGroupIndex === spliceIndex) {
          if (card) {
            dataset.data.splice(idx, 1, card);
          }
          else {
            dataset.data.splice(idx, 1);
          }

          return true;
        }
        setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
      }

      return deleteGroupOrSet(cardOrGroup, spliceIndex, card, setIndex);
    }
    else {
      if (setIndex.hasOwnProperty('currentCardIndex')) {
        if (setIndex.currentCardIndex === spliceIndex) {
          if (card) {
            dataset.data.splice(idx, 1, card);
          }
          else {
            dataset.data.splice(idx, 1);
          }

          return true;
        }
        setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
      }
    }

    return false;
  });
};

export const editGroupOrSet = (dataset, editIndex, card, setIndex: { currentCardIndex?: number, currentGroupIndex?: number }) => {
  return dataset?.data?.some((cardOrGroup) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      if (setIndex.hasOwnProperty('currentGroupIndex')) {
        if (setIndex.currentGroupIndex === editIndex) {
          cardOrGroup.setJoinOperator = card.setJoinOperator;

          return true;
        }
        setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
      }

      return editGroupOrSet(cardOrGroup, editIndex, card, setIndex);
    }
    else {
      if (setIndex.hasOwnProperty('currentCardIndex')) {
        if (setIndex.currentCardIndex === editIndex) {
          if (card.hasOwnProperty('ruleRows')) {
            cardOrGroup.ruleRows = card.ruleRows;
          }
          if (card.hasOwnProperty('setJoinOperator')) {
            cardOrGroup.setJoinOperator = card.setJoinOperator;
          }
          if (card.hasOwnProperty('rowJoinOperator')) {
            cardOrGroup.rowJoinOperator = card.rowJoinOperator;
          }

          return true;
        }
        setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
      }
    }

    return false;
  });
};

export const moveGroupOrSet = (dataset, cardIndex, upOrDown, setIndex: { currentCardIndex?: number, currentGroupIndex?: number }) => {
  return dataset?.data?.some((cardOrGroup, idx) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      if (setIndex.hasOwnProperty('currentGroupIndex')) {
        if (setIndex.currentGroupIndex === cardIndex) {
          dataset.data = arrayMoveMutate(dataset.data, idx, idx + upOrDown);
          return true;
        }
        setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
      }

      return moveGroupOrSet(cardOrGroup, cardIndex, upOrDown, setIndex);
    }
    else {
      if (setIndex.hasOwnProperty('currentCardIndex')) {
        if (setIndex.currentCardIndex === cardIndex) {
          dataset.data = arrayMoveMutate(dataset.data, idx, idx + upOrDown);
          return true;
        }
        setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
      }
    }

    return false;
  });
};

export const validateRules = (dataset, setIndex: { currentCardIndex?: number }) => {
  dataset.data.forEach((cardOrGroup) => {
    if (cardOrGroup.hasOwnProperty('data')) {
      validateRules(cardOrGroup, setIndex);
      cardOrGroup.isValid = cardOrGroup.data.every((item) => item.isValid);
      cardOrGroup.validationMessage = cardOrGroup.data.filter((item) => !item.isValid).map((item) => item.validationMessage).join(',');
    }
    else {
      validateCard(cardOrGroup, setIndex);
      setIndex.currentCardIndex = setIndex.currentCardIndex + 1;
    }
  });
};
