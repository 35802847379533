import React from 'react';

import { IMDFFieldState } from './FieldState';
import { MDFRequiredField } from './MDFRequiredField';
import { Validations } from './Validations';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { ValidationContainerContext } from './MDFContext';

export function MDFValidatedTextField(Field) {
  const RequiredField = MDFRequiredField(Field);

  return class extends React.Component<any, any> {
    static displayName = `Validated${Field.displayName ?? 'Field'}`;
    static contextType = ValidationContainerContext;
    declare context: React.ContextType<typeof ValidationContainerContext>;

    onValidate = (validState: IMDFFieldState) => {
      if (validState.isValid) {
        validState = this.validateRegExp(this.validateMaxLength(this.validateMinLength(validState)));

        if (validState.isValid && this.props.onValidate) {
          validState = this.props.onValidate(validState);
        }
      }

      return validState;
    };

    validateMaxLength = (validState: IMDFFieldState): IMDFFieldState => {
      if (validState.isValid) {
        const { maxLength, maxInvalidMessage } = this.props;

        if (!Validations.isEmpty(maxLength) && !Validations.isEmpty(validState.value)) {
          // Validate minimum length
          validState.isValid = validState.value.toString().trim().length <= maxLength;
        }

        if (!validState.isValid) {
          validState.validationMessage = maxInvalidMessage ? ` ${maxInvalidMessage}` : ` ${FormatHelper.formatMessage('@@mdfMaxLength', { maxLength: maxLength })}`;
        }
      }

      return validState;
    };

    validateMinLength = (validState: IMDFFieldState): IMDFFieldState => {
      if (validState.isValid) {
        const { minLength, minInvalidMessage } = this.props;

        if (!Validations.isEmpty(minLength)) {
          // Validate minimum length, except if there is no value (the required validation handles that)
          if (Validations.isEmpty(validState.value)) {
            validState.isValid = true;
          }
          else {
            validState.isValid = validState.value.toString().trim().length >= minLength;
          }
        }

        if (!validState.isValid) {
          validState.validationMessage = minInvalidMessage ? ` ${minInvalidMessage}` : ` ${FormatHelper.formatMessage('@@mdfMinLength', { minLength: minLength })}`;
        }
      }

      return validState;
    };

    validateRegExp = (validState: IMDFFieldState): IMDFFieldState => {
      if (validState.isValid) {
        const { regExp, isValidIfRegexMatches } = this.props;
        let validRegExp: boolean;

        // Depending on the user preferences, an error will be thrown if a regex match occurs.
        if (isValidIfRegexMatches !== false) {
          validRegExp = (new RegExp(regExp).test(validState.value)) ? true : false;
        }
        else {
          validRegExp = (new RegExp(regExp).test(validState.value)) ? false : true;
        }

        // Validate Regular Expression
        if (regExp && !Validations.isEmpty(validState.value)) {
          validState.isValid = ((validState.value === '') || validRegExp);
        }

        if (!validState.isValid) {
          validState.validationMessage = this.props.regExInvalidMessage ? ` ${this.props.regExInvalidMessage}` : ` ${FormatHelper.formatMessage('@@mdfFailedRegEx')}`;
        }
      }

      return validState;
    };

    render() {
      // The unused values here are just to remove them from the properties passed down the chain.
      const {
        children,
        maxLength,
        minLength,
        regExp,
        onValidate,
        regExInvalidMessage,
        maxInvalidMessage,
        minInvalidMessage,
        ...passDownProps
      } = this.props;

      return <RequiredField onValidate={this.onValidate} immediate={this.context.immediate} {...passDownProps}>{children}</RequiredField>;
    }
  };
}
