import React from 'react';
import { get } from 'lodash';
import { GridHelper } from '../../util/GridHelper';
import classNames from 'classnames';

export enum GridSortDirectionKeys {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export interface IGridHeadCellProps {
  column?: any;
  lockedColumns?: number;
  showSortingArrows?: boolean;
  columnIndex?: number;
  sortInfo?: any;
  onSort?: (sortObject: object) => void;
  sortDirection?: string;
  selectColumn?: (columnIndex: number) => void;
  setRowHeights?: (rowIndex: number | string, columnIndex: number, height: number) => void;
  sortIconClassName?: string;
}

export class HeadCell extends React.Component<React.PropsWithChildren<IGridHeadCellProps>, any> {
  private cellElement;
  private height;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.height = this.cellElement.getBoundingClientRect().height;

    if (this.props.setRowHeights && this.props.lockedColumns && this.height) {
      this.props.setRowHeights('header', this.props.columnIndex, this.height);
    }
  }

  handleSelectColumn = () => {
    const { showSortingArrows = false } = this.props.column;

    if (this.props.selectColumn) {
      this.props.selectColumn(this.props.columnIndex);
    }

    if (showSortingArrows) {
      this.handleSortClick();
    }
  };

  handleSortClick = () => {
    const { columnIndex, column, onSort } = this.props;

    if (onSort) {
      onSort({
        columnIndex: columnIndex,
        HeaderLabel: column.HeaderLabel,
        DefaultDirection: this.props.column.sortDirection || ''
      });
    }
  };

  render() {
    const style = GridHelper.getFlexStyles(this.props.column);
    const cellTitle = typeof this.props.column.HeaderLabel === 'string' ? this.props.column.HeaderLabel : '';
    const columnClassName = this.props.column.className || get(this.props.column, 'custom.properties.className', '');
    const { showSortingArrows = false } = this.props.column;
    const { resizable } = this.props.column;
    const { previousSelectedColumn, sortDirection } = this.props.sortInfo;
    const isSortUp = ((this.props.columnIndex === previousSelectedColumn) && (sortDirection === GridSortDirectionKeys.ASCENDING));
    const isSortDown = ((this.props.columnIndex === previousSelectedColumn) && (sortDirection === GridSortDirectionKeys.DESCENDING));
    let alignHeader;
    let alignColumn;

    if (this.props.column && this.props.column['alignHeader']) {
      alignHeader = this.props.column['alignHeader'];
    }
    else if (this.props.column && this.props.column['alignColumn']) {
      alignColumn = this.props.column['alignColumn'];
    }

    const classes = classNames(
      {
        'vdl-col-xs': true,
        'mdf-grid-headerCell': true,
        'sort-enabled': showSortingArrows,
        'mdf-grid-locked-column-min-width': !!this.props.lockedColumns,
        'mdf-grid-resizable-column': !!resizable,
        'justify-content-end': alignHeader === 'right' || alignColumn === 'right',
        'justify-content-center': alignHeader === 'center' || alignColumn === 'center'
      },
      'column-' + this.props.columnIndex,
      columnClassName
    );

    const sortIconClasses = classNames(
      {
        fa: true,
        'fa-sort': true,
        'sort-arrow': true,
        'fa-sort-up': isSortUp,
        'fa-sort-down': isSortDown
      },
      this.props.column.sortIconClassName
    );

    return (
      <div
        style={style}
        className={classes}
        title={cellTitle}
        onClick={this.handleSelectColumn}
        ref={(el) => this.cellElement = el}
      >
        <span className="mdf-grid-headerCell-title">
          {this.props.column.HeaderLabel}
        </span>
        {showSortingArrows &&
          <span className={sortIconClasses}></span>
        }
        {this.props.children}
      </div>
    );
  }
}
