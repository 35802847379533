export function getParentContainer(currentNode: any) {
  if (!currentNode) {
    return null;
  }

  const element = currentNode as HTMLElement;
  const parentNodeClasses = ['vdl-slide-in-content', 'mdf-verso-view-body', 'vdl-mdf-modal-content', 'side-panel-body', 'pane-type-anchored', 'pane-type-floating'];

  if (element.tagName === 'SDF-FOCUS-PANE' || element.tagName === 'WFN-FOCUS-PANE-CLONE' || (element?.classList && parentNodeClasses.findIndex((className) => element.classList?.contains(className)) !== -1)) {
    return element;
  }

  return getParentContainer(currentNode.parentNode);
}

export const highestZIndex = (): number => {
  let zIndex = 0;

  const all = document.getElementsByTagName('*');

  // DOM node lists aren't real Arrays
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < all.length; i++) {
    const element = all[i] as HTMLElement;
    zIndex = parseInt(element.style.zIndex, 10) > zIndex ? parseInt(element.style.zIndex, 10) : zIndex;
  }

  return zIndex;
};

export const isEventBubbledThroughOverlay = (event) => {
  const nodes = event.composedPath?.() || event.nativeEvent?.composedPath?.() || [];

  for (const node of nodes) {
    if (node?.classList?.contains('vdl-overlay-container')) {
      return true;
    }
  }

  return false;
};

export const getLastEnabledCell = () => {
  const lastRow = document.activeElement.parentElement.parentElement.parentElement.lastElementChild as HTMLElement;
  const enabledCells = lastRow.querySelectorAll('div:not([disabled])');

  return enabledCells[enabledCells.length - 1];
};

export const getFirstEnabledCell = () => {
  const firstRow = document.activeElement.parentElement.parentElement.parentElement.firstElementChild as HTMLElement;
  const enabledCells = firstRow.querySelectorAll('div:not([disabled])');

  return enabledCells[0];
};

export const getNextActiveElement = (currentNode: any) => {
  if (!currentNode) {
    return null;
  }

  const element = currentNode.nextElementSibling as HTMLElement;

  if (element && element.hasAttribute('disabled')) {
    return getNextActiveElement(element);
  }
  else {
    return element;
  }
};

export const getNextRangeActiveElement = (currentNode: any) => {
  const element = getNextActiveElement(currentNode);

  if (!element && !currentNode.parentElement.parentElement.parentElement.classList?.contains('vdl-date-range-picker-calendar-second')) {
    // move to next calender
    const secondCalender = currentNode.parentElement.parentElement.parentElement.parentElement.querySelector('.vdl-date-range-picker-calendar-second');
    const firstDayElement = secondCalender.querySelector('.calendar-grid').firstElementChild;

    if (firstDayElement.hasAttribute('disabled')) {
      return getNextActiveElement(firstDayElement);
    }
    else {
      return firstDayElement;
    }
  }
  else {
    return element;
  }
};

export const getPreviousActiveElement = (currentNode: any) => {
  if (!currentNode) {
    return null;
  }

  const element = currentNode.previousElementSibling as HTMLElement;

  if (element && element.hasAttribute('disabled')) {
    return getPreviousActiveElement(element);
  }
  else {
    return element;
  }
};

export const getPreviousRangeActiveElement = (currentNode: any) => {
  const element = getPreviousActiveElement(currentNode);

  if (!element && !currentNode.parentElement.parentElement.parentElement.classList?.contains('vdl-date-range-picker-calendar-first')) {
    // move to previous calender
    const firstCalender = currentNode.parentElement.parentElement.parentElement.parentElement.querySelector('.vdl-date-range-picker-calendar-first');
    const lastDayElement = firstCalender.querySelector('.calendar-grid').nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.lastElementChild;

    if (lastDayElement.hasAttribute('disabled')) {
      return getPreviousActiveElement(lastDayElement);
    }
    else {
      return lastDayElement;
    }
  }
  else {
    return element;
  }
};
