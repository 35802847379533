import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../util/CustomPropTypes';
import vdlPrefix from '../util/vdlPrefix';
import classNames from 'classnames';
import { TimeoutManager } from '@synerg/vdl-react-components/lib/util/timeout-manager';
import { handleBlur, handleFocus, IFocusMixinProps } from '@synerg/vdl-react-components/lib/util/focus-mixin';
import { MDFNumberInput } from './MDFNumberInput';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface INumberState {
  focused?: boolean;
  autoFormat?: boolean;
  value: number;
}

export interface INumberProps {
  id?: string;
  ariaInvalid?: any;
  'aria-invalid'?: any;
  ariaLabel?: any;
  'aria-label'?: any;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
  enableAutoSelect?: boolean;
  value?: number;
  onChange?: (value?: number, isValid?: boolean, validationMessage?: string) => void;
  min?: number;
  max?: number;
  adaptive?: boolean;
  maxLength?: number;
  minimumFractionDigits?: number;
  decimal?: number;
  limitTrailingZeros?: boolean;
  onFormatValue?: any;
  culture?: string;
  format?: any;
  name?: string;
  autoFormat?: any;
  parse?: (str: string, culture?: string) => number;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  tabIndex?: number;
  className?: string;
  immediate?: boolean;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  minValidationMessage?: string;
  maxValidationMessage?: string;
  currencyBoxCallback?: (isValid: boolean, validationMessage: string) => void;
  regExp?: string;
  regExInvalidMessage?: string;
}

export class MDFNumberBox extends React.Component<INumberProps, INumberState> {
  static propTypes = {
    // -- controlled props -----------
    value: PropTypes.number,
    onChange: PropTypes.func,
    // ------------------------------------
    id: PropTypes.string,
    ariaInvalid: PropTypes.any,
    ariaLabel: PropTypes.any,
    ariaDescribedby: PropTypes.string,
    enableAutoSelect: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    precision: PropTypes.number,
    culture: PropTypes.string,
    adaptive: PropTypes.bool,
    format: PropTypes.any,
    autoFormat: PropTypes.any,
    autoComplete: PropTypes.string,
    decimal: PropTypes.number,
    limitTrailingZeros: PropTypes.bool,
    minimumFractionDigits: PropTypes.number,
    formatCurrency: PropTypes.any,
    name: PropTypes.string,
    parse: PropTypes.func,
    autoFocus: PropTypes.bool,
    disabled: CustomPropTypes.disabled,
    readOnly: CustomPropTypes.readOnly,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    immediate: PropTypes.bool,
    minValidationMessage: PropTypes.string,
    maxValidationMessage: PropTypes.string,
    currencyBoxCallback: PropTypes.func,
    regExp: PropTypes.string,
    regExInvalidMessage: PropTypes.string
  };

  private inputRef: MDFNumberInput;
  private timeoutManager = new TimeoutManager();

  // The ADP React Components have a hidden dependency on this name and that it's not truly private
  // so that handleBlur() and handleFocus() can inspect the value.
  // tslint:disable-next-line:variable-name
  _isMounted = false;
  isValid = true;
  validationMessage = '';

  static defaultProps: INumberProps = {
    min: -Infinity,
    max: Infinity,
    enableAutoSelect: false
  };

  state: INumberState = {
    focused: false,
    autoFormat: this.props.autoFormat || false,
    value: this.props.value
  };

  static displayName = 'MDFNumberBox';

  validate = (strVal: string, executeCallBacksOnComponentUpdate = true) => {
    // get the decimalSeperator for the locale
    const delimChar = MDFNumberInput.getlocaleChar(this.props);

    // When decimals other "." is entered replace the decimal character before parsing the value.
    strVal = strVal?.replace(delimChar, '.');

    if (isNaN(parseFloat(strVal))) {
      strVal = '';
    }

    // Parsing string to number before sending it to onChange.
    const numValue = parseFloat(strVal);
    // Check for validation
    this.numberIsValid(numValue);

    // Update the onChange of ValidationField with isValid and validationMessage
    // Onchange that sends the strVal as number to the application.
    // when input value is empty update the value to null
    if (this.props.onChange) {
      const numStrVal = strVal ? Number(strVal) : null;

      // Check if the input value is empty and not required and make it valid.
      // !numStrVal will return false when the value is 0. Add a check to see if the numStriVal is not 0
      if ((numStrVal !== 0 && !numStrVal) && !this.props.required) {
        this.isValid = true;
        this.validationMessage = null;
      }

      // Clears the validation message if the input is empty.
      if ((numStrVal !== 0 && !numStrVal) && this.props.required) {
        this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfRequiredField')}`;
      }

      this.setState({
        value: numStrVal
      });

      // Segregating callback events execution from validate method, validate method gets called from multiple places in the MDFNumberBox component.
      // Modified validate method signature to accept 2 parameters, second parameter is a optional, from componentDidUpdate method when we call the validate method we do not need to execute callback events, we just need to update component internal state.
      if (executeCallBacksOnComponentUpdate) {
        this.props.onChange(numStrVal, this.isValid, this.validationMessage);
        this.updateCurrencyBox();
      }
    }
  };

  // wrapped a div to call the onBlur which was causing issue in currencybox as well
  handleNumberBoxBlur = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleBlur(focusMixinProps, event);
    this.validate(event.target.value);
  };

  updateCurrencyBox = () => {
    // update MDFCurrencyBox isValid and validationMessage values
    if (this.props.currencyBoxCallback) {
      this.props.currencyBoxCallback(this.isValid, this.validationMessage);
    }
  };

  handleNumberBoxFocus = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleFocus(focusMixinProps, event, undefined,
      (/* focused */) => {
        this.focus();
      }
    );

    if (this.props.enableAutoSelect) {
      setTimeout(() => {
        event.target.select();
      }, 3);
    }
  };

  handleNumberBoxClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  isExisty(value) {
    return ((value !== null) && (value !== undefined) && (value !== ''));
  }

  componentDidMount() {
    // When the page loads validate the field.
    // Check for validation and update the isValid and validationMessage on the page load.
    // onload validation only when the value exists.
    if (this.isExisty(this.state.value)) {
      this.numberIsValid(this.state.value);
    }

    this._isMounted = true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }

    return null;
  }

  // Validating the number box when there is a change in min, max and value props dynamically.
  componentDidUpdate(prevProps) {
    if (prevProps.min !== this.props.min || prevProps.max !== this.props.max || prevProps.value !== this.props.value) {
      this.validate(this.isExisty(this.props.value) ? this.props.value.toString() : '', false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.timeoutManager.clearAllTimeouts();
  }

  // This method is used by the ADP React library.
  setTimeout = (key, callback, duration) => {
    if (this._isMounted) {
      return this.timeoutManager.setTimeout(key, callback, duration);
    }
  };

  focus = () => {
    if (this.inputRef) {
      this.inputRef.getInput().focus();
    }

    this.setState({ focused: true });
  };

  change = (val) => {
    this.validate(val?.toString());
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.validate(e.target.value);
    }
  };

  isValidComponent = (_value) => (
    {
      value: this.state.value,
      isValid: this.isValid,
      validationMessage: this.validationMessage
    }
  );

  isInRange = (value, min, max) => {
    const outOfRangeError = ` ${FormatHelper.formatMessage('@@mdfOutOfRange')}`;

    // Checking with null since value and min can be 0
    if (this.isNumber(value) && this.isNumber(min) && this.isNumber(max)) {
      this.validationMessage = (value < min) ? (this.props.minValidationMessage || outOfRangeError) :
        (value > max) ? (this.props.maxValidationMessage || outOfRangeError) : '';
      return ((value >= min) && (value <= max));
    }

    return false;
  };

  numberIsValid = (value: number) => {
    const max = this.isNumber(this.props.max) ? this.props.max : Infinity;
    const min = this.isNumber(this.props.min) ? this.props.min : -Infinity;

    // Set isValid to false when the number entered is not in min/max range.
    if (!isNaN(value)) {
      this.isValid = this.isInRange(value, min, max);

      if (this.isValid && this.props.regExp && !(new RegExp(this.props.regExp).test(value.toString()))) {
        this.isValid = false;
        this.validationMessage = this.props.regExInvalidMessage || ` ${FormatHelper.formatMessage('@@mdfFailedRegEx')}`;
      }
    }

    this.updateCurrencyBox();

    return this.isValid;
  };

  isNumber = (value) => !((value === undefined) || (value === null) || (value === ''));

  render() {
    const { id, className, required } = this.props;
    const ariaInvalid = resolveAriaProperty('MDFNumberBox', 'aria-invalid', 'ariaInvalid', this.props);
    const ariaLabel = resolveAriaProperty('MDFNumberBox', 'aria-label', 'ariaLabel', this.props);
    const val = this.state.value;
    let negativeNumberFlag = false;
    let ariaLabelValue;
    const numberClasses = {
      [vdlPrefix('textbox')]: true,
      [vdlPrefix('textbox--disabled')]: this.props.disabled,
      [vdlPrefix('textbox--readonly')]: this.props.readOnly,
      [vdlPrefix('textbox--required')]: this.props.required,
      [vdlPrefix('validation-error')]: !this.props.disabled && !this.isValid
    };

    let nodeId = id;

    if (!nodeId) {
      nodeId = generateId('mdfNumberBox');
    }

    // Accessibility: if the number is negative, include aria-label to ensure an accurate screenreader announcement.
    if (val && val < 0) {
      negativeNumberFlag = true;
      ariaLabelValue = val * -1;
    }
    const ariaDescribedby = resolveAriaProperty('MDFNumberBox', 'aria-describedby', 'ariaDescribedby', this.props);

    return (
      <MDFNumberInput
        ref={(c) => this.inputRef = c}
        tabIndex={this.props.tabIndex}
        className={classNames(className, numberClasses)}
        placeholder={this.props.placeholder}
        maxLength={this.props.maxLength}
        adaptive={this.props.adaptive}
        value={val}
        formatValue={this.props.onFormatValue}
        decimal={this.props.decimal}
        minimumFractionDigits={this.props.minimumFractionDigits}
        culture={this.props.culture}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autoComplete}
        editing={this.state.focused}
        format={this.props.format}
        autoFormat={(this.props.autoFormat !== false) ? true : this.props.autoFormat}
        parse={this.props.parse}
        name={this.props.name}
        min={this.props.min}
        aria-invalid={ariaInvalid}
        aria-disabled={this.props.disabled}
        aria-readonly={this.props.readOnly}
        aria-label={negativeNumberFlag ? FormatHelper.formatMessage('@@negative') + ' ' + ariaLabelValue : ariaLabel || ''}
        aria-describedby={ariaDescribedby}
        required={required}
        disabled={this.props.disabled}
        id={nodeId}
        readOnly={this.props.readOnly}
        onChange={this.change}
        immediate={this.props.immediate}
        onBlur={this.handleNumberBoxBlur}
        onClick={this.handleNumberBoxClick}
        onFocus={this.handleNumberBoxFocus}
        onKeyPress={this.handleKeyPress}
      />
    );
  }
}
