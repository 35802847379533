import React, { useEffect, useRef, useState } from 'react';
import dragula from 'react-dragula';
import { ListView } from '@synerg/vdl-react-components';
import 'dragula/dist/dragula.min.css';

interface IMDFDraggableListChildProps {
  nodes: any;
  index: any;
}

export interface IMDFDraggableListProps {
  items?: any[];
  itemComponent?: React.ClassType<any, any, any>;
  onChange?: (items) => void;
}

export const MDFDraggableList = (props: IMDFDraggableListProps) => {
  let drake;
  const { items, itemComponent: ItemComponent, onChange } = props;
  const [newItems, setNewItems] = useState(items || []);
  const myStateRef = useRef(newItems);

  const setMyState = (data) => {
    myStateRef.current = data;
    setNewItems(data);
  };

  const removeElementFromLayout = (layoutItem, rowIndex) => layoutItem.splice(rowIndex, 1);

  const insertElementInLayout = (layoutItem, targetRowIndex, item) => layoutItem.splice(targetRowIndex, 0, item);

  const updateDraggableList = (el, target, sibling) => {
    const currentRowIndex = el.getAttribute('data-index');
    let targetRowIndex = target.childNodes.length;
    let draggableItems = [];

    if (el && el.getAttribute('data-parent-index')) {
      const nestedItems = myStateRef.current[el.getAttribute('data-parent-index')].items.map((r) => ({ ...r }));
      const item = removeElementFromLayout(nestedItems, currentRowIndex)[0];
      insertElementInLayout(nestedItems, targetRowIndex, item);

      draggableItems = [...myStateRef.current];
      draggableItems[el.getAttribute('data-parent-index')].items = nestedItems;
    }
    else {
      if (sibling && sibling.getAttribute('data-parent-index')) {
        return;
      }
      if (sibling) {
        targetRowIndex = sibling.getAttribute('data-index');

        if (currentRowIndex < targetRowIndex) {
          targetRowIndex--;
        }
      }
      draggableItems = [...myStateRef.current];
      const item = removeElementFromLayout(draggableItems, currentRowIndex)[0];
      insertElementInLayout(draggableItems, targetRowIndex, item);
    }

    // update the state with the new items.
    setMyState(draggableItems);

    // call onChange to send the updated items.
    onChange?.(draggableItems);

    return draggableItems;
  };

  const initDraggable = () => {
    // Instantiate dragula, tell it which containers hold draggable rows, and which rows are draggable
    drake = dragula([].slice.apply(document.querySelectorAll('.nested')));
    // eslint-disable-next-line no-empty-pattern
    drake.on('drop', (el, target, { }, sibling) => {
      // Ensure nothing happens to the DOM that react doesn't know about.
      drake.cancel(true);
      // we don't need to set this items again since the onChange alreay updated the list
      updateDraggableList(el, target, sibling);
    });
  };

  useEffect(() => {
    initDraggable();

    return () => {
      drake?.destroy();
    };
  }, []);

  useEffect(() => {
    if (items) {
      setMyState(items);
    }
  }, [items]);

  return (
    <div className="container">
      <div className="nested">
        {myStateRef.current && myStateRef.current.map((item, index) => (
          <div key={`drag-item-${index}`} data-index={index}>
            {ItemComponent ? <ItemComponent itemIndex={index} item={item} /> : <ListView status={item.status} selectable={item.selectable} showCheckbox={item.showCheckbox} disabled={item.disabled} >
              {item.label}
              {item.items && <DraggableListChild nodes={item.items} index={index} />}
            </ListView>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export const DraggableListChild = (props: IMDFDraggableListChildProps) => {
  return (
    <div className="nested">
      {props.nodes.map((node, index) => (
        <div key={`drag-child-${index}`} data-parent-index={props.index} data-index={index}>
          <ListView status={node.status} selectable={node.selectable} showCheckbox={node.showCheckbox} disabled={node.disabled} >
            {node.label}
          </ListView>
        </div>
      ))}
    </div>
  );
};

MDFDraggableList.displayName = 'MDFDraggableList';
