import React, { useContext } from 'react';
import { renderResponsiveClass } from './ResponsiveClass';
import classNames from 'classnames';
import { LayoutBoxContext } from '../MDFContext';

export interface ILayoutItemProps {
  className?: string;
  children?: any;
}

export function LayoutItem(props: ILayoutItemProps) {
  const context: any = useContext(LayoutBoxContext);
  const columnCount = context.columnCount;
  const gutterWidth = context.gutterWidth || 0;
  const isHorizontal = context.isHorizontal;

  const { children } = props;
  const numberOfColumn = columnCount && Number(columnCount);

  // for verticalAlignments we only need offsetPadding for each item and need gutterWidth on each column instead.
  return (
    <div className={classNames(`layoutItem ${isHorizontal ? `${renderResponsiveClass(numberOfColumn)} gutterWidth-${gutterWidth}` : `offsetPadding-${gutterWidth}`}  ${(props.className || '')}`)}>
      {children}
    </div>
  );
}
