import Select from 'react-responsive-ui/commonjs/Select';
import React, { useMemo } from 'react';

interface CountrySelectOptions {
  value: string;
  label: string;
  divider?: boolean;
}

interface CountrySelectProps {
  iconComponent: any;

  // `<Select/>` options.
  options: CountrySelectOptions[];

  /**
   * A two-letter country code.
   * Example: "US", "RU", etc.
   */
  value?: string,

  /**
   * Updates the `value`.
   */
  onChange?: () => void,

  // Other `<Select/>` props.
  [x: string]: any
}

const CountrySelect = ({
  value,
  onChange,
  iconComponent,
  options,
  ...rest
}: CountrySelectProps) => {
  const optionsWithIcons = useMemo(() => {
    return options.map((option) => {
      return {
        ...option,
        icon: () => React.createElement(iconComponent, { country: option.value })
      };
    });
  }, [options, iconComponent]);

  return (
    <Select
      {...rest}
      value={value}
      icon
      saveOnIcons
      onChange={onChange}
      options={optionsWithIcons}
    />
  );
};

export default CountrySelect;
