import React from 'react';
import { IWFNMockShellFooterItem } from '../types';
import { IWFNMockShellChange } from '../WFNMockShell';
import { Checkbox } from '@synerg/vdl-react-components';

export interface IWFNMockShellFooterItemProps extends IWFNMockShellFooterItem {
  footerDisabled: boolean;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
}

export const WFNMockShellFooterItem = ({ name, oid, isSelected, renderCheckbox, disableCheckbox, onWFNShellChange, footerDisabled }: IWFNMockShellFooterItemProps) => {
  const handleCheckboxChange = (value) => {
    onWFNShellChange({
      item: {
        oid,
        name
      },
      type: 'footerItemCheckbox',
      value
    });
  };

  return (
    <div className="wfn-footer-item">
      {(renderCheckbox && !footerDisabled) && <Checkbox onChange={handleCheckboxChange} checked={isSelected} disabled={disableCheckbox} />}
      <div className="wfn-footer-item-label">{name}</div>
    </div>
  );
};

WFNMockShellFooterItem.displayName = 'WFNMockShellFooterItem';
