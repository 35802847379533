import React from 'react';

export const TextComponent = (props: any) => {
  if (props.hidden) {
    // The component is hidden, so do not render.
    return null;
  }
  else {
    // The component should be rendered.
    if (props.html) {
      // The component will inject simple HTML.
      const { html, ...rest } = props;
      // Don't allow children if the component is injecting HTML
      delete rest.children;

      return <span {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
    }
    else {
      // The component will render non-HTML text.
      const { children, ...rest } = props;

      return (<span {...rest} >{children}</span>);
    }
  }
};
