import React from 'react';

interface IResizeHandleProps {
  resize?: boolean;
  onMouseDown: (event) => void;
}

export const ResizeHandle = (props: IResizeHandleProps) => (
  <div className={props.resize ? 'cell-resize' : ''} onMouseDown={props.onMouseDown} aria-hidden={true}>
  </div>
);

ResizeHandle.displayName = 'ResizeHandle';
