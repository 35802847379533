import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { Button, StatusIndicator, StatusIndicatorSize, StatusIndicatorType } from '@synerg/vdl-react-components';
import { IMDFDialogOrientation, MDFPopupDialog } from './MDFPopupDialog';

export type IStatusIndicatorStyle = 'banner' | 'inline';

export interface IInlineAlertProps {
  // The four different status of Alerts.
  status: StatusIndicatorType;
  // Whether the Alert is small or large.
  size?: StatusIndicatorSize;
  // Whether the alert is inline or not
  type?: IStatusIndicatorStyle;
  // message for inlineAlert
  message?: React.ReactNode;
  // Additional Class to be applied
  className?: string;
  // Children to render within the popupDialog
  children?: React.ReactNode;
  // show or hideViewDetails button
  showViewDetails?: boolean;
  // Label for buttonLabel
  buttonLabel?: string;
  // Opens the PopupDialog to the right or left. Defaults to 'right' which will open Filter to align on left edge and expand out to the right.
  orientation?: IMDFDialogOrientation;
  // Title for PopupDialog
  popupDialogTitle?: string;
  // callBack function for application to update data on closing of the popup.
  onHideDetails?: () => void;
  // callback when viewDetails button is clicked.
  onShowDetails?: () => void;
  // Property to control the show/hide of a popupDialog
  isOpen?: boolean;
}

export const InlineAlert = (props: IInlineAlertProps) => {
  const { buttonLabel, className, children, isOpen, message, popupDialogTitle, status, size, type, showViewDetails, onHideDetails, onShowDetails, orientation } = props;
  const [isViewDetails, setViewDetails] = useState(showViewDetails);
  const targetRef = useRef(null);
  const [showPopup, setShow] = useState(isOpen || false);

  const inlineAlertClass = {
    ['mdf-inlineAlert']: true,
    [`mdf-inlineAlert--${type}`]: type
  };

  useEffect(() => {
    setViewDetails(showViewDetails);
  }, [showViewDetails]);

  useEffect(() => {
    if (isOpen !== showPopup) {
      setShow(isOpen);
    }
  }, [isOpen]);

  const openViewDetails = () => {
    setShow(true);
    onShowDetails?.();
  };

  const onHidePopupDialog = () => {
    setShow(false);
    onHideDetails?.();
  };

  // Render PopupDialog only when the button or targetRef.current is available.
  const renderViewDetails = () => {
    return (
      <React.Fragment>
        <Button ref={targetRef} buttonStyle="link" onClick={openViewDetails}>
          <span className="font-size-base">
            {buttonLabel || FormatHelper.formatMessage('@@ViewDetails')}
          </span>
        </Button>
        {targetRef.current && <MDFPopupDialog containerClassName={'mdf-inlineAlert-dialog'} title={popupDialogTitle} isOpen={showPopup}
          targetRef={targetRef.current}
          onClose={onHidePopupDialog}
          orientation={orientation}>
          {children}
        </MDFPopupDialog>}
      </React.Fragment >
    );
  };

  return (
    <div className={classNames(inlineAlertClass, className)}>
      <StatusIndicator type={status} size={size}>
        <div className={'mdf-inlineAlert-title'}>
          {message}
        </div>
      </StatusIndicator>
      {isViewDetails && <div className={'mdf-inlineAlert-content'}>
        {renderViewDetails()}
      </div>}
    </div>
  );
};

InlineAlert.displayName = 'InlineAlert';

InlineAlert.PropTypes = {
  status: PropTypes.oneOf(['success', 'info', 'error', 'warning', 'pending']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['banner', 'inline']),
  message: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  showViewDetails: PropTypes.bool,
  buttonLabel: PropTypes.string,
  orientation: PropTypes.oneOf(['left', 'right'])
};

InlineAlert.defaultProps = {
  status: 'success',
  size: 'md',
  type: 'inline',
  showViewDetails: false
};
