import React from 'react';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

interface ICellProps {
  className?: string;
  children?: React.ReactNode;
  column?: number;
  // This is a property MDFGrid / MDFSpreadSheet uses internally and sets on cells for applying focus on selectedCells and it is not intended to be set on view jsons.
  // We need this fake indexing on cells as MDFGrid / MDFSpreadSheet both have ability to skip some columns from rendering this removes the continuity on cell numbering and makes difficult to focus on the next cell if we are missing some columns.
  // fakeIndex will make sure to have continuous numbers on the cell regardless of the visible columns / skipped ones.
  fakeIndex?: number;
  row?: number;
  onClick?: (event) => void;
  onKeyDown?: (event) => void;
  onFocus?: (event) => void;
  width?: number;
  tabIndex?: number;
  title?: string;
  isHeaderCell?: boolean;
  ariaSortDirection?: any;
  editModeOn?: boolean;
  expanded?: boolean;
  id?: string;
  role?: string;
  ariaColIndex?: number;
}

export const Cell = (props: ICellProps) => {

  return (
    <div
      role={props.role || 'gridcell'}
      id={props.id}
      className={props.className}
      unselectable={'on'}
      tabIndex={props.tabIndex}
      data-row={props.row}
      data-column={props.column}
      data-fakeindex={props.fakeIndex}
      style={{ width: props.width }}
      onClick={props.onClick}
      data-edit={props.editModeOn && 'on'}
      aria-expanded={props.expanded}
      aria-sort={props.ariaSortDirection}
      aria-colindex={props.ariaColIndex}
      onKeyDown={props.onKeyDown}
      onFocus={props.onFocus}>
      {props.children}
    </div>
  );
};

Cell.displayName = 'Cell';

interface ICellContentProps {
  ariaDescribedBy?: string;
  'aria-describedby'?: string;
  children?: React.ReactNode;
  width?: number;
}

export const CellContent = (props: ICellContentProps) => {
  const ariaDescribedby = resolveAriaProperty('CellContent', 'aria-describedby', 'ariaDescribedBy', props);

  return <div className="cell-content" aria-describedby={ariaDescribedby} style={{ width: props.width }} role={'presentation'}>{props.children}</div>;
};

CellContent.displayName = 'CellContent';

interface ICompoundCellHeaderProps {
  ariaColIndex?: number;
  children?: React.ReactNode;
  className?: string;
  column: number;
  onClick?: (event) => void;
  onKeyDown?: (event) => void;
  sortable?: boolean;
  sortClass?: string;
  sortOrder?: number;
  subTitleLeft: string;
  subTitleRight: string;
  leftCellId: string;
  rightCellId: string;
  title: string;
  width: number;
  ariaSortDirection?: any;
  fakeIndex?: number;
}

export const CompoundCellHeader = (props: ICompoundCellHeaderProps) => {
  const Title = props.title;
  const Compoundtitle = (typeof props.title === 'function') ? <Title /> : Title;

  return (
    <div className={props.className} role={'none'} data-column={props.column} data-fakeindex={props.fakeIndex} style={{ width: props.width }} tabIndex={0} aria-sort={props.ariaSortDirection} onKeyDown={props.onKeyDown}>
      {props.children}
      <CellContent width={props.width}>
        <div className={'compound-title' + (props.sortable ? ' sort-enabled' : '')} onClick={props.onClick} title={typeof Title === 'string' && Title}>
          {Compoundtitle}
          {props.sortable && <span className={'fa ' + props.sortClass} aria-hidden={true}>
            {((props.sortOrder > 0) && props.sortable) && props.sortOrder}
          </span>}
        </div>
        <div className="compound-subtitle">
          <div title={typeof props.subTitleLeft === 'string' && props.subTitleLeft} role={'columnheader'} aria-colindex={props.ariaColIndex} id={props.leftCellId}><span className="vdl-invisible ariaInvisibleText">{Compoundtitle}:</span>{props.subTitleLeft}</div>
          <div title={typeof props.subTitleRight === 'string' && props.subTitleRight} role={'columnheader'} aria-colindex={props.ariaColIndex + 1} id={props.rightCellId}><span className="vdl-invisible ariaInvisibleText">{Compoundtitle}:</span>{props.subTitleRight}</div>
        </div>
      </CellContent>
    </div>
  );
};

CompoundCellHeader.displayName = 'CompoundCellHeader';

interface ICompoundCellContentProps {
  leftCellId?: string;
  rightCellId?: string;
  leftValue: string;
  rightValue: string;
  leftTitle?: string;
  rightTitle?: string;
  width: number;
  onClick?: (event, position) => void;
  ariaColIndex?: number;
}

export const CompoundCellContent = (props: ICompoundCellContentProps) => (
  <React.Fragment>
    <div className="compound-cell-content" onClick={(event) => props.onClick(event, 'left')} tabIndex={-1} title={props.leftTitle} role={'presentation'}>
      <div className="left" style={{ width: props.width / 2 }} tabIndex={-1} role={'gridcell'} aria-colindex={props.ariaColIndex} aria-describedby={props.leftCellId}>{props.leftValue}</div>
    </div>
    <div className="compound-cell-content" onClick={(event) => props.onClick(event, 'right')} tabIndex={-1} title={props.rightTitle} role={'presentation'}>
      <div className="right" style={{ width: props.width / 2 }} tabIndex={-1} role={'gridcell'} aria-colindex={props.ariaColIndex + 1} aria-describedby={props.rightCellId}>{props.rightValue}</div>
    </div>
  </React.Fragment>
);

CompoundCellContent.displayName = 'CompoundCellContent';
