import React from 'react';

import { Row } from './GridRow';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { GridContext } from '../MDFContext';
import classNames from 'classnames';
import { GridHelper } from '../../util/GridHelper';

export interface IGridBodyProps {
  columnWidths?: any;
  displayHeader?: boolean;
  enableScroll?: boolean;
  lockedGrid?: boolean;
  draggable?: boolean;
  lockedColumns?: number;
  headerHeight?: number;
  noDataMessage?: string;
  items?: object[];
  columns?: object[];
  rows?: object[];
  model?: object[];
  sortInfo?: object[];
  itemVar?: string;
  collectContainers?: (container: HTMLElement) => void;
  handleHover?: (rowIndex: number, isHovering: boolean) => void;
  handleScroll?: (event) => void;
  handleRowClick?: (rowIndex) => void;
  handleResize?: (columnIndex, columnWidth, isLockedGrid) => void;
  setRowHeights?: (rowIndex, columnIndex, height) => void;
  // Application defined function accepts the index of the selected row
  selectRow?: (rowIndex: number) => void;
  // Application defined function accepts the index of the selected column
  selectColumn?: (columnIndex: number) => void;
  collectRows?: (row) => void;
  onScrollAnchorChange?: (index: number, offset: number) => void;
  onSort?: (sortObject: object) => void;
  fetchItems?: (
    startIndex: number,
    count: number
  ) => object[];
}

export class Body extends React.Component<IGridBodyProps, any> {
  private gridBodyWrapper;
  private rowElements: any[] = [];
  private isHeaderRendered = false;

  constructor(props: any) {
    super(props);
  }

  declare context: React.ContextType<typeof GridContext>;
  static contextType = GridContext;

  componentDidMount() {
    if (this.context && this.context.registerScrollPane) {
      this.context.registerScrollPane(this.gridBodyWrapper);
    }
  }

  componentWillUnmount() {
    if (this.context && this.context.unregisterScrollPane) {
      this.context.unregisterScrollPane(this.gridBodyWrapper);
    }
  }

  getRows = () => this.rowElements;

  displayHeader = () => {
    if (this.isHeaderRendered) {
      return false;
    }

    this.isHeaderRendered = true;

    return (this.props.displayHeader !== false) && this.props.enableScroll;
  };

  // Render header row
  headerRow = () => (
    <Row
      head={true}
      setRowHeights={this.props.setRowHeights}
      height={this.props.headerHeight}
      columns={this.props.columns}
      columnWidths={this.props.columnWidths}
      onSort={this.props.onSort}
      sortInfo={this.props.sortInfo}
      selectColumn={this.props.selectColumn}
      lockedGrid={this.props.lockedGrid}
      lockedColumns={this.props.lockedColumns}
      handleResize={this.props.handleResize}
      fetchItems={this.props.fetchItems}
    />
  );

  // Render row
  row = (index, item) => (
    <Row
      ref={(row) => this.rowElements[index] = row}
      collectRows={this.props.collectRows}
      data-grid-index={index}
      key={index}
      lockedGrid={this.props.lockedGrid}
      columns={this.props.columns}
      columnWidths={this.props.columnWidths}
      lockedColumns={this.props.lockedColumns}
      setRowHeights={this.props.setRowHeights}
      selectRow={this.props.selectRow}
      handleHover={this.props.handleHover}
      rows={this.props.rows}
      handleRowClick={this.props.handleRowClick}
      rowIndex={index}
      item={item}
      model={this.props.model}
      itemVar={this.props.itemVar}
      fetchItems={this.props.fetchItems}
      {...this.props}
    />
  );

  renderRows = () => {
    const items = this.props.items || [];
    return (
      <div ref={this.props.collectContainers}>
        {/* conditionally render header here OR in Grid depending on whether enableScroll is true */}
        {this.displayHeader() && this.headerRow()}
        {items.map((item, index) => this.row(index, item))}
        {(!items.length && !this.props.lockedGrid) ? this.renderNoDataMessage() : ''}
      </div>
    );
  };

  renderRowsWithSubheadersAndNoLockedColumns = () => {
    const items = this.props.items || [];
    return items.map((itemGroup, objIndex) => (
      <div key={itemGroup['subheader']} ref={this.props.collectContainers} data-grid-index={objIndex} >
        {<Row key={objIndex} rowIndex={objIndex} item={itemGroup['subheader']} subheader={true} columns={[itemGroup['subheader']]} />}
        {itemGroup['rows'].map((item, itemGroupIndex) => this.row(itemGroupIndex, item))}
      </div>
    ));
  };

  renderRowsWithSubheadersAndLockedColumns = () => {
    const items = this.props.items || [];

    return items.map((itemGroup, objIndex) => (
      <div key={itemGroup['subheader']} ref={this.props.collectContainers} data-grid-index={objIndex} >
        {/* conditionally render header here OR in Grid depending on whether enableScroll is true */}
        {this.displayHeader() && this.headerRow()}
        {<Row key={objIndex} rowIndex={objIndex} item={itemGroup['subheader']} subheader={true} columns={[itemGroup['subheader']]} />}
        {itemGroup['rows'].map((item, itemGroupIndex) => this.row(itemGroupIndex, item))}
      </div>
    ));
  };

  renderNoDataMessage = () => (
    <div className={'mdf-grid-noDataMessage'}>
      {this.props.noDataMessage ? this.props.noDataMessage : ` ${FormatHelper.formatMessage('@@NoRowsFound')}`}
    </div>
  );

  render() {
    this.isHeaderRendered = false;
    // Check if subheader exists
    const subheader = this.props.items && this.props.items[0] && this.props.items[0].hasOwnProperty('subheader');

    const classes = classNames({
      'vdl-col-xs': true,
      'mdf-grid-scroll-pane': (this.props.items && this.props.items.length > 0),
      'mdf-grid-locked-enable-scroll': (this.props.enableScroll && !this.props.fetchItems && this.props.lockedGrid),
      'mdf-grid-unlocked-enable-scroll': (this.props.enableScroll && !this.props.fetchItems && !this.props.lockedColumns),
      'mdf-grid-locked-infinite-scroll': (this.props.fetchItems && this.props.lockedGrid),
      'mdf-grid-unlocked-infinite-scroll': (this.props.fetchItems && !this.props.lockedColumns),
      // set flexbasis to auto for locked columns to be visible in IE when enableScroll is set to true for InfiniteGrid.
      'mdf-grid-locked-infinite-scroll-IE': (this.props.fetchItems && this.props.enableScroll && this.props.lockedGrid && GridHelper.isIE()),
      'mdf-grid-locked-enable-scroll-IE': (!this.props.fetchItems && this.props.enableScroll && this.props.lockedGrid && GridHelper.isIE()),
      // set flexbasis to auto for unlocked columns to be visible in IE when enableScroll is set to true for InfiniteGrid. This is added in v24Nov as we have seen issue of unlocked columns not being visible in IE11 with enable-scroll set to true and locked columns combination
      'mdf-grid-unlocked-infinite-scroll-IE': (this.props.fetchItems && this.props.enableScroll && !this.props.lockedGrid && GridHelper.isIE()),
      'mdf-grid-unlocked-enable-scroll-IE': (!this.props.fetchItems && this.props.enableScroll && !this.props.lockedGrid && GridHelper.isIE()),
      // apply overflow: hidden only when it is infiniteGrid and does not have locked columns and for windowScroll
      // Taking out overflow hidden from the normal windowScroll stops the issue of DROPDOWM to showing up.
      'mdf-grid-set-overflow': (this.props.fetchItems && !this.props.lockedGrid)
    });

    return (
      <div className="vdl-row">
        <div className={classes} ref={(el) => this.gridBodyWrapper = el}>
          {/* If there is a subheader and no locked columns, render header row */}
          {subheader && !this.props.lockedColumns && this.displayHeader() && this.headerRow()}
          {/* If there is a subheader and no locked columns, render rows with subheaders */}
          {subheader && !this.props.lockedColumns && this.renderRowsWithSubheadersAndNoLockedColumns()}
          {/* If there is a subheader and 1+ locked columns, render rows with subheaders */}
          {subheader && this.props.lockedColumns && this.renderRowsWithSubheadersAndLockedColumns()}
          {/* If there is no subheader, render rows */}
          {!subheader && this.renderRows()}
        </div>
      </div>
    );
  }
}
