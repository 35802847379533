export class Validations {
  public static isExisty(value) {
    return ((value !== null) && (value !== undefined));
  }

  public static isEmpty(value) {
    if (!Validations.isExisty(value)) {
      return true;
    }
    else if (value instanceof Date) {
      // If the Date was null or undefined, we wouldn't be here.
      // Date objects have no keys, so it fails non-empty object test below.
      return false;
    }
    else if (typeof value === 'string') {
      return value === '';
    }
    else if (typeof value === 'number') {
      return Number.isNaN(value);
    }
    else if (Array.isArray(value)) {
      return value.length === 0;
    }
    else if (typeof value === 'object') {
      return Object.keys(value).length === 0;
    }
    else {
      return false;
    }
  }

  public static validateObjectKeys(object) {
    let isKeysInvalid = true;

    for (const key in object) {
      if (this.isExisty(object[key]) && object[key] !== '') {
        isKeysInvalid = false;
        break;
      }
    }

    return !isKeysInvalid;
  }

  public static isRequired(value, params) {
    if (params.required === true) {
      return !Validations.isEmpty(value);
    }

    return true;
  }

  public static isEmail(value) {
    return Validations.matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);
  }

  public static matchRegexp(value, regexp) {
    return Validations.isEmpty(value) || regexp.test(value);
  }
}
