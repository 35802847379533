import React from 'react';
import { IWFNMockShellChange } from '../WFNMockShell';
import { IWFNMockShellMMLevel1Item } from '../types';
import { MDFButton } from '../../../..';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellMMLevel2TitleBarProps {
  level1Item: IWFNMockShellMMLevel1Item;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
}

export const WFNMockShellMMLevel2TitleBar = ({ level1Item, onWFNShellChange }: IWFNMockShellMMLevel2TitleBarProps) => {
  const handleSelectUnselectClick = (type: 'menuLevel2SelectAll' | 'menuLevel2UnselectAll') => {
    onWFNShellChange({
      item: {
        oid: level1Item.oid,
        name: level1Item.name
      },
      type,
      value: true
    });
  };
  const isLegacyShell = (window['isADPUnified'] === undefined);

  const renderSelectAllUnselectAllButtons = () => {
    return level1Item.renderSelectAllUnselectAll &&
      <span className="wfnmockshell-sub-item-title-buttons">
        <MDFButton buttonStyle={'link'} onClick={handleSelectUnselectClick.bind(null, 'menuLevel2SelectAll')} disabled={level1Item.disableSelectAll}>{FormatHelper.formatMessage('@@label_eeregmgmt_selectall')}</MDFButton>
        <span>{' | '}</span>
        <MDFButton buttonStyle={'link'} onClick={handleSelectUnselectClick.bind(null, 'menuLevel2UnselectAll')} disabled={level1Item.disableUnselectAll}>{FormatHelper.formatMessage('@@label_clear_all')}</MDFButton>
      </span>;
  };

  return (
    <div className="wfnmockshell-sub-title-wrapper">
      {isLegacyShell && <div className="wfnmockshell-sub-title">
        <div className="wfnmockshell-sub-title--title">{level1Item.name}</div>
      </div>}
      {renderSelectAllUnselectAllButtons()}
    </div>
  );
};

WFNMockShellMMLevel2TitleBar.displayName = 'WFNMockShellMMLevel2TitleBar';
