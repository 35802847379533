import { useState } from 'react';

// this is to execute any code that needs to be executed only once before rendering in a functional component similar to constructor in class component
export const UseSingleTon = (callBack = () => { }) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) {
    return;
  }
  callBack();
  setHasBeenCalled(true);
};

UseSingleTon.displayName = 'UseSingleTon';
