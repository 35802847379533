import React from 'react';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import DualListBox, { IMultiSelectItem } from './DualListBox/DualList';
import PropTypes from 'prop-types';
import { find, isEqual } from 'lodash';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

// Added onBlur and call onChange to update the Validation Container with the isValid state.
// Update the onChange with isValid and validationmessage params.
// Added isValid as part of state to re-render the component when the state value changes which is passed as a property to DualList.

export interface IMDFDualMultiSelectProps {
  // Accessibility message for when component is invalid.
  ariaInvalid?: any;
  'aria-invalid'?: any;

  // Display this component in a disabled state.
  disabled?: boolean;

  // Allow filtering/search
  filter?: boolean;

  // Element id for this component.
  id?: string;

  // Label text for when the selection is invalid.
  invalidLabelText?: string;

  // Label for left side.
  leftLabel?: string;

  // Called when selection changes from one side to the other.
  onChange?: (selected: string[], valid: boolean, message: string) => void;

  // Array of all options.
  options: IMultiSelectItem[];

  // Is this component required.
  required?: boolean;

  // Label for right side.
  rightLabel?: string;

  // Array of selected items as an array of strings.
  selected: string[];

  // Called when onSelect is passed and click on list items
  onSelect?: (value) => void;

  // Detects blur event; used in handling error messaging.
  onBlur?: (value) => void;

  // Detects focus event; used in handling error messaging.
  onFocus?: (value) => void;

  // Placeholder text when filter option is being used.
  placeholder?: string;

  // id for keyboardInstructions
  keyboardInstructionsId?: string;

  // Accessibility feature. Identify the next focusable element on the page by id.
  manageFocusNodeId?: string;
}

export class MDFDualMultiSelect extends React.Component<IMDFDualMultiSelectProps, any> {
  constructor(props: any) {
    super(props);
    this.state = { selected: props.selected || [], isValid: true };
  }

  static propTypes = Object.assign({
    id: PropTypes.string,
    ariaInvalid: PropTypes.any,
    selected: PropTypes.array,
    required: PropTypes.bool,
    invalidLabelText: PropTypes.string,
    options: PropTypes.array,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    filter: PropTypes.bool,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    keyboardInstructionsId: PropTypes.string
  });

  static displayName = 'MDFDualMultiSelect';

  public static isExisty(value) {
    return ((value !== null) && (value !== undefined) && (value && value.length !== 0));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const selected = [];

    // Checking selected items are present in the given options. If not, then removing them from the selected list as it is invalid entry.
    if (Array.isArray(nextProps.selected)) {
      nextProps.selected.forEach((item) => {
        if (find(nextProps.options, ['value', item])) {
          selected.push(item);
        }
      });
    }

    if (!isEqual(selected, prevState.selected)) {
      return { selected };
    }

    return null;
  }

  validationMessage = '';

  // when the selected options in state changes, check the validation
  componentDidUpdate(_prevProps, prevState) {
    if (!isEqual(this.state.selected, prevState.selected)) {
      this.setState({ isValid: MDFDualMultiSelect.isExisty(this.state.selected) });
    }
  }

  multiOnBlur = () => {
    setTimeout(() => {
      const selectedValue = this.state.selected;

      if (!MDFDualMultiSelect.isExisty(selectedValue)) {
        this.setState({ isValid: false });
        this.validationMessage = ' is required';
      }

      if (this.props.onChange) {
        this.props.onChange(selectedValue, this.state.isValid, this.validationMessage);
      }
    }, 140);
  };

  onChange = (selected) => {
    this.setState({ selected });

    if (MDFDualMultiSelect.isExisty(selected)) {
      this.setState({ isValid: true });
      this.validationMessage = '';
    }
    else {
      this.setState({ isValid: false });
    }

    if (this.props.onChange) {
      this.props.onChange(selected, this.state.isValid, this.validationMessage);
    }
  };

  render() {
    const { id, leftLabel, rightLabel, filter, onSelect, options, onBlur, onFocus, keyboardInstructionsId, ...passDownProps } = this.props;
    const ariaInvalid = resolveAriaProperty('MDFDualMultiSelect', 'aria-invalid', 'ariaInvalid', this.props);

    const optionWithId = options.map((item) => ({
      ...item,
      id: item.id || generateId('dualListItem')
    }));

    let nodeId = id;

    if (!nodeId) {
      nodeId = generateId('mdfDualMultiSelect');
    }

    let actionButtonId = keyboardInstructionsId;

    if (!actionButtonId) {
      actionButtonId = generateId('keyboardInstructions');
    }

    return (
      <div onBlur={this.props.required && this.multiOnBlur}>
        <p className="vdl-invisible" id={actionButtonId}>{FormatHelper.formatMessage('@@dualMultiKeyboardInstructions')}</p>
        <DualListBox id={nodeId} ariaDescribedby={actionButtonId} aria-invalid={ariaInvalid} options={optionWithId} {...passDownProps} onChange={this.onChange} onSelect={onSelect} isValid={this.state.isValid} selected={this.state.selected} filter={filter} disabled={this.props.disabled} leftLabel={leftLabel} rightLabel={rightLabel} onBlur={onBlur} onFocus={onFocus} />
      </div>
    );
  }
}
