import React from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

export interface IMDFFroalaEditorViewProps {
  // A string containing HTML generated by Froala. If Froala didn't generate the HTML, then you don't need to use this component.
  value?: string;
}

export const MDFFroalaEditorView = (props: IMDFFroalaEditorViewProps) => {
  return <FroalaEditorView model={props.value} />;
};

MDFFroalaEditorView.displayName = 'MDFFroalaEditorView';
