import React, { useEffect, useMemo, useState } from 'react';
import { ComponentManager, generateId } from '@adp-wfn/mdf-core';

const isChildless = (item, childField) => {
  return !item[childField] || item[childField].length === 0;
};

const useExpandCollapse = (initialValue = false): [boolean, () => void] => {
  const [ isExpanded, setIsExpanded ] = useState(initialValue);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return [ isExpanded, toggleExpanded ];
};

const renderTreeItem = (itemProps) => {
  return <TreeItem {...itemProps}/>;
};

const renderContent = (ContentComponent, props) => {
  return (
    <div className={'mdf-tree-list__tree-content'}>
      <div className={`level${props.level}`}/>
      <ContentComponent {...props}/>
    </div>
  );
};

const TreeItem = (props: any) => {
  const { childField, contentView, item, itemIdField, itemProps, itemView, key, keyField, level } = props;
  const [ isExpanded, toggleExpanded ] = useExpandCollapse(item.isExpanded);

  const hasChildren = !isChildless(item, childField);
  const id = useMemo(() => item[itemIdField] || generateId('TreeItem'), [item[itemIdField]]);
  const iconClass = isChildless(item, childField) ? '' : (isExpanded ? 'fa fa-minus-circle' : 'fa fa-plus-circle');

  // Tell the application that the user clicked on the tree item, which changed the value of isExpanded.
  const onTreeItemClick = () => {
    toggleExpanded();
    props.onExpand?.(item, !isExpanded);
  };

  // Update isExpanded state on application modifies tree item isExpanded property through external action.
  useEffect(() => {
    if ((item.isExpanded || false) !== isExpanded) {
      toggleExpanded();
    }
  }, [item.isExpanded]);

  const ItemComponent = useMemo(() => ComponentManager.getComponent(itemView), [itemView]);
  const ContentComponent = useMemo(() => ComponentManager.getComponent(contentView), [contentView]);

  return (
    <>
      <div id={id} key={key} className={'mdf-tree-list__tree-item'} onClick={onTreeItemClick}>
        <div className={`level${level}`}/>
        <div className={'mdf-tree-list__expand-icon'}><i id={`${id}_expandIcon`} className={iconClass}/></div>
        {ItemComponent && <ItemComponent item={item} {...itemProps}/>}
      </div>
      {
        isExpanded && hasChildren && item[childField].map((child, index) => renderTreeItem({
          ...props,
          key: item[keyField] || `${id}_${index}`,
          item: child,
          level: level + 1
        }))
      }
      {
        isExpanded && !hasChildren && ContentComponent && renderContent(ContentComponent, props)
      }
    </>
  );
};

export interface ITreeListProps {
  // The name of the field in the item that contains the item's children array. Defaults to 'children'.
  childField?: string;
  // A CSS classname to put on the top-level div that wraps the entire component.
  className?: string;
  // The string name of the view to render in a content area under the item when a 'leaf' node is 'expanded' (clicked)
  contentView?: string;
  // The id for this component. If not provided, a random id is generated.
  id?: string;
  // The name of the field in the item that is the item's id. Defaults to 'id'.
  itemIdField?: string;
  // Additional properties that are passed to the itemView component when rendering the item.
  itemProps?: any;
  // The array of items to render in the TreeList. Each item as passed to the itemView as the item property. This property is required.
  items: any[];
  // The string name of the view or component used to render each item in the tree. This property is required.
  itemView: string;
  // The name of the field to use as the React key for this item. This helps React re-render lists more efficiently. Defaults to the id field with the loop index value appended.
  keyField?: string;
  // The event handler called when a tree item is clicked (triggers expand/collapse)
  onExpand?: (item, isExpanded) => void;
}

export const TreeList = (props: ITreeListProps) => {
  const { className, items, onExpand } = props;
  const id = useMemo(() => props.id || generateId('TreeList'), [props.id]);
  const treeListClassName = useMemo(() => 'mdf-tree-list ' + (className || ''), [className]);

  return (
    <div id={id} className={treeListClassName}>
      {
        items?.map((item, index) => renderTreeItem({
          childField: props.childField,
          contentView: props.contentView,
          key: item[props.keyField] || `${id}_${index}`,
          keyField: props.keyField,
          item,
          itemIdField: props.itemIdField,
          itemProps: props.itemProps,
          itemView: props.itemView,
          level: 0,
          onExpand
        }))
      }
    </div>
  );
};

TreeList.displayName = 'TreeList';
TreeList.defaultProps = {
  childField: 'children',
  itemIdField: 'id',
  itemProps: {}
};
