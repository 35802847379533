import React from 'react';
import { SdfCarousel, SdfCarouselItem } from '@waypoint/react-components';
import PropTypes from 'prop-types';

export interface IMDFCarouselProps {
  // The items to display in the Carousel.
  children: React.ReactNode;
  // A CSS class name for overriding styles.
  className?: string;
  // A CSS class name for Carousel container.
  carouselClassName?: string;
  // How many slides to scroll at once
  slidesToScroll?: number;
  // How many slides to show in one frame
  slidesToShow?: number
}

export const MDFCarousel = (props: IMDFCarouselProps) => {
  const { children, className, carouselClassName, slidesToScroll, slidesToShow, ...settings } = props;

  return (
    <SdfCarousel inset itemsPerPage={slidesToShow} {...settings}>
      {React.Children.map(children, (child, i) => (<SdfCarouselItem key={i} className={className}>{child}</SdfCarouselItem>))}
    </SdfCarousel>
  );
};

MDFCarousel.displayName = 'MDFCarousel';

MDFCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  carouselClassName: PropTypes.string
};
