import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SdfTab, SdfTabGroup } from '@waypoint/react-components';
import { IconMap, IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';
import { ComponentManager, MDFCore } from '@adp-wfn/mdf-core';
import { ISidebarItemData } from './MDFSidebar';
import { isEqual } from 'lodash';

export interface IVerticalNavState {
  canNavigate?: boolean;
  menuOpen?: boolean;
  selectedItem?: number;
}

export interface IVerticalNavProps {
  active?: boolean;
  alertNum?: number;
  canNavigate?: boolean;
  className?: any;
  data: ISidebarItemData[];
  id?: string;
  onChange?: (index: number) => void;
  selectedItem: number;
  title?: any;
}

export class MDFVerticalNav extends React.Component<IVerticalNavProps, IVerticalNavState> {
  static propTypes = {
    data: PropTypes.any,
    className: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      canNavigate: !(this.props.canNavigate === false)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState: any = {};

    if (!isEqual(prevState.canNavigate, !(nextProps.canNavigate === false))) {
      nextState.canNavigate = nextProps.canNavigate;

      if (nextState.canNavigate && (!isEqual(prevState.selectedItem, nextProps.selectedItem))) {
        nextState.menuOpen = false;
      }
    }

    return nextState;
  }

  getSelectedItem = () => {
    const data = this.props.data;
    let selectedIndex = 0;

    data.forEach((dataItem, i) => {
      if (dataItem.active) {
        selectedIndex = i;
      }
    });

    return selectedIndex;
  };

  handleMenuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  };

  handleSelect = (selectedItem) => {
    console.log('MDFVerticalNav.handleSelect(): Entering. selectedItem = ', selectedItem);

    if (this.state.canNavigate) {
      console.log('MDFVerticalNav.handleSelect(): Setting state. selectedItem.value = ', selectedItem.value);

      if (this.props.onChange) {
        this.props.onChange(selectedItem.value);
      }
    }
  };

  private static renderCustomItem(item: ISidebarItemData) {
    const View = ComponentManager.getComponent(item.customItem);

    return <View/>;
  }

  render() {
    if (MDFCore.shouldLog()) {
      console.log('MDFVerticalNav.render(): Entering. state = ', this.state);
      console.log('MDFVerticalNav.render(): Entering. props = ', this.props);
    }

    const data = this.props.data;
    const className = this.props.className;
    // Get navigation views by the selectedItemIndex
    const viewComponent = ComponentManager.getComponent(data[this.props.selectedItem].view);
    const rootClasses = {
      'left-nav-framework': true,
      'left-nav-framework--menu-open': this.state.menuOpen
    };

    return (
      <div className={classNames(className, rootClasses)}>
        <div className="left-nav-framework__container flex">
          <div className="flex-none">
            <SdfTabGroup
              vertical={true}
              onSdfChange={(e) => this.handleSelect(e.detail)}
            >
              {
                data.map((dataItem, i) => (
                  <SdfTab
                    key={dataItem.key}
                    id={dataItem.id}
                    value={i}
                    icon={IconMap[dataItem.icon] ? dataItem.icon as IconName : undefined}
                    active={i === this.props.selectedItem ? true : undefined}
                  >
                    {dataItem['customItem'] ? MDFVerticalNav.renderCustomItem(dataItem) : <span>{dataItem.label}</span>}
                  </SdfTab>
                ))
              }
            </SdfTabGroup>
          </div>
          <div className="flex-auto min-h-screen">
            <div className="left-nav-framework__top-bar">
              <h1 className="left-nav-framework__title">
                {this.props.title}
              </h1>
            </div>
            <div className="flex flex-col min-h-screen">
              <div className="flex flex-col flex-auto">
                {React.createElement(viewComponent)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
