import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormatHelper, MDFCore } from '@adp-wfn/mdf-core';
import { IModalProps } from '@synerg/vdl-react-components';
import { SdfButton, SdfFocusPane } from '@waypoint/react-components';

export interface IMDFModalDialog2Props extends IModalProps {
  children?: React.ReactNode;
  closeable?: boolean;
  closeButtonId?: string;
  closeButtonText?: string;
  containerClassName?: string;
  disabled?: boolean;
  disabledNoButton?: boolean;
  disabledOkButton?: boolean;
  disabledYesButton?: boolean;
  footer?: any;
  hasNoButton?: boolean;
  hideFooter?: boolean;
  modalType?: string;
  modalTypeClass?: string;
  noButtonId?: string;
  noButtonText?: string;
  okButtonId?: string;
  okButtonText?: string;
  onNo?: () => void;
  onOkClicked?: () => void;
  onYes?: () => void;
  portalEnabled?: boolean;
  // Accessibility feature - Moves focus to a specific element after the modal dialog closes.
  // Enable feature by passing matching string values to the modal dialog's restoreFocusNodeId property and the next focused element's id property.
  restoreFocusNodeId?: string;
  title?: string;
  titleElement?: React.ReactNode;
  yesButtonId?: string;
  yesButtonText?: string;
}

export const MDFModalDialog2 = (props: IMDFModalDialog2Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(props.show);
  const [trigger, setTrigger] = useState(null);
  const focusPane = useRef<HTMLSdfFocusPaneElement>(null);
  const modalType = props.modalType?.toLowerCase() ?? '';

  let footer = props.footer;
  let showFooter = !!footer && !props.hideFooter;

  const afterOpened = useCallback(() => {
    props.onShow?.();
  }, []);

  const afterClosed = useCallback(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFModalDialog2.afterClosed: Calling setIsShowing(${false})`);
    }

    setIsShowing(false);
    props.onExited?.();

    // Accessibility: Use restoreFocusNodeId property to place focus on a custom element after the slide in closes.
    if (props.restoreFocusNodeId) {
      const id = props.restoreFocusNodeId;
      const customFocusElement = document.getElementById(id) as any;

      if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
        customFocusElement.setFocus?.();
      }
      else if (customFocusElement) {
        customFocusElement.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFModalDialog2.useEffect(): Entering. isShowing = ${isShowing}, isOpen = ${isOpen}`);
    }

    if (props.show) {
      if (MDFCore.shouldLog()) {
        console.warn(`MDFModalDialog2.useEffect(): Calling setIsShowing(${true})`);
      }

      setIsShowing(true);
    }
    else if (isOpen) {
      void focusPane.current?.close();

      if (MDFCore.shouldLog()) {
        console.warn(`MDFModalDialog2.useEffect(): Calling setIsOpen(${false})`);
      }

      setIsOpen(false);
    }
  }, [props.show]);

  useLayoutEffect(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFModalDialog2.useLayoutEffect(): Entering. isShowing = (${isShowing})`);
    }

    // The open/close methods need to be run before repainting the screen.
    if (isShowing) {
      // Focus management: Identify element that opens the modal.
      setTrigger(document.activeElement.id);
      void focusPane.current?.open();

      if (MDFCore.shouldLog()) {
        console.warn(`MDFModalDialog2.useLayoutEffect(): Calling setIsOpen(${true})`);
      }

      setIsOpen(true);
    }
    else {
      if (MDFCore.shouldLog()) {
        console.warn(`MDFModalDialog2.useLayoutEffect(): Resetting focus. props.restoreFocusNodeId = ${props.restoreFocusNodeId}, trigger = ${trigger}`);
      }

      // Handles focus management upon slide in closing
      if (props.restoreFocusNodeId || trigger) {
        // Checks if a custom focus element (restoreFocusNodeId property) is specified first. If a
        // focus element is not specified, then focus will be moved back to the original trigger element.
        const focusNodeId = props.restoreFocusNodeId ? props.restoreFocusNodeId : trigger;
        const customFocusElement = document.getElementById(focusNodeId) as any;

        if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
          customFocusElement.setFocus?.();
        }
        else if (customFocusElement) {
          customFocusElement.focus();
        }
      }
    }
  }, [isShowing]);

  const focusPaneProps: any = {
    className: (`mdf ${props.containerClassName ?? ''} ${props.dialogClassName ?? ''}`).trim(),
    closeable: props.closeable,
    'dismiss-on-click-outside': props.backdrop,
    'pane-type': 'floating',
    size: props.size,
    onSdfAfterClose: afterClosed,
    onSdfAfterOpen: afterOpened
  };

  // Using titleElement will force the use of the "title" slot, so don't set "heading".
  // If props.title isn't a string, that will also force the use of the "title" slot.
  if (!props.titleElement && props.title && typeof props.title === 'string') {
    focusPaneProps.heading = props.title;
  }

  switch (modalType) {
    case 'error':
    case 'info':
    case 'success':
      focusPaneProps['accept-label'] = props.closeButtonText || FormatHelper.formatMessage('@@Close');
      focusPaneProps['hide-dismiss-button'] = true;
      focusPaneProps.onSdfAccept = props.onHide;
      focusPaneProps.onSdfDismiss = props.onHide;
      focusPaneProps.status = modalType;
      break;

    case 'alert':
      if (!footer) {
        // Add the "Yes" and "No" buttons for the standard "alert" modal, if the application hasn't provided their own.
        footer = (
          <React.Fragment>
            <SdfButton slot="custom-buttons" id={props.yesButtonId ?? undefined} disabled={props.disabledYesButton || props.disabled || undefined} onClick={props.onYes} emphasis="primary">{props.yesButtonText || FormatHelper.formatMessage('@@Yes')}</SdfButton>
            {props.hasNoButton && <SdfButton slot="custom-buttons" class="ml-4" id={props.noButtonId ?? undefined} disabled={props.disabledNoButton || props.disabled || undefined} onClick={props.onNo} emphasis="secondary">{props.noButtonText || FormatHelper.formatMessage('@@No')}</SdfButton>}
          </React.Fragment>
        );
      }

      showFooter = !props.hideFooter;

      focusPaneProps['hide-accept-button'] = true;
      focusPaneProps['hide-dismiss-button'] = true;
      focusPaneProps.status = 'warning';
      focusPaneProps.onSdfDismiss = props.onHide;
      break;

    default:
      focusPaneProps['accept-label'] = props.okButtonText || FormatHelper.formatMessage('@@Ok');
      focusPaneProps['disable-accept-button'] = props.disabledOkButton || props.disabled;
      focusPaneProps['hide-accept-button'] = !props.onOkClicked;
      focusPaneProps['hide-dismiss-button'] = true;
      focusPaneProps.onSdfAccept = props.onOkClicked;
      focusPaneProps.onSdfDismiss = props.onHide;
  }

  if (props.footer) {
    focusPaneProps['hide-footer'] = props.hideFooter;
    focusPaneProps['hide-accept-button'] = true;
    focusPaneProps['hide-dismiss-button'] = true;
  }

  const renderContent = () => {
    if (props.show && isOpen) {
      return <div className="vdl-mdf-modal-content">{props.children}</div>;
    }
    else if (!(props.show && isOpen)) {
      return null;
    }
  };

  const getTitle = () => {
    if (props.titleElement) {
      return <div slot="title">{props.titleElement}</div>;
    }
    else if (props.title && typeof props.title === 'object' && (props.title as React.ReactElement).type) {
      console.error('The MDFModalDialog title property must be a string. Use titleElement to pass a React element.');
      return <div slot="title">{props.title}</div>;
    }
    else {
      return null;
    }
  };

  const getFooter = () => {
    if (showFooter) {
      return <div slot="custom-buttons">{footer}</div>;
    }
    else {
      return null;
    }
  };

  const renderBody = () => {
    return (
      <React.Fragment>
        {getTitle()}
        <div className="px-4">{renderContent()}</div>
        {getFooter()}
      </React.Fragment>
    );
  };

  if (MDFCore.shouldLog()) {
    console.warn(`MDFModalDialog2: Rendering. isShowing = ${isShowing}. props =`, props);
  }

  if (props.show || isShowing) {
    if (props.portalEnabled) {
      return createPortal(<SdfFocusPane {...focusPaneProps} ref={focusPane}>{renderBody()}</SdfFocusPane>, document.body);
    }
    else {
      return <SdfFocusPane {...focusPaneProps} ref={focusPane}>{renderBody()}</SdfFocusPane>;
    }
  }
  else {
    return null;
  }
};

MDFModalDialog2.defaultProps = {
  hasNoButton: true,
  hideFooter: false,
  portalEnabled: false,
  size: 'md'
};

MDFModalDialog2.displayName = 'MDFModalDialog2';
