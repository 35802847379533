import React from 'react';
import { MDFTree } from '../MDFTree';
import classNames from 'classnames';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';

export class WFNXLeftNav extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  static defaultProps = {
    showCollapsible: true
  };

  state = {
    containerCollapsed: this.props.containerCollapsed
  };

  handleCollapseClick = (event: any) => {
    event.stopPropagation();

    this.setState({
      containerCollapsed: !this.state.containerCollapsed
    });

    if (this.state.containerCollapsed) {
      if (this.props.onContainerCollapse) {
        this.props.onContainerCollapse();
      }
    }
    else {
      if (this.props.onContainerExpand) {
        this.props.onContainerExpand();
      }
    }
  };

  handleKeyDown = (event) => {
    // Accessibility feature: open and close navigation with keyboard
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

      this.setState({
        containerCollapsed: !this.state.containerCollapsed
      });

      if (this.state.containerCollapsed) {
        if (this.props.onContainerCollapse) {
          this.props.onContainerCollapse();
        }
      }
      else {
        if (this.props.onContainerExpand) {
          this.props.onContainerExpand();
        }
      }
    }
  };

  renderCollapseControl = (id) => {
    return (
      <div className={classNames('collapse-control', !this.state.containerCollapsed ? 'collapse-control--open' : 'collapse-control--closed')}
        role="button"
        tabIndex={0}
        aria-label={!this.state.containerCollapsed ? FormatHelper.formatMessage('@@closeNavigation') : FormatHelper.formatMessage('@@openNavigation')}
        onClick={this.handleCollapseClick}
        onKeyDown={this.handleKeyDown}
        aria-expanded={!this.state.containerCollapsed ? true : false}
        aria-controls={id}
      >
        <i className={classNames('fa', !this.state.containerCollapsed ? 'fa-chevron-left' : 'fa-chevron-right')} />
      </div>
    );
  };

  render() {
    const { className, showCollapsible, ...rest } = this.props;
    const id = generateId('mdf-leftNavContainer');

    return (
      <div className="mdf-leftNavContainer">
        {showCollapsible && this.renderCollapseControl(id)}
        <div className={this.state.containerCollapsed ? 'mdf-leftNavContainer nav-collapsed' : 'mdf-leftNavContainer nav-expand'}
          id={id}
          aria-hidden={this.state.containerCollapsed ? true : false}
        >
          <MDFTree isTree={false} {...rest} className={`mdf-leftNav ${className || ''}`}></MDFTree>
        </div>
      </div>
    );
  }
}
