import React, { useContext, useEffect, useRef, useState } from 'react';
import { IWFNMockShellRoot } from '../types';
import { IWFNMockShellActivePopover, IWFNMockShellChange, PopoverExemptionContext } from '../WFNMockShell';
import { Avatar, Checkbox } from '@synerg/vdl-react-components';
import { WFNMockShellToolbarPopoverNav } from './WFNMockShellToolbarPopoverNav';
import { WFNMockShellPopoverDetail } from './WFNMockShellPopoverDetail';
import { WFNMockShellPopoverInstructionalText } from './WFNMockShellPopoverInstructionalText';
import { MDFPopupDialog } from '../../MDFPopupDialog';

export interface IWFNMockShellUserMenuProps {
  activePopover: IWFNMockShellActivePopover;
  shellData: IWFNMockShellRoot;
  megaMenuActive: boolean;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
}

export const WFNMockShellUserMenu = ({ shellData, onWFNShellChange, activePopover, megaMenuActive }: IWFNMockShellUserMenuProps) => {
  const renderUserMenuCheckbox = !shellData.headerMenu.disabled && !shellData.toolbar.disabled && shellData.headerMenu.renderCheckbox;
  const [showPopover, setShowPopover] = useState(false);
  const popoverClassName = showPopover ? 'wfnmockshell-wrapper wfnmockshell-popover usermenu-popover' : 'wfnmockshell-wrapper wfnmockshell-popover usermenu-popover popover-hidden'; // hide popover while it unmounts
  const [target, setTarget] = useState() as any;
  const containerRef = useRef();
  const targetRef = useRef();
  const popoverExemptionChange = useContext(PopoverExemptionContext).popoverExemptionChange;
  const handlePopoverClose = useContext(PopoverExemptionContext).handlePopoverClose;
  const isLegacyShell = (window['isADPUnified'] === undefined);

  // Set refs after initial rendering.
  useEffect(() => {
    setTarget(targetRef.current);

    popoverExemptionChange([containerRef.current]);
    return () => popoverExemptionChange([containerRef.current], true);
  }, []);

  useEffect(() => {
    setShowPopover(!megaMenuActive && activePopover.type === 'usermenu');
  }, [activePopover, megaMenuActive]);

  const handleAvatarCheckboxChange = (value) => {
    onWFNShellChange({
      item: {
        oid: shellData.headerMenu.oid,
        name: shellData.headerMenu.name
      },
      type: 'headerMenuChange',
      value
    });
  };

  const handleOverlayTriggerClick = (event) => {
    // If header menu is disabled do not open PopupDialog
    if (shellData.headerMenu.disabled) {
      return;
    }

    const clickedNode = event.target;
    const containerNode = containerRef.current as any;

    if (clickedNode === containerNode || containerNode?.contains(clickedNode)) {
      activePopover.handlePopoverClick({
        ...activePopover,
        type: 'usermenu',
        renderNextBtn: shellData.search.renderSearchBar,
        renderPrevBtn: shellData.toolbar.childList?.length > 0
      });
    }
  };

  const popover = (
    <MDFPopupDialog title={shellData.headerMenu.name} onClose={() => handlePopoverClose()} orientation="left" className={popoverClassName} isOpen={showPopover} targetId={shellData.headerMenu.oid} targetRef={target}>
      <WFNMockShellPopoverInstructionalText instructionalText={shellData.headerMenu.instructionalText} />
      <WFNMockShellPopoverDetail popoverType="usermenu" childList={shellData.headerMenu.childList} onWFNShellChange={onWFNShellChange} />
      <WFNMockShellToolbarPopoverNav type="usermenu" activePopover={activePopover} />
    </MDFPopupDialog>
  );

  return (
    <div ref={containerRef} id={shellData.headerMenu.oid} className="wfn-mock-shell-icon-bar--user" onClick={(event) => handleOverlayTriggerClick(event)}>
      {renderUserMenuCheckbox && <Checkbox checked={shellData.headerMenu.isSelected} onChange={handleAvatarCheckboxChange} disabled={shellData.headerMenu.disableCheckbox} />}
      <button ref={targetRef}><Avatar className={`wfn-mock-shell-icon-bar--user--avatar${shellData.headerMenu.disabled ? '--disabled' : ''}`} initials={shellData.headerMenu.avatar.initials} size={'sm'} url={shellData.headerMenu.avatar.url} /></button>
      {isLegacyShell && <button ref={targetRef} className={`wfn-mock-shell-icon-bar--user--name ${shellData.headerMenu.disabled ? 'wfn-mock-shell-icon-bar--user--disabled' : ''}`}>{shellData.headerMenu.username}<span id="userNameChevronIcon" className="fa fa-chevron-down" /></button>}
      {popover}
    </div>
  );
};

WFNMockShellUserMenu.displayName = 'WFNMockShellUserMenu';
