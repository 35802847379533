import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { MDFButton } from './MDFButton';

export interface IToggleContentProps {
  text: string;
  minChars?: number;
  showMoreLabel?: string;
  showLessLabel?: string;
}

const getHtmlTextWithOutStyle = (content: any) => {
  const div = document.createElement('div');
  div.innerHTML = content;
  return div.textContent || div.innerText || '';
};

const getComponentState = (props: IToggleContentProps) => {
  const text = props.text;
  let collapseText = getHtmlTextWithOutStyle(text);
  let showMore = false;

  if (collapseText && collapseText.length > props.minChars) {
    collapseText = collapseText.substring(0, props.minChars);
    showMore = true;
  }

  return {
    collapseText: collapseText,
    showMore: showMore,
    showLess: false
  };
};

export const ToggleContent = (props: IToggleContentProps) => {
  const showMoreLabel: string = '...' + (props.showMoreLabel ? props.showMoreLabel : FormatHelper.formatMessage('@@Rec_Ext_Cand_SHOW_MORE'));
  const showLessLabel: string = ' ' + (props.showLessLabel ? props.showLessLabel : FormatHelper.formatMessage('@@Rec_Ext_Cand_SHOW_LESS'));
  const [toggleContentState, setToggleContentState] = React.useState(getComponentState(props));

  const expand = () => {
    setToggleContentState({
      collapseText: toggleContentState.collapseText,
      showLess: true,
      showMore: false
    });
  };

  const collapse = () => {
    setToggleContentState({
      collapseText: toggleContentState.collapseText,
      showMore: true,
      showLess: false
    });
  };

  if (toggleContentState.showMore) {
    return (
      <div className="more-label">
        <span dangerouslySetInnerHTML={{ __html: toggleContentState.collapseText }}></span>
        <MDFButton
          buttonStyle="link"
          onClick={expand}
          variant="text"
          aria-live="polite"
        >
          {showMoreLabel}
        </MDFButton>
      </div>
    );
  }
  else if (toggleContentState.showLess) {
    return (
      <div className="more-label">
        <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
        <MDFButton
          buttonStyle="link"
          onClick={collapse}
          variant="text"
          aria-live="polite"
        >
          {showLessLabel}
        </MDFButton>
      </div>
    );
  }
  else {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
      </div>
    );
  }
};

ToggleContent.displayName = 'ToggleContent';
