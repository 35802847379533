import React from 'react';
import { IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellMMLevel3Item } from './WFNMockShellMMLevel3Item';
import { WFNMockShellMMLevel2TitleBar } from './WFNMockShellMMLevel2TitleBar';
import { IWFNMockShellMMLevel1Item, IWFNMockShellMMLevel2Item, IWFNMockShellMMLevel3Item } from '../types';
import { Checkbox } from '@synerg/vdl-react-components';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellMMExpandedLevel2Items {
  setExpanded: any;
  level1ItemIndex: number;
  level1Item: IWFNMockShellMMLevel1Item;
  childList: IWFNMockShellMMLevel2Item[];
  additionalSubnavMargin: number;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string
}

export const WFNMockShellMMNavExpandedLevel2Items = ({ setExpanded, level1ItemIndex, childList, onWFNShellChange, level1Item, additionalSubnavMargin, editOptionsLabel }: IWFNMockShellMMExpandedLevel2Items) => {
  const handleLevel2ItemChange = (level2Item, value) => {
    onWFNShellChange({
      item: {
        oid: level2Item.oid,
        name: level2Item.name
      },
      type: 'menuLevel2Checkbox',
      value
    });
  };

  const isLegacyShell = (window['isADPUnified'] === undefined);

  // create columns of level 2 items
  const createColumnsFromLevel2Items = () => {
    // Break up the 3rd level into 5 columns and try to even out the column lengths.
    const menuColumns: IWFNMockShellMMLevel2Item[][] = [[], [], [], [], []];
    const columnItemCount = [0, 0, 0, 0, 0];

    childList.forEach((level2Item) => {
      const shortestColumn = columnItemCount.reduce((shortest, value, index) => {
        if (value < shortest.value) {
          return { index, value };
        }
        else {
          return shortest;
        }
      }, { index: 0, value: 1000 });

      const menuColumn = menuColumns[shortestColumn.index];
      columnItemCount[shortestColumn.index] += level2Item.childList ? level2Item.childList.length + 1 : 0;
      menuColumn.push(level2Item);
    });

    return menuColumns.filter((level2Items) => level2Items.length);
  };

  // render column with level 2 items
  const renderLevel2ItemsInColumn = (level2Item: IWFNMockShellMMLevel2Item) => {
    return (
      <div key={level2Item.oid} className="wfnmockshell-sub-item">
        <div className="wfnmockshell-sub-item-title-content">
          {level2Item.renderCheckbox && <Checkbox onChange={handleLevel2ItemChange.bind(null, level2Item)} checked={level2Item.isSelected} disabled={level2Item.disableCheckbox} />}
          <div className="wfnmockshell-sub-item--button-content-text">
            <div className="wfnmockshell-sub-item-button-content-title" title={level2Item.name}>
              <h3 className="my-3">{level2Item.name}</h3>
            </div>
          </div>
        </div>
        {level2Item.childList && level2Item.childList.map((level3Item: IWFNMockShellMMLevel3Item) => (
          <WFNMockShellMMLevel3Item key={level3Item.oid} extended onWFNShellChange={onWFNShellChange} {...level3Item} editOptionsLabel={editOptionsLabel} />
        ))}
      </div>
    );
  };

  // render columns
  const renderColumns = (colLevel2Items: IWFNMockShellMMLevel2Item[]) => {
    return (
      <div key={`${colLevel2Items[0]?.oid} ${colLevel2Items[0]?.name}`} className="wfnmockshell-sub-items">
        {colLevel2Items.map(renderLevel2ItemsInColumn)}
      </div>
    );
  };

  return (
    <div className="wfnmockshell-top-item--wrapper wfnmockshell-expanded">
      <div className="wfnmockshell-top-item--wrapper-dart" />
      <div className="wfnmockshell-sub mdf-mock-shell-wfnmockshell-slide-down" style={{ marginLeft: `calc(-${level1ItemIndex * 100}% + ${additionalSubnavMargin}px)` }}>
        <WFNMockShellMMLevel2TitleBar onWFNShellChange={onWFNShellChange} level1Item={level1Item} />
        <div className="wfnmockshell-sub-container">
          {createColumnsFromLevel2Items().map(renderColumns)}
        </div>
        {isLegacyShell && <div className="wfnmockshell-sub-collapse">
          <div onClick={setExpanded.bind(null, false)} className="wfnmockshell-sub-collapse--button"><i className="wfnmockshell-sub-collapse--icon fa fa-compress"></i>
            <div className="wfnmockshell-sub-collapse--title">{FormatHelper.formatMessage('@@label_collapse_menu')}</div>
          </div>
        </div>}
      </div>
    </div>
  );
};

WFNMockShellMMNavExpandedLevel2Items.displayName = 'WFNMockShellMMNavExpandedLevel2Items';
