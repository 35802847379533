import React from 'react';
import ReactDOM from 'react-dom';
import { Cell } from './GridCell';
import Resizable from './Resizable';
import { HeadCell } from './GridHeadCell';
import { get } from 'lodash';
import { RenderHelper } from '@adp-wfn/mdf-core';
import { GridContext } from '../MDFContext';
import classNames from 'classnames';

export interface IRowProps {
  item?: any;
  columns?: any;
  columnWidths?: any;
  rows?: any;
  lockedGrid?: boolean;
  head?: boolean;
  draggable?: boolean;
  subheader?: boolean;
  lockedColumns?: number;
  height?: number;
  rowIndex?: number;
  scrollBarWidth?: number;
  itemVar?: string;
  model?: object[];
  sortInfo?: any;
  handleHover?: (rowIndex: number, isHovering: boolean) => void;
  // Application defined function accepts the index of the selected row
  selectRow?: (rowIndex: number) => void;
  handleRowClick?: (rowIndex: number) => void;
  // Application defined function accepts the index of the selected column
  selectColumn?: (columnIndex: number) => void;
  handleResize?: (columnIndex: any, columnWidth: any, isLockedGrid: any) => void;
  onSort?: (sortObject: object) => void;
  collectRows?: (row) => void;
  onScrollAnchorChange?: (index: number, offset: number) => void;
  fetchItems?: (startIndex: number, count: number) => object[];
  setRowHeights?: (rowIndex, columnIndex, height) => void;
}

const ResizableHeadCell = Resizable(HeadCell);

export class Row extends React.Component<IRowProps, any> {
  private rowElement;
  private lockedColumnsHeaderRow;

  constructor(props: any) {
    super(props);
  }

  declare context: React.ContextType<typeof GridContext>;
  static contextType = GridContext;

  componentDidMount() {
    if (this.lockedColumnsHeaderRow) {
      this.context.registerScrollPane(this.lockedColumnsHeaderRow);
    }

    if (this.props.collectRows && !this.props.lockedGrid) {
      this.props.collectRows(this.rowElement);
    }
  }

  componentWillUnmount() {
    if (this.lockedColumnsHeaderRow) {
      this.context.unregisterScrollPane(this.lockedColumnsHeaderRow);
    }
  }

  createHeaderRefForLockedColumns = (el) => {
    if (this.props.head && this.props.lockedColumns && !this.props.lockedGrid && this.props.fetchItems) {
      this.lockedColumnsHeaderRow = el;
    }
  };

  getColumnIndex = (index) => {
    // If the grid has locked cells and this is NOT the locked portion, returns index that takes locked columns into account
    if (!this.props.lockedGrid && this.props.lockedColumns) {
      return this.props.lockedColumns + index;
    }

    return index;
  };

  renderCell = (column, i, itemModel) => {
    let CellElement;
    let content;

    if (column.visible === false) {
      return null;
    }

    // If the column is resizable, and this is NOT the locked portion of the grid, and this is a header row, render ResizableHeadCell element
    if (column.resizable && !this.props.lockedGrid && this.props.head) {
      CellElement = ResizableHeadCell;
    }
    // If this row is a header row, render HeadCell element
    else if (this.props.head) {
      CellElement = HeadCell;
    }
    // Otherwise, render Cell element
    else {
      CellElement = Cell;
    }

    if (itemModel) {
      content = RenderHelper.renderContent(column[column.hasOwnProperty('custom') ? 'custom' : 'data'], itemModel);
    }

    if (this.props.subheader) {
      content = this.props.item;
    }

    return (
      <CellElement
        head={this.props.head}
        height={this.props.height}
        selectColumn={this.props.head && this.props.selectColumn}
        onSort={this.props.head && this.props.onSort}
        lockedGrid={this.props.head && this.props.lockedGrid}
        handleResize={this.props.head && this.props.handleResize}
        key={i}
        value={content ? content : ''}
        setRowHeights={this.props.setRowHeights}
        columns={this.props.columns}
        columnWidths={this.props.columnWidths}
        column={column}
        rows={this.props.rows}
        onClick={this.handleSelectRow}
        lockedColumns={this.props.lockedColumns}
        rowIndex={this.props.rowIndex}
        columnIndex={i}
        className={this.getProp(i, 'className')}
        width={this.getProp(i, 'width')}
        onScrollAnchorChange={this.props.onScrollAnchorChange}
        {...this.props}
      />
    );
  };

  renderCells = (index = null, item = null) => {
    let itemModel = null;

    if (!this.props.columns) {
      return null;
    }

    if (item) {
      itemModel = {
        ...this.props.model,
        [this.props.itemVar]: item,
        [this.props.itemVar + 'Index']: index
      };
    }

    // If the gird has locked cells and this is the locked portion, only render the number of columns that are locked
    if (this.props.lockedGrid) {
      return this.props.columns.map((col) => ({ ...col })).splice(0, this.props.lockedColumns).map((column, i) => this.renderCell(column, i, itemModel));
    }

    // If the gird has locked cells and this is NOT the locked portion, only render the number of columns that are NOT locked
    if (!this.props.lockedGrid && this.props.lockedColumns) {
      return this.props.columns.map((col) => ({ ...col })).splice(this.props.lockedColumns).map((column, i) => this.renderCell(column, this.getColumnIndex(i), itemModel));
    }

    // Otherwise, render all the columns
    return this.props.columns.map((column, i) => this.renderCell(column, i, itemModel));
  };

  // Apply hover style to the rowElement
  updateHoverStyle = (isHovering: boolean) => {
    const rowNode = ReactDOM.findDOMNode(this.rowElement) as Element;

    if (isHovering) {
      rowNode?.classList.add('mdf-grid-row-hover');
    }
    else {
      rowNode?.classList.remove('mdf-grid-row-hover');
    }
  };

  getProp = (index, propName) => {
    if (this.props.columns && this.props.columns[index]) {
      return get(this.props.columns[index], propName) || get(this.props.columns[index], `custom.properties.${propName}`, '');
    }
  };

  getStyle = () => {
    const style: any = {};

    if (this.props.head && this.props.height) {
      style.height = this.props.height;
    }

    if (this.props.rows && this.props.rows[this.props.rowIndex]) {
      style.height = this.props.rows[this.props.rowIndex].height;
    }

    return style;
  };

  // If the grid contains locked columns
  handleMouseEnter = () => {
    if (this.props.lockedColumns && this.props.handleHover) {
      this.props.handleHover(this.props.rowIndex, true);
    }
  };

  // If the grid contains locked columns
  handleMouseLeave = () => {
    if (this.props.lockedColumns && this.props.handleHover) {
      this.props.handleHover(this.props.rowIndex, false);
    }
  };

  handleSelectRow = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    // Application defined function
    if (this.props.selectRow) {
      this.props.selectRow(this.props.rowIndex);
    }

    // Calls function that handles the state update (internally defined)
    if (this.props.handleRowClick) {
      this.props.handleRowClick(this.props.rowIndex);
    }
  };

  // Render row
  renderRow = () => {
    const hasRowMetaData = this.props.rows && this.props.rows[this.props.rowIndex];
    const rowClassName = hasRowMetaData && this.props.rows[this.props.rowIndex].className;

    const classes = classNames({
      'vdl-row mdf-grid-row': true,
      'mdf-grid-subheader no-dnd': this.props.subheader,
      'mdf-grid-row-selected': (hasRowMetaData && this.props.rows[this.props.rowIndex].isSelected)
    }, rowClassName);

    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        unselectable="on"
        draggable={this.props.draggable}
        onClick={this.handleSelectRow}
        ref={(el) => this.rowElement = el}
        data-index={this.props.rowIndex}
        className={classes}
        style={this.getStyle()}
      >
        {this.renderCells(this.props.rowIndex, this.props.item)}
      </div>
    );
  };

  // Render head row
  renderHeadRow = () => (
    <div className={'vdl-row mdf-grid-header'} style={this.getStyle()} ref={this.createHeaderRefForLockedColumns}>
      {this.renderCells()}
    </div>
  );

  render() {
    // Render head rows or rows
    return this.props.head ? this.renderHeadRow() : this.renderRow();
  }
}
