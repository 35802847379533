import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WFNXCardBody } from './WFNXCardBody';
import { WFNXIconTitleBar } from './WFNXIconTitleBar';

export interface IWFNXBigCardProps {
  className?: string;
}

export class WFNXBigCard extends React.Component<React.PropsWithChildren<IWFNXBigCardProps>, any> {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    let header = null;
    let body = null;
    const { className } = this.props;
    const classes = [
      'wfnx-big-card'
    ];

    React.Children.forEach(this.props.children, (child: any): any => {
      if (child.type === WFNXIconTitleBar) {
        header = child;
      }
      else if (child.type === WFNXCardBody) {
        body = child;
      }
    });

    return (
      <div className={classNames(className, classes)}>
        {header}
        <div className="wfnx-big-body-card">
          {body}
        </div>
      </div>
    );
  }
}
