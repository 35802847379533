import React from 'react';
import PropTypes from 'prop-types';
import { IMDFCircleIconSize, MDFCircleIcon } from '../MDFCirlceIcon';
import classNames from 'classnames';
import { WFNXTitleBar } from './WFNXTitleBar';
import { MDFIcon } from '../MDFIcon';

export interface IWFNXTitleBarProps {
  className?: string;
  title?: React.ReactNode;
  titleBarIcon?: string;
  iconSize?: IMDFCircleIconSize;
  children?: React.ReactNode;
}

export class WFNXIconTitleBar extends React.Component<IWFNXTitleBarProps, any> {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    iconSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    children: PropTypes.node
  };

  render() {
    const { className, children, iconSize, title, titleBarIcon } = this.props;

    return (
      <div className={classNames(`wfnx-icon-titleBar-wrapper ${className}`)}>
        <WFNXTitleBar>
          <MDFCircleIcon size={iconSize || 'sm'} className={`titleBar-circle titleBar-circle--${iconSize}`}>
            <MDFIcon className={`${titleBarIcon} titleBar-icon-color`}></MDFIcon>
          </MDFCircleIcon>
          <div className="title"> {title}</div>
          {children}
        </WFNXTitleBar>
      </div>
    );
  }
}
