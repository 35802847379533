import React, { useContext } from 'react';
import classNames from 'classnames';
import { SmallCardTitle } from './SmallCardTitle';
import { SmallCardAction } from './SmallCardAction';
import { SmallCardContext } from '../MDFContext';

export interface ISmallCardTopProps {
  // Assign className for SmallCardTop
  className?: string;
  // The children to render within this SmallCardTop.
  children?: any;
  // id support
  id?: string;
}

export function SmallCardTop(props: ISmallCardTopProps) {
  const { className } = props;
  let cardTitle = null;
  let smallCardAction = null;
  const context: any = useContext(SmallCardContext);
  const isSelectable = context && context.selectable;

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === SmallCardTitle) {
        return cardTitle = <SmallCardTitle id={child.id || props.id} {...child.props}></SmallCardTitle>;
      }

      if (child.type === SmallCardAction) {
        smallCardAction = <SmallCardAction id={child.id || props.id} {...child.props}></SmallCardAction>;
      }
    }
    else {
      return null;
    }
  });

  return (
    <div className={classNames(`small-card-top small-card-top--${isSelectable && 'selectable' || 'columnLayout'} ${cardTitle && isSelectable && 'mdf-cardTop-flexDirection' || ''}`, className)}>
      {cardTitle}
      {smallCardAction}
    </div>
  );
}
