import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { FormatHelper } from '@adp-wfn/mdf-core';

import {
  Button,
  IModalProps,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from '@synerg/vdl-react-components';

import SuccessIcon from 'adp-react-icons/lib/fa/check-circle';
import ErrorIcon from 'adp-react-icons/lib/fa/exclamation-circle';
import AlertIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import InfoIcon from 'adp-react-icons/lib/fa/info-circle';

export interface IMDFModalDialogProps extends IModalProps {
  children?: React.ReactNode;
  closeable?: boolean;
  closeButtonId?: string;
  closeButtonText?: string;
  containerClassName?: string;
  disabled?: boolean;
  disabledNoButton?: boolean;
  disabledOkButton?: boolean;
  disabledYesButton?: boolean;
  footer?: any;
  hasNoButton?: boolean;
  hideFooter?: boolean;
  modalType?: string;
  modalTypeClass?: string;
  noButtonId?: string;
  noButtonText?: string;
  okButtonId?: string;
  okButtonText?: string;
  onNo?: () => void;
  onOkClicked?: () => void;
  onYes?: () => void;
  // Accessibility feature - Moves focus to a specific element after the modal dialog closes.
  // Enable feature by passing matching string values to the modal dialog's restoreFocusNodeId property and the next focused element's id property.
  restoreFocusNodeId?: string;
  title?: string;
  titleElement?: React.ReactNode;
  yesButtonId?: string;
  yesButtonText?: string;
}

export class MDFModalDialog extends React.Component<IMDFModalDialogProps, {}> {
  static mdfModalPropTypes = {
    closeable: PropTypes.bool,
    closeButtonId: PropTypes.string,
    closeButtonText: PropTypes.string,
    containerClassName: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledNoButton: PropTypes.bool,
    disabledOkButton: PropTypes.bool,
    disabledYesButton: PropTypes.bool,
    hasNoButton: PropTypes.bool,
    hideFooter: PropTypes.bool,
    modalType: PropTypes.string,
    noButtonId: PropTypes.string,
    noButtonText: PropTypes.string,
    onNo: PropTypes.func,
    okButtonId: PropTypes.string,
    okButtonText: PropTypes.string,
    onOkClicked: PropTypes.func,
    onYes: PropTypes.func,
    restoreFocusNodeId: PropTypes.string,
    yesButtonId: PropTypes.string,
    yesButtonText: PropTypes.string
  };

  static propTypes = Object.assign(
    {},
    Modal.propTypes,
    MDFModalDialog.mdfModalPropTypes
  );

  static defaultProps: IMDFModalDialogProps = {
    hasNoButton: true,
    hideFooter: false
  };

  render() {
    const modalType = (this.props.modalType && this.props.modalType.toLowerCase()) || '';
    let modalTypeClass;
    let titleElement;
    let modalFooter;
    const customProps = omit(this.props, Object.keys(MDFModalDialog.mdfModalPropTypes), ['backdrop']);

    switch (modalType) {
      case 'success':
        modalTypeClass = 'vdl-modal--success';
        titleElement = <SuccessIcon className="vdl-modal__title-icon" />;
        modalFooter = <ModalFooter><Button {...(this.props.closeButtonId && { id: this.props.closeButtonId })} onClick={this.props.onHide}>{this.props.closeButtonText || FormatHelper.formatMessage('@@Close')} </Button></ModalFooter>;
        break;
      case 'error':
        modalTypeClass = 'vdl-modal--error';
        titleElement = <ErrorIcon className="vdl-modal__title-icon" />;
        modalFooter = <ModalFooter><Button {...(this.props.closeButtonId && { id: this.props.closeButtonId })} onClick={this.props.onHide}>{this.props.closeButtonText || FormatHelper.formatMessage('@@Close')}</Button></ModalFooter>;
        break;
      case 'alert':
        modalTypeClass = 'vdl-modal--alert';
        titleElement = <AlertIcon className="vdl-modal__title-icon" />;
        modalFooter = <ModalFooter>{this.props.hasNoButton && <Button {...(this.props.noButtonId && { id: this.props.noButtonId })} disabled={this.props.disabledNoButton || this.props.disabled} onClick={this.props.onNo} buttonStyle="secondary">{this.props.noButtonText || FormatHelper.formatMessage('@@No')}</Button>} <Button {...(this.props.yesButtonId && { id: this.props.yesButtonId })} disabled={this.props.disabledYesButton || this.props.disabled} onClick={this.props.onYes}>{this.props.yesButtonText || FormatHelper.formatMessage('@@Yes')}</Button></ModalFooter>;
        break;
      case 'info':
        modalTypeClass = 'vdl-modal--info';
        titleElement = <InfoIcon className="vdl-modal__title-icon" />;
        modalFooter = <ModalFooter><Button {...(this.props.closeButtonId && { id: this.props.closeButtonId })} onClick={this.props.onHide}>{this.props.closeButtonText || FormatHelper.formatMessage('@@Close')} </Button></ModalFooter>;
        break;
      default:
        modalTypeClass = this.props.modalTypeClass ? this.props.modalTypeClass : '';
        titleElement = this.props.titleElement ? this.props.titleElement : '';
        modalFooter = this.props.onOkClicked ? <ModalFooter><Button {...(this.props.okButtonId && { id: this.props.okButtonId })} disabled={this.props.disabledOkButton || this.props.disabled} onClick={this.props.onOkClicked}>{this.props.okButtonText || FormatHelper.formatMessage('@@Ok')}</Button></ModalFooter> : '';
    }

    modalTypeClass = 'mdf ' + modalTypeClass;

    return (
      <Modal backdrop="static" {...(customProps as IModalProps)} className={`${modalTypeClass} ${this.props.containerClassName || ''}`} restoreFocusNodeId={this.props.restoreFocusNodeId || null}>
        <ModalHeader closeButton={this.props.closeable}>
          <ModalTitle>
            {titleElement}
            {this.props.title}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="vdl-mdf-modal-content">
            {this.props.children}
          </div>
        </ModalBody>
        {(this.props.footer && !this.props.hideFooter) ? <ModalFooter>{this.props.footer}</ModalFooter> : modalFooter}
      </Modal>
    );
  }
}
