import React from 'react';

export default function Resizable(WrappedComponent) {
  const elem: any = {};
  let newWidth: string;

  return class ResizableCell extends React.Component<any, any> {
    constructor(props) {
      super(props);
    }

    dragStart = (e) => {
      elem.lastMousePos = e.clientX;
      elem.startingWidth = e.target.parentElement.offsetWidth;
    };

    drag = (e) => {
      const currMousePos = e.clientX;

      if (currMousePos > 0) {
        const delta = currMousePos - elem.lastMousePos;
        newWidth = elem.startingWidth + delta + 'px';

        e.target.parentElement.style['width'] = newWidth;
        e.target.parentElement.style['flex'] = '0 0 auto';
        e.target.style['cursor'] = 'col-resize';

        e.preventDefault();
      }
    };

    dragEnd = (/* e */) => {
      this.props.handleResize?.(this.props.columnIndex, newWidth, this.props.lockedGrid);
    };

    render() {
      return (
        <WrappedComponent {...this.props}>
          <div className={'mdf-grid-resizable-cell'} draggable={true} onDragStart={this.dragStart} onDrag={this.drag} onDragEnd={this.dragEnd}>      {this.props.children}
          </div>
        </WrappedComponent>
      );
    }
  };
}
