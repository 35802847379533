import React from 'react';

import { Textbox } from './Textbox';
import { omit } from 'lodash';

export class MDFTextbox extends React.Component<any, any> {
  public textBox: Textbox;

  private setTextBox = (textBox: Textbox) => {
    this.textBox = textBox;
  };

  static displayName = 'MDFTextbox';

  // The code that used to be here was moved to the Textbox class, so this becomes just a pass-through for backward compatibility.
  render() {
    const props = omit(this.props, 'usePopover', 'helpMessage', 'labelText');
    return <Textbox {...props} ref={this.setTextBox}/>;
  }
}
