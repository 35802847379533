import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { MDFDropdownList } from './MDFDropdownList';
import { MDFFlag } from './MDFFlag';
import { MDFIcon } from './MDFIcon';

export interface ILocalePickerProps {
  locales?: any;
  onChange?: (value) => {};
  selectedLocaleText?: string;
  translationAvailableText?: string;
  translationNotAvailableText?: string;
  compact?: boolean;
  disabled?: boolean;
}

export interface ILocalePickerState {
  selected?: number;
}

function LocaleItem(props) {
  const idIndex = props.item.id.indexOf('_');
  let country = 'us';

  if (idIndex >= 0) {
    country = props.item.id.substring(idIndex + 1, props.item.id.length).toLowerCase();
  }

  return <div className="locale-picker__item"><MDFFlag countryCode={country} /> <span>{props.item.name}</span></div>;
}

export class LocalePicker extends React.Component<ILocalePickerProps, ILocalePickerState> {
  static propTypes = {
    locales: PropTypes.any,
    onChange: PropTypes.func,
    translationAvailableText: PropTypes.string,
    translationNotAvailableText: PropTypes.string,
    selectedLocaleText: PropTypes.string,
    compact: PropTypes.bool,
    disabled: PropTypes.bool
  };

  private defaultLocales = [
    {
      id: 'en_US',
      name: 'English (US)',
      translationsAvailable: true,
      selected: true
    },
    {
      id: 'es_US',
      name: 'Spanish (US)',
      translationsAvailable: false,
      selected: false
    },
    {
      id: 'en_CA',
      name: 'English (CA)',
      translationsAvailable: false,
      selected: false
    },
    {
      id: 'fr_CA',
      name: 'French (CA)',
      translationsAvailable: true,
      selected: false
    }
  ];

  locales: any;

  constructor(props: any) {
    super(props);

    this.locales = this.props.locales || this.defaultLocales;

    const selected: any = this.locales.filter((item: any, index) => {
      if (item.selected) {
        item.index = index;
        return item;
      }
      else {
        return false;
      }
    });

    if (selected && selected.length > 0) {
      this.state = { selected: selected[0].index };
    }
    else {
      this.state = { selected: null };
    }
  }

  // tslint:disable-next-line:variable-name
  private _onChange = (_e, index) => {
    console.log('LocalePicker._onChange(). Entering.', index);

    if (this.locales) {
      this.locales.forEach((locale) => locale.selected = false);
    }

    this.locales[index].selected = true;

    this.setState(() => ({
      selected: index
    }));

    if (this.props.onChange) {
      this.props.onChange(this.locales[index]);
    }
  };

  private onSelect = (item) => {
    console.log('LocalePicker.onSelect(). Entering.', item);
    let selectedIndex = null;

    if (this.locales) {
      this.locales = this.locales.map((locale, index) => {
        locale.selected = locale.id === item.id;

        if (locale.selected) {
          selectedIndex = index;
        }

        return locale;
      });
    }

    this.setState(() => ({
      selected: selectedIndex
    }));

    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  handleItemKeyDown = (event, index) => {
    let node;

    if (event.key === 'ArrowRight' && this.locales && this.locales[index + 1]) {
      this._onChange(null, index + 1);
      node = event.target.nextSibling;
    }

    if (event.key === 'ArrowLeft' && this.locales && this.locales[index - 1]) {
      this._onChange(null, index - 1);
      node = event.target.previousSibling;
    }

    if (node) {
      node.focus();
    }
  };

  renderLocale = (locale, index) => {
    const id = locale.id;
    let selected = false;
    const disabled = this.props.disabled;

    const idIndex = id.indexOf('_');
    let country = 'us';

    if (index >= 0) {
      country = id.substring(idIndex + 1, id.length).toLowerCase();
    }

    // Label (Text & Flag)
    const labelText = locale.name;

    // Description (Check + Text)
    let descriptionText = '';

    if (locale.translationsAvailable) {
      if (this.props.translationAvailableText) {
        descriptionText = this.props.translationAvailableText;
      }
      else {
        descriptionText = FormatHelper.formatMessage('@@TranslationsAvailable');
      }
    }
    else {
      if (this.props.translationNotAvailableText) {
        descriptionText = this.props.translationNotAvailableText;
      }
      else {
        descriptionText = FormatHelper.formatMessage('@@NotAvailable');
      }
    }

    const innerClassName = 'locale-picker__card-inner mdf-card-atom mdf-card--flat';

    if (locale.selected) {
      selected = true;
      if (this.props.selectedLocaleText) {
        descriptionText = this.props.selectedLocaleText;
      }
    }

    let iconClassName = 'locale-picker__card-description-icon';

    if (locale.translationsAvailable) {
      iconClassName += ' fa fa-check';
    }

    const localeClasses = classNames({
      ['locale-picker__card-outer']: true,
      ['locale-picker__card-outer-disbled']: disabled
    });

    return (
      <div className={localeClasses} role="option" aria-selected={selected} {...(disabled && { disabled: true })} aria-disabled={disabled} tabIndex={(!disabled && selected) ? 0 : -1} onKeyDown={(event) => !disabled ? this.handleItemKeyDown(event, index) : null} >
        <div className={`${innerClassName} ${selected ? 'selected' : ''}`} {...(disabled && { disabled: true })} aria-disabled={disabled} onClick={(e) => !disabled ? this._onChange(e, index) : null}>
          <div className="locale-picker__card-label">
            <span className="locale-picker__card-label-text">{labelText}</span>
            <MDFFlag countryCode={country} />
          </div>
          <div className="locale-picker__card-description">
            {locale.translationsAvailable && <MDFIcon className={iconClassName} />}
            <span className="locale-picker__card-description-text">{descriptionText}</span>
          </div>
        </div>
      </div>
    );
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.locales) {
      const selected: any = nextProps.locales.filter((item: any, index) => {
        if (item.selected) {
          item.index = index;
          return item;
        }
        else {
          return false;
        }
      });

      if (selected && selected.length > 0) {
        return {
          selected: selected[0].index
        };
      }
      else {
        return {
          selected: null
        };
      }
    }

    return null;
  }

  render() {
    this.locales = this.props.locales || this.locales;

    if (this.props.compact) {
      const selectedItem = this.state.selected !== null ? this.locales[this.state.selected] : null;

      return (
        <MDFDropdownList
          data={this.locales}
          itemComponent={LocaleItem}
          textField={'name'}
          valueField={'id'}
          value={selectedItem}
          valueComponent={LocaleItem}
          onSelect={this.onSelect}
          aria-label={this.props['aria-label']}
        />);
    }
    else {
      return (
        <div className="locale-picker" role="listbox" aria-label={this.props['aria-label']}>
          {this.locales && this.locales.map(this.renderLocale)}
        </div>
      );
    }
  }
}
