import React from 'react';
import PropTypes from 'prop-types';

import { FormatHelper } from '@adp-wfn/mdf-core';
import { SdfExpandableBox, SdfIcon } from '@waypoint/react-components';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

export interface IWFNXCollapsibleCardProps {
  className?: string;
  collapsible?: boolean;
  collapsed?: boolean;
  onCollapse?: () => void;
  onExpand?: () => void;
  headerClass?: string;
  iconClass?: string;
  title: string;
}

export class WFNXCollapsibleCard extends React.Component<React.PropsWithChildren<IWFNXCollapsibleCardProps>, any> {
  static propTypes = {
    className: PropTypes.string,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  private onCollapse = () => this.props.onCollapse?.();
  private onExpand = () => this.props.onExpand?.();

  render() {
    const { className, collapsed, headerClass, iconClass, title } = this.props;
    const props: any = {
      collapseLabel: FormatHelper.formatMessage('@@CollapseCard'),
      expandLabel: FormatHelper.formatMessage('@@ExpandCard'),
      triggerPlacement: 'after',
      variant: 'shadowed',
      onSdfOpen: this.onExpand,
      onSdfClose: this.onCollapse
    };

    if (className) {
      props.className = className;
    }

    if (title && !(headerClass || iconClass)) {
      props.header = title;
    }

    if (!collapsed) {
      props.opened = true;
    }

    return (
      <SdfExpandableBox {...props}>
        {(headerClass || iconClass) &&
          <div slot="header" className={headerClass ?? ''}>
            {iconClass && <SdfIcon className="mr-2" icon={iconClass as IconName}/>}
            <span className={'text-xl font-bold leading-md tracking-xs'}>{title}</span>
          </div>
        }
        <div className="mt-4 pt-4 border-t-2 border-l-0 border-r-0 border-b-0 border-solid border-dark">
          {this.props.children}
        </div>
      </SdfExpandableBox>
    );
  }
}
