import React from 'react';
import PropTypes from 'prop-types';
import filters from './filter';

const filterTypes = Object.keys(filters).filter((i) => i !== 'filter');

function getInteractionPropType(key) {
  const types = [
    PropTypes.bool,
    PropTypes.oneOf([key])
  ];
  const propType = PropTypes.oneOfType(types);

  propType['acceptsArray'] = PropTypes.oneOfType(types.concat(PropTypes.array));

  return propType;
}

function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, ...rest) {
    componentName = componentName || '<<anonymous>>';
    if (props[propName] == null) {
      if (isRequired) {
        return new Error('Required prop `' + propName + '` was not specified in  `' + componentName + '`.');
      }
    }
    else {
      return validate(props, propName, componentName, ...rest);
    }
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

export const elementType = createChainableTypeChecker(
  function(props, propName, componentName) {

    if (typeof props[propName] !== 'function') {
      if (React.isValidElement(props[propName])) {
        return new Error(
          'Invalid prop `' + propName + '` specified in  `' + componentName + '`.' +
          ' Expected an Element `type`, not an actual Element');
      }

      if (typeof props[propName] !== 'string') {
        return new Error(
          'Invalid prop `' + propName + '` specified in  `' + componentName + '`.' +
          ' Expected an Element `type` such as a tag name or return value of React.createClass(...)');
      }
    }

    return null;
  }
);

export const disabled = getInteractionPropType('disabled');

export const readOnly = getInteractionPropType('readOnly');

export const selectOnly = getInteractionPropType('readOnly');

export const accessor = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func
]);

export const message = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string
]);

export const filter = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.bool,
  PropTypes.oneOf(filterTypes)
]);

export default {
  accessor,
  disabled,
  elementType,
  filter,
  message,
  readOnly,
  selectOnly
};
