import React from 'react';
import ReactDOM from 'react-dom';
import { DeviceHelper } from '@adp-wfn/mdf-core';
import { MDFButton } from './MDFButton';
import { IButtonStyle } from '@synerg/vdl-react-components';

export interface IMDFOpenExternalUriProps {
  cancelLabel?: string;
  buttonStyle?: IButtonStyle;
  disabled?: boolean;
  hideAlert?: boolean;
  iconClass?: string;
  message?: string;
  okLabel?: string;
  onClick?: () => void;
  popupTitle?: string;
  title: string;
  uri: string;
}

export class MDFOpenExternalUri extends React.Component<IMDFOpenExternalUriProps, any> {
  private id: string;
  private isMobile: boolean;
  private mobileContainer: any;

  constructor(props: IMDFOpenExternalUriProps) {
    super(props);

    this.id = new Date().getTime().toString(16);
    this.isMobile = DeviceHelper.isADPMobileApp();
    this.mobileContainer = DeviceHelper.getADPMobileContainer();
  }

  handleOnClick = () => {
    if (this.isMobile && this.mobileContainer) {
      const externalResourceAction = {
        ExternalResourceAction: {
          cancel: this.props.cancelLabel || 'Cancel',
          forceBrowser: false,
          hideAlert: this.props.hideAlert || false,
          message: this.props.message || 'Open in browser',
          ok: this.props.okLabel || 'Browser',
          resourceURI: this.props.uri,
          title: this.props.popupTitle || 'Open in Browser'
        },
        type: 'ExternalResourceAction'
      };

      console.log('externalResourceAction: ' + JSON.stringify(externalResourceAction));

      this.mobileContainer.Action.processAction(
        externalResourceAction,
        (success: any) => {
          console.log(JSON.stringify(success));
        },
        (error: any) => {
          console.error(JSON.stringify(error));
        }
      );
    }
    else {
      // Desktop just click the a tag programmatically.
      const link = document.getElementById(this.id);

      if (link) {
        link.click();
      }
    }

    this.props.onClick?.();
  };

  renderPortal = () => ReactDOM.createPortal(<a id={this.id} href={this.props.uri} rel="noopener noreferrer" target="_blank"></a>, document.body);

  render() {
    return (
      <React.Fragment>
        <MDFButton buttonStyle={this.props.buttonStyle || 'primary'} disabled={this.props.disabled} iconClass={this.props.iconClass} onClick={this.handleOnClick}>{this.props.title}</MDFButton>
        {!this.isMobile && this.renderPortal()}
      </React.Fragment>
    );
  }
}
