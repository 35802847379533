import React from 'react';
import dragula from 'react-dragula';
import autoScroll from 'dom-autoscroller';
import 'dragula/dist/dragula.min.css';

export interface IDraggableSpreadSheetProps {
  isGrid?: boolean;
  children: any;
  onChange: any;
  draggable: any;
  componentId?: string;
}

export default class DraggableSpreadSheet extends React.Component<IDraggableSpreadSheetProps, any> {
  private containers: HTMLElement[];
  private drake;

  constructor(props) {
    super(props);

    this.containers = [];
  }

  componentDidMount() {
    if (this.props.draggable) {
      this.initDraggable();
    }
  }

  componentWillUnmount() {
    if (this.drake) {
      this.drake.destroy();
    }
  }

  initDraggable = () => {
    const draggable = (el) => !el.classList.contains('no-dnd');

    // Instantiate dragula, tell it which containers hold draggable rows, and which rows are draggable
    const drake = dragula(this.containers, {
      accepts: draggable,
      moves: draggable
    });

    this.drake = drake;

    // Enable dom autoscroller only when autoScroll property is set to true.
    const autoScrollProps = {
      syncMove: true,
      margin: 30,
      maxSpeed: 10,
      scrollWhenOutside: true,
      autoScroll: function() {
        // Only scroll when the pointer is down, and there is a child being dragged.
        return this.down && drake.dragging;
      }
    };

    const draggableLockedBody = this.containers[0]?.closest(`div.${this.props.isGrid ? 'mdf-grid-layout' : 'mdf-spreadsheet'}`)?.querySelector('div.locked-body');
    const draggableUnLockedBody = this.containers[0]?.closest(`div.${this.props.isGrid ? 'mdf-grid-layout' : 'mdf-spreadsheet'}`)?.querySelector('div.unlocked-body')?.parentElement;
    const draggableContainers = [];

    if (draggableLockedBody) {
      draggableContainers.push(draggableLockedBody);
    }

    if (draggableUnLockedBody) {
      draggableContainers.push(draggableUnLockedBody);
    }

    autoScroll(draggableContainers, autoScrollProps);

    this.drake.on('drop', (el, target, source, sibling) => {
      // Ensure nothing happens to the DOM that react doesn't know about.
      this.drake.cancel(true);

      // Function to update row positions
      this.updateGridLayout(el, target, source, sibling);
    });
  };

  collectContainers = (container: HTMLElement) => {
    this.containers.push(container);
  };

  updateGridLayout = (el, target, source, sibling) => {
    const currentRowIndex = Number(el.getAttribute('data-row'));
    const currentSubGroupIndex = Number(source.getAttribute('data-group'));
    const targetSubGroupIndex = Number(target.getAttribute('data-group'));
    let targetRowIndex = -1;

    if (sibling) {
      targetRowIndex = Number(sibling.getAttribute('data-row'));
    }

    if (this.props.onChange) {
      this.props.onChange({
        currentIndex: currentRowIndex, currentSubGroupIndex: currentSubGroupIndex, targetSubGroupIndex: targetSubGroupIndex, targetIndex: targetRowIndex, componentId: this.props.componentId
      });
    }

    this.forceUpdate();
  };

  render() {
    const draggableProps = Object.assign({}, this.props, { collectContainers: this.collectContainers });

    return (
      <div>
        {this.props.children(draggableProps)}
      </div>
    );
  }
}
