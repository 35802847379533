import ReactDOM from 'react-dom';

export class GridHelper {
  // Method to bind the dataset
  static fetchItem(items: any[], start: number, count: number, resolve) {
    let fetchItems = items;

    // Slice
    fetchItems = this.fetchPaginatedList(fetchItems, start, count);

    // Return
    resolve(fetchItems, items.length);
  }

  static fetchPaginatedList(items: any[], start: number, count: number): any[] {
    let paginatedList;

    if (start + count < items.length) {
      paginatedList = items.slice(start, start + count);
    }
    else {
      paginatedList = items.slice(start, items.length);
    }

    return paginatedList;
  }

  static getFlexStyles = (column) => {
    const width = column ? column.width : null;

    return width ? { flex: '0 0 auto', width } : {};
  };

  static shallowEqual = (objA, objB) => {
    if (objA === objB) {
      return true;
    }

    if ((typeof objA !== 'object') || (objA === null) ||
      (typeof objB !== 'object') || (objB === null)) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    // Test for A's keys different from B.
    const bHasOwnProperty = objB.hasOwnProperty.bind(objB);

    for (const i of keysA) {
      if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }

    return true;
  };

  static shallowCompare = (instance, nextProps, nextState) => (
    !GridHelper.shallowEqual(instance.props, nextProps) ||
    !GridHelper.shallowEqual(instance.state, nextState)
  );

  static getElementsByClassName = (classname: string, thisComponent) => {
    const component = ReactDOM.findDOMNode(thisComponent) as HTMLElement;

    if (component) {
      return component.getElementsByClassName(classname);
    }

    return undefined;
  };

  // helper function to check if it is an IE browser
  static isIE = () => {
    const uAgent = window.navigator.userAgent;

    return /MSIE|Trident/.test(uAgent) ? true : false;
  };
}
