import React from 'react';
import { IWFNMockShellActivePopover, WFNMockShellActivePopoverType } from '../WFNMockShell';
import { Button } from '@synerg/vdl-react-components';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellToolbarPopoverNavProps {
  activePopover: IWFNMockShellActivePopover;
  toolbarItemIndex?: number;
  type: WFNMockShellActivePopoverType;
}

export const WFNMockShellToolbarPopoverNav = ({ toolbarItemIndex, activePopover, type }: IWFNMockShellToolbarPopoverNavProps) => {
  const handlePopoverClick = (action: 'next' | 'prev') => {
    activePopover.handlePopoverNav({
      index: toolbarItemIndex,
      type,
      action
    });
  };

  const prevBtnClassName = activePopover.renderPrevBtn ? '' : 'nav-hidden';
  const nextBtnClassName = activePopover.renderNextBtn ? '' : 'nav-hidden';

  return (
    <div className="wfnmockshell-popover-nav">
      <Button className={`wfnmockshell-popover-nav-btn ${prevBtnClassName}`} onClick={handlePopoverClick.bind(null, 'prev')} buttonStyle="link">{'< ' + FormatHelper.formatMessage('@@label_ei9_btn_previous')}</Button>
      <Button className={`wfnmockshell-popover-nav-btn ${nextBtnClassName}`} onClick={handlePopoverClick.bind(null, 'next')} buttonStyle="link">{FormatHelper.formatMessage('@@DC_Next') + ' >'}</Button>
    </div>
  );
};

WFNMockShellToolbarPopoverNav.displayName = 'WFNMockShellToolbarPopoverNav';
