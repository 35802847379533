declare const process;

const vdlPrefix = (className: string): string => {
  if (process?.env?.VDL_PREFIX) {
    return process.env.VDL_PREFIX + className;
  }
  else {
    return 'vdl-' + className;
  }
};

export default vdlPrefix;
