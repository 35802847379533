import React from 'react';
import { SdfAlert, SdfButton } from '@waypoint/react-components';

export type IAlertType = 'success' | 'info' | 'warning' | 'error' | 'neutral';
export type IAlertSize = 'xs' | 'sm' | 'md' | 'lg';
export type IAlertStyle = 'default' | 'inline';

const DEFAULT_TIMEOUT = 4000;

export interface IAlertProps {
  alertStyle?: IAlertStyle;
  animate?: boolean;
  autoClose?: boolean;
  autoCloseAfter?: number;
  children?: React.ReactNode;
  className?: string;
  closeable?: boolean;
  defaultVisible?: boolean;
  emphasis?: 'primary' | 'secondary'
  hideIcon?: boolean;
  icon?: React.ReactNode;
  iconClassName?: string;
  isVisible?: boolean;
  onClose?: () => void;
  onUnmount?: () => void;
  role?: 'alert' | 'none';
  size?: IAlertSize;
  title?: React.ReactNode;
  toast?: boolean;
  type?: IAlertType;
  variableContent?: boolean;
}

export interface IAlertState {
  isVisible: boolean;
}

export class Alert extends React.Component<IAlertProps, IAlertState> {
  state: IAlertState = {
    isVisible: this.props.defaultVisible ?? true
  };

  isVisible = () => {
    return this.state.isVisible;
  };

  toggle = () => {
    this.setState({
      isVisible: !this.state.isVisible
    });
  };

  show = () => {
    this.setState({
      isVisible: true
    });
  };

  hide = () => {
    this.setState({
      isVisible: false
    });
  };

  static getDerivedStateFromProps(props: IAlertProps, state: IAlertState) {
    if (props.isVisible !== undefined && props.isVisible !== state.isVisible) {
      return {
        isVisible: props.isVisible
      };
    }

    return null;
  }

  render() {
    const alertProps: any = {
      autoClose: this.props.autoClose,
      autoCloseAfter: this.props.autoCloseAfter ?? DEFAULT_TIMEOUT,
      className: this.props.className,
      closeable: this.props.closeable,
      emphasis: this.props.emphasis,
      hidden: !this.state.isVisible,
      hideIcon: this.props.hideIcon,
      icon: this.props.iconClassName,
      role: this.props.role,
      size: this.props.size,
      status: this.props.type,
      toast: this.props.toast,
      type: this.props.alertStyle === 'inline' ? 'inline' : 'banner',
      useAnimation: this.props.animate,
      onSdfDismiss: this.props.onClose
    };

    Object.keys(alertProps).forEach((key) => {
      if (alertProps[key] === false || alertProps[key] === undefined) {
        delete alertProps[key];
      }
    });

    return (
      <SdfAlert {...alertProps}>
        {this.props.title && <span slot="title">{this.props.title}</span>}
        {this.props.icon && !this.props.iconClassName && <span slot="icon">{this.props.icon}</span>}
        {this.props.children}
        {this.props.variableContent && <span slot="action"><SdfButton variant="text">View insights</SdfButton></span>}
      </SdfAlert>
    );
  }
}
