import React from 'react';
import PropTypes from 'prop-types';
import { DeviceHelper, FormatHelper } from '@adp-wfn/mdf-core';
import { MDFButton } from './MDFButton';

export interface IVideoProps {
  adaptive?: boolean;
  autoPlay?: boolean;
  className?: string;
  loop?: boolean;
  src?: string;
  controls?: boolean;
  url?: string;
  'aria-label'?: string;
}

export class Video extends React.Component<IVideoProps, any> {

  constructor(props: IVideoProps) {
    super(props);
  }

  static propTypes = {
    adaptive: PropTypes.bool,
    autoPlay: PropTypes.bool,
    className: PropTypes.string,
    loop: PropTypes.bool,
    src: PropTypes.string,
    controls: PropTypes.bool,
    url: PropTypes.string
  };

  videoPropsError = () => {
    throw new Error('Video Error: Only the "src" OR "url" property is allowed. Please pick one.');
  };

  handleOverlayClick = () => {
    DeviceHelper.getADPMobileContainer().Logger.log('info', 'mobile overlay click');

    if (!this.props.url) {
      DeviceHelper.getADPMobileContainer().Logger.log('info', 'No url provided.');
      return;
    }

    const url = this.props.url;
    const videoProperties = this.parseUrl(url);

    if (!videoProperties) {
      DeviceHelper.getADPMobileContainer().Logger.log('error', 'No video properties found.');
      return;
    }

    DeviceHelper.getADPMobileContainer().Logger.log('info', 'video properties: ' + JSON.stringify(videoProperties));

    const actionObject = {
      OpenVideoAction: {
        videos: [videoProperties]
      }
    };

    DeviceHelper.getADPMobileContainer().Action.processAction(actionObject, (response) => {
      DeviceHelper.getADPMobileContainer().Logger.log('info', 'success response: ' + JSON.stringify(response));
    }, (error) => {
      DeviceHelper.getADPMobileContainer().Logger.log('error', 'error response: ' + JSON.stringify(error));
    });
  };

  parseUrl = (url: string) => {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    let type: string;
    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

    if (RegExp.$3.includes('youtu')) {
      type = 'youtube';
    }
    else if (RegExp.$3.includes('vimeo')) {
      type = 'vimeo';
    }

    const result = {
      uri: RegExp.$6,
      deliveryMethod: type,
      videoId: RegExp.$6
    };

    console.log('parsed properties: ' + JSON.stringify(result));

    return result;
  };

  renderMobile = () => {
    const title = FormatHelper.formatMessage('@@play_video').toUpperCase();

    return (
      <div className="mdf-video-mobile">
        <MDFButton iconClass="fa fa-play-circle" onClick={this.handleOverlayClick}>{title}</MDFButton>
      </div>
    );
  };

  render() {
    const isMobile = DeviceHelper.isADPMobileApp() && this.props.adaptive;
    let element;

    if (this.props.url) {
      console.log('video props: ' + JSON.stringify(this.parseUrl(this.props.url)));
    }

    if (!this.props.url && this.props.src) {
      element = (
        <video
          className={this.props.className}
          autoPlay={this.props.autoPlay}
          loop={this.props.loop}
          src={this.props.src}
          controls={this.props.controls}>
        </video>
      );
    }
    else if (this.props.url && !this.props.src) {
      element = (
        <iframe aria-label={this.props['aria-label']} src={this.props.url} className="mdf-video iframe"></iframe>
      );
    }
    else {
      this.videoPropsError();
    }

    return (
      <React.Fragment>
        {
          isMobile && this.renderMobile()
        }
        {
          !isMobile && <div className="mdf-video-container">{element}</div>
        }
      </React.Fragment>
    );
  }
}
