import React from 'react';
import { isEqual } from 'lodash';
import { DateHelper } from './DateHelper';
import { FormatHelper, RenderHelper } from '@adp-wfn/mdf-core';
import { MDFBusyIndicator } from './MDFBusyIndicator';

export interface ITimeEvent {
  date: Date | string;
  label: string;
}

export interface ITimeFrameProps {
  points: string[] | Date[] | ITimeEvent[];
  onTimelineSelect: (selectedDate: Date, origPointIdx?: number, isoDate?) => Promise<any>;
  onTimelineClose?: () => any;
  iconClass?: string;
  selectedDate: string | Date;
  tentativeDate?: string | Date;
  loading?: boolean;
  sort?: boolean;
  header?: object;
}

export class TimeFrame extends React.Component<React.PropsWithChildren<ITimeFrameProps>, any> {
  constructor(props: ITimeFrameProps) {
    super(props);

    this.state = {
      timelineOpen: false,
      timeline: [],
      points: [],
      contentClass: '',
      timelineLinkClass: '',
      selectedIdx: -1
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let timeline = prevState.timeline;
    const currentSelectedDate = nextProps.selectedDate && DateHelper.getDate(nextProps.selectedDate);
    let selectedIdx = prevState.selectedIdx;
    let timelineShouldOpen = prevState.timelineOpen;
    // control slide in animation from within component only if not controlled by loading prop and ensure animation does not happen on initial render
    const newContentClass = nextProps.loading ? 'mdf-time-frame-slide-out-bottom' : nextProps.loading === false ? prevState.contentClass && 'mdf-time-frame-slide-in-top' : prevState.contentClass;

    // reconstruct timeline if new date list or new tentative date
    if (!isEqual(nextProps.points, prevState.points) || !isEqual(nextProps.tentativeDate, prevState.tentativeDate)) {
      timeline = TimeFrame.constructTimeline(nextProps.points, nextProps.sort, nextProps.tentativeDate !== nextProps.selectedDate ? nextProps.tentativeDate : undefined);
    }

    if (currentSelectedDate && !DateHelper.equals(currentSelectedDate, timeline[selectedIdx] && timeline[selectedIdx].date)) {
      selectedIdx = timeline.findIndex((event) => DateHelper.equals(currentSelectedDate, event.date));
    }

    // derive from props only if new selected date or new tentative date
    if (!isEqual(currentSelectedDate, prevState.selectedDate) || !isEqual(nextProps.tentativeDate, prevState.tentativeDate)) {
      timelineShouldOpen = selectedIdx !== -1 || nextProps.tentativeDate;
    }

    return {
      ...prevState,
      contentClass: newContentClass,
      animating: newContentClass === 'mdf-time-frame-slide-out-bottom' || prevState.animating,
      timeline: timeline,
      timelineOpen: timelineShouldOpen,
      timelineLinkClass: timelineShouldOpen ? 'vdl-invisible' : prevState.timelineLinkClass,
      points: nextProps.points,
      selectedDate: currentSelectedDate,
      tentativeDate: nextProps.tentativeDate,
      selectedIdx: selectedIdx
    };
  }

  static constructTimeline(points, sort = true, tentativeDate): ITimeEvent[] {
    // this doesn't need to be a separate function anymore, no more markers
    const eventFormatter = (point, idx) => {
      const date = DateHelper.getDate(point.date || point);

      return {
        date: date,
        type: 'point',
        label: point.label || FormatHelper.formatDate(date, 'short'),
        pointIdx: idx
      };
    };

    const timeline = points.map(eventFormatter);

    // if the tentative date is already in the timeline, make that date tentative
    if (tentativeDate) {
      const tentativeDateIdx = timeline.findIndex((event) => DateHelper.equals(tentativeDate, event.date));

      if (tentativeDateIdx === -1) {
        timeline.push({ ...eventFormatter(tentativeDate, null), type: 'temp' });
      }
      else {
        timeline[tentativeDateIdx].type = 'temp';
      }
    }

    return sort ? timeline.sort((event1, event2) => event1.date - event2.date) : timeline;
  }

  private slideAnimationComplete = () => {
    // slide frame back in after render if not controlled by loading prop
    if (this.state.contentClass === 'mdf-time-frame-slide-out-bottom' && !this.props.loading) {
      this.setState(() => ({ contentClass: 'mdf-time-frame-slide-in-top' }));
    }
    else if (this.state.contentClass === 'mdf-time-frame-slide-in-top') {
      this.setState(() => ({ animating: false }));
    }
  };

  private dateSelected = (event, selectedIndex) => {
    this.slideContentOut(selectedIndex);
    void this.props.onTimelineSelect?.(event.date, event.pointIdx, event.date.toISOString().split('T')[0]);
  };

  private slideContentOut = (selectedIndex) => {
    this.setState(() => ({
      selectedIdx: selectedIndex,
      contentClass: this.props.loading !== false ? 'mdf-time-frame-slide-out-bottom' : this.state.contentClass
    }));
  };

  private showTimeline = () => {
    this.setState(() => ({
      timelineLinkClass: 'vdl-invisible',
      timelineOpen: true
    }));
  };

  private closeTimeline = () => {
    this.setState(() => ({
      timelineOpen: false
    }));
    this.slideContentOut(-1);
    this.props.onTimelineClose?.();
  };

  private onTimelineTransitionComplete = () => {
    if (!this.state.timelineOpen) {
      this.setState(() => ({
        timelineLinkClass: ''
      }));
    }
  };

  renderDates() {
    const timeline = this.state.timeline;
    const events = [];

    for (let i = 0; i < timeline.length; i++) {
      const eventData = timeline[i];

      events.push((
        <div className={`mdf-time-frame__date mdf-time-frame__date--${eventData.type} ` + `mdf-time-frame__date${i === this.state.selectedIdx ? '--selected' : ''}`} onClick={() => this.dateSelected(eventData, i)}>
          <div className="mdf-time-frame__timeline-point"> </div>
          <div className="mdf-time-frame__point-label">
            {eventData.label}
            <div className="mdf-time-frame__point-sublabel"> {eventData.subLabel} </div>
          </div>
        </div>
      ));
    }

    return events;
  }

  renderTimeline() {
    return (
      <div className="mdf-time-frame__frame-timeline-container">
        <div className="mdf-time-frame__circles-connector"></div>
        <div
          className={'mdf-time-frame__calendar-container mdf-time-frame__calendar-container' + (this.state.timelineOpen ? '--timeline-open' : '')}
          onClick={this.closeTimeline}
          title={FormatHelper.formatMessage(this.state.timelineOpen ? '@@timeFrameBackButtonHover' : '@@timeFrameCalendarHover')}
        >
          <span className={this.state.timelineOpen ? 'fa fa-times' : 'fa fa-calendar'}></span>
        </div>
        <div className={`mdf-time-frame__timeline-wrapper mdf-time-frame__timeline-wrapper${this.state.timelineLinkClass ? '--timeline-open' : ''}`}>
          <div className={'mdf-time-frame__open-timeline-link ' + (this.state.timelineLinkClass)} onClick={this.showTimeline}>
            <span>{FormatHelper.formatMessage('@@timeFrameTimelineLinklabel')}</span>
          </div>
          <div
            className={`mdf-time-frame__dates-container mdf-time-frame__dates-container${this.state.timelineOpen ? '--slide-in-left' : ' vdl-invisible'}`}
            onTransitionEnd={this.onTimelineTransitionComplete}
          >
            <div className="mdf-time-frame__timeline-line"></div>
            <div className="mdf-time-frame__dates">
              {this.renderDates()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="mdf-time-frame">
        <div>
          {this.props.loading ? <MDFBusyIndicator /> : null}
          {this.state.timeline.length > 0 ? this.renderTimeline() : null}
          <div className={`mdf-time-frame__frame-wrapper mdf-time-frame__frame-wrapper${this.state.animating ? '--frame-animating' : ''}`}>
            <div className={this.state.contentClass} onAnimationEnd={this.slideAnimationComplete}>
              <div className="mdf-time-frame__header-wrapper">
                <div className="mdf-time-frame__header-icon">
                  <span className={this.props.iconClass}></span>
                </div>
                <div className="mdf-time-frame__header">
                  {this.props.header ? RenderHelper.renderContent(this.props.header, {}) : null}
                </div>
              </div>
              <div className="mdf-time-frame__main-content-container">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
