import React from 'react';
import PropTypes from 'prop-types';
import { IPopoverProps, Popover } from '@synerg/vdl-react-components';

export interface IMDFPopoverProps extends IPopoverProps {
  // Accessibility: this id links the popover to the corresponding page element.
  errorId?: string;
  containerClassName?: string;
}

export class MDFPopover extends React.Component<IMDFPopoverProps, any> {
  static propTypes = Object.assign({}, Popover.propTypes, { popoverClassName: PropTypes.string, containerClassName: PropTypes.string });

  render() {
    const { className, children, errorId, id, ...customProps } = this.props;
    let renderClassName = className;
    const { containerClassName, ...popoverProps } = { ...customProps };

    renderClassName = 'mdf ' + (renderClassName || '');

    // The popover gets the property from its parent and the className gets overridden,
    // adding containerClassName to accept custom Styles and passing it over to the popover Component.
    renderClassName = customProps.containerClassName ? `${renderClassName} ${customProps.containerClassName} ` : renderClassName;

    return <Popover className={renderClassName} id={errorId || id} {...popoverProps}>{children}</Popover>;
  }
}
