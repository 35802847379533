import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { Button, ProgressBar } from '@synerg/vdl-react-components';
import { SnackBar } from './SnackBar';
import { StepType } from './Wizard';
import { CustomWizardContext } from './MDFContext';

export interface ICustomWizardProps {
  // The step to appear in the wizard.
  currentStep?: number;
  // Custom class for wizard.
  className?: string;
  // To enable/disable the next button.
  isValid?: boolean;
  // To invoke function when cancel button is clicked.
  onCancel?: () => void;
  // To invoke function when save button is clicked.
  onSave?: () => void;
  // To invoke function when next button is clicked.
  onNext?: () => void;
  // To invoke function when previous button is clicked.
  onPrev?: () => void;
}

export interface ICustomWizardStepProps {
  stepType: StepType;
  title?: string;
  isDisabled?: boolean;
  children?: any;
  index: number;
}

export interface ICustomWizardHeaderProps {
  isStep: boolean;
  currentStep: number;
  stepsCount: number;
  title?: string;
}

export interface ICustomWizardFooterProps {
  isStep: boolean;
  nextButtonText: string;
  prevButtonText: string;
  saveButtonText: string;
  cancelButtonText: string;
  onNext: (e) => void;
  onPrev: (e) => void;
  onSave: () => void;
  onCancel: () => void;
  isValid?: boolean;
}

interface ICustomWizardState {
  // The current step to appear.
  currentStep: number;
  isValid: boolean;
}

export class CustomWizardSteps extends React.Component<ICustomWizardStepProps, {}> {
  render() {
    return (
      <CustomWizardContext.Consumer>
        {(context) => (
          <div className="mdf-custom-wizard-steps">{this.props.children[context.currentStep]}</div>
        )}
      </CustomWizardContext.Consumer>
    );
  }
}

export class CustomWizardStep extends React.Component<ICustomWizardStepProps, {}> {
  render() {
    return <div className="mdf-custom-wizard-step">{this.props.children}</div>;
  }
}

export class CustomWizard extends React.Component<React.PropsWithChildren<ICustomWizardProps>, ICustomWizardState> {
  // list of all steps - provided by the CustomWizardSteps
  wizardSteps: JSX.Element[] = [];
  regularSteps = 0;

  constructor(props: any) {
    super(props);

    const currentStepIndex = props.currentStep || 0;

    this.state = {
      currentStep: currentStepIndex,
      isValid: (props.isValid !== false)
    };
  }

  static getDerivedStateFromProps(nextProps: ICustomWizardProps, prevState: ICustomWizardState) {
    if (prevState.currentStep !== nextProps.currentStep || prevState.isValid !== nextProps.isValid) {
      return {
        currentStep: nextProps.currentStep,
        isValid: nextProps.isValid
      };
    }

    return null;
  }

  getContext() {
    const thisStep = this.wizardSteps[this.state.currentStep] || { props: { stepType: StepType.Step, title: '' } };

    return {
      currentStep: this.state.currentStep,
      getTitles: this.getTitles,
      isCompleteStep: thisStep.props.stepType === StepType.Complete,
      isStep: (!thisStep.props.stepType || thisStep.props.stepType === StepType.Step),
      isValid: this.state.isValid,
      onCancel: this.onCancel,
      onNext: this.onNext,
      onPrev: this.onPrev,
      onSave: this.onSave,
      setCurrentStep: this.setCurrentStep,
      stepCount: this.regularSteps,
      stepTitle: thisStep.props.title || ''
    };
  }

  private getTitles = () =>
    this.wizardSteps.map((item) => (
      {
        title: (item.props.title || ''),
        stepType: item.props.stepType,
        isDisabled: !!item.props.isDisabled
      }
    ));

  private setCurrentStep = (newStep) => {
    this.setState((/* prevState, props */) => (
      {
        currentStep: newStep
      }
    ));
  };

  onNext = (e) => {
    e.preventDefault();

    if (this.props.onNext) {
      this.props.onNext();
    }
    else {
      this.setState((prevState) => (
        {
          currentStep: prevState.currentStep + 1
        }
      ));
    }
  };

  onPrev = (e) => {
    e.preventDefault();

    if (this.props.onPrev) {
      this.props.onPrev();
    }
    else {
      this.setState((prevState) => (
        {
          currentStep: prevState.currentStep - 1
        }
      ));
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
  };

  getWizardSteps = () => {
    this.wizardSteps = [];
    this.regularSteps = 0;

    // Getting steps from the children
    React.Children.forEach(this.props.children, (child: any): any => {
      if (child && child.type === CustomWizardSteps) {
        React.Children.forEach(child.props.children, (step: any): any => {
          if (step && step.type === CustomWizardStep) {
            this.wizardSteps.push(step);

            if (!step.props.stepType || step.props.stepType === StepType.Step) {
              this.regularSteps++;
            }
          }
        });
      }
    });
  };

  render() {
    // To get the wizard steps dynamically
    this.getWizardSteps();

    return (
      <CustomWizardContext.Provider value={this.getContext()}>
        <div className={`mdf-custom-wizard ${this.props.className || ''}`}>
          {this.props.children}
        </div>
      </ CustomWizardContext.Provider>
    );
  }
}

export class CustomWizardHeader extends React.Component<ICustomWizardHeaderProps, any> {
  declare context: React.ContextType<typeof CustomWizardContext>;
  static contextType = CustomWizardContext;

  DEFAULT_REVIEW_HEADER: string;

  constructor(props: ICustomWizardHeaderProps) {
    super(props);

    this.DEFAULT_REVIEW_HEADER = FormatHelper.formatMessage('@@Review');
  }

  render() {
    let headerPrefixText: string;

    if (this.context.isCompleteStep) {
      return null;
    }
    else {
      if (this.context.isStep) {
        headerPrefixText = FormatHelper.formatMessage('@@WizardStepPrefix', {
          currentStep: this.context.currentStep + 1,
          stepsCount: this.context.stepCount
        });
      }
      else {
        headerPrefixText = this.DEFAULT_REVIEW_HEADER;
      }

      const progressPercentage = (100 / (this.context.stepCount + 1)) * (this.context.currentStep + 1);

      return (
        <div className="mdf-custom-wizard-header">
          <span className="mdf-custom-wizard-header-prefix"> {headerPrefixText} </span>
          <span className="mdf-custom-wizard-header-title"> {this.context.stepTitle} </span>
          <ProgressBar className="mdf-custom-wizard-header-progress" percentage={progressPercentage} />
        </div>
      );
    }
  }
}

export class CustomWizardFooterButton extends React.Component<any, any> {
  declare context: React.ContextType<typeof CustomWizardContext>;
  static contextType = CustomWizardContext;

  onNext = (e) => {
    e.preventDefault();

    if (this.props.onNext) {
      this.props.onNext(e);
    }
    else {
      this.context.onNext(e);
    }
  };

  onPrev = (e) => {
    e.preventDefault();

    if (this.props.onPrev) {
      this.props.onPrev(e);
    }
    else {
      this.context.onPrev(e);
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    else {
      this.context.onCancel();
    }
  };

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
    else {
      this.context.onSave();
    }
  };

  private renderOnThisStep = () => {
    if (Array.isArray(this.props.renderOnSteps) && this.props.renderOnSteps.some((value) => value === this.context.currentStep)) {
      return true;
    }

    if (Array.isArray(this.props.doNotRenderOnSteps) && !this.props.doNotRenderOnSteps.some((value) => value === this.context.currentStep)) {
      return true;
    }
    else {
      return this.props.renderOnSteps;
    }
  };

  render() {
    const { children, renderOnSteps, renderOnNonSteps, doNotRenderOnSteps, disableIfInvalid, className, ...originalButtonProps } = this.props;
    let buttonProps = Object.assign({}, { ...originalButtonProps });

    // TypeScript 2.6 preserves the 'readonly' flag on the items copied from this.props above, so we have to clone the data.
    if (disableIfInvalid) {
      buttonProps = Object.assign({}, { ...buttonProps }, { disabled: !this.context.isValid });
    }

    buttonProps = Object.assign({}, { ...buttonProps }, { className: 'mdf-custom-wizard-footer-navbutton ' + (className || '') });

    if ((this.renderOnThisStep() && this.context.isStep) || (this.props.renderOnNonSteps && !this.context.isStep)) {
      return <Button {...buttonProps}>{children}</Button>;
    }
    else {
      return null;
    }
  }
}

export class CustomWizardFooter extends React.Component<React.PropsWithChildren<ICustomWizardFooterProps>, any> {
  declare context: React.ContextType<typeof CustomWizardContext>;
  static contextType = CustomWizardContext;

  render() {
    if (this.context.isCompleteStep) {
      return null;
    }
    else {
      return (
        <SnackBar>
          <div className="mdf-custom-wizard-footer">
            {this.props.children}
          </div>
        </SnackBar>
      );
    }
  }
}
