import React from 'react';
import PropTypes from 'prop-types';
import { generateId } from '@adp-wfn/mdf-core';
import { SdfRadioButton, SdfRadioGroup } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';

export interface IRadioButtonSetProps {
  className?: string;
  isHorizontal?: boolean;
  items?: { id: string; value: string; radioGroupValue: string; iconClass: string; contentLabel: string; disabled: boolean }[];
  name?: string;
  required?: boolean;
  onChange: (option) => void;
}

export class RadioButtonSet extends React.Component<IRadioButtonSetProps, any> {
  private name = generateId('mdfRadioSet');

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func,
    isHorizontal: PropTypes.bool,
    required: PropTypes.bool
  };

  radioBoxOnChange = (e) => {
    this.props.onChange(e?.detail);
  };

  renderRadioButton = () => this.props.items?.map((option) => {
    const props: any = {
      id: option.id,
      value: option.value
    };

    if (option.disabled) {
      props.disabled = option.disabled;
    }

    return (
      <SdfRadioButton key={option.id} {...props}>
        <span>
          <MDFIcon className={option.iconClass}></MDFIcon>
          {option.contentLabel}
        </span>
      </SdfRadioButton>
    );
  });

  render() {
    const { isHorizontal } = this.props;

    return (
      <SdfRadioGroup
        name={this.props.name || this.name}
        aria-labelledby={this.props['aria-labelledby']}
        direction={isHorizontal ? 'row' : 'column'}
        onSdfChange={(e) => this.radioBoxOnChange(e)}
        value={this.props.items && this.props.items[0]?.radioGroupValue}
      >
        {this.renderRadioButton()}
      </SdfRadioGroup>
    );
  }
}
