import React from 'react';
import { IWFNMockShellRoot, IWFNMockShellToolbarItem } from '../types';
import { IWFNMockShellActivePopover, IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellToolbarItem } from './WFNMockShellToolbarItem';
import { WFNMockShellDisabledOverlay } from '../WFNMockShellDisabledOverlay';
import { WFNMockShellUserMenu } from './WFNMockShellUserMenu';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellToolbarProps {
  activePopover: IWFNMockShellActivePopover;
  shellData: IWFNMockShellRoot;
  megaMenuActive: boolean;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
}

export const WFNMockShellToolbar = ({ shellData, onWFNShellChange, activePopover, megaMenuActive }: IWFNMockShellToolbarProps) => {
  const renderToolbarItem = (toolbarItem: IWFNMockShellToolbarItem, i) => {
    return <WFNMockShellToolbarItem key={toolbarItem.oid}
      activePopover={activePopover}
      toolbarItemIndex={i}
      onWFNShellChange={onWFNShellChange}
      toolbarDisabled={shellData.toolbar.disabled}
      megaMenuActive={megaMenuActive}
      headerMenuDisabled={shellData.headerMenu.disabled}
      renderSearchBar={shellData.search.renderSearchBar}
      lastToolbarItemIndex={shellData.toolbar.childList?.length - 1}
      {...toolbarItem}
    />;
  };
  const isLegacyShell = (window['isADPUnified'] === undefined);

  return (
    <div className="wfnmockshell-toolbar">
      <WFNMockShellDisabledOverlay disabled={shellData.toolbar.disabled} />
      <div className="wfn-masthead-tup">
        <div className="wfn-masthead">
          <span className="wfn-logo"><i className="icon-adp" /></span>
        </div>
      </div>
      <nav className="wfn-mock-shell-icon-bar">
        <div className="wfn-mock-shell-icon-bar--icons">
          {shellData.toolbar.childList.map(renderToolbarItem)}
          {isLegacyShell && <div className="wfn-mock-shell-icon-bar-item-logout">
            <div className="wfn-mock-shell-icon-bar--icon">
              <i className={'fa fa-sign-out'} title={FormatHelper.formatMessage('@@OB_Log_Out')} />
              <span className="wfn-mock-shell-icon-bar--icon-text">{FormatHelper.formatMessage('@@OB_Log_Out')}</span>
            </div>
          </div>}
          <WFNMockShellUserMenu megaMenuActive={megaMenuActive} shellData={shellData} activePopover={activePopover} onWFNShellChange={onWFNShellChange} />
        </div>
      </nav>
    </div>
  );
};

WFNMockShellToolbar.displayName = 'WFNMockShellToolbar';
