import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createChainedFunction from '@synerg/vdl-react-components/lib/util/create-chained-function';
import { isEqual } from 'lodash';
import { map, toArray } from '@synerg/vdl-react-components/lib/util/valid-element-children';

export interface IWFNXBtnGroupProps {
  values?: any[];
  onChange?: (value?: any) => void;
  className?: string;
  disabled?: boolean;
}

export interface IWFNXBtnGroupState {
  values?: any[];
}

export class WFNXMultiButtonGroup extends React.Component<React.PropsWithChildren<IWFNXBtnGroupProps>, IWFNXBtnGroupState> {
  constructor(props) {
    super(props);

    // set the default state from props
    this.state = { values: this.props.values };
  }

  buttonContainer: HTMLElement;

  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.values, prevState.values)) {
      return { values: nextProps.values };
    }
  }

  // Taken the code from adp react library to support keyboard events
  handleItemKeyDown = (event: React.KeyboardEvent<any>, index: number) => {
    if (event.defaultPrevented) {
      return;
    }

    const buttons = toArray(this.props.children as React.ReactElement<any>);

    let goToIndex = -1;

    if (event.key === 'ArrowRight') {
      for (let i = index + 1; i < buttons.length; i++) {
        if (!buttons[i].props.disabled) {
          goToIndex = i;
          break;
        }
      }
    }
    else if (event.key === 'ArrowLeft') {
      for (let i = index - 1; i >= 0; i--) {
        if (!buttons[i].props.disabled) {
          goToIndex = i;
          break;
        }
      }
    }

    if (goToIndex >= 0) {
      event.preventDefault();
      const clickEvent = document.createEvent('HTMLEvents');
      clickEvent.initEvent('click', true, true);
      const goToButton = ReactDOM.findDOMNode(this.buttonContainer).childNodes[goToIndex] as HTMLElement;
      goToButton.focus();
      goToButton.dispatchEvent(clickEvent);
    }
  };

  handleItemClick = (event: React.SyntheticEvent<any>, newValue) => {
    (event.target as HTMLElement).focus();

    const values = (this.state.values || []).slice(0);

    if ((values.includes(newValue) && values.length >= 1)) {
      // If the value is already selected, unselect it
      values.splice(values.indexOf(newValue), 1);
    }
    else {
      // Otherwise merge with other values and return
      if (!values.includes(newValue)) {
        values.push(newValue);
      }
    }

    this.setState({ values: values });
    this.props.onChange(values);
  };

  renderButton = (child, index: number) => {
    const values = this.state.values;
    const disabled = child.props.disabled || this.props.disabled;
    const buttonValue = child.props.value;

    // check to see if the children is a valid Element and its children is of 'button' type to avoid submit button type
    if (!React.isValidElement(child) && child.type !== 'button' && child.props.type !== 'button') {
      return null;
    }

    // find the buttonValue from the values array
    const selected = values.includes(buttonValue);

    const props = {
      selected,
      ['aria-selected']: selected,
      ['aria-disabled']: disabled,
      onClick: createChainedFunction(child.props.onClick, (event) => this.handleItemClick(event, buttonValue)),
      onKeyDown: createChainedFunction(child.props.onKeyDown, (event) => this.handleItemKeyDown(event, index))
    };

    return React.cloneElement(child, props);
  };

  render() {
    return (
      <div className={'wfnx-multi-buttonGroup-wrapper'} ref={(c) => (this.buttonContainer = c)}>
        {map(this.props.children as React.ReactElement<any>, this.renderButton)}
      </div>
    );
  }
}
