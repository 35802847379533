import React, { useContext } from 'react';
import { RuleRowContext } from '../MDFContext';
import { DatePicker } from '../DatePicker';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { MDFRequiredField } from '../MDFRequiredField';
import { MDFDropdownList } from '../MDFDropdownList';
import { MDFNumberBox } from '../MDFNumberBox';
import { MDFSelectBox } from '../MDFSelectBox';
import { MDFTextbox } from '../MDFTextbox';
import { MDFValidatedTextField } from '../MDFValidatedTextField';

export interface ILabelValuePair<T> {
  label: string;
  valueLabel: string;
  value: T;
  oid?: string;
  fieldType?: FieldType;
  isDisabled?: boolean;
}

export interface IRuleColumn {
  label?: string;
  value?: string;
  hasOperator?: boolean;
  operatorOptions?: ILabelValuePair<string>[];
  regEx?: string;
  fieldType?: FieldType;
  valueProperties?: any;
  valueOptions?: ILabelValuePair<string>[];
  isDisabled?: boolean;
}

export enum OperatorType {
  IS_EQUAL_TO = 'isequalto',
  IS_NOT_EQUAL_TO = 'isnotequalto',
  IS_IN = 'in',
  IS_NOT_IN = 'notin',
  IS_NULL = 'isnull',
  IS_NOT_NULL = 'isnotnull',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notcontains',
  IS_GREATER_THAN = 'greaterthan',
  IS_LESS_THAN = 'lessthan',
  IS_GREATER_THAN_EQUALS = 'isgreaterthanequals',
  IS_LESS_THAN_EQUALS = 'islessthanequals',
  STARTS_WITH = 'startswith',
  NOT_STARTS_WITH = 'notstartswith',
  ENDS_WITH = 'endswith',
  NOT_ENDS_WITH = 'notendswith',
  CHANGE = 'change',
  AFTER = 'after',
  BEFORE = 'before'
}

export enum FieldType {
  FREEFORM = 'FREEFORM',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DYNAMIC_SELECT = 'DYNAMIC_SELECT',
  DYNAMIC_MULTI_SELECT = 'DYNAMIC_MULTI_SELECT',
  NUMBER_INPUT = 'NUMBER_INPUT',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN'
}

export interface IRuleCellProps extends IRuleColumn {
  // To enable/disable the component
  disabled?: boolean;

  // value to set operator dropdown value
  operator?: string;

  // values to set field values component
  values?: IRuleCellValues;

  // To enable/disable an individual option in dropdown list
  disabledItems?: any;
}

export interface IRuleCellValue {
  value?: string;
  label?: string;
}

export interface IRuleCellValues extends Array<IRuleCellValue> {
}

const MDFValidatedTextbox = MDFValidatedTextField(MDFTextbox);
const MDFValidatedDatePicker = MDFRequiredField(DatePicker);
const MDFValidatedSelectBox = MDFRequiredField(MDFSelectBox);
const MDFValidatedDropdownList = MDFRequiredField(MDFDropdownList);
const MDFValidatedNumberBox = MDFRequiredField(MDFNumberBox);

export const RuleCell = (props: IRuleCellProps) => {
  // use RuleRowContext context to update the state
  const context: any = useContext(RuleRowContext);

  const YES_NO_OPTIONS = [
    { label: FormatHelper.formatMessage('@@Yes'), value: 'true' },
    { label: FormatHelper.formatMessage('@@No'), value: 'false' }
  ];

  const updateState = (values: IRuleCellValue[]) => {
    if (context?.onFieldValueChange) {
      context.onFieldValueChange(values);
    }
  };

  const updateValues = (cellValues: IRuleCellValues) => {
    updateState(cellValues);
  };

  const handleTextBoxChange = (e) => {
    const label = e ? e : undefined;
    updateValues([{ value: label, label: label }]);
  };

  const handleNumberBoxChange = (e) => {
    const value = (e || e === 0) ? e : undefined;
    const minimumFractionDigits = props?.valueProperties?.minimumFractionDigits || props?.valueProperties?.decimal;
    const label = (value || value === 0) ? FormatHelper.formatNumber(value, { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: props?.valueProperties?.decimal || 0 }, null) : '';
    updateValues([{ value: value, label: label }]);
  };

  const handleDropDownChange = (e) => {
    updateValues(e ? [e] : []);
  };

  const handleDatePickerChange = (e) => {
    const date = e ? e : undefined;
    updateValues([{ value: date, label: (date ? FormatHelper.formatDate(date) : undefined) }]);
  };

  const fetchLoadOptions = async (selectValue, prevOptions) => {
    if (context?.onFetchValueOptions) {
      return context.onFetchValueOptions(selectValue, prevOptions);
    }
    else {
      return new Promise((resolve, _reject) => {
        resolve({
          options: [],
          hasMore: false
        });
      });
    }
  };

  const handleSelectChange = (e) => {
    updateValues(e ? [e] : []);
  };

  const handleMultiSelectChange = (e) => {
    updateValues(e || []);
  };

  const renderField = (fieldType?: FieldType) => {
    switch (fieldType) {
      case FieldType.FREEFORM:
        return (
          <MDFValidatedTextbox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            disabled={props.disabled}
            required={true}
            value={props.values?.[0]?.value}
            regEx={props.regEx}
            placeholder={FormatHelper.formatMessage('@@Value')}
            aria-label={FormatHelper.formatMessage('@@Value')}
            onChange={handleTextBoxChange}
          />
        );

      case FieldType.SELECT:
        return (
          <MDFValidatedDropdownList
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            disabled={props.disabled}
            required={true}
            disabledItems={props.disabledItems}
            data={props.valueOptions}
            textField={'label'}
            valueField={'value'}
            value={props.values?.[0]?.value}
            placeholder={FormatHelper.formatMessage('@@selectValue')}
            aria-label={FormatHelper.formatMessage('@@selectValue')}
            onChange={handleDropDownChange}
          />
        );

      case FieldType.MULTI_SELECT:
        return (
          <MDFValidatedSelectBox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            placeholder={`${FormatHelper.formatMessage('@@selectAtLeastOneValue')}`}
            aria-label={`${FormatHelper.formatMessage('@@selectAtLeastOneValue')}`}
            disabled={props.disabled}
            isMulti={true}
            required={true}
            value={props.values}
            optionsList={props.valueOptions}
            noOptionsMessage={() => FormatHelper.formatMessage('@@noOptions')}
            onChange={handleMultiSelectChange}
          />
        );

      case FieldType.DYNAMIC_SELECT: {
        return (
          <MDFValidatedSelectBox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            placeholder={`${FormatHelper.formatMessage('@@selectValue')}`}
            aria-label={`${FormatHelper.formatMessage('@@selectValue')}`}
            className="vdl-dropdown-list-nowrap"
            disabled={props.disabled}
            required={true}
            async={true}
            paginate={true}
            loadOptions={fetchLoadOptions}
            noOptionsMessage={() => FormatHelper.formatMessage('@@noOptions')}
            value={props.values}
            onChange={handleSelectChange}
          />
        );
      }

      case FieldType.DYNAMIC_MULTI_SELECT: {
        return (
          <MDFValidatedSelectBox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            placeholder={`${FormatHelper.formatMessage('@@selectAtLeastOneValue')}`}
            aria-label={`${FormatHelper.formatMessage('@@selectAtLeastOneValue')}`}
            className="vdl-dropdown-list-nowrap"
            disabled={props.disabled}
            isMulti={true}
            required={true}
            async={true}
            paginate={true}
            loadOptions={fetchLoadOptions}
            noOptionsMessage={() => FormatHelper.formatMessage('@@noOptions')}
            value={props.values}
            onChange={handleMultiSelectChange}
          />
        );
      }

      case FieldType.DATE:
        return (
          <MDFValidatedDatePicker
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            disabled={props.disabled}
            required={true}
            selectedDate={props.values?.[0]?.value}
            onChange={handleDatePickerChange}
          />
        );

      case FieldType.NUMBER_INPUT: {
        // ValidationNumberInput
        return (
          <MDFValidatedNumberBox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            {...props?.valueProperties}
            disabled={props.disabled}
            required={true}
            value={props.values?.[0]?.value}
            onChange={handleNumberBoxChange}
          />
        );
      }

      case FieldType.BOOLEAN:
        return (
          <MDFValidatedDropdownList
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            placeholder={`${FormatHelper.formatMessage('@@Value')}`}
            aria-label={`${FormatHelper.formatMessage('@@Value')}`}
            disabled={props.disabled}
            disabledItems={props.disabledItems}
            required={true}
            data={YES_NO_OPTIONS}
            textField={'label'}
            valueField={'value'}
            value={props.values?.[0]?.value}
            onChange={handleDropDownChange}
          />
        );

      default:
        return (
          <MDFValidatedTextbox
            id={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            name={`rule_${context.cardIndex}_row_${context.rowIndex}_filedValue`}
            required={true}
            placeholder={FormatHelper.formatMessage('@@Value')}
            aria-label={FormatHelper.formatMessage('@@Value')}
            disabled={props.disabled}
            usePopover={true}
            onChange={null}
            readOnly={true}
            value={null}
          />
        );
    }
  };

  const renderCell = () => {
    const { fieldType, operatorOptions, operator } = props;
    // check operator has fieldType, if yes then render the value component based on operator field type
    const currentOperator = operatorOptions?.find((option) => {
      return option.value === operator;
    });

    switch (operator as OperatorType) {
      case OperatorType.IS_EQUAL_TO:
      case OperatorType.IS_NOT_EQUAL_TO:
      case OperatorType.IS_IN:
      case OperatorType.IS_NOT_IN:
      case OperatorType.CONTAINS:
      case OperatorType.NOT_CONTAINS:
      case OperatorType.IS_GREATER_THAN:
      case OperatorType.IS_LESS_THAN:
      case OperatorType.IS_GREATER_THAN_EQUALS:
      case OperatorType.IS_LESS_THAN_EQUALS:
      case OperatorType.STARTS_WITH:
      case OperatorType.NOT_STARTS_WITH:
      case OperatorType.ENDS_WITH:
      case OperatorType.NOT_ENDS_WITH:
      case OperatorType.AFTER:
      case OperatorType.BEFORE:
        return renderField(currentOperator?.fieldType || fieldType);

      case OperatorType.CHANGE:
      case OperatorType.IS_NULL:
      case OperatorType.IS_NOT_NULL:
        return null;

      default:
        // render default component when there is no operator
        return renderField();
    }
  };

  return renderCell();
};

RuleCell.displayName = 'RuleCell';
