import React from 'react';
import { StepType } from './Wizard';
import { CustomWizardContext } from './MDFContext';

export interface ISegmentedWizardHeaderProps {
  onStepSelect?: (step: any) => {};
}

export class SegmentedWizardHeader extends React.Component<ISegmentedWizardHeaderProps, any> {
  constructor(props: ISegmentedWizardHeaderProps) {
    super(props);

    this.state = { activeIndex: this.context?.currentStep };
  }

  declare context: React.ContextType<typeof CustomWizardContext>;
  static contextType = CustomWizardContext;

  onStepSelect = (newStep) => {
    if (this.context.isValid) {
      if (this.props.onStepSelect) {
        this.props.onStepSelect(newStep);
      }
      else {
        this.context.setCurrentStep(newStep);
      }
    }
  };

  getActiveIndex = (currentIndex, isMoveRight) => {
    const steps = this.context.getTitles()?.filter((item) => item.stepType !== StepType.Complete);
    let updateIndex = currentIndex || 0;

    if (isMoveRight) {
      if (steps.length - 1 === updateIndex) {
        updateIndex = 0;
      }
      else {
        updateIndex = updateIndex + 1;
      }
    }

    if (!isMoveRight) {
      if (updateIndex === 0) {
        updateIndex = steps.length - 1;
      }
      else {
        updateIndex = updateIndex - 1;
      }
    }

    return updateIndex;
  };

  onFocus = () => {
    this.setState(() => (
      {
        activeIndex: this.context?.currentStep
      }
    ));
  };

  onBlur = () => {
    this.setState(() => (
      {
        activeIndex: -1
      }
    ));
  };

  keyDown = (e) => {
    const key = e.key;

    if (e.defaultPrevented) {
      return;
    }

    if (key === 'ArrowLeft') {
      e.preventDefault();
      this.setState((prevState) => (
        {
          activeIndex: this.getActiveIndex(prevState.activeIndex, false)
        }
      ));
    }
    else if (key === 'ArrowRight') {
      e.preventDefault();
      this.setState((prevState) => (
        {
          activeIndex: this.getActiveIndex(prevState.activeIndex, true)
        }
      ));
    }
    else if (key === 'Tab') {
      this.setState(() => (
        {
          activeIndex: -1
        }
      ));
    }
    else if (key === 'Enter') {
      e.preventDefault();
      if (this.context.isValid && !this.context.getTitles()[this.state.activeIndex].isDisabled) {
        this.onStepSelect(this.state.activeIndex);
        this.setState(() => (
          {
            activeIndex: -1
          }
        ));
      }
    }
  };

  createHeaderItem = (titleItem, index) => {
    let itemClass = 'mdf-segmented-wizard-header-item ' + (index <= this.context.currentStep ? 'mdf-segmented-wizard-header-visited-item' : '');
    itemClass = itemClass + (index === this.context.currentStep ? ' mdf-segmented-wizard-header-current-item' : '') + (this.context.isValid && !titleItem.isDisabled ? ' mdf-segmented-wizard-header-item-bar-click' : ' mdf-segmented-wizard-header-item-bar-disabled');
    itemClass = itemClass + ((this.state.activeIndex === index) ? ' mdf-segmented-wizard-header-item-active ' : '');
    return (
      <div
        className={itemClass}
        aria-current={index === this.context.currentStep ? 'true' : 'false'}
        key={'swhi-' + index}
        role="listitem"
        {...((this.context.isValid && !titleItem.isDisabled) && {
          onClick: () => {
            this.onStepSelect(index);
          }
        })}
        {...(titleItem.isDisabled && index !== this.context.currentStep && { 'aria-disabled': true })}
        {...(index === this.context.currentStep && { tabIndex: 0 })}
        onKeyDown={(e) => this.keyDown(e)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <div className="mdf-segmented-wizard-header-item-bar" />
        <span className="mdf-segmented-wizard-header-item-text">{titleItem.title}</span>
      </div>
    );
  };

  render() {
    const titles = this.context.getTitles();

    if (this.context.isCompleteStep) {
      return null;
    }
    else {
      return (
        <div className="mdf-segmented-wizard-header" role="list">
          {titles &&
            titles.map((titleItem, index) => {
              if (titleItem.stepType !== StepType.Complete) {
                return this.createHeaderItem(titleItem, index);
              }
              else {
                return undefined;
              }
            })
          }
        </div>
      );
    }
  }
}
