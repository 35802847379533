import React from 'react';
import { IWFNMockShellHeaderMenuChildList2, IWFNMockShellSearchChild, IWFNMockShellToolbarNestedSubItem, IWFNMockShellToolbarSubItem } from '../types';
import { Checkbox } from '@synerg/vdl-react-components';
import { IWFNMockShellChange } from '../WFNMockShell';

export type WfnMockShellPopoverChildListItem = IWFNMockShellToolbarSubItem | IWFNMockShellSearchChild;

export interface IWFNMockShellPopoverDetailProps {
  onWFNShellChange: (wfnmockshellChange: IWFNMockShellChange) => void;
  childList?: WfnMockShellPopoverChildListItem[];
  popoverType: 'toolbar' | 'usermenu' | 'searchBar';
}

export const WFNMockShellPopoverDetail = ({ childList, onWFNShellChange, popoverType }: IWFNMockShellPopoverDetailProps) => {
  const handleCheckboxChange = (item, type, value) => {
    onWFNShellChange({
      item,
      type,
      value
    });
  };

  const renderNestedToolbarSubItems = (nestedSubItem: IWFNMockShellToolbarNestedSubItem) => {
    return (
      <div key={nestedSubItem.oid} className="neutral-dark wfnmockshell-popover-detail-item-nested">
        {nestedSubItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: nestedSubItem.oid, name: nestedSubItem.name }, 'toolbarItemNestedSubItemCheckbox', value)} checked={nestedSubItem.isSelected} disabled={nestedSubItem.disableCheckbox} />}
        <div>{nestedSubItem.name}</div>
      </div>
    );
  };

  const renderNestedSearchbarSubItems = (nestedSubItem: IWFNMockShellSearchChild) => {
    return (
      <div key={nestedSubItem.oid} className="neutral-dark wfnmockshell-popover-detail-item-nested">
        {nestedSubItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: nestedSubItem.oid, name: nestedSubItem.name }, 'searchBarItemNestedSubItemCheckbox', value)} checked={nestedSubItem.isSelected} disabled={nestedSubItem.disableCheckbox} />}
        <div className="label-wrapper">{nestedSubItem.name}</div>
      </div>
    );
  };

  const renderNestedUserMenuSubItems = (nestedSubItem: IWFNMockShellHeaderMenuChildList2) => {
    return (
      <React.Fragment key={nestedSubItem.oid}>
        <div className="neutral-dark wfnmockshell-popover-detail-item-nested">
          {nestedSubItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: nestedSubItem.oid, name: nestedSubItem.name }, 'userMenuItemNestedSubItemCheckbox', value)} checked={nestedSubItem.isSelected} disabled={nestedSubItem.disableCheckbox} />}
          <div className="label-wrapper">{nestedSubItem.name}</div>
        </div>
        {!!nestedSubItem.childList?.length && nestedSubItem.childList.map((level3UserMenuItem) => (
          <div key={level3UserMenuItem.oid} className="neutral-dark wfnmockshell-popover-detail-item-nested-level3">
            <div className="level3-item">
              {level3UserMenuItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: level3UserMenuItem.oid, name: level3UserMenuItem.name }, 'userMenuItemLevel3temCheckbox', value)} checked={level3UserMenuItem.isSelected} disabled={nestedSubItem.disableCheckbox} />}
              <div className="label-wrapper">{level3UserMenuItem.name}</div>
            </div>

            {!!level3UserMenuItem.childList?.length && level3UserMenuItem.childList.map((level4UserMenuItem) => (
              <div key={level4UserMenuItem.oid} className="neutral-dark wfnmockshell-popover-detail-item-nested-level4">
                {level4UserMenuItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: level4UserMenuItem.oid, name: level4UserMenuItem.name }, 'userMenuItemLevel4ItemCheckbox', value)} checked={level4UserMenuItem.isSelected} disabled={level4UserMenuItem.disableCheckbox} />}
                <div className="label-wrapper">{level4UserMenuItem.name}</div>
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  };

  const renderSubItems = () => {
    return childList.map((subItem) => {
      let nestedChildList;
      let renderer;
      let callbackType;

      switch (popoverType) {
        case 'toolbar':
          nestedChildList = subItem.childList?.length ? subItem.childList : null;
          renderer = renderNestedToolbarSubItems;
          callbackType = 'toolbarItemSubItemCheckbox';
          break;

        case 'searchBar':
          nestedChildList = subItem.childList?.length ? subItem.childList : null;
          renderer = renderNestedSearchbarSubItems;
          callbackType = 'searchBarItemSubItemCheckbox';
          break;

        case 'usermenu':
          nestedChildList = subItem.childList?.length ? subItem.childList : null;
          renderer = renderNestedUserMenuSubItems;
          callbackType = 'userMenuItemSubItemCheckbox';
          break;
      }

      return (
        <React.Fragment key={subItem.oid}>
          <div className="neutral-dark wfnmockshell-popover-detail-item">
            {subItem.renderCheckbox && <Checkbox onChange={(value) => handleCheckboxChange({ oid: subItem.oid, name: subItem.name }, callbackType, value)} checked={subItem.isSelected} disabled={subItem.disableCheckbox} />}
            <div className="label-wrapper">{subItem.name}</div>
          </div>
          {!!nestedChildList?.length && nestedChildList.map(renderer)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="wfnmockshell-popover-detail">
      {!!childList?.length && renderSubItems()}
    </div>
  );
};

WFNMockShellPopoverDetail.displayName = 'WFNMockShellPopoverDetail';
