// This component wraps SmallCardCircle and SmallCardContent
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SmallCardCircle } from './SmallCardCircle';
import { SmallCardContext } from '../MDFContext';
import { DeviceHelper } from '@adp-wfn/mdf-core';
import { colorStyles, VDLColors } from '../../util/colorStyles';

export interface ISmallCardMiddleProps {
  // Assign className for SmallCardMiddle
  className?: string;
  // SmallCardCircle | SmallCardContent
  children?: any;
}

export interface ISmallCardContentProps {
  children: any;
  className?: string;
  middleBackgroundColor?: VDLColors;
  applyTopBorderLine?: boolean;
}

// Allow any children for the content
export const SmallCardContent = (props: ISmallCardContentProps) => {
  const middleBackgroundColor = props.middleBackgroundColor && `${colorStyles(props.middleBackgroundColor)} mdf-cardBackground` || '';

  return (
    <div className={classNames(`small-card-content ${middleBackgroundColor}`, props.className)}>
      {props.children}
    </div>
  );
};

SmallCardContent.displayName = 'SmallCardContent';

export class SmallCardMiddle extends React.Component<ISmallCardMiddleProps, any> {
  static propTypes = {
    className: PropTypes.string
  };

  circle: any;
  content: any;
  declare context: React.ContextType<typeof SmallCardContext>;

  // passing static ContextType to set the this.context
  static contextType = SmallCardContext;

  render() {
    const { className } = this.props;
    let applyLineBorder = '';
    let circlePlacement = '';
    const isSelected = this.context && this.context.selectable;
    const isMobile = DeviceHelper.isMobileDevice();

    React.Children.forEach(this.props.children, (child: any): any => {
      if (child.type === SmallCardCircle || child.type.displayName === 'SmallCardCircle') {
        this.circle = child;

        // Need the circlePlacement to apply the small-card-middle
        circlePlacement = !isSelected && this.circle && this.circle.props.circleType === 'status' ? 'low' : 'high';
      }
      else if (child.type === SmallCardContent || child.type.displayName === 'SmallCardContent') {
        this.content = child;
        applyLineBorder = (this.content && this.content.props.applyTopBorderLine && 'mdf-cardLineBorder') || '';
      }
      else {
        console.error(`The SmallCardMiddle cannot render a ${child.type} component. Use only SmallCardCircle or SmallCardContent components.`);
      }
    });

    // Apply reverse direction from the small-card-middle to handle high/low circle positions using flex.
    return (
      <div className={classNames(`small-card-middle ${applyLineBorder} small-card-middle--${circlePlacement} ${isMobile ? 'small-card-mobile' : ''}`, className)}>
        {this.circle && this.circle.props.circleType !== 'none' && this.circle}
        {this.content}
      </div>
    );
  }
}
