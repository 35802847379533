import React from 'react';
import { Alert, IAlertType } from '@synerg/vdl-react-components';

export interface IMessage {
  messageType: IAlertType;
  message: string;
}

export interface IMessagesProps {
  messages: IMessage[];
}

function categorizeMessages(messages: IMessage[]) {
  const messageMap = {
    success: [],
    error: [],
    warning: [],
    info: []
  };

  messages.forEach((msg) => messageMap[msg.messageType].push(msg.message));

  return messageMap;
}

function alertContent(items = []) {
  const components = [];
  let brIndex = 0;
  let first = true;

  items.forEach((item) => {
    if (!first) {
      components.push(<br key={brIndex++} />);
    }

    components.push(item);

    if (first) {
      first = false;
    }
  });

  return components;
}

export function Messages(props: IMessagesProps) {
  if (!props.messages) {
    return null;
  }

  if (!Array.isArray(props.messages)) {
    console.error('Messages(): props.messages is not an array.', props.messages);
    return null;
  }

  const messageMap = categorizeMessages(props.messages);

  return (
    props.messages && (
      <div>
        {
          ['success', 'error', 'warning', 'info'].map((type: IAlertType) => (
            (messageMap[type].length > 0) && <Alert key={type} type={type}>
              { alertContent(messageMap[type]) }
            </Alert>
          ))
        }
      </div>
    )
  );
}
