import React from 'react';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { Textbox } from './Textbox';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface ISearchBoxProps {
  tabIndex?: number;
  defaultSearchOption?: boolean;
  onQueryChange: (query) => void;
  placeHolder?: string;
  query?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  ariaLabel?: string;
  'aria-label'?: string;
}

export interface ISearchBoxState {
  previousPropsQuery?: string;
  query?: string;
  defaultSearchOption?: boolean;
}

export class SearchBox extends React.Component<ISearchBoxProps, ISearchBoxState> {
  static defaultProps = {
    defaultSearchOption: true,
    autoFocus: false
  };

  private timeoutID: any;

  constructor(props) {
    super(props);

    this.state = {
      previousPropsQuery: props.query || '',
      query: props.query || '',
      defaultSearchOption: props.defaultSearchOption
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState: any = {};

    if (!isEqual(prevState.previousPropsQuery, nextProps.query)) {
      nextState.previousPropsQuery = nextProps.query;
      nextState.query = nextProps.query;
      return nextState;
    }

    return null;
  }

  onQueryChange = (query) => {
    const defaultSearchLength = 3;

    if ((query.length >= defaultSearchLength && this.props.defaultSearchOption) || query.length === 0) {
      this.handleQueryChange(query);
    }
    else {
      const newState = {
        ...this.state,
        query
      };
      this.setState(newState);
    }
  };

  handleQueryChange = (query) => {
    const TIMEOUT_INTERVAL = 150;

    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }

    this.timeoutID = setTimeout(() => this.props.onQueryChange(query), TIMEOUT_INTERVAL);

    const newState = {
      ...this.state,
      query
    };

    this.setState(newState);
  };

  onCloseClick = () => {
    this.handleQueryChange('');
  };

  onCloseIconKeyPress = (event) => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      this.onCloseClick();
    }
  };

  handleKeyPress = (e) => {
    e.stopPropagation();
    e.nativeEvent?.stopImmediatePropagation();

    if (e.key === 'Enter') {
      this.handleQueryChange(e.target.value);
    }
  };

  onSearchIconClick = () => {
    if (this.state.query) {
      this.handleQueryChange(this.state.query);
    }
  };

  onSearchIconKeyPress = (event) => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      this.onSearchIconClick();
    }
  };

  render() {
    const placeHolder = this.props.placeHolder || FormatHelper.formatMessage('@@DC_Search_placeholder');
    const searchButtonClasses = {
      ['mdf-searchbox-searchicon fa fa-search']: true,
      ['mdf-searchbox-searchicon--disabled']: this.props.disabled
    };

    const closeIconClasses = {
      ['mdf-searchbox-close']: true,
      ['mdf-searchbox-close--disabled']: this.props.disabled
    };

    let nodeId = this.props.id;

    if (!nodeId) {
      nodeId = generateId('SearchBox');
    }
    const ariaLabel = resolveAriaProperty('SearchBox', 'aria-label', 'ariaLabel', this.props);

    return (
      <div className="mdf-searchbox-container " onKeyPress={this.handleKeyPress} id={nodeId}>
        <Textbox
          className="mdf-searchbox-input"
          data-id="mdf-searchbox-id"
          aria-label={ariaLabel}
          placeholder={placeHolder}
          immediate={true}
          tabIndex={this.props.disabled ? -1 : this.props.tabIndex}
          value={this.state.query}
          disabled={this.props.disabled}
          onBlur={() => { }}
          onChange={this.onQueryChange}
          autoFocus={this.props.autoFocus}
        />
        {this.state.query &&
          <a className={classNames(closeIconClasses)} onClick={this.onCloseClick} onKeyPress={this.onCloseIconKeyPress} tabIndex={this.props.disabled ? -1 : 0} role="button" aria-label={FormatHelper.formatMessage('@@Clear')} >
            <i className="fa mdf-searchbox-close-icon" />
          </a>
        }
        <span className={classNames(searchButtonClasses)} onClick={this.onSearchIconClick} onKeyPress={this.onSearchIconKeyPress} role="button" tabIndex={this.props.disabled ? -1 : 0} aria-label={FormatHelper.formatMessage('@@SubmitSearch')} />
      </div>
    );
  }
}
