import React from 'react';

export interface IMDFWizardHeader {
  // A CSS class name for overriding styles.
  className?: string;
  // Children like EmployeeIdBar to display in the header
  children?: React.ReactNode;
}

export const MDFWizardHeader = (props: IMDFWizardHeader) => (
  <div className={`mdf-wizard-component--header ${props.className ?? ''}`}>
    {props.children}
  </div>
);
