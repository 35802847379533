const CONSECUTIVE_CHAR_SPACE = / {2}|-{2}|'{2}|\.{2}|,{2}/;
const NOT_ALLOW_SPECIAL_CHAR = /^[^()<>]+$/;

export class GenericValidator {
  static isNotNullOrEmpty(value): boolean {
    return ((value !== null) && (value !== '') && (value !== undefined));
  }

  static isNotMoreThanMaxLength(value: string, maxLength: number): boolean {
    return (!value || (value.length <= maxLength));
  }

  static isNotLessThanMinLength(value: string, minLength: number): boolean {
    return (!value || (value.length >= minLength));
  }

  static isValidPattern(value: string, regExp: RegExp): boolean {
    if (!value) {
      return true;
    }

    return regExp.test(value);
  }

  static hasConsecutiveSpecialChars(value: string) {
    return ((new RegExp(CONSECUTIVE_CHAR_SPACE)).test(value));
  }

  static isDateLaterThanCurrent(value): boolean {
    if (!value) {
      return true;
    }

    const isValid = (new Date(value).toISOString()).localeCompare(this.getCurrentDate());
    return (isValid === -1);
  }

  // Make sure date value is not null or empty
  static isValidDate(dateValue: string): boolean {
    if (dateValue.includes('/') || dateValue.includes('-') && (dateValue.length === 8 || dateValue.length === 7 || dateValue.length === 6)) {
      return true;
    }

    return (new RegExp('[A-Z0-9 ,]*').test(dateValue));
  }

  static isEndLaterThanStart(start, end): boolean {
    start = new Date(start).toISOString();
    end = new Date(end).toISOString();
    const isValid = start.localeCompare(end);
    return (isValid === -1 || isValid === 0);
  }

  static isNotMoreThanMaxDate(end, maxDate: string): boolean {
    end = new Date(end);
    const isValid = maxDate.localeCompare(end);
    return (isValid === -1 || isValid === 0);
  }

  static isNotLessThanMinDate(dateValue, minDate: string) {
    dateValue = new Date(dateValue).toISOString();
    const isValid = minDate.localeCompare(dateValue);
    return (isValid === -1 || isValid === 0);
  }

  private static getCurrentDate(): string {
    return (new Date().toISOString());
  }

  static isNumberInRange(value: number, minRange: number, maxRange: number): boolean {
    return (minRange <= value) && (value <= maxRange);
  }

  static hasInvalidCharacters(value: string) {
    return !(NOT_ALLOW_SPECIAL_CHAR.test(value));
  }

  static formatEIN = (employerIdentificationNumber) => {
    let formattedValue = employerIdentificationNumber.replace(new RegExp('-', 'gm'), '');
    const employerIdentificationNumberLength = formattedValue.length;

    if (employerIdentificationNumberLength > 2 && employerIdentificationNumberLength <= 11) {
      formattedValue = formattedValue.substr(0, 2) + '-' + formattedValue.substr(2, 7);
    }
    return formattedValue;
  };

  static formatSSN = (socialSecurityNumber) => {
    let formattedValue = socialSecurityNumber.replace(new RegExp('-', 'gm'), '');
    const socialSecurityNumberLength = formattedValue.length;

    if (socialSecurityNumberLength > 3 && socialSecurityNumberLength <= 11) {
      formattedValue = formattedValue.substr(0, 3) + '-' + formattedValue.substr(3, 2) + '-' + formattedValue.substr(5, 4);
    }
    return formattedValue;
  };

  static formatITIN = (individualTaxpayerNumber) => {
    let formattedValue = individualTaxpayerNumber.replace(new RegExp('-', 'gm'), '');
    const individualTaxpayerNumberLength = formattedValue.length;

    if (individualTaxpayerNumberLength > 3 && individualTaxpayerNumberLength <= 11) {
      formattedValue = formattedValue.substr(0, 3) + '-' + formattedValue.substr(3, 2) + '-' + formattedValue.substr(5, 4);
    }
    return formattedValue;
  };

  static formatSIN = (socialInsuranceNumber) => {
    let formattedValue = socialInsuranceNumber.replace(new RegExp(' ', 'gm'), '');
    const socialInsuranceNumberLength = formattedValue.length;

    if (socialInsuranceNumberLength > 3 && socialInsuranceNumberLength <= 11) {
      formattedValue = formattedValue.substr(0, 3) + ' ' + formattedValue.substr(3, 3) + ' ' + formattedValue.substr(6, 3);
    }
    return formattedValue;
  };
}
