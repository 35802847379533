import React from 'react';
import { MDFCore } from '@adp-wfn/mdf-core';
import { MDFButton } from './MDFButton';
import { ValidationContainerContext } from './MDFContext';

export class MDFFormButton extends React.Component<any, any> {
  declare context: React.ContextType<typeof ValidationContainerContext>;
  static contextType = ValidationContainerContext;

  state = {
    formIsValid: true
  };

  constructor(props) {
    super(props);

    if (!props.name) {
      console.error('Form buttons must have a name attribute!');
    }
  }

  componentDidMount() {
    if (this.context.registerButton) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFFormButton.componentDidMount(): ${this.props.id} Registering.`);
      }

      // Attaching the component to the form
      this.context.registerButton(this);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterButton) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFFormButton.componentWillUnmount(): ${this.props.id} Unregistering.`);
      }

      // Detaching if unmounting
      this.context.unregisterButton(this);
    }
  }

  updateButtonState = (formIsValid) => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFFormButton.updateButtonState(): ${this.props.id} Entering. formIsValid =`, formIsValid);
    }

    this.setState(() => ({ formIsValid }));
  };

  submit = () => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFFormButton.submit(): ${this.props.id} Submitting.`);
    }

    this.context.handleSubmit(this.props.name);
  };

  cancel = () => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFFormButton.cancel(): ${this.props.id} Cancelling.`);
    }

    this.context.handleCancel(this.props.name);
  };

  render() {
    if (MDFCore.shouldLog()) {
      console.log(`MDFFormButton.render(): ${this.props.id} Entering. context = `, this.context);
    }

    // eslint-disable-next-line prefer-const
    let { children, type, ignoreValidation, ...passDownProps } = this.props;

    let isDisabled = false;

    if (!ignoreValidation) {
      isDisabled = !(this.state.formIsValid);
    }

    // TypeScript 2.6 preserves the 'readonly' flag on the items copied from this.props above, so we have to clone the data.
    if (type === 'submit') {
      passDownProps = Object.assign({}, { ...passDownProps }, { onClick: this.submit });
    }
    else if (type === 'cancel') {
      passDownProps = Object.assign({}, { ...passDownProps }, { onClick: this.cancel });
    }

    return <MDFButton disabled={isDisabled} {...passDownProps}>{children}</MDFButton>;
  }
}
