import React, { useEffect, useRef } from 'react';

// This code is adapted from reactify-wc from Mercedes-Benz Research and Development North America Inc.
// The original can be found here: https://github.com/BBKolton/reactify-wc
export const createReactWrapper = (tagName: string) => {
  // Wrap the tag as a functional React component
  const component = (props: any) => {
    console.log(`${tagName} has props ${JSON.stringify(Object.keys(props))}`);

    // We need to keep a reference to the tag's mounted node so that we can
    // assign attributes and properties and attach event handlers as React can only
    // handle string or number attributes and standard event handlers. Web components
    // might have complex properties (array/object) or generate custom events.
    const node = props.ref || useRef(null);

    // We need to keep track of the event handlers assigned to the tag so we can remove them
    // when the properties change or the tag is unmounted.
    const eventHandlers = [];

    const removeEventHandlers = () => {
      console.log(`${tagName} clearing ${eventHandlers.length} event handlers.`);

      eventHandlers.forEach(([event, handler]) => {
        // Sometimes the node is destroyed BEFORE we can call removeEventListener,
        // so use some optional chaining to avoid errors.
        node?.current?.removeEventListener(event, handler);
      });
    };

    // Map the props object to the attributes, properties, and event handlers of the web component.
    useEffect(() => {
      if (node.current) {
        Object.entries(props).forEach(([prop, value]) => {
          console.log(`${tagName} handling ${prop}`);

          // props.children will be handled in React.createElement below.
          if (prop === 'children') {
            return undefined;
          }

          // props.className will be assigned to the className property of the node.
          if (prop.toLowerCase() === 'classname') {
            return node.current.className = value as string;
          }

          // props.onSomething that are functions will be treated as event handlers.
          // This is so that we can map to a web component's custom events.
          if (typeof value === 'function' && prop.match(/^on[A-Z]/)) {
            const event = prop[2].toLowerCase() + prop.substr(3);
            eventHandlers.push([event, value]);
            return node.current.addEventListener(event, value as EventListener);
          }

          // props.on-something that are functions are also treated as event handlers.
          if (typeof value === 'function' && prop.match(/^on\-[a-z]/)) {
            const event = prop.substr(3);
            eventHandlers.push([event, value]);
            return node.current.addEventListener(event, value as EventListener);
          }

          // props.something that are strings and numbers are assigned to the tag as both properties and attributes.
          // It's possible that the web component would keep scalar properties and attributes in sync, but we can't assume.
          if (typeof value === 'string' || typeof value === 'number') {
            node.current[prop] = value;
            return node.current.setAttribute(prop, value as string);
          }

          // props.something that are booleans and true are assigned to the tag as properties and as attributes with a string value.
          // If the boolean is false, then we need to delete the property and the attribute.
          if (typeof value === 'boolean') {
            if (value) {
              node.current[prop] = true;
              return node.current.setAttribute(prop, (value as unknown) as string);
            }
            else {
              delete node.current[prop];
              return node.current.removeAttribute(prop);
            }
          }

          // For anything else (arrays, objects) we only assign the tag property since they can't be attributes.
          node.current[prop] = value;
          return undefined;
        });
      }

      return () => removeEventHandlers();
    });

    return React.createElement(tagName, { ref: node }, props.children);
  };

  // The component will use the web component tag name as its displayName to help debugging.
  component.displayName = tagName;

  return component;
};
