import React from 'react';
import { MDFNumberBox } from './MDFNumberBox';
import { GenericValidator } from '../validators/GenericValidator';
import { COUNTRY_TO_CURRENCY_CODE, FormatHelper, generateId, LocaleHelper } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export class MDFCurrencyBox extends React.Component<any, any> {
  private numberBoxRef: MDFNumberBox;
  isValid: boolean;
  validationMessage: string;

  constructor(props: any) {
    super(props);
  }

  static displayName = 'MDFCurrencyBox';

  formatCurrency = (props) => {
    // When no currency code is specified pick the currency code based on the locale
    const currencyType = props.currencyCode ? props.currencyCode : COUNTRY_TO_CURRENCY_CODE[LocaleHelper.getCountryCode()];
    let minimumFractionDigits = props.minimumFractionDigits ? props.minimumFractionDigits : props.decimal;

    if (props.limitTrailingZeros === true && minimumFractionDigits && GenericValidator.isNotNullOrEmpty(props.value)) {
      const strValue = props.value.toString().split('.');
      const defaultCurrencyFractionDigits = FormatHelper.getCurrencyFractionDigits(currencyType);

      if (strValue.length > 1) {
        minimumFractionDigits = strValue[1].trimEnd().length;
        minimumFractionDigits = minimumFractionDigits <= defaultCurrencyFractionDigits ? defaultCurrencyFractionDigits : minimumFractionDigits;
      }
      else {
        minimumFractionDigits = defaultCurrencyFractionDigits;
      }
    }

    return FormatHelper.formatNumber(props.value, { minimumFractionDigits, style: 'currency', currency: currencyType }, props.culture);
  };

  isValidComponent = (_value) => this.numberBoxRef.isValidComponent(_value);

  currencyBoxCallback = (isValid, validationMessage) => {
    this.isValid = isValid;
    this.validationMessage = validationMessage;
  };

  render() {
    const { id, currencyCode, decimal, enableAutoSelect, ...compProps } = this.props;
    const ariaDescribedby = resolveAriaProperty('MDFCurrencyBox', 'aria-describedby', 'ariaDescribedby', this.props);
    const currencyType = currencyCode ? currencyCode : COUNTRY_TO_CURRENCY_CODE[LocaleHelper.getCountryCode()];
    const defaultCurrencyFractionDigits = FormatHelper.getCurrencyFractionDigits(currencyType);
    const currencyDecimal = decimal !== undefined ? decimal : defaultCurrencyFractionDigits;

    let nodeId = id;

    if (!nodeId) {
      nodeId = generateId('mdfCurrencyBox');
    }

    return <MDFNumberBox id={nodeId} ref={(c) => this.numberBoxRef = c} {...compProps} decimal={currencyDecimal} currencyBoxCallback={this.currencyBoxCallback} onFormatValue={this.formatCurrency(this.props)} aria-describedby={ariaDescribedby} enableAutoSelect={enableAutoSelect} />;
  }
}
