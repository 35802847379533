import React from 'react';
import PropTypes from 'prop-types';
import { INavStep, NavStep } from './NavStep';
import { colorNames, VDLColors } from '../../util/colorStyles';

export type StepNavType = 'numerical' | 'icon';

export interface IStepNavigation {
  // To set step navigation type. Defaults to numerical.
  stepNavType?: StepNavType;

  // Whether or not the navigation steps are displayed sequential or not. Defaults to true.
  sequential?: boolean;

  // Show lines between steps. When 'sequential' set to false, this property has no effect. Defaults to true.
  showLines?: boolean;

  // To set the active step color. Defaults to accent-0-dark.
  activeAccent?: VDLColors;

  // To set the incomplete step color. Defaults to neutral-dark.
  incompleteAccent?: VDLColors;

  // Child nodes to render within the step navigation container.
  children?: React.ReactNode;
}

export function StepNavigation(props: IStepNavigation) {
  const { children, sequential, showLines, ...otherProps } = props;
  const steps = [];
  const childCount = React.Children.count(children);

  React.Children.forEach(children, (child: any, idx: number): any => {
    if (child && child.type === NavStep) {
      steps.push(React.cloneElement(child, {
        lastChild: idx === (childCount - 1),
        ...otherProps,
        showLines: sequential ? showLines : false,
        active: sequential ? child.props.active : true,
        position: idx + 1
      } as INavStep));
    }
  });

  return (
    <div className="step-navigation-container">
      {steps}
    </div>
  );
}

StepNavigation.displayName = 'StepNavigation';

StepNavigation.propTypes = {
  stepNavType: PropTypes.oneOf(['numerical', 'icon']),
  sequential: PropTypes.bool,
  showLines: PropTypes.bool,
  activeAccent: PropTypes.oneOf(colorNames()),
  incompleteAccent: PropTypes.oneOf(colorNames())
};

StepNavigation.defaultProps = {
  stepNavType: 'numerical',
  sequential: true,
  showLines: true,
  activeAccent: 'accent-0-dark',
  incompleteAccent: 'neutral-dark'
};
