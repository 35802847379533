import React from 'react';
import { Button, IButtonProps } from '@synerg/vdl-react-components';
import { MDFIcon } from './MDFIcon';
import { DeviceHelper, NativeHelper } from '@adp-wfn/mdf-core';

// Notes
// The MDFButton wraps the Button component from @synerg/vdl-react-components.

export interface IOpenPageParams {
  baseUrl: string;
  documentType: string;
  title: string;
  uri: string;
  onDismiss?: (any) => void;
}

export interface IMobileSsoParams {
  clientId: string,
  onError: () => void,
  onSuccess: () => void,
  redirect_uri: string;
}

export interface IMDFButtonProps extends IButtonProps {
  // Set the id attribute on the component.
  id?: string;
  // The Font Awesome or ADP Icon Font to use for the buttons icon.
  iconClass?: string;
  // SSO support from the mobile app.
  mobileSsoParams?: IMobileSsoParams;
  // Open page support for Mobile running in Cordova.
  openPageParams?: IOpenPageParams;
  // Set the tabIndex attribute on the component.
  tabIndex?: number;
  // Set the title attribute of the component. Used primarily for text that might overflow or hover 'help'.
  title?: string;
}

export const MDFButton = (props: IMDFButtonProps) => {
  const { className, iconClass, ...compProps } = props;
  let buttonClasses = className;

  // If the button is icon-only, add the vdl-button--icon class to make the button round.
  if (!props.children && iconClass) {
    buttonClasses += ' vdl-button--icon';
  }

  const handleMobileOnClick = () => {
    if (props.mobileSsoParams) {
      NativeHelper.openPageWithSso(props.mobileSsoParams);
    }
    else {
      NativeHelper.openPage(props.openPageParams);
    }
  };

  // Do not pass altText to mdfIcon for icon + text only scenario
  const iconAltText = props.children ? '' : (props['aria-label'] || iconClass);

  return (
    <Button {...compProps} className={buttonClasses} onClick={(DeviceHelper.isADPMobileApp() && (props.mobileSsoParams || props.openPageParams)) ? handleMobileOnClick : props.onClick}>
      {(iconClass) ? <MDFIcon altText={iconAltText} className={`vdl-button__icon ${iconClass}`} /> : ''}
      {props.children}
    </Button>
  );
};

MDFButton.displayName = 'MDFButton';
