import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WFNXCardBody } from './WFNXCardBody';
import { WFNXSmallCardFooter } from './WFNXSmallCardFooter';
import { WFNXSmallCardHeader } from './WFNXSmallCardHeader';

export interface IWFNXSmallCardProps {
  className?: string;
  draggable?: boolean;
}

export class WFNXSmallCard extends React.Component<React.PropsWithChildren<IWFNXSmallCardProps>, any> {
  static propTypes = {
    className: PropTypes.string,
    draggable: PropTypes.bool
  };

  render() {
    let header = null;
    let body = null;
    let footer = null;
    const { className } = this.props;

    React.Children.forEach(this.props.children, (child: any): any => {
      if (child.type === WFNXSmallCardHeader) {
        header = child;
      }
      else if (child.type === WFNXCardBody) {
        body = child;
      }
      else if (child.type === WFNXSmallCardFooter) {
        footer = child;
      }
    });

    return (
      <div className={classNames('wfnx-small-card', className)} draggable={this.props.draggable}>
        {header}
        {body}
        {footer}
      </div>
    );
  }
}
