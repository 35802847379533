import React from 'react';

import { IMDFFieldState } from './FieldState';
import { MDFValidatedField } from './MDFValidatedField';
import { Validations } from './Validations';
import { FormatHelper } from '@adp-wfn/mdf-core';

export function MDFRequiredField(Field: any) {
  const ValidatedField = MDFValidatedField(Field);

  return class extends React.Component<any, any> {
    static displayName = `MDFRequired${Field.displayName || 'Field'}`;
    private field: any;

    componentDidUpdate(prevProps: any) {
      const propsHasChanged = (!!prevProps.required) !== (!!this.props.required);

      if (propsHasChanged) {
        const fieldState = this.getFieldState();
        this.updateField(fieldState.value, this.props.validateOnRender, this.field.isValid, this.field.validationMessage);
      }
    }

    onValidate = (validState: IMDFFieldState) => {
      if (validState.isValid) {
        validState = this.validateRequired(validState);

        if (validState.isValid && this.props.onValidate) {
          validState = this.props.onValidate(validState);
        }
      }

      return validState;
    };

    validateRequired = (validState: IMDFFieldState): IMDFFieldState => {
      const { required, requiredMessage } = this.props;

      if (required) {
        // Validate Required Field
        validState.isValid = !Validations.isEmpty(validState.value);

        if (validState.isValid && typeof validState.value === 'object' && !(validState.value instanceof Date)) {
          validState.isValid = Validations.validateObjectKeys(validState.value);
        }
      }

      if (!validState.isValid) {
        validState.validationMessage = requiredMessage ? ` ${requiredMessage}` : ` ${FormatHelper.formatMessage('@@mdfRequiredField')}`;
      }

      return validState;
    };

    getFieldState = () => this.field.state;

    updateField = (value: any, showValidation: boolean, isValid: boolean = null, validationMessage: string = null) => {
      this.field.updateField(value, showValidation, isValid, validationMessage);
    };

    setField = (field: any) => {
      this.field = field;
    };

    render() {
      const {
        children,
        onValidate,
        ...passDownProps
      } = this.props;

      return <ValidatedField ref={this.setField} onValidate={this.onValidate} {...passDownProps}>{children}</ValidatedField>;
    }
  };
}
