import React from 'react';
import { ModelHelper } from '@adp-wfn/mdf-core';
import { WfnInfiniteList } from '@adp-wfn/mdf-wc-react';
import { AgGridManager } from '../components/AgGrid/AgGridManager';

// type WfnInfiniteList needs to be specially handled
// Special attributes:
//  fetchItems: a model reference to a function that will load items on demand
//  itemVar: the name under which the item under iteration and the item index is exposed to the content of the InfiniteList
//    defaults to 'item' and 'itemIndex' when not provided.
export default function wfnInfiniteListRenderer({ viewName, view, model }) {
  const fetchItems = ModelHelper.resolve(view.fetchItems, model, viewName);

  const itemVar = view.itemVar || 'item';
  const itemContent = view.content;

  const viewProperties = ModelHelper.resolve(view.properties, model, viewName);
  viewProperties.fetchItems = fetchItems;

  viewProperties.renderItem = (item, index) => {
    const itemModel = { ...model, [itemVar]: item, [itemVar + 'Index']: index };
    return AgGridManager.renderContent(itemContent, itemModel, viewName);
  };

  return React.createElement(WfnInfiniteList, viewProperties);
}
