import React from 'react';
import { SdfBox } from '@waypoint/react-components';
import { colorStyles, VDLColors, waypointColorStyles } from '../../util/colorStyles';
import { FeatureFlags } from '@adp-wfn/mdf-core';

export type CardGutterWidth = 0 | 5 | 10 | 15 | 20;

const newGutterWidth = {
  0: 'none',
  5: 'p-2',
  10: 'p-2',
  15: 'tight',
  20: 'loose'
};

export type CardStyle = 'shadow' | 'flat' | 'dash' | 'blank';

const newCardStyle = {
  blank: 'clear',
  dash: 'bordered',
  flat: 'bordered',
  shadow: 'shadowed',
  underlined: 'underlined'
};

export interface ICardAtomProps {
  // The background color for the card. Defaults to 'white'.
  cardColor?: VDLColors;
  // The border/box shadow style for the card. Defaults to 'shadow'.
  cardStyle?: CardStyle;
  // Placeholder for the component's children.
  children?: any;
  // ClassName to apply for cardAtom
  className?: string;
  // The internal padding for the card. Defaults to 10.
  gutterWidth?: CardGutterWidth;
  // Whether the card is displayed inline or not. Defaults to false.
  inline?: boolean;
  // Use the Waypoint version of the layout styles, will be ignored once the feature flag is removed
  isWaypoint?: boolean;
}

export const CardAtom = (props: ICardAtomProps) => {
  const { cardColor, cardStyle, className, children, gutterWidth, inline } = props;
  const hasOldShell = (window as any).isLegacyAppShell || (window as any).isClassicShell;
  const hasWaypointCards = FeatureFlags.hasFeature('waypointCards') && !hasOldShell;
  const isWaypoint = props.isWaypoint || hasWaypointCards;
  const BoxComponent = isWaypoint ? SdfBox : 'div';
  const colorClasses = isWaypoint ? waypointColorStyles(cardColor) : colorStyles(cardColor);

  const boxProps: Record<string, string> = {};

  if (isWaypoint) {
    boxProps.className = `${className} ${colorClasses} ${gutterWidth === 5 || gutterWidth === 10 ? 'p-2' : ''} ${cardStyle === 'dash' ? 'border-dashed' : ''}`.trim();
    boxProps.variant = newCardStyle[cardStyle];

    if (gutterWidth !== 5 && gutterWidth !== 10) {
      boxProps.spacing = newGutterWidth[gutterWidth];
    }
  }
  else {
    boxProps.className = `mdf-card-atom mdf-card--${cardStyle} ${className} gutterWidth-${gutterWidth} ${colorClasses} ${inline ? 'inline' : ''}`;
  }

  return <BoxComponent {...boxProps}>{children}</BoxComponent>;
};

CardAtom.displayName = 'CardAtom';
CardAtom.defaultProps = {
  cardColor: 'white',
  cardStyle: 'flat',
  gutterWidth: 10,
  inline: false
};
