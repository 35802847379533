import React, { useReducer } from 'react';
import { any, clone, either, filter, isNil, path, propEq } from 'ramda';
import { FormatHelper, ModelHelper } from '@adp-wfn/mdf-core';
import { CheckboxSet, LayoutBox, LayoutItem, MDFButton, MDFContentPane, MDFLabeledNumberBox, MDFLabeledTextarea, MDFLabeledTextbox, MDFModalDialog, MDFNumberBox, MDFTextarea, MDFTextbox, RadioButtonSet } from '../../index';

function SomeOf(props: any) {
  const checkboxes = props.data.map((item) => (
    {
      id: props.id + '_' + item.id,
      optionId: item.id,
      label: item.text,
      checked: item.selected
    }
  ));

  const newData = [ ...props.data ];

  return (
    <LayoutBox>
      <LayoutItem>
        <CheckboxSet
          items={checkboxes}
          isHorizontal={props.isHorizontal}
          onChange={(option, checked) => {
            newData.forEach((item) => {
              if (item.id === option.optionId) {
                item.selected = checked;
              }
            });

            if (props.onChange) {
              props.onChange(Object.assign({}, props, { data: newData }));
            }
          }}
        />
      </LayoutItem>
    </LayoutBox>
  );
}

function OneOf(props: any) {
  let radioButtons = props.data.map((item) => (
    {
      id: props.id + '_' + item.id,
      value: item.value,
      contentLabel: item.text,
      selected: item.selected
    }
  ));

  const selectedItem = radioButtons.filter((item) => item.selected);

  if (selectedItem && selectedItem.length > 0) {
    const radioGroupValue = selectedItem[0].value;
    radioButtons = radioButtons.map((item) => Object.assign({}, item, { selected: (item.value === radioGroupValue), radioGroupValue }));
  }

  let newData = [ ...props.data ];

  return (
    <LayoutBox>
      <LayoutItem>
        <RadioButtonSet
          items={radioButtons}
          isHorizontal={props.isHorizontal}
          onChange={(option) => {
            newData = newData.map((item) => {
              item.radioGroupValue = option.value;
              item.selected = item.value === option.value;
              return item;
            });

            if (props.onChange) {
              props.onChange(Object.assign({}, props, { data: newData }));
            }
          }}
        />
      </LayoutItem>
    </LayoutBox>
  );
}

function YesNo(props: any) {
  const radioGroupValue = props.data.value === true ? 'Yes' : (props.data.value === false ? 'No' : undefined);
  const buttons: any = [
    { id: props.id + '-yes', value: 'Yes', contentLabel: 'Yes', radioGroupValue },
    { id: props.id + '-no', value: 'No', contentLabel: 'No', radioGroupValue }
  ];

  return (
    <LayoutBox className="mdf-interview-section__answer">
      <LayoutItem>
        <RadioButtonSet
          items={buttons}
          isHorizontal={true}
          onChange={(option) => {
            let newValue;

            if (option.value === 'Yes') {
              newValue = true;
            }
            else if (option.value === 'No') {
              newValue = false;
            }

            if (props.onChange) {
              props.onChange(newValue);
            }
          }}
        />
      </LayoutItem>
    </LayoutBox>
  );
}

function renderAnswerWithDoubleDare(rendering, answer, onChange) {
  return (
    <>
      {rendering}
      {answer.doubleDare &&
      <MDFModalDialog
        show={answer.showDoubleDare}
        modalType={'Alert'}
        size={'md'}
        backdrop={true}
        closeable={false}
        title={answer.doubleDare.title}
        yesButtonText={answer.doubleDare.yesLabel}
        noButtonText={answer.doubleDare.noLabel}
        onYes={() => {
          console.log('renderAnswerWithDoubleDare.onYes(): Entering.');
          answer.showDoubleDare = false;
          answer.originalData = undefined;

          if (onChange) {
            onChange(answer);
          }
        }}
        onNo={() => {
          console.log('renderAnswerWithDoubleDare.onNo(): Entering.');
          answer.showDoubleDare = false;
          answer.data = answer.originalData;

          if (onChange) {
            onChange(answer);
          }
        }}
      >
        <MDFContentPane html={answer.doubleDare.content}/>
      </MDFModalDialog>
      }
    </>
  );
}

function renderAnswers(state, answer, onChange) {
  const answers = Object.keys(answer);

  return (
    <>
      {
        answers.map((answerId) => {
          const item = answer[answerId];
          item.id = answerId;

          let shouldRender = item.render !== false;

          if (item.render) {
            // Render functions need to start with ':quote' so that they aren't interpreted until now.
            // Use slice(1) to remove ':quote' and then run the script.
            shouldRender = ModelHelper.resolve(item.render.slice(1), state, 'InterviewSection');
          }

          const multiValueOnChange = (value) => {
            const safeValue = path(['doubleDare', 'safeData', 'value'], value);
            const isSelected = propEq(true, 'selected');
            const selectedItems = filter(isSelected, value.data);
            const isSafe = any(either<any>(propEq(safeValue, 'id'), propEq(safeValue, 'value')), selectedItems);

            if (value.doubleDare && (!isSafe)) {
              value.showDoubleDare = true;
            }

            // Don't fire onChange if there is a double-dare.
            if (onChange) {
              onChange(value);
            }
          };

          const genericOnChange = (value) => {
            item.data.value = value;

            if (item.doubleDare && (item.data.value !== path(['doubleDare', 'safeData', 'value'], item))) {
              item.showDoubleDare = true;
            }

            // Don't fire onChange if there is a double-dare.
            if (onChange) {
              onChange(item);
            }
          };

          if (shouldRender) {
            if (isNil(item.originalData)) {
              item.originalData = clone(item.data);
            }

            let component;

            switch (item.type) {
              case 'OneOf':
                component = <OneOf {...item} onChange={multiValueOnChange}/>;
                break;

              case 'SomeOf':
                component = <SomeOf {...item} onChange={multiValueOnChange}/>;
                break;

              case 'Number':
                if (item.data.label) {
                  component = <MDFLabeledNumberBox labelText={item.data.label} value={item.data.value} onChange={genericOnChange}/>;
                }
                else {
                  component = <MDFNumberBox value={item.data.value} onChange={genericOnChange}/>;
                }

                break;

              case 'String':
                if (item.data.label) {
                  component = <MDFLabeledTextbox labelText={item.data.label} value={item.data.value} onChange={genericOnChange}/>;
                }
                else {
                  component = <MDFTextbox value={item.data.value} onChange={genericOnChange}/>;
                }

                break;

              case 'Text':
                if (item.data.label) {
                  component = <MDFLabeledTextarea labelText={item.data.label} value={item.data.value} onChange={genericOnChange}/>;
                }
                else {
                  component = <MDFTextarea value={item.data.value} onChange={genericOnChange}/>;
                }

                break;

              case 'YesNo':
                component = <YesNo {...item} onChange={genericOnChange}/>;
            }

            return renderAnswerWithDoubleDare(component, item, onChange);
          }
          else {
            return undefined;
          }
        })
      }
    </>
  );
}

function updateAnswer(answer) {
  if (!answer.showDoubleDare) {
    answer.originalData = undefined;
  }

  return answer;
}

function interviewReducer(state, action) {
  const newState: any = { ...state };

  switch (action.type) {
    case 'goToPage':
      newState.currentPage = action.currentPage;

      if (newState.onInterviewNavigate) {
        newState.onInterviewNavigate(newState, state.currentPage);
      }

      break;

    case 'updateAnswer':
      newState.questions[action.questionId].answer[action.answer.id] = updateAnswer(action.answer);

      if (newState.onInterviewChange) {
        newState.onInterviewChange(newState);
      }

      break;
  }

  return newState;
}

function renderQuestions(questions, title, state, dispatch) {
  return (
    <>
      { title && <h4>{title}</h4> }
      { questions.map((questionId) => {
        const question = state.questions[questionId];
        let shouldRender = true;

        if (question.render) {
          // Render functions need to start with ':quote' so that they aren't interpreted until now.
          // Use slice(1) to remove ':quote' and then run the script.
          shouldRender = ModelHelper.resolve(question.render.slice(1), state, 'InterviewSection');
        }

        if (shouldRender) {
          return (
            <div key={questionId} className={'mdf-interview-queston'} style={{ margin: '5px' }}>
              <div>{question.text}</div>
              {
                renderAnswers(state, question.answer, (answer) => {
                  console.log(JSON.stringify(answer));
                  dispatch({ type: 'updateAnswer', questionId, answer });
                })
              }
            </div>
          );
        }
        else {
          return undefined;
        }
      })}
    </>
  );
}

export function InterviewSection(props: any) {
  const state = props.state;
  const dispatch = props.dispatch;

  const currentPage = props.currentPage || state.questionFlow[state.currentPage];

  return (
    <div className={'mdf-interview-section'} style={{ margin: '5px' }}>
      { renderQuestions(currentPage.questions, currentPage.title, state, dispatch) }
    </div>
  );
}

function renderPages(pages, state, dispatch) {
  return (
    <>
      { pages.map((page, index) => <InterviewSection key={`is-${index}`} currentPage={state.questionFlow[page]} state={state} dispatch={dispatch}/>) }
    </>
  );
}

export function InterviewSummary(props: any) {
  const state = props.state;
  const dispatch = props.dispatch;

  const currentPage = state.questionFlow[state.currentPage];

  return (
    <div>
      { renderPages(currentPage.pages, state, dispatch) }
    </div>
  );
}

export function Interview(props: any) {
  const [ state, dispatch ] = useReducer(interviewReducer, props);

  if (!state.currentPage) {
    dispatch({ type: 'goToPage', currentPage: state.questionFlow.startAt || state.questionFlow[Object.keys(state.questionFlow)[0]].id });
    return;
  }

  const currentPage = state.questionFlow[state.currentPage];
  let Component;
  let isSummary = false;

  if (currentPage.questions) {
    Component = InterviewSection;
  }
  else {
    isSummary = true;
    Component = InterviewSummary;
  }

  const summaryButton = (
    <MDFButton
      buttonStyle="primary"
      onClick={() => {
        console.log('Done');

        if (props.onInterviewDone) {
          props.onInterviewDone(state);
        }
      }}
    >
      {FormatHelper.formatMessage('@@Done')}
    </MDFButton>
  );

  const saveButton = (
    <MDFButton
      buttonStyle="link"
      onClick={() => {
        console.log('Save and return');

        if (props.onInterviewSaveAndReturn) {
          props.onInterviewSaveAndReturn(state);
        }
      }}
    >
      {FormatHelper.formatMessage('@@save_finish_later')}
    </MDFButton>

  );

  const previousButton = (
    <MDFButton
      buttonStyle="link"
      onClick={() => {
        console.log(`Moving to page ${currentPage.previous}`);
        dispatch({ type: 'goToPage', currentPage: currentPage.previous });
      }}
    >
      {FormatHelper.formatMessage('@@Previous')}
    </MDFButton>
  );

  const nextButton = (
    <MDFButton
      buttonStyle="link"
      onClick={() => {
        console.log(`Moving to page ${currentPage.next}`);
        dispatch({ type: 'goToPage', currentPage: currentPage.next });
      }}
    >
      {FormatHelper.formatMessage('@@Next')}
    </MDFButton>
  );

  return (
    <>
      <Component {...props} state={state} dispatch={dispatch}/>
      {isSummary ? summaryButton : saveButton}
      {currentPage.previous && previousButton}
      {currentPage.next && nextButton}
    </>
  );
}
