import React from 'react';
import { MDFGrid } from '../components/MDFSpreadSheet/MDFGrid';
import { MDFSpreadSheet } from '../components/MDFSpreadSheet/MDFSpreadSheet';
import { ModelHelper, RenderHelper } from '@adp-wfn/mdf-core';
import { Composer } from '../util/Composer';
import DraggableSpreadSheet from '../components/MDFSpreadSheet/DraggableSpreadSheet';
import { MDFGridWindowScroll } from '../components/MDFSpreadSheet/MDFGridWindowScroll';

export default function renderSpreadSheet({ viewName, view, model }) {
  const columns = Array.isArray(view.properties.columns) ? view.properties.columns : ModelHelper.resolve(view.properties.columns, model, viewName);
  const rows = Array.isArray(view.properties.rows) ? view.properties.rows : ModelHelper.resolve(view.properties.rows, model, viewName);

  columns.forEach((column: any) => {
    column.alignColumn = ModelHelper.resolve(column.alignColumn, model, viewName);
    column.alignHeader = ModelHelper.resolve(column.alignHeader, model, viewName);
    column.className = ModelHelper.resolve(column.className, model, viewName);
    column.headerLabel = column.properties ? ModelHelper.resolve(column.headerLabel, ModelHelper.resolve(column.properties, model), viewName) : ModelHelper.resolve(column.headerLabel, model, viewName);
    column.hidden = ModelHelper.resolve(column.hidden, model, viewName);
    column.key = ModelHelper.resolve(column.key, model, viewName);
    column.locked = ModelHelper.resolve(column.locked, model, viewName);
    column.resize = ModelHelper.resolve(column.resize, model, viewName);
    column.sortable = ModelHelper.resolve(column.sortable, model, viewName);
    column.sortDirection = ModelHelper.resolve(column.sortDirection, model, viewName);
    column.width = ModelHelper.resolve(column.width, model, viewName);

    if (column.children) {
      column.children.forEach((child) => {
        child.headerLabel = child.properties ? ModelHelper.resolve(child.headerLabel, ModelHelper.resolve(child.properties, model), viewName) : ModelHelper.resolve(child.headerLabel, model, viewName);
        child.key = ModelHelper.resolve(child.key, model, viewName);
        child.width = ModelHelper.resolve(child.width, model, viewName);
      });
    }
  });

  const viewProperties = ModelHelper.resolve(view.properties, model, viewName);
  viewProperties.columns = columns;
  viewProperties.rows = rows;
  viewProperties.model = model;

  if (view.events) {
    let viewEvents = ModelHelper.resolve(view.events, model, viewName);
    viewEvents = Array.isArray(viewEvents) ? viewEvents : [viewEvents];

    // Adding events to the properties
    RenderHelper.addEventsToProperties(viewProperties, model, viewEvents);
  }

  const isGrid = (view.type === 'MDFSpreadSheet') ? false : true;

  const getWrapperComponents = () => {
    // conditionally add wrapping components to this array as needed
    const components = [];

    if (viewProperties.draggable) {
      components.push(<DraggableSpreadSheet {...viewProperties} isGrid={isGrid} />);
    }

    if (viewProperties.enableWindowScroll === true && isGrid) {
      components.push(<MDFGridWindowScroll {...viewProperties} />);
    }

    return components;
  };

  const wrappedCallback = () => (
    (wrappedProps) => (isGrid ? <MDFGrid {...wrappedProps} {...viewProperties} /> : <MDFSpreadSheet {...wrappedProps} {...viewProperties} />)
  );

  return (
    // below, we are using "functions as children" (AKA render props) - see https://reactjs.org/docs/render-props.html#using-props-other-than-render
    <Composer components={getWrapperComponents()}>
      {wrappedCallback()}
    </Composer>
  );
}
