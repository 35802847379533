import React, { useContext, useEffect, useRef, useState } from 'react';
import { IWFNMockShellToolbarItem } from '../types';
import { IWFNMockShellActivePopover, IWFNMockShellChange, PopoverExemptionContext } from '../WFNMockShell';
import { Checkbox } from '@synerg/vdl-react-components';
import { WFNMockShellToolbarPopoverNav } from './WFNMockShellToolbarPopoverNav';
import { WFNMockShellPopoverDetail } from './WFNMockShellPopoverDetail';
import { WFNMockShellPopoverInstructionalText } from './WFNMockShellPopoverInstructionalText';
import { MDFPopupDialog } from '../../MDFPopupDialog';

export interface IWFNMockShellToolbarProps extends IWFNMockShellToolbarItem {
  activePopover: IWFNMockShellActivePopover;
  toolbarItemIndex: number;
  toolbarDisabled: boolean;
  megaMenuActive: boolean;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  headerMenuDisabled: boolean;
  renderSearchBar: boolean;
  lastToolbarItemIndex: number;
}

export const WFNMockShellToolbarItem = ({ oid, icon, name, shortName, renderCheckbox, disableCheckbox, isSelected, onWFNShellChange, toolbarDisabled, toolbarItemIndex, activePopover, instructionalText, childList, megaMenuActive, headerMenuDisabled, renderSearchBar, lastToolbarItemIndex }: IWFNMockShellToolbarProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverClassName = showPopover ? 'wfnmockshell-wrapper wfnmockshell-popover' : 'wfnmockshell-wrapper wfnmockshell-popover popover-hidden'; // hide popover while it unmounts
  const [target, setTarget] = useState() as any;
  const containerRef = useRef();
  const targetRef = useRef();
  const popoverExemptionChange = useContext(PopoverExemptionContext).popoverExemptionChange;
  const handlePopoverClose = useContext(PopoverExemptionContext).handlePopoverClose;

  // Set refs after initial rendering.
  useEffect(() => {
    setTarget(targetRef.current);

    popoverExemptionChange([containerRef.current]);
    return () => popoverExemptionChange([containerRef.current], true);
  }, []);

  useEffect(() => {
    setShowPopover(!megaMenuActive && activePopover.activeToolbarItemIndex === toolbarItemIndex && activePopover.type === 'toolbar');
  }, [activePopover, megaMenuActive, toolbarItemIndex]);

  const handleCheckboxChange = (value) => {
    onWFNShellChange({
      item: {
        oid,
        name
      },
      type: 'toolbarItemCheckbox',
      value
    });
  };

  const handleOverlayTriggerClick = (event) => {
    const clickedNode = event.target;
    const containerNode = containerRef.current as any;

    if (clickedNode === containerNode || containerNode?.contains(clickedNode)) {
      // Last toolbar item - Render based on header and search availability
      // Not last toolbar item - Always render
      const renderNextBtn = toolbarItemIndex === lastToolbarItemIndex ? !headerMenuDisabled || renderSearchBar : true;

      activePopover.handlePopoverClick({
        ...activePopover,
        activeToolbarItemIndex: toolbarItemIndex,
        type: 'toolbar',
        renderNextBtn: renderNextBtn,
        renderPrevBtn: toolbarItemIndex !== 0
      });
    }
  };

  const popover = (
    <MDFPopupDialog title={shortName || name} onClose={() => handlePopoverClose()} className={popoverClassName} isOpen={showPopover} targetId={oid} targetRef={target}>
      <WFNMockShellPopoverInstructionalText instructionalText={instructionalText} />
      <WFNMockShellPopoverDetail popoverType="toolbar" childList={childList} onWFNShellChange={onWFNShellChange} />
      <WFNMockShellToolbarPopoverNav type="toolbar" toolbarItemIndex={toolbarItemIndex} activePopover={activePopover} />
    </MDFPopupDialog>
  );

  return (
    <div ref={containerRef} className="wfn-mock-shell-icon-bar-item-outer" onClick={(event) => handleOverlayTriggerClick(event)}>
      <div className="wfn-mock-shell-icon-bar-item-inner">
        {(renderCheckbox && !toolbarDisabled) && <Checkbox onChange={handleCheckboxChange} checked={isSelected} disabled={disableCheckbox} />}
        <div ref={targetRef} className="wfn-mock-shell-icon-bar--icon" onClick={(event) => handleOverlayTriggerClick(event)} id={oid}>
          <i className={`fa ${icon}`} title={name} />
          <span className="wfn-mock-shell-icon-bar--icon-text" title={name}>{shortName || name}</span>
        </div>
      </div>
      {popover}
    </div>
  );
};

WFNMockShellToolbarItem.displayName = 'WFNMockShellToolbarItem';
