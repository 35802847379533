import React from 'react';
import { ComponentManager } from '@adp-wfn/mdf-core';

export interface IFilterColumnProps {
  // CSS class name used to override styling.
  className?: string;
  // Title that can be an string to render title for the column
  title?: string;
  // Child nodes to render within the filter column
  children?: any;
  /**
   * Application can change this value to change the HTML heading tag
   * It defaults to h4 but can be overridden in cases where it doesn't semantically make sense based on the page
   * structure.
   */
  headerTag?: string;
  // Accessibility: Use id to link header to column content
  id?: string;
}

export function FilterColumn(props: IFilterColumnProps) {
  const { title, children, className, id } = props;
  const HeaderTag = ComponentManager.getComponent(props.headerTag);

  return (
    <div className={`mdf-filterColumn ${className || ''}`} >
      {title && <HeaderTag id={id} className={'mdf-filterTitle'}>{title}</HeaderTag>}
      <div className={'mdf-filterItems'}>{children}</div>
    </div >
  );
}

FilterColumn.defaultProps = {
  headerTag: 'h4'
};
