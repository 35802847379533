import React from 'react';

import { ModelHelper, RenderHelper } from '@adp-wfn/mdf-core';

import { InfiniteSearch } from '../components/InfiniteSearch';

// type InfiniteSearch needs to be specially handled
// Special attributes:
//  fetchItems: a model reference to a function that will load items on demand
//  itemVar: the name under which the item under iteration and the item index is exposed to the content of the InfiniteSearch
//    defaults to 'item' and 'itemIndex' when not provided.
export default function infiniteSearchRenderer({ viewName, view, model }) {
  const itemVar = view.itemVar || 'item';
  const itemContent = view.content;
  itemContent.properties['role'] = 'gridcell';

  const viewProperties = ModelHelper.resolve(view.properties, model, viewName);
  viewProperties.fetchItems = viewProperties.fetchItems || ModelHelper.resolve(view.fetchItems, model, viewName);

  viewProperties.renderItem = (index, item) => {
    const itemModel = { ...model, [itemVar]: item, [itemVar + 'Index']: index };
    return RenderHelper.renderContent(itemContent, itemModel, viewName);
  };

  return React.createElement(InfiniteSearch, viewProperties);
}
