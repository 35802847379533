import React from 'react';

export interface IWFNMockShellPopoverInstructionalTextProps {
  instructionalText: string;
}

export const WFNMockShellPopoverInstructionalText = ({ instructionalText }: IWFNMockShellPopoverInstructionalTextProps) => {
  return (
    <h4 className="wfnmockshell-popover-instructional-text">
      {instructionalText}
    </h4>
  );
};

WFNMockShellPopoverInstructionalText.displayName = 'WFNMockShellPopoverInstructionalText';
