import React from 'react';
import { ButtonGroup, IButtonGroupProps, IButtonProps } from '@synerg/vdl-react-components';
import { MDFButton } from './MDFButton';

export interface IToolBarProps extends IButtonGroupProps {
  buttonList?: IButtonProps[];
}

export function MDFToolBar(props: IToolBarProps) {
  const { buttonList = [], ...compProps } = props;

  const buttonFactory = (buttonProps) => (
    <MDFButton { ...buttonProps } buttonStyle="link">
      {buttonProps.label}
    </MDFButton>
  );

  return (
    <ButtonGroup { ...compProps } className="mdf-tool-bar">
      {buttonList.map((b) => buttonFactory(b))}
    </ButtonGroup>
  );
}
