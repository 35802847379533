import React, { useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';
import { SdfIcon } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';

export const MDFActionMenuOption = React.forwardRef((props: any, ref: any) => {
  const { focused, className, disabled, onClick } = props;
  const inputRef = useRef(null);
  let actionMenuOption;

  useImperativeHandle(ref, () => ({
    linkOnClick(event) {
      return !disabled ? (onClick && onClick(event)) : null;
    },
    isDisabled() {
      return disabled;
    },
    focus: () => {
      inputRef.current.focus();
    }
  }));
  const linkClasses = {
    ['vdl-action-link']: true,
    ['vdl-action-link--focus']: !disabled && focused,
    ['vdl-action-link--focus--disabled']: disabled && focused,
    ['vdl-action-link--disabled']: disabled
  };
  const ariaLabel = resolveAriaProperty(MDFActionMenuOption.displayName, 'aria-label', 'ariaLabel', props);

  if (props.iconClass?.includes('fa-') || props.iconClass?.includes('icon-')) {
    actionMenuOption = <MDFIcon className={`vdl-action-link__icon ${props.iconClass}`} />;
  }
  else {
    actionMenuOption = <SdfIcon icon={props.iconClass} />;
  }

  return (
    <a ref={inputRef} tabIndex={disabled ? -1 : 0} role="menuitem" aria-label={ariaLabel} className={classNames(linkClasses, className)} onClick={() => props.onClick && props.onClick()} id={props.id}>
      {actionMenuOption}
      <span title={props.text}>{props.text}</span>
    </a>
  );
});

MDFActionMenuOption.displayName = 'MDFActionMenuOption';
