import React, { useEffect, useRef } from 'react';
import stickyfill from 'stickyfilljs';
import { GridHelper } from '../../util/GridHelper';
import { getParentContainer } from '../../util/DOMHelper';

export interface IMDFGridWindowScrollProps {
  onFetchItems?: any;
  children?: any;
  enableWindowScroll?: boolean;
}

export const MDFGridWindowScroll = (props: IMDFGridWindowScrollProps) => {
  const headers = useRef(null);
  const bodies = useRef([]);
  const elementRef = useRef(null);
  const unlockedHeaderInitialOffsetTop = useRef(0);
  const isHeaderSticky = useRef(false);
  const isParentElementDocument = useRef(null);
  const sidePanelBodyPadding: any = useRef(0);
  const lastScrollTop = useRef(0);
  const childRef = useRef(null);

  useEffect(() => {
    isParentElementDocument.current = getParentContainer(elementRef.current);

    // Trying to get the padding top of SidePanel's body to include in the calculations to stick them to them top of the Sidepanel (i.e. right below the SidePanel's header)
    if (isParentElementDocument.current) {
      sidePanelBodyPadding.current = window.getComputedStyle(isParentElementDocument.current).paddingTop?.split('px')[0];
    }
  }, []);

  const handleMDFGridWindowScroll = (event) => {
    // default the scrolltop to pick from event.target
    let scrollTop = event.target && event.target.scrollTop;

    // event.target is referring to #document when the grid is not inside the slide-in
    // IE does not support scrollingElement hence using the scrollTop from document.body.
    if (GridHelper.isIE()) {
      // quirks || standard mode
      // get document scrollTop only when the event.target.scrollTop is not available.
      scrollTop = event.target && !scrollTop && (document.body.scrollTop || document.documentElement.scrollTop) || scrollTop;
    }
    else if (event && event.target && event.target.scrollingElement && !scrollTop) {
      scrollTop = event.target.scrollingElement.scrollTop;
    }

    const scrollDirection = (scrollTop >= lastScrollTop.current) ? 'down' : 'up';

    if (props.enableWindowScroll) {
      if (!unlockedHeaderInitialOffsetTop.current) {
        unlockedHeaderInitialOffsetTop.current = headers.current.offsetTop;
      }

      const heightOfHeader = headers.current.offsetHeight;

      if (!isHeaderSticky.current) {
        const topOfHeader = headers.current.offsetTop;

        if (scrollTop >= topOfHeader) {
          headers.current.classList.remove('mdf-grid-layout-fixed-header');
          headers.current.classList.add('mdf-grid-layout-sticky-header');

          if (GridHelper.isIE() && !isParentElementDocument.current) {
            stickyfill.add(headers.current);
          }

          isHeaderSticky.current = true;
        }
      }
      else {
        const topsOfBodies = bodies.current.map((body) => body.getBoundingClientRect().top);

        if (topsOfBodies[1] > 0 && topsOfBodies[1] > heightOfHeader) {
          headers.current.classList.remove('mdf-grid-layout-sticky-header');
          headers.current.classList.remove('mdf-grid-layout-fixed-header');
          headers.current.style['top'] = 0;

          if (GridHelper.isIE() && !isParentElementDocument.current) {
            stickyfill.remove(headers.current);
          }

          isHeaderSticky.current = false;
        }
        else {
          const topPixels = scrollTop.toString();

          if (GridHelper.isIE() && !isParentElementDocument.current) {
            headers.current.style['top'] = 0;
          }
          else {
            if (isParentElementDocument.current) {
              headers.current.style['top'] = isParentElementDocument.current.getBoundingClientRect().top + 'px';
              // assign the position fixed only if it is inside MDFSidePanel Body so that headers get stick to the top of the SidePanel body
              headers.current.classList.add('mdf-grid-layout-fixed-header');
            }
            else {
              if (topsOfBodies[1] <= 0) {
                headers.current.style['top'] = 0 + 'px';
                headers.current.classList.add('mdf-grid-layout-fixed-header');
              }
              else {
                headers.current.classList.remove('mdf-grid-layout-fixed-header');
                headers.current.style['top'] = (topPixels - sidePanelBodyPadding.current) + 'px';
              }
            }
          }
        }
      }

      if (props.onFetchItems && (scrollDirection === 'down')) {
        childRef.current.fireScrollFunctions(null, scrollTop);
      }
    }

    lastScrollTop.current = scrollTop;
  };

  useEffect(() => {
    // enable scroll true means it is grid scroll we need to call the window scroll when enableWindowScroll is false
    if (props.enableWindowScroll) {
      if (isParentElementDocument.current) {
        isParentElementDocument.current.addEventListener('scroll', handleMDFGridWindowScroll);
      }
      else {
        // Attaching scroll event listener in capturing phase.
        // Scroll events won't bubble up. So if the listener has been attached in bubbling phase then it won't be triggered on MDFSlideIn/MDFSidePanel.
        window.addEventListener('scroll', handleMDFGridWindowScroll, true);
      }
    }

    headers.current = elementRef.current.querySelector('.mdf-grid-layout-header');
    bodies.current.push(elementRef.current.querySelector('.locked-body'));
    bodies.current.push(elementRef.current.querySelector('.unlocked-body'));

    return () => {
      console.log('MDFGridWindowScroll.componentWillUnmount(): Removing the handleMDFGridWindowScroll event listener.');

      if (isParentElementDocument.current) {
        isParentElementDocument.current.removeEventListener('scroll', handleMDFGridWindowScroll);
      }
      else {
        window.removeEventListener('scroll', handleMDFGridWindowScroll, true);
      }
    };
  }, []);

  return (
    <div className={'mdf-grid-layout-window-scroll-container'} ref={elementRef}>
      {props.children({ ...props, ref: childRef })}
    </div>
  );
};
