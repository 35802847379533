import { FormatHelper } from '@adp-wfn/mdf-core';

export enum CompareOption {
  Equals,
  Greater,
  Less
}

export enum DateModes {
  Date = 'date',
  Month = 'month',
  Year = 'year'
}

const ISO_DATEONLY_FORMAT = /\d{4}-\d{2}-\d{2}$/;

export class DateHelper {
  static toUTCDate = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  static getIsoDate = (value) => {
    if (typeof value === 'string') {
      value = FormatHelper.deleteLTRMark(value);
    }

    return new Date(value).toISOString();
  };

  static getDate = (value?: Date | string): Date => {
    let parsedDate: Date = null;

    // if passed as string, parse it to date object
    if (!value) {
      parsedDate = DateHelper.toUTCDate(new Date());
    }
    else if (typeof value === 'string') {
      // Convert ISO date strings to just the date without the time part (if present).
      const valueWithoutTime = value.split('T')[0];

      if (ISO_DATEONLY_FORMAT.test(valueWithoutTime)) {
        // Add midnight UTC as the time and timezone to normalize the date.
        parsedDate = new Date(`${FormatHelper.deleteLTRMark(valueWithoutTime)}T00:00:00Z`);

        if (isNaN(parsedDate.getTime())) {
          parsedDate = null;
        }
      }
      else {
        console.error(`DateHelper.getDate(): ${value} is not a valid ISO date string.`);
      }
    }
    else if (value instanceof Date) {
      // Some applications are passing full date/time object. Strip off the time part, if there.
      parsedDate = new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate()));
    }

    return parsedDate;
  };

  static today = DateHelper.getDate();

  static compare = (date1: Date, date2: Date): CompareOption => {
    if (date1.getTime() === date2.getTime()) {
      return CompareOption.Equals;
    }
    else if (date1.getTime() > date2.getTime()) {
      return CompareOption.Greater;
    }

    return CompareOption.Less;
  };

  static equals = (date1: Date, date2: Date): boolean => {
    if (date1 && date2) {
      return date1.getTime() === date2.getTime();
    }

    return false;
  };

  static isInRange = (value: Date, minDate: Date, maxDate: Date): boolean => {
    if (value && minDate && maxDate) {
      return ((value.getTime() >= minDate.getTime()) && (value.getTime() <= maxDate.getTime()));
    }

    return false;
  };

  static isToday = (value: Date): boolean => {
    if (value) {
      return value.getTime() === DateHelper.today.getTime();
    }

    return false;
  };

  static getFirstDayOfTheMonth = (value: Date) => new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), 1));

  static getLastDayOfTheMonth = (value: Date) => new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth() + 1, 0));

  static getFirstDayOfTheYear = (value: Date) => new Date(Date.UTC(value.getUTCFullYear(), 0, 1));

  static add = (date: Date | string, value: number, mode: DateModes | DateModes.Date): Date => {
    const addedDate = DateHelper.getDate(date);

    switch (mode) {
      case DateModes.Year:
        addedDate.setUTCFullYear(addedDate.getUTCFullYear() + value);
        break;
      case DateModes.Month:
        addedDate.setUTCMonth(addedDate.getUTCMonth() + value);
        break;
      default:
        addedDate.setUTCDate(addedDate.getUTCDate() + value);
        break;
    }

    return addedDate;
  };

  static isDateChange(newDate: Date, previousDate: Date) {
    if (previousDate) {
      return newDate ? previousDate.getTime() !== newDate.getTime() : true;
    }
    else {
      return !!newDate;
    }
  }
}
