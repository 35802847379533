import React, { useEffect } from 'react';
import { FeatureFlags } from '@adp-wfn/mdf-core';

import { IMDFButtonProps, MDFButton } from './MDFButton';
import html2pdf from './html2pdf';

declare const window: any;

export interface IMDFPrintButtonProps extends IMDFButtonProps {
  // ID of element whose contents are to be printed.
  containerId: string;

  // Options specified here: https://github.com/eKoopmans/html2pdf.js#options
  pdfOptions?: object;
}

export const MDFPrintButton = (props: IMDFPrintButtonProps) => {
  const { containerId, pdfOptions, children, ...mdfButtonProps } = props;

  if (!containerId) {
    console.warn('MDFPrintButton required property "containerId" was not provided!');
  }

  useEffect(() => {
    if (!window.html2pdf) {
      window.WFNShell?.addScript('/theme/static/editor/html2pdf.bundle.min.js');
    }
  }, []);

  const onClick = () => {
    if (containerId) {
      const element = document.getElementById(containerId);

      if (element) {
        if (FeatureFlags.hasFeature('wfnHtml2Pdf')) {
          void html2pdf(element, pdfOptions);
        }
        else if (window.html2pdf) {
          window.html2pdf(element, pdfOptions);
        }
      }
    }
  };

  return <MDFButton {...mdfButtonProps} onClick={onClick}>{children}</MDFButton>;
};
