import React from 'react';
import PropTypes from 'prop-types';

export type SeparatorOrientation = 'horizontal' | 'vertical';

export interface ISeparatorProps {
  className?: string;
  label?: string;
  orientation?: SeparatorOrientation;
  size?: string;
}

export const Separator = (props: ISeparatorProps) => {
  const { className, size } = props;
  const title = props.label ? props.label : 'or';

  let classname = `mdf-separator-horizontal-${size}`;

  if (props.orientation && props.orientation === 'vertical') {
    classname = ` mdf-separator-vertical-${size}`;
  }

  if (className) {
    classname += ' ' + className;
  }

  return (
    <div className={classname}>
      <div></div>
      <div>{title}</div>
      <div></div>
    </div>
  );
};

Separator.displayName = 'Separator';

Separator.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg'])
};

Separator.defaultProps = {
  orientation: 'horizontal',
  size: 'sm'
};
