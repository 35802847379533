import React from 'react';
import { MDFDropdownList } from './MDFDropdownList';
import { MDFSelectBox } from './MDFSelectBox';
import { DeviceHelper, LocaleHelper } from '@adp-wfn/mdf-core';

declare let require: any;

export class Country extends React.Component<any, any> {
  private data: any;
  private countryList = require('./data/countries.json');
  private useDevicePicker = false;

  constructor(props) {
    super(props);
    const locale = LocaleHelper.getUserLocale().split('-')[0];
    this.data = this.getData(locale);
    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();
  }

  private getData(locale: string) {
    let data = [];
    const count: number = this.countryList.options.length;

    for (let i = 0; i < count; i++) {
      const option = this.countryList.options[i];
      let countryName = option.labels.find((myObj) => myObj.locale === locale).value;

      if (countryName && option.code) {
        countryName = countryName.replace(option.code + ' - ', '');
      }

      data.push({ value: option.id, label: countryName });
    }

    if (data.length > 3) {
      let toBeSortedData = data.slice(3, data.length);

      toBeSortedData = toBeSortedData.sort((a, b) => a.label < b.label ? -1 : 1);

      data = data.slice(0, 3).concat(toBeSortedData);
    }

    return data;
  }

  render() {
    return (
      <React.Fragment>
        {
          !this.useDevicePicker && <MDFSelectBox optionsList={this.data} {...this.props}/>
        }
        {
          this.useDevicePicker && <MDFDropdownList value={this.props.value} adaptive={true} valueField={'value'} textField={'label'} data={this.data} filter={undefined} groupBy={undefined} onChange={this.props.onChange}/>
        }
      </React.Fragment>
    );
  }
}
