export const renderResponsiveClass = (cardColumn) => {
  let columnClass = '';

  switch (cardColumn) {
    case 1:
      columnClass = 'mdf-col-xl-12 mdf-col-md-12 mdf-col-sm-6 mdf-col-xs-12';
      break;
    case 2:
      columnClass = 'mdf-col-lg-6 mdf-col-md-6 mdf-col-sm-12 mdf-col-xs-12';
      break;
    case 3:
      columnClass = 'mdf-col-lg-4 mdf-col-md-6 mdf-col-sm-12 mdf-col-xs-12';
      break;
    case 4:
      columnClass = 'mdf-col-lg-3 mdf-col-md-4 mdf-col-sm-6 mdf-col-xs-12';
      break;
    case 5:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 6:
      columnClass = 'mdf-col-lg-2 mdf-col-md-4 mdf-col-sm-6 mdf-col-xs-12';
      break;
    case 7:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 8:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 9:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 10:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 11:
      console.warn(`Currently we do not support ${cardColumn} column layout`);
      break;
    case 12:
      columnClass = 'mdf-col-lg-1 mdf-col-md-6 mdf-col-sm-4 mdf-col-xs-12';
      break;
  }

  return columnClass;
};
