import { FormatHelper, MDFCore } from '@adp-wfn/mdf-core';
import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { GridPreDestroyedEvent, GridReadyEvent } from 'ag-grid-community';
import { ColDef, ColGroupDef, ICellRendererParams, LicenseManager } from 'ag-grid-enterprise';
import { omit } from 'lodash';
import { SdfAlert } from '@waypoint/react-components';

// Notes
// AgGrid Documentation Link: https://www.ag-grid.com/react-grid/grid-interface/
// AgGrid is being served out of mdf as is without having any modifications to the interface and its properties.
// Please refer to the link for more information on AgGrid properties, interface, callbacks and api methods.
// Redux Note: It is mandatory for application teams to have an unique id property on each row data object in
// order for the react-redux to work and reflect data modifications to the grid.

export type IAgGridProps = Record<string, any>;

// set license to access enterprise version of ag-grid
/*
fetch('https://synerg.adp.com/data/keys.json')
  .then((response) => response.json())
  .then((keys) => LicenseManager.setLicenseKey(keys.grid))
  .catch((error) => console.error('Error fetching keys.json:', error));
*/

const agGridKey = 'Using_this_{AG_Grid}_Enterprise_key_{AG-051333}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Automatic_Data_Processing,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{20}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{10}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_February_2025}____[v3]_[01]_MTczODk3MjgwMDAwMA==2385b342d99488aca2b08cda8858b8fc';
LicenseManager.setLicenseKey(agGridKey);

const getLocaleText = () => {
  return {
    decimalSeparator: FormatHelper.getLocaleFormats().numberFormat.decimalSeparator,
    first: FormatHelper.formatMessage('@@First'),
    firstPage: `${FormatHelper.formatMessage('@@First')} ${FormatHelper.formatMessage('@@label_page')}`,
    last: FormatHelper.formatMessage('@@Last'),
    lastPage: `${FormatHelper.formatMessage('@@Last')} ${FormatHelper.formatMessage('@@label_page')}`,
    more: FormatHelper.formatMessage('@@More'),
    nextPage: `${FormatHelper.formatMessage('@@Next')} ${FormatHelper.formatMessage('@@label_page')}`,
    page: FormatHelper.formatMessage('@@label_page'),
    previousPage: `${FormatHelper.formatMessage('@@Previous')} ${FormatHelper.formatMessage('@@label_page')}`,
    of: FormatHelper.formatMessage('@@of'),
    thousandSeparator: FormatHelper.getLocaleFormats().numberFormat.thousandSeparator,
    to: FormatHelper.formatMessage('@@conn_to_lower'),
    totalAndFilteredRows: FormatHelper.formatMessage('@@label_rows'),
    totalRows: FormatHelper.formatMessage('@@TotalRows'),
    pageSizeSelectorLabel: FormatHelper.formatMessage('@@PAGE_SIZE')
  };
};

// AgGrid react wrapper
export const AgGrid = (props: IAgGridProps) => {
  if (MDFCore.shouldLog()) {
    console.log('AgGrid(): Entering. props =', props);
  }

  const gridRef = useRef();
  const { gridHeight, headerHeight, rowHeight, messageText } = props;
  let { width } = props;
  const filteredProps = omit(props, ['gridHeight', 'headerHeight', 'rowHeight', 'width', 'rowInsert', 'onGridReady', 'onGridPreDestroyed']);
  const isVDL = window['isADPUnified'] === undefined;
  let themeName = 'ag-theme-adp-32-1 ag-wfn-focused';
  // The default row heights:
  //   VDL - 40px
  //   OneUX - 48px (3rem, but we have to use pixels here)
  const defaultRowHeight = isVDL ? 40 : 48;
  const defaultHeaderRowHeight = isVDL ? 40 : 48;

  // Turn off row striping by default
  themeName = props.striped ? themeName : `${themeName} no-stripes`;

  // To disable minimum height of 150px of grid.
  if (props.disableMinHeight) {
    themeName = `${themeName} ag-disable-min-height`;
  }

  const height = gridHeight || '100%';
  width = width || '100%';

  filteredProps.headerHeight = headerHeight || defaultHeaderRowHeight;
  filteredProps.rowHeight = rowHeight || defaultRowHeight;

  // Default for floating-filter header height
  filteredProps.floatingFiltersHeight = filteredProps.headerHeight;

  // Add a default no rows message
  if (!props.overlayNoRowsTemplate) {
    filteredProps.overlayNoRowsTemplate = `<div class="p-8 bg-white">${FormatHelper.formatMessage('@@NoRowsFound')}</div>`;
  }

  const context = useMemo(() => ({
    dispatch: useDispatch(),
    store: useStore()
  }), []);

  const localeText = useMemo(() => getLocaleText(), []);

  // Add aria-label for Ag-grid after its ready
  const onGridReady = useCallback((params: GridReadyEvent) => {
    if (props['aria-label']) {
      const node = gridRef.current as any;
      node.api.setGridAriaProperty('label', props['aria-label']);
    }

    // Call the onGridReady event defined in the view if it's available
    props.onGridReady?.(params);
  }, []);

  const onGridPreDestroyed = useCallback((params: GridPreDestroyedEvent) => {
    if (MDFCore.shouldLog()) {
      console.log('onGridPreDestroyed(): Entering. params = ', params);
    }

    props.onGridPreDestroyed?.(params);
  }, []);

  const onKeyDown = useCallback((event: any) => {
    const key = (event.event as KeyboardEvent).key;
    const gridApi = event.api;

    if (MDFCore.shouldLog()) {
      console.log('AgGrid.onKeyDown(): event =', event);
      console.log('AgGrid.onKeyDown(): key =', key);
    }

    if (!gridApi) {
      return;
    }

    switch (key) {
      case 'F7':
        if (event.rowIndex === 0 || (event.rowIndex % gridApi.paginationGetPageSize() === 0)) {
          return;
        }

        gridApi.setFocusedCell(event.rowIndex - 1, event.column, event.rowPinned);
        break;

      case 'F8':
        if (event.rowIndex + 1 >= gridApi.getDisplayedRowCount() || ((event.rowIndex + 1) % gridApi.paginationGetPageSize() === 0)) {
          return;
        }

        gridApi.setFocusedCell(event.rowIndex + 1, event.column, event.rowPinned);
        break;

      case 'F9':
        props.rowInsert?.(event);
        break;

      default:
        return;
    }
  }, []);

  const setCellRendererSelector = (columnDef: ColDef) => {
    // Check for user provided cellRendererSelector and store it.
    const userProvidedSelector = columnDef['cellRendererSelector'];

    columnDef['cellRendererSelector'] = (params: ICellRendererParams) => {
      // Add an aria description to the editable cell.
      params.eParentOfValue.setAttribute('aria-description', FormatHelper.formatMessage('@@EDITABLE_TEXT_FIELD'));
      return userProvidedSelector?.(params);
    };
  };

  const findChildrenDef = (columnDef: ColDef | ColGroupDef) => {
    if ((columnDef as ColDef).editable) {
      setCellRendererSelector(columnDef);
    }

    if (!(columnDef as ColGroupDef).children) {
      return;
    }

    // Recursion to find all children columns
    (columnDef as ColGroupDef).children.forEach((childDef) => {
      findChildrenDef(childDef);
    });
  };

  filteredProps.columnDefs?.forEach((columnDef: ColDef | ColGroupDef) => {
    findChildrenDef(columnDef);
  });

  if (props.domLayout === 'autoHeight' && (props.rowModelType === 'serverSide' || props.rowModelType === 'infinite') && !props.pagination) {
    console.error('This AgGrid will fail soon as "autoHeight" domLayout and infinite scrolling cannot be mixed.');
  }

  filteredProps.localeText = localeText;
  filteredProps.style = { height, width };

  return (
    <React.Fragment>
      {messageText && <SdfAlert className="mb-2" type="inline" hideIcon={true} ariaLive="polite" size="md" status="neutral">{messageText}</SdfAlert>}
      <div className={themeName} style={{ height, width }}>
        <AgGridReact ref={gridRef} {...filteredProps} context={context} onGridReady={onGridReady} onCellKeyDown={onKeyDown} onGridPreDestroyed={onGridPreDestroyed}/>
      </div>
    </React.Fragment>
  );
};
