import { Worker } from './worker';

/**
 * Generate a PDF from an HTML element or string using html2canvas and jsPDF.
 *
 * @param {Element|string} src The source element or HTML string.
 * @param {Object=} opt An object of optional settings: 'margin', 'filename',
 *    'image' ('type' and 'quality'), and 'html2canvas' / 'jspdf', which are
 *    sent as settings to their corresponding functions.
 */
const html2pdf = (src, opt): Promise<Worker> => {
  // Create a new worker with the given options.
  const worker = new Worker(opt);

  if (src) {
    // If src is specified, perform the traditional 'simple' operation.
    console.log('html2pdf(): Have src. Creating PDF. src =', src, opt);
    return worker.from(src).save();
  }
  else {
    // Otherwise, return the worker for new Promise-based operation.
    return Promise.resolve(worker);
  }
};

// Expose the html2pdf function.
export default html2pdf;
