import React from 'react';
import { Calendar, ICalendarProps } from './Calendar';
import { dateFormats, IDatePickerSize } from './DatePicker';
import { DeviceHelper, FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
import { Button, Popup } from '@synerg/vdl-react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CalendarIcon from 'adp-react-icons/lib/fa/calendar';
import { SdfIcon } from '@waypoint/react-components';
import { DateHelper } from './DateHelper';
import { TimeoutManager } from '@synerg/vdl-react-components/lib/util/timeout-manager';
import { handleBlur, handleFocus, IFocusMixinProps } from '@synerg/vdl-react-components/lib/util/focus-mixin';
import { MDFBackdrop } from './MDFBackdrop';
import { activeElement } from 'dom-helpers';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IDatePickerProps extends ICalendarProps {
  // Accessibility message for component
  'aria-label'?: any;
  ariaLabel?: any;
  // Accessibility message for when component is invalid.
  'aria-invalid'?: any;
  ariaInvalid?: any;
  // to enable the required property
  required?: boolean;
  // Accessibility feature manages focus.
  id?: string;
  // to override default locale
  locale?: string;
  // to enable/disable
  readOnly?: boolean;
  // to set the tab index
  tabIndex?: number;
  // input placeholder
  placeholder?: string;
  // input name
  name?: string;
  // Intl format object or predefined format string which are supported by FormatHelper
  format?: string | {};
  // To use mobile date picker if supported
  adaptive?: boolean;
  // To allow input as editable
  disableEdit?: boolean;
  // to allow size to be either small or large
  size?: IDatePickerSize;
  // to Allow autoFocus on page load
  autoFocus?: boolean;
  // first Calender Date
  startDate?: Date;
  // second Calender Date
  endDate?: Date;
  // Called when dates are selected
  onRangeChange: (rangeDates: IRangeDates, isValid?: boolean, validationMessage?: string, isoDates?: IISODates) => void;
  // Use backdrop rather than popup
  useBackdrop?: boolean;
  // message to display when min validation fails.
  minValidationMessage?: string;
  // message to display when max validation fails.
  maxValidationMessage?: string;
  // A css class or set of classes applied to the portal container when useBackdrop is true and calendar is opened
  containerClassName?: string;
}

interface IDatePickerState {
  // state to identify the datepicker popup is open or not
  isOpen: boolean;
  // value of the first date picker
  startDate?: Date;
  // value of the second date picker
  endDate?: Date;
  isValid?: boolean;
  validationMessage?: string;
  isoStartDate?: string;
  formattedStartValue?: string;
  isoEndDate?: string;
  formattedEndValue?: string;
  renderingDate?: Date;
  focused?: boolean;
}

interface IRangeDates {
  startDate?: Date;
  /* second Calender Date */
  endDate?: Date;
}

interface IISODates {
  isoStartDate: string;
  isoEndDate: string;
}

export class DateRangePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  static propTypes = Object.assign(
    {},
    Calendar.propTypes,
    {
      // DatePicker Props
      id: PropTypes.string,
      readOnly: PropTypes.bool,
      tabIndex: PropTypes.number,
      placeholder: PropTypes.string,
      format: PropTypes.any,
      adaptive: PropTypes.bool,
      disableEdit: PropTypes.bool,
      size: PropTypes.oneOf([
        'sm',
        'lg'
      ]),
      autoFocus: PropTypes.oneOf([
        true,
        false
      ]),
      required: PropTypes.bool,
      startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]),
      containerClassName: PropTypes.string
    }
  );

  static displayName = 'DataRangePicker';

  disableEdit = true;
  useDevicePicker = false;
  isValid = true;
  validationMessage = '';
  divDateInput = null;
  dateInput = null;
  calendarButton = null;
  min?: string = LocaleHelper.dateAndTime('1900-01-01').toISOString();
  max?: string = LocaleHelper.dateAndTime('2099-12-31').toISOString();
  timeoutManager = new TimeoutManager();

  // This name comes from the ADP React Component library, so we must use it as-is.
  // tslint:disable:variable-name
  private _isMounted = false;

  constructor(props: IDatePickerProps) {
    super(props);

    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();
    this.disableEdit = props.disableEdit;

    let state: IDatePickerState = {
      isOpen: false,
      formattedStartValue: '',
      isoStartDate: '',
      formattedEndValue: '',
      isoEndDate: '',
      focused: false
    };

    if (props.startDate || props.endDate) {
      const rangeDates = this.initializeRangeDatesState(props);
      state = this.getDateState(rangeDates, false, props);

      if (rangeDates.startDate) {
        state.renderingDate = rangeDates.startDate;
      }
      else if (rangeDates.endDate) {
        state.renderingDate = rangeDates.endDate;
      }
    }
    else {
      state.renderingDate = props.defaultCalendarDate || DateHelper.today;
    }

    this.setMinAndMax(props);
    this.state = state;
  }

  static defaultProps = {
    size: 'lg',
    autoFocus: false
  };

  private setMinAndMax = (props) => {
    if (props.min) {
      if (props.min instanceof Date) {
        this.min = props.min.toISOString();
      }
      else {
        this.min = props.min;
      }
    }

    if (props.max) {
      const moment = LocaleHelper.dateAndTime;

      // setting the max value allowed as per dateFormats regex
      const maxDateAllowed = moment('2099-12-31');

      if (props.max instanceof Date) {
        this.max = props.max > maxDateAllowed.toDate() ? maxDateAllowed.toISOString() : props.max.toISOString();
      }
      else {
        const maxDateProp = moment(props.max);
        this.max = maxDateProp.toDate() > maxDateAllowed.toDate() ? maxDateAllowed.toISOString() : props.max;
      }
    }
  };

  private initializeRangeDatesState = (props) => {
    let startDate = null;

    if (props.startDate) {
      startDate = DateHelper.getDate(props.startDate);
    }

    let endDate = null;

    if (props.endDate) {
      endDate = DateHelper.getDate(props.endDate);
    }

    return {
      startDate: startDate,
      endDate: endDate
    };
  };

  private onPickerClick = (event) => {
    // It's not enough to tell React to stop propagation, we need to also stop propagation of
    // of the native event to sibling event handlers we may not be aware of that would still
    // propagate the event up the tree. This prevents popovers and such from closing when we
    // open the picker.
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.setState({
      isOpen: !this.state.isOpen,
      renderingDate: this.state.startDate || this.state.endDate || this.props.defaultCalendarDate || DateHelper.today
    });
  };

  clear = (event) => {
    // It's not enough to tell React to stop propagation, we need to also stop propagation of
    // of the native event to sibling event handlers we may not be aware of that would still
    // propagate the event up the tree. This prevents popovers and such from closing when we
    // open the picker.
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.onCalendarSelect({
      startDate: null,
      endDate: null
    }, true);
  };

  private done = (event) => {
    // It's not enough to tell React to stop propagation, we need to also stop propagation of
    // of the native event to sibling event handlers we may not be aware of that would still
    // propagate the event up the tree. This prevents popovers and such from closing when we
    // open the picker.
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.setState({ isOpen: !this.state.isOpen });
    // Pass isValid and validationMessage to notify when the date entered is not in range.
    if (this.props.onRangeChange) {
      this.props.onRangeChange({
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }, this.isValid, this.validationMessage, {
        isoStartDate: this.state.isoStartDate,
        isoEndDate: this.state.isoEndDate
      });
    }
  };

  close = () => {
    if (!this.props.useBackdrop) {
      this.setState({ isOpen: false });
    }
  };

  // When new props are received, compare the value and render the component if required.
  componentDidUpdate(prevProps: IDatePickerProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      const startDate = this.props.startDate ? DateHelper.getDate(this.props.startDate) : null;
      const endDate = this.props.endDate ? DateHelper.getDate(this.props.endDate) : null;
      const maxValue = this.max;
      const minValue = this.min;

      this.setMinAndMax(this.props);

      if (DateHelper.isDateChange(startDate, this.state.startDate) || DateHelper.isDateChange(endDate, this.state.endDate) || maxValue !== this.max || minValue !== this.min) {
        this.setState(this.getDateState({
          startDate: this.props.startDate,
          endDate: this.props.endDate
        }, false, this.props));
      }
    }
  }

  componentDidMount() {
    if (this.state.formattedStartValue || this.state.formattedEndValue) {
      this.dateInput.value = `${this.state.formattedStartValue}${this.state.formattedStartValue && this.state.formattedEndValue ? ' - ' : ''}${this.state.formattedEndValue}`;
    }
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.timeoutManager.clearAllTimeouts();
  }

  // This method is used by handleBlur and handleFocus from the @synerg/vdl-react-components, so do not delete this method.
  setTimeout = (key, callback, duration) => {
    if (this._isMounted) {
      return this.timeoutManager.setTimeout(key, callback, duration);
    }
  };

  private dateRangeValidation = (parsedDate, props) => {
    const min = props.min ? DateHelper.getDate(props.min) : null;
    const max = props.max ? DateHelper.getDate(props.max) : null;

    // Set isValid to false when the date entered is not in min/max range.
    this.isValid = DateHelper.isInRange(parsedDate, (min || parsedDate), (max || parsedDate));

    // when isValid is false update validation message to "out of Range"
    if (!this.isValid) {
      this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfOutOfRange')}`;

      if (min && (parsedDate.getTime() < min.getTime()) && this.props.minValidationMessage) {
        this.validationMessage = ` ${this.props.minValidationMessage}`;
      }

      if (max && (parsedDate.getTime() > max.getTime()) && this.props.maxValidationMessage) {
        this.validationMessage = ` ${this.props.maxValidationMessage}`;
      }
    }
    else {
      this.validationMessage = '';
    }

    return {
      isValid: this.isValid,
      validationMessage: this.validationMessage
    };
  };

  private parseStringToDate = (selectedDate: string) => {
    // If selectedDate is empty or null, return null.
    if (!selectedDate) {
      return null;
    }

    // replacing all possible delimiters (like -, /,) in the string and making sure the date will parse after delimiters are removed
    const dateParts = selectedDate.match(/(\d+)|(\w+)/g);
    let formattedInput = selectedDate;

    switch (dateParts.length) {
      case 2:
      case 3:
        formattedInput = dateParts.reduce((reducedValue, value) => {
          if (value.length === 1) {
            return reducedValue + '0' + value;
          }
          else {
            return reducedValue + value;
          }
        }, '');

        break;

      default:
        formattedInput = selectedDate;
    }

    const userLocale = LocaleHelper.getUserLocale().toLowerCase();
    let inputFormats = [];

    // Arrange the inputFormats based on the user locale. US locale users will be parsed with US formats along with all default formats, non-US users will be parsed with default formats along with US formats.
    if (userLocale.includes('us')) {
      inputFormats = dateFormats.us.concat(dateFormats.default);
    }
    else {
      inputFormats = dateFormats.default.concat(dateFormats.us);
    }

    // Finding matching format by evalulating all input regex formats.
    const matchingFormat = inputFormats.find((format) => format.regEx.test(formattedInput));

    // if the selectedDate matches any of the pattern, use its replace function to extract ISO date string.
    if (matchingFormat) {
      const selectedISODate = formattedInput.replace(matchingFormat.regEx, matchingFormat.ISOFormat);
      const parsedDate = DateHelper.getDate(selectedISODate);

      // return the parsed date only when the value is valid
      return parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null;
    }

    return null;
  };

  private getDateState(rangeDates: IRangeDates, isOpen: boolean, props) {
    let parsedStartDate: Date;
    let formattedStartDate = '';
    let isoStartDate: string;
    let parsedEndDate: Date;
    let formattedEndDate = '';
    let isoEndDate: string;

    let updatedVal;
    let timelessDateValue;

    // Reset validation.
    this.isValid = true;
    this.validationMessage = '';

    if (rangeDates.startDate) {
      try {
        parsedStartDate = DateHelper.getDate(rangeDates.startDate);
        timelessDateValue = DateHelper.getIsoDate(rangeDates.startDate);

        // If the user entered any invalid date format, the parsedDate will be null.
        // If the parsedDate is not in Range of min/max then the parsedDate will be null.
        updatedVal = this.dateRangeValidation(parsedStartDate, props);

        if (parsedStartDate) {
          formattedStartDate = FormatHelper.formatDate(parsedStartDate, props.format, props.locale);
          isoStartDate = timelessDateValue.split('T')[0];
        }
      }
      catch (error) {
        console.error(` Input value: ${rangeDates.startDate} ${error} `);
        parsedStartDate = null;
        this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfInvalidDate')}`;
        this.isValid = false;
      }

    }

    if (rangeDates.endDate) {
      try {
        parsedEndDate = DateHelper.getDate(rangeDates.endDate);
        timelessDateValue = DateHelper.getIsoDate(rangeDates.endDate);

        // If the user entered any invalid date format, the parsedDate will be null.
        // If the parsedDate is not in Range of min/max then the parsedDate will be null.
        updatedVal = (updatedVal && !updatedVal.isValid) ? updatedVal : this.dateRangeValidation(parsedEndDate, props);

        if (parsedEndDate) {
          formattedEndDate = FormatHelper.formatDate(parsedEndDate, props.format, props.locale);
          isoEndDate = timelessDateValue.split('T')[0];
        }
      }
      catch (error) {
        console.error(` Input value: ${rangeDates.endDate} ${error} `);
        parsedEndDate = null;
        this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfInvalidDate')}`;
        this.isValid = false;
      }

    }
    else if (parsedStartDate && (!updatedVal || updatedVal.isValid)) {
      this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfEndDateRequired')}`;
      this.isValid = false;
      updatedVal = null;
    }

    // Updating uncontrolled input value
    if (this.dateInput) {
      this.dateInput.value = `${formattedStartDate}${formattedStartDate && formattedEndDate ? ' - ' : ''}${formattedEndDate}`;
    }

    return {
      isOpen,
      startDate: parsedStartDate,
      endDate: parsedEndDate,
      isValid: updatedVal ? updatedVal.isValid : this.isValid,
      validationMessage: updatedVal ? updatedVal.validationMessage : this.validationMessage,
      formattedStartValue: formattedStartDate,
      isoStartDate,
      formattedEndValue: formattedEndDate,
      isoEndDate,
      focused: this.state?.focused || false
    };
  }

  private getPlaceholder(props) {
    // parse a dummy date(Nov 22, 3333) and replace with placeholders to get date pattern
    const dummyDate = new Date(Date.UTC(3333, 10, 22, 6, 44, 55));
    const formattedDate = FormatHelper.formatDate(dummyDate, props.format, props.locale);

    // replace numbers with respective placeholder 3333 with 'YYYY', 11 with 'M' 22 with 'D'
    let datePattern = formattedDate.replace(new RegExp('1', 'g'), 'M')
      .replace(new RegExp('2', 'g'), 'D')
      .replace(new RegExp('3', 'g'), 'Y')
      .replace(new RegExp('4', 'g'), 'm')
      .replace(new RegExp('5', 'g'), 's')
      .replace(new RegExp('0|6', 'g'), 'h')
      .replace(new RegExp('AM|PM', 'g'), 'tt');

    // replace weekdays or months with respective placeholder Sun with 'ddd', 'Nov' with 'MMM' // this is used when format = long|short-date-time|
    const cultureFormat = FormatHelper.getLocaleFormats();

    cultureFormat.dateFormat.weeks.forEach((week) => {
      if (formattedDate.includes(week.short)) {
        datePattern = datePattern.replace(week.short, 'ddd');
      }

      if (formattedDate.includes(week.long)) {
        datePattern = datePattern.replace(week.long, 'dddd');
      }
    });

    cultureFormat.dateFormat.months.forEach((month) => {
      if (formattedDate.includes(month.short)) {
        datePattern = datePattern.replace(month.short, 'MMM');
      }

      if (formattedDate.includes(month.long)) {
        datePattern = datePattern.replace(month.long, 'MMMM');
      }
    });

    return `${datePattern} - ${datePattern}`;
  }

  private onRangeCalendarSelect = (selectedDate: Date, autoClosePicker = true) => {
    const newState = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    if (!this.state.startDate) {
      newState.startDate = selectedDate;
    }
    else if (!this.state.endDate) {
      newState.endDate = selectedDate;
    }

    // swap the dates if startDate is greater than endDate
    if (newState.startDate && newState.endDate && newState.startDate > newState.endDate) {
      const greaterDate = newState.startDate;
      newState.startDate = newState.endDate;
      newState.endDate = greaterDate;
    }

    this.onCalendarSelect(newState, autoClosePicker);
  };

  private onCalendarSelect = (rangeDates: IRangeDates, autoClosePicker = false, callRangeChange = false) => {
    const newState = this.getDateState(rangeDates, autoClosePicker, this.props);
    this.setState(newState);

    if (callRangeChange && this.props.onRangeChange) {
      this.props.onRangeChange({
        startDate: newState.startDate,
        endDate: newState.endDate
      }, this.isValid, this.validationMessage, {
        isoStartDate: newState.isoStartDate,
        isoEndDate: newState.isoEndDate
      });
    }
  };

  private onFirstVisibleMonthChange = (date: Date) => {
    this.setState({ renderingDate: date });
  };

  private onSecondVisibleMonthChange = (date: Date) => {
    const nextDate = new Date(date);
    nextDate.setUTCMonth(nextDate.getUTCMonth() - 1);
    this.setState({ renderingDate: nextDate });
  };

  // When the popup is opened navigate to month/year onTabbing set the focus back to the input
  navigateFocus = (event) => {
    if (this.state.isOpen && activeElement() !== this.dateInput && event.key === 'Tab') {
      // this.dateInput?.focus();
    }
  };

  private handleDateInputFocus = (_event) => {
    if (!this.props.useBackdrop) {
      this.setState({ isOpen: false });
    }
  };

  private handleDatePickerBlur = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    event.persist();
    handleBlur(focusMixinProps, event, (_focused) => {
      if (!this.props.useBackdrop && this.state.isOpen) {
        this.done(event);
      }
    });
  };

  isValidComponent = () => (
    {
      isValid: this.state.isValid,
      validationMessage: this.state.validationMessage,
      value: {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    }
  );

  private handleDatePickerFocus = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleFocus(focusMixinProps, event);
  };

  private onDateChange = (e) => {
    const rangeDates = {
      startDate: null as Date,
      endDate: null as Date
    };

    if (e.target.value) {
      const dates = e.target.value.split(' - ');

      if (dates.length > 0) {
        rangeDates.startDate = this.parseStringToDate(dates[0].trim());
      }
      if (dates.length > 1) {
        rangeDates.endDate = this.parseStringToDate(dates[1].trim());
      }

      // swap the dates if startDate is greater than endDate
      if ((rangeDates.startDate && rangeDates.endDate && rangeDates.startDate > rangeDates.endDate) || (!rangeDates.startDate && rangeDates.endDate)) {
        const greaterDate = rangeDates.startDate;
        rangeDates.startDate = rangeDates.endDate;
        rangeDates.endDate = greaterDate;
      }
    }

    this.onCalendarSelect(rangeDates, this.state.isOpen, true);
  };

  render() {
    const {
      required,
      id,
      disabled,
      readOnly,
      tabIndex,
      name,
      placeholder,
      onChange,
      size,
      autoFocus,
      className,
      ...passThroughProps
    } = this.props;
    const ariaLabel = resolveAriaProperty('DateRangePicker', 'aria-label', 'ariaLabel', this.props);
    const ariaInvalid = resolveAriaProperty('DateRangePicker', 'aria-invalid', 'ariaInvalid', this.props);
    const {
      startDate,
      endDate,
      renderingDate
    } = this.state;
    const disabledOrReadonly = disabled || readOnly;
    const placeholderText = placeholder ? placeholder : this.getPlaceholder(this.props);
    let nodeId = id;

    // get the 1st day of the rendering month
    const firstRenderingDate = renderingDate;
    let secondRenderingDate = null;

    if (firstRenderingDate) {
      secondRenderingDate = new Date(firstRenderingDate);
      secondRenderingDate.setUTCMonth(secondRenderingDate.getUTCMonth() + 1);
    }

    if (!nodeId) {
      nodeId = `datePicker${Date.now()}`;
    }

    const popupClasses = classNames({
      'vdl-calendar-popup': true,
      'vdl-date-range-desktop-popup': !this.useDevicePicker
    });

    const calenderClasses = classNames({
      'vdl-date-range-desktop-calender': !this.useDevicePicker
    });

    const isADPUnified = !window['isLegacyAppShell'];

    // Date Range Picker select icon is marked aria-hidden so that AT users can only type input into the component to avoid confusion
    return (
      <div
        ref={(divInput) => this.divDateInput = divInput}
        onKeyDown={this.navigateFocus}
        className={`vdl-date-time-picker vdl-date-time-picker--${size} dateTimePicker ${this.props.className}`}
        onFocus={this.handleDatePickerFocus}
        onBlur={this.handleDatePickerBlur}
      >
        <input
          aria-label={ariaLabel}
          aria-invalid={ariaInvalid}
          aria-required={required}
          id={nodeId}
          placeholder={placeholderText}
          name={name}
          type="text"
          tabIndex={tabIndex}
          className={`vdl-date-time-picker__input vdl-date-time-picker__input--${size}`}
          aria-disabled={this.props.disabled}
          aria-readonly={this.props.readOnly}
          disabled={this.props.disabled}
          autoFocus={!!autoFocus}
          readOnly={this.props.readOnly || this.props.disableEdit}
          onFocus={this.handleDateInputFocus}
          ref={(input) => this.dateInput = input}
          onBlur={this.onDateChange}
        />
        <span className={'vdl-date-time-picker__select'}>
          <button
            tabIndex={disabledOrReadonly ? -1 : 0}
            type="button"
            className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
            disabled={disabledOrReadonly}
            aria-label={ariaLabel || FormatHelper.formatMessage('@@SelectDate')}
            onClick={this.onPickerClick}
            ref={(button) => this.calendarButton = button}
          >
            {isADPUnified ? <SdfIcon icon="date" /> : <CalendarIcon />}
          </button>
        </span>
        {!this.props.useBackdrop && <Popup
          id={nodeId + '_cal'}
          className={popupClasses}
          show={this.state.isOpen}
          target={() => this.divDateInput}
          flip={true}
          placement={'bottom-start'}
          isAriaExpanded={false}
        >
          <div>
            <div className={calenderClasses}>
              <Calendar
                className="vdl-date-range-picker-calendar vdl-date-range-picker-calendar-first"
                monthFormat="long"
                calenderType="Range"
                onChange={this.onRangeCalendarSelect}
                onVisibleMonthChange={this.onFirstVisibleMonthChange}
                renderingDate={firstRenderingDate}
                selectedDate={startDate}
                {...passThroughProps}
                endDate={endDate}
                reset={this.state.isOpen}
                max={DateHelper.getDate(this.max)}
                min={DateHelper.getDate(this.min)} />
              <Calendar
                className="vdl-date-range-picker-calendar vdl-date-range-picker-calendar-second"
                monthFormat="long"
                calenderType="Range"
                onChange={this.onRangeCalendarSelect}
                onVisibleMonthChange={this.onSecondVisibleMonthChange}
                renderingDate={secondRenderingDate}
                selectedDate={startDate}
                {...passThroughProps}
                endDate={endDate}
                reset={this.state.isOpen}
                max={DateHelper.getDate(this.max)}
                min={DateHelper.getDate(this.min)} />
            </div>
            <div className="vdl-date-range-picker-footer">
              <div className="vdl-date-range-picker-footer-left">
                <Button
                  buttonStyle="link"
                  className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
                  disabled={disabledOrReadonly}
                  aria-disabled={disabledOrReadonly}
                  onClick={this.clear}
                >
                  {FormatHelper.formatMessage('@@Clear')}
                </Button>
              </div>
              <div>
                <Button
                  buttonStyle="link"
                  className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
                  disabled={disabledOrReadonly}
                  aria-disabled={disabledOrReadonly}
                  onClick={(evt) => {
                    this.calendarButton?.focus();
                    this.done(evt);
                  }}
                >
                  {FormatHelper.formatMessage('@@Done')}
                </Button>
              </div>
            </div>
          </div>
        </Popup>}
        {this.props.useBackdrop &&
          <MDFBackdrop show={this.state.isOpen} containerClassName={this.props.containerClassName}>
            <div style={{ width: '300px' }}>
              <div className={calenderClasses}>
                <Calendar
                  className="vdl-date-range-picker-calendar vdl-date-range-picker-calendar-first"
                  monthFormat="long"
                  calenderType="Range"
                  onChange={this.onRangeCalendarSelect}
                  onVisibleMonthChange={this.onFirstVisibleMonthChange}
                  renderingDate={firstRenderingDate}
                  selectedDate={startDate}
                  {...passThroughProps}
                  endDate={endDate}
                  reset={this.state.isOpen}
                  max={DateHelper.getDate(this.max)}
                  min={DateHelper.getDate(this.min)} />
                <Calendar
                  className="vdl-date-range-picker-calendar vdl-date-range-picker-calendar-second"
                  monthFormat="long"
                  calenderType="Range"
                  onChange={this.onRangeCalendarSelect}
                  onVisibleMonthChange={this.onSecondVisibleMonthChange}
                  renderingDate={secondRenderingDate}
                  selectedDate={startDate}
                  {...passThroughProps}
                  endDate={endDate}
                  reset={this.state.isOpen}
                  max={DateHelper.getDate(this.max)}
                  min={DateHelper.getDate(this.min)} />
              </div>
              <div className="vdl-date-range-picker-footer">
                <div className="vdl-date-range-picker-footer-left">
                  <Button
                    buttonStyle="link"
                    className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
                    disabled={disabledOrReadonly}
                    aria-disabled={disabledOrReadonly}
                    onClick={this.clear}
                  >
                    {FormatHelper.formatMessage('@@Clear')}
                  </Button>
                </div>
                <div>
                  <Button
                    buttonStyle="link"
                    className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
                    disabled={disabledOrReadonly}
                    aria-disabled={disabledOrReadonly}
                    onClick={this.done}
                  >
                    {FormatHelper.formatMessage('@@Done')}
                  </Button>
                </div>
              </div>
            </div>
          </MDFBackdrop>}
      </div>
    );
  }

}
