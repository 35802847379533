import React from 'react';
import { MDFWizardHeader } from './MDFWizardHeader';
import { MDFWizardContainer } from './MDFWizardContainer';

export interface IMDFWizard {
  // A CSS class name for overriding styles
  className?: string;
  // Accepts children of type MDFWizardHeader/MDFWizardContainer
  children?: React.ReactNode;
}

export const MDFWizard = ((props: IMDFWizard) => {
  let header = null;
  let container = null;

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === MDFWizardHeader) {
        header = child;
      }
      else if (child.type === MDFWizardContainer) {
        container = child;
      }
    }
    else {
      return null;
    }
  });

  return (
    <div className={`mdf-wizard-component ${props.className ?? ''}`}>
      {header}
      {container}
    </div>
  );
});
