import React, { useContext } from 'react';
import classNames from 'classnames';
import { MDFIcon } from '../MDFIcon';
import { SmallCardContext } from '../MDFContext';
import { ComponentManager, generateId } from '@adp-wfn/mdf-core';

export interface ICardTitleProps {
  // The value used for the data-adp-field-id attribute required by the Field Grabber.
  adpFieldId?: string;
  // Assign className to the Title portion of Header
  className?: string;
  // The HTML tag to use for the header text. "h2" is the default, but it can be overridden in cases where it doesn't semantically make sense based on the page structure.
  headerTag?: string;
  // id for SmallCardTitle
  id?: string;
  // SubTitle String
  subTitle?: string;
  // Title that can be an html string to render title for the header
  title?: any;
  // Title icon to be rendered to the left of the title
  titleIconClass?: string;
  // To set the font size of the title for Title/subtitle"
  titleSize?: 'small' | 'medium' | 'large';
  // To set font weight for Title/subtitle
  weight?: 'semibold' | 'light';
  // option to wrap Title and SubTitle
  wrapTitle?: boolean;
}

export function SmallCardTitle({ adpFieldId, className, id, headerTag = 'h2', subTitle, title, titleIconClass, titleSize = 'large', weight = 'semibold', wrapTitle }: ICardTitleProps) {
  const HeaderTag = ComponentManager.getComponent(headerTag);

  const context: any = useContext(SmallCardContext);
  const selectableCard = (context && context.selectable);

  // Title case style will not be supported when headerTag property is being passed.
  const titleClasses = classNames({
    'small-card-title--titleSubTitle': true,
    'small-card-title--selectable': selectableCard,
    'small-card-title--titleSubTitle--wrapTitle': wrapTitle,
    [`small-card-title--${weight}`]: !selectableCard && (weight && true),
    [`small-card-title--${titleSize}`]: (headerTag !== 'h2' ? false : titleSize && true)
  });

  let cardId = id;

  if (!cardId) {
    cardId = generateId('mdfSmallCardTitle');
  }

  const headerProps = {
    className: `${classNames(`${titleClasses} `, className)} no-margin`,
    title
  };

  if (adpFieldId) {
    headerProps['data-adp-field-id'] = adpFieldId;
  }

  return (
    <React.Fragment>
      <div
        id={cardId}
        className={`small-card-title ${subTitle ? 'small-card-title--alignItem' : ''}`}
      >
        {(!selectableCard) && titleIconClass && <MDFIcon className={`${titleIconClass} small-card-title--icon`}></MDFIcon>}
        <div className="header">
          <HeaderTag {...headerProps}>{title}
            {title && subTitle && <span className={'small-card-subTitle'}>{subTitle}</span>}
          </HeaderTag>
        </div>
      </div>
    </React.Fragment>
  );
}
