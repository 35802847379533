import { MDFCircleIcon } from './MDFCirlceIcon';
import PropTypes from 'prop-types';
import { SdfIcon } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';
import React from 'react';

export interface IStatusIcon {
  // Choose one of the statusType.
  statusType?: 'success' | 'info' | 'warning' | 'error' | 'delegate' | 'notStarted' | 'inProgress' | 'submitted' | 'inReview' | 'approved' | 'accepted' | 'completed' | 'inComplete' | 'archived' | 'generalLiability' | 'default';
  // Size of the statusIcon
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  // ClassName to be applied for the icon
  className: string;
}

// should we take this statusType or leave it to the application developer to render it as a component?
const renderStatusIcon = (statusType) => {
  let iconDetails: { icon: string } = null;

  switch (statusType) {
    case 'warning':
    case 'inComplete':
      iconDetails = {
        icon: 'fa-exclamation'
      };
      break;

    case 'notStarted':
    case 'inProgress':
      iconDetails = {
        icon: 'fa-clock-o'
      };
      break;

    case 'submitted':
      iconDetails = {
        icon: 'fa-paper-plane'
      };
      break;

    case 'inReview':
      iconDetails = {
        icon: 'icon-clocked-in'
      };
      break;

    case 'error':
      iconDetails = {
        icon: 'fa-exclamation'
      };
      break;

    case 'archived':
      iconDetails = {
        icon: 'fa-archive'
      };
      break;

    case 'success':
    case 'approved':
    case 'accepted':
    case 'completed':
      iconDetails = {
        icon: 'fa-check'
      };
      break;

    case 'info':
      iconDetails = {
        icon: 'fa-info'
      };
      break;

    case 'delegate':
      iconDetails = {
        icon: 'fa-share'
      };
      break;

    case 'generalLiability':
      iconDetails = {
        icon: 'general-liability'
      };
      break;

    case 'default':
      iconDetails = {
        icon: ''
      };
      break;
  }

  return iconDetails;
};

export const StatusIcon = (props: IStatusIcon) => {
  const statusIcon = renderStatusIcon(props.statusType)?.icon;
  const faStatusIcon = `fa ${statusIcon}`;

  // TODO: Once the UX confirms the selectable card can follow the vdl standard for the colorcode need to add this - `vdl-alert--${props.statusType ?? ''
  const renderIcon = (
    <MDFCircleIcon size={props.size} className={`${props.className ?? ''} mdf-status--${props.statusType ?? ''}`}>
      {statusIcon && (props?.statusType === 'generalLiability' ? (<SdfIcon icon="general-liability" />) : (<MDFIcon className={props.statusType === 'inReview' ? statusIcon : faStatusIcon}></MDFIcon>))}
    </MDFCircleIcon>
  );

  return props.statusType ? renderIcon : null;
};

StatusIcon.propTypes = {
  statusType: PropTypes.oneOf(['success', 'info', 'warning', 'error', 'delegate', 'notStarted', 'inProgress', 'submitted', 'inReview', 'approved', 'accepted', 'completed', 'inComplete', 'archived', 'generalLiability', 'default']),
  className: PropTypes.string,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg'])
};

StatusIcon.defaultProps = {
  statusType: 'default'
};
