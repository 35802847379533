import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export interface IWFNXSmallCardHeaderProps {
  className?: string;
}

export class WFNXSmallCardHeader extends React.Component<React.PropsWithChildren<IWFNXSmallCardHeaderProps>, any> {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    return (
      <div className={classNames('wfnx-small-card-header', className)}>
        {this.props.children}
      </div>
    );
  }
}
