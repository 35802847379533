import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export interface IWFNXListCardProps {
  className?: string;
}

export class WFNXListCard extends React.Component<React.PropsWithChildren<IWFNXListCardProps>, any> {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };

  render() {
    const { className, children } = this.props;
    const classes = [
      'wfnx-list-card'
    ];

    return (
      <div className={classNames(className, classes)}>
        {children}
      </div>
    );
  }
}
