import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';

export class NumberComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    if (!this.props.hidden) {
      const { style, format, children, ...rest } = this.props;
      const formattedValue = FormatHelper.formatNumber(children, format);

      return <span {...rest} >{formattedValue}</span>;
    }
    else {
      return null;
    }
  }
}
