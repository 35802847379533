import React from 'react';

export const RuleCardContext: React.Context<any> = React.createContext({});
export const RuleRowContext: React.Context<any> = React.createContext({});
export const CustomWizardContext: React.Context<any> = React.createContext({});
export const GridContext: React.Context<any> = React.createContext({});
export const ValidationContainerContext: React.Context<any> = React.createContext({});
export const EditorContext: React.Context<any> = React.createContext({});
export const SmallCardContext: React.Context<any> = React.createContext({});
export const LayoutBoxContext: React.Context<any> = React.createContext({});
