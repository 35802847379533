import React, { useRef, useState } from 'react';
import { WFNMockShellMMNavLevel1Item } from './WFNMockShellMMNavLevel1Item';
import { IWFNMockShellMMLevel1Item, IWFNMockShellRoot } from '../types';
import { IWFNMockShellActivePopover, IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellDisabledOverlay } from '../WFNMockShellDisabledOverlay';
import { WFNMockShellMMSearchbar } from './WFNMockShellMMSearchbar';

export interface IWFNMockShellMegaMenuProps {
  selectedTopItem: string;
  setSelectedTopItem: any;
  shellData: IWFNMockShellRoot;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  activePopover: IWFNMockShellActivePopover;
  megaMenuActive: boolean;
  editOptionsLabel: string;
}

export const WFNMockShellMegaMenu = ({ shellData, onWFNShellChange, selectedTopItem, setSelectedTopItem, activePopover, megaMenuActive, editOptionsLabel }: IWFNMockShellMegaMenuProps) => {
  const isLegacyShell = (window['isADPUnified'] === undefined);
  // Expected behaviour in the new UnifiedShell is to always be expanded, and on the legacy shell the initial value is collapsed
  const [expanded, setExpanded] = useState(!isLegacyShell);

  // hide mega menu when clicking outside it
  const containerRef = useRef();

  // keep track of larger items for expanded menu styling
  const additionalSubnavMarginMap = new Map<number, number>();

  shellData.menu.resources.forEach((item, i) => {
    const curItemMargin = ((item.name.length >= 9) && item.renderCheckbox && !shellData.menu.disabled) ? 30 : 0;
    const reportsAndAnalyticsMenuItemMargin = ((item.name.length >= 19) && item.renderCheckbox && !shellData.menu.disabled) ? 250 : 0;
    const reportsAndAnalyticsPeopleMenuItemMargin = ((item.name.length >= 19) && item.renderCheckbox && !shellData.menu.disabled && (i === 6) ? 140 : 0);
    const reportsAndAnalyticsManagerMenuItemMargin = ((item.name.length >= 19) && item.renderCheckbox && !shellData.menu.disabled && (i === 3) ? -70 : 0);
    let totalMarginToAdd = curItemMargin + reportsAndAnalyticsMenuItemMargin + reportsAndAnalyticsPeopleMenuItemMargin + reportsAndAnalyticsManagerMenuItemMargin;

    if (i === 0) {
      additionalSubnavMarginMap.set(i, curItemMargin);
    }

    // decrease margin for each large item before it
    for (let j = 0; j < i; j++) {
      const innerReportsAndAnalyticsMenuItemMargin = ((shellData.menu.resources[j].name.length >= 19) && item.renderCheckbox && !shellData.menu.disabled) ? 40 : 0;
      totalMarginToAdd -= innerReportsAndAnalyticsMenuItemMargin + (((shellData.menu.resources[j].name.length >= 9) && item.renderCheckbox && !shellData.menu.disabled) ? 30 : 0);
    }

    additionalSubnavMarginMap.set(i, totalMarginToAdd);
  });

  function renderLevel1Item(level1Item: IWFNMockShellMMLevel1Item, i) {
    return <WFNMockShellMMNavLevel1Item
      key={level1Item.oid}
      handleTopItemClick={setSelectedTopItem.bind(this, !shellData.menu.disabled ? level1Item.oid : null)}
      active={selectedTopItem === level1Item.oid}
      level1ItemIndex={i}
      expanded={expanded}
      hasSubItems={!!level1Item.childList?.length}
      setExpanded={setExpanded}
      onWFNShellChange={onWFNShellChange}
      menuDisabled={shellData.menu.disabled}
      additionalSubnavMargin={additionalSubnavMarginMap.get(i)}
      editOptionsLabel={editOptionsLabel}
      {...level1Item}
    />;
  }

  return (
    <div ref={containerRef} className="wfnmockshell-mega-menu">
      <WFNMockShellDisabledOverlay disabled={shellData.menu.disabled} />
      <nav className="wfnmockshell">
        <div className="wfnmockshell-top">
          {shellData.menu.resources.map(renderLevel1Item)}
        </div>
        <WFNMockShellMMSearchbar megaMenuActive={megaMenuActive} activePopover={activePopover} onWFNShellChange={onWFNShellChange} shellData={shellData} />
      </nav>
    </div>
  );
};

WFNMockShellMegaMenu.displayName = 'WFNMockShellMegaMenu';
