import React from 'react';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';
import { SdfIcon } from '@waypoint/react-components';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

export interface IMDFIcon {
  /**
   * The FontAwesome or ADP icon font CSS class for the icon.
   */
  className?: string;
  /**
   * The OneUX icon name. This property takes precedence over className. FontAwesome and ADP icon font CSS classNames will be deleted if this property is set.
   */
  icon?: IconName;
  /**
   * The DOM id for the icon's DOM node.
   */
  id?: string;
  /**
   * Role property added for accessibility
   */
  role?: string;
  /**
   * altText added for accessibility
   */
  altText?: string;
  /**
   * ariaLabel added for accessibility
   */
  ariaLabel?: string;
  'aria-label'?: string;
  /**
   * ariaHidden added to hide decorative icons.
   */
  ariaHidden?: boolean;
}

export function MDFIcon(props: IMDFIcon) {
  const ariaLabel = resolveAriaProperty('MDFIcon', 'aria-label', 'ariaLabel', props);
  const ariaHidden = !(ariaLabel || props.role);

  if (props.icon) {
    // If the icon property is specified, render a OneUX icon.
    // Try to extract non-icon CSS classes from the className property
    const layoutClasses = props.className?.split(' ')
      .filter((name) => !(name === 'fa' || name.includes('fa-') || name.includes('icon-')))
      .join(' ');

    const iconProps = {
      className: layoutClasses,
      icon: props.icon,
      iconTitle: ariaLabel,
      id: props.id,
      role: props.role
    };

    Object.keys(iconProps).forEach((key) => {
      if (iconProps[key] === undefined || iconProps[key] === null) {
        delete iconProps[key];
      }
    });

    return <SdfIcon {...iconProps}/>;
  }
  else {
    const iconProps = {
      'aria-hidden': props.ariaHidden || ariaHidden,
      'aria-label': ariaLabel,
      className: props.className,
      id: props.id,
      role: props.role
    };

    Object.keys(iconProps).forEach((key) => {
      if (iconProps[key] === undefined || iconProps[key] === null || iconProps[key] === false) {
        delete iconProps[key];
      }
    });

    return (
      <span {...iconProps}>
        {props.altText && <span className="mdf-sr-only">{props.altText || props.className}</span>}
      </span>
    );
  }
}
