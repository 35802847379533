import React from 'react';
import { COUNTRY_TO_CURRENCY_CODE, FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';

export const CurrencyComponent = (props: any) => {
  if (!props.hidden) {
    const { style, currencyCode, children, decimal, ...rest } = props;
    const currencyType = currencyCode ? currencyCode : COUNTRY_TO_CURRENCY_CODE[LocaleHelper.getCountryCode()];

    // When decimal property is null or undefined default the decimal to 2
    const formattedValue = FormatHelper.formatNumber(children, {
      style: 'currency',
      currency: currencyType,
      minimumFractionDigits: (decimal || decimal === 0) ? decimal : 2,
      maximumFractionDigits: (decimal || decimal === 0) ? decimal : 2,
      ...rest
    });

    return <span {...rest} >{formattedValue}</span>;
  }
  else {
    return null;
  }
};

CurrencyComponent.displayName = 'Currency';
