import React from 'react';
import ReactDOM from 'react-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import { cloneDeep, isEqual } from 'lodash';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { getParentContainer } from '../util/DOMHelper';

const DEFAULT_LIST_HEIGHT = 400;

export interface IInfiniteListProps {
  pageSize: number;
  useWindowsScroll?: boolean;
  fetchItems: (startIndex: number, count: number, resolve: (items: any[], updatedItemCount: number) => void) => void;
  contentModel?: (index: number, item: any) => any;
  renderItem: (index: any, item: any, viewColumn: any, viewRows: any, lockedColumns: number, lockedGrid: boolean, setRowHeights: any, handleHover: any, selectRow: (rowIndex: number) => void, handleRowClick: (rowIndex: number) => void) => React.ReactNode;
  handleRowClick?: (rowIndex: number) => void;
  selectRow?: () => void;
  handleHover?: (rowIndex: number, isHovering: boolean) => void;
  height?: any;
  className?: any;
  columns?: object[];
  rows?: any;
  lockedColumns?: number; // number of locked columns on the left side of the grid
  lockedGrid?: boolean; // whether or no this is the locked portion of the grid
  setRowHeights?: () => {};
  resetRows?: boolean;
  items?: object[];
  noDataMessage?: string;
  rowCount?: number;
  tabIndex?: number;

  // Set to true to handle rows with different dimensions. Component re-calculate the dimensions of affected row on user action(For example.: Add/Update/Delete).
  autoSizeRows?: boolean;

  // Application should send an user action of boolean value which affects all the rows. For example in case of Expand All - true & Collapse All - false.
  expandAllRows?: boolean;

  // Set to true to move infiniteList scroll position to top
  scrollToTop?: boolean;

  // Called when the infiniteList scrolls to top. Signals the application to reset 'scrollToTop' property to false.
  onScrollToTop?: () => void;
}

interface IInfiniteListState {
  pageSize: number;
  rowCount: number;
  itemsFetched: any;
  scrollElement: any;
  scrollToTop: boolean;
}

export class InfiniteList extends React.Component<IInfiniteListProps, IInfiniteListState> {
  private pagesFetched: any = {};
  private infiniteLoader: InfiniteLoader;
  private list: List;
  private childScrollFnRef;
  private pageLoadTimeoutID: any;
  private renderedRowsInfo;

  generateCellKey = (rowIdx, columnIdx) => {
    let cellkey = rowIdx + '_' + columnIdx;

    // To support Add/Delete row, applications should send unique "key" for each row to map exact cell measurements of that row in
    // cellMeasurementCache.
    if (this.props.autoSizeRows && this.state.itemsFetched && this.state.itemsFetched[rowIdx]?.key) {
      cellkey = this.state.itemsFetched[rowIdx]?.key;
    }

    return cellkey;
  };

  private cellMeasurementCache = new CellMeasurerCache({
    defaultHeight: 20,
    fixedWidth: true,
    keyMapper: this.generateCellKey
  });

  state: IInfiniteListState = {
    pageSize: this.props.pageSize,
    rowCount: this.props.rowCount || this.props.pageSize,
    itemsFetched: this.props.items,
    scrollElement: window,
    scrollToTop: false
  };

  constructor(props: IInfiniteListProps) {
    super(props);
  }

  private setInfiniteLoader = (infiniteLoader: InfiniteLoader) => {
    this.infiniteLoader = infiniteLoader;
  };

  private setList = (list: List) => {
    this.list = list;
  };

  private setChildScroll = (childScroll) => {
    this.childScrollFnRef = childScroll;
  };

  private getPageNumber = (index): number => Math.floor(index / this.props.pageSize);

  private isRowLoaded = ({ index }): boolean => {
    return this.props.autoSizeRows ? this.state.itemsFetched[index] : this.pagesFetched[this.getPageNumber(index)];
  };

  private loadMoreRows = ({ startIndex, stopIndex }) => {
    const SCROLL_TIMEOUT_INTERVAL = 200;

    if (this.pageLoadTimeoutID) {
      clearTimeout(this.pageLoadTimeoutID);
    }

    let resolver;

    const promise = new Promise<undefined>((resolve) => {
      resolver = resolve;
    });

    this.pageLoadTimeoutID = setTimeout(() => this.loadMoreRowsCallback(startIndex, stopIndex, resolver), SCROLL_TIMEOUT_INTERVAL);

    return promise;
  };

  private loadMoreRowsCallback = (startIndex: number, stopIndex: number, resolve: (value?: PromiseLike<undefined>) => void) => {
    this.pageLoadTimeoutID = undefined;

    const startPage = this.getPageNumber(startIndex);
    const stopPage = this.getPageNumber(stopIndex);
    let startPageIndex;
    let fetchCount;

    for (let pg = startPage; pg <= stopPage; pg++) {
      this.pagesFetched[pg] = true;
    }

    if (this.props.autoSizeRows) {
      startPageIndex = startIndex;
      fetchCount = Math.abs(stopIndex - startPageIndex + 1);
    }
    else {
      startPageIndex = startPage * this.props.pageSize;
      fetchCount = (stopPage - startPage + 1) * this.props.pageSize;
    }

    this.props.fetchItems(startPageIndex, fetchCount, (items, itemCount) => {
      const stateItems = cloneDeep(this.state.itemsFetched) || {};
      const clonedItems = cloneDeep(items);

      for (let index = 0; index < clonedItems.length; index++) {
        stateItems[startPageIndex + index] = clonedItems[index];

        // In case of autosize rows if each row has unique key, cellMeasurementCache consider that unique key instead of default key while rendering.
        if (!this.props.autoSizeRows || !stateItems[startPageIndex + index].key) {
          this.cellMeasurementCache.clear(startPageIndex + index, 0);
        }
      }

      if (!isEqual(stateItems, this.state.itemsFetched)) {
        this.setState((/* state, props */) => (
          { itemsFetched: stateItems }
        ));
      }

      if (itemCount && this.state.rowCount !== itemCount) {
        this.setState({ rowCount: itemCount });
      }
      else {
        resolve();
      }
    });
  };

  private getContentModel = (index: number) => (
    this.props.contentModel
      ? this.props.contentModel(index, this.state.itemsFetched[index])
      : this.state.itemsFetched[index]
  );

  // TODO: Need to work on how to display when the items is less than the pageSize
  private rowRenderer = ({ key, index, style, parent }) => (
    <CellMeasurer key={key} parent={parent} cache={this.cellMeasurementCache} columnIndex={0} rowIndex={index} tabIndex={this.props.tabIndex}>
      {
        // TODO: Per documentation, CellMeasurer allows children that are:
        // either a React element as a child (eg <div />) or a function (eg. ({ measure }) => <div />).
        // but the available @types only allow a function child. Fix when newer @types are available.
        (/* unused: any */) => (
          <div role="row" style={style}>
            {(this.state.itemsFetched && this.state.itemsFetched[index]) ? this.props.renderItem(index, this.getContentModel(index), this.props.columns, this.props.rows, this.props.lockedColumns, this.props.lockedGrid, this.props.setRowHeights, this.props.handleHover, this.props.selectRow, this.props.handleRowClick) : '...'}
          </div>
        )
      }
    </CellMeasurer>
  );

  // This method is called when rowCount is 0
  private noRowsRenderer = () => (
    <div>
      {this.props.noDataMessage ? this.props.noDataMessage : ` ${FormatHelper.formatMessage('@@NoRowsFound')}`}
    </div>
  );

  reset() {
    this.pagesFetched = {};
    this.infiniteLoader.resetLoadMoreRowsCache();
    this.cellMeasurementCache.clearAll();
    this.list.scrollToRow(0);

    // Workaround for resetLoadMoreRowsCache(true) by resetting the rowCount so that InfiniteLoader reloads.
    let resetTotalRows = 1;

    if (this.state.rowCount === 1) {
      resetTotalRows = 2;
    }

    this.setState({ rowCount: resetTotalRows });
  }

  // Calling reset in willReceiveProps since this only looks for props changes and not the state Changes
  componentWillReceiveProps(nextProps) {
    if (nextProps.resetRows) {
      this.reset();
    }

    // Allow the "items" property to work with the the Grid component. Users can modify the items in their
    // Redux state and the changes will reflect in the Grid.
    if (nextProps.hasOwnProperty('items')) {
      // clearMeasurementCache: true will reset the measurement cache of the element to support growing/shrinking functionality.
      nextProps.items.forEach((element, index) => {
        if (element && element.clearMeasurementCache) {
          this.cellMeasurementCache.clear(index, 0);
        }
      });

      if (!isEqual(nextProps.items, this.state.itemsFetched)) {
        this.setState((/* state, props */) => (
          { itemsFetched: cloneDeep(nextProps.items) }
        ));
      }
    }
    else {
      // Allow InfiniteSearch component to continute to work using componentModel.
      this.setState((state) => {
        const itemsFetched = Object.assign({}, state.itemsFetched, nextProps.items);
        return { itemsFetched };
      });
    }

    if ((typeof nextProps.rowCount === 'number') && (nextProps.rowCount !== this.state.rowCount)) {
      this.setState({
        rowCount: nextProps.rowCount
      });
    }

    if (nextProps.scrollToTop !== this.state.scrollToTop) {
      this.setState({
        scrollToTop: nextProps.scrollToTop
      });
    }
  }

  componentWillUpdate(_nextProps) {
    this.list.forceUpdateGrid();
  }

  componentDidMount() {
    if (this.props.useWindowsScroll) {
      this.setState({
        scrollElement: getParentContainer(ReactDOM.findDOMNode(this)) || window
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.expandAllRows !== prevProps.expandAllRows) {
      this.cellMeasurementCache.clearAll();
      this.list.scrollToRow(0);
      this.list.recomputeRowHeights();
      this.infiniteLoader.resetLoadMoreRowsCache(true);
    }
    else if (this.props.autoSizeRows && prevState.itemsFetched && this.renderedRowsInfo) {
      const overscanStartIndex = this.renderedRowsInfo.overscanStartIndex;
      const overscanStopIndex = this.renderedRowsInfo.overscanStopIndex;

      for (let rowIdx = overscanStartIndex; rowIdx <= overscanStopIndex; rowIdx++) {
        if (prevState.itemsFetched[rowIdx] && this.state.itemsFetched[rowIdx] && !isEqual(prevState.itemsFetched[rowIdx], this.state.itemsFetched[rowIdx])) {
          this.cellMeasurementCache.clear(rowIdx, 0);
          this.list.recomputeRowHeights(rowIdx);
        }
      }
    }

    if (prevState.rowCount !== this.state.rowCount) {
      this.infiniteLoader.resetLoadMoreRowsCache(true);
    }

    if (this.state.scrollToTop && prevState.scrollToTop !== this.state.scrollToTop) {
      if (this.props.useWindowsScroll) {
        this.childScrollFnRef?.({ scrollTop: 0 });
      }
      else {
        this.list.scrollToRow(0);
      }

      this.props.onScrollToTop?.();
    }
  }

  render() {
    const useWindowsScroll = this.props.useWindowsScroll || false;
    const listHeight = this.props.height || DEFAULT_LIST_HEIGHT;

    return (
      <div className="mdf-grid-infinite">
        {useWindowsScroll &&
          <InfiniteLoader ref={this.setInfiniteLoader} isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadMoreRows} rowCount={this.state.rowCount} threshold={0}>
            {
              ({ onRowsRendered, registerChild }) => (
                <WindowScroller scrollElement={this.state.scrollElement}>
                  {({ height, onChildScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                      {
                        ({ width }) => (
                          <List
                            autoHeight
                            width={width}
                            height={height}
                            onRowsRendered={(info) => {
                              this.renderedRowsInfo = info;
                              onRowsRendered(info);
                            }}
                            noRowsRenderer={this.noRowsRenderer}
                            ref={(node) => {
                              registerChild(node);
                              this.setList(node);
                              this.setChildScroll(onChildScroll);
                            }}
                            rowCount={this.state.rowCount}
                            rowHeight={this.cellMeasurementCache.rowHeight}
                            rowRenderer={this.rowRenderer}
                            scrollTop={scrollTop}
                          />
                        )
                      }
                    </AutoSizer>
                  )}
                </WindowScroller>
              )
            }
          </InfiniteLoader>
        }
        {!useWindowsScroll &&
          <InfiniteLoader ref={this.setInfiniteLoader} isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadMoreRows} rowCount={this.state.rowCount} threshold={0}>
            {
              ({ onRowsRendered, registerChild }) => (
                <AutoSizer disableHeight>
                  {
                    ({ width }) => (
                      <List
                        width={width}
                        height={listHeight}
                        onRowsRendered={(info) => {
                          this.renderedRowsInfo = info;
                          onRowsRendered(info);
                        }}
                        noRowsRenderer={this.noRowsRenderer}
                        ref={(node) => {
                          registerChild(node);
                          this.setList(node);
                        }}
                        rowCount={this.state.rowCount}
                        rowHeight={this.cellMeasurementCache.rowHeight}
                        rowRenderer={this.rowRenderer}
                      />
                    )
                  }
                </AutoSizer>
              )
            }
          </InfiniteLoader>
        }
      </div>
    );
  }
}
