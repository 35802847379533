import React from 'react';
import PropTypes from 'prop-types';
import { FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
import { Avatar } from '@synerg/vdl-react-components';
import { DateHelper } from './DateHelper';
import classNames from 'classnames';

export interface INewsFeedItemProps {
  // The url for the Avatar photo
  url?: any;
  // TimeStamp
  time?: any;
  // Text to display on each sub item
  text?: React.ReactNode;
  // Class name to style each sub item
  className?: string;
  // The initials for the Avatar if a photo is not used
  initials?: React.ReactNode;
  // Event that triggers on sub item click to send the item object to the application
  onClick?: (value: any) => void;
  // Pass an id for each item.
  itemId?: any;
  // show relativeTime
  hasDateTime?: boolean;
}

export class NewsFeedItem extends React.Component<INewsFeedItemProps, {}> {
  static propTypes = {
    url: PropTypes.any,
    time: PropTypes.any,
    text: PropTypes.node,
    initials: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    itemId: PropTypes.any,
    hasDateTime: PropTypes.bool
  };

  addZero = (minutes) => {
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return minutes;
  };

  handleClick = () => {
    const { onClick, ...items } = this.props;

    if (this.props.onClick) {
      this.props.onClick({ ...items });
    }
  };

  renderTime = (time?: string) => {
    let parsedTime: Date = new Date(time);

    if (isNaN(parsedTime.getTime())) {
      parsedTime = null;
    }

    let timeString = parsedTime && parsedTime.toString();

    if (timeString) {
      const hours = parsedTime.getHours();
      const minutes = this.addZero(parsedTime.getMinutes());

      if (hours >= 12) {
        timeString = (hours === 12) ? ('12:' + minutes + ' PM') : ((hours - 12) + ':' + minutes + ' PM');
      }
      else {
        timeString = (hours === 0) ? ('12:' + minutes + ' AM') : (hours + ':' + minutes + ' AM');
      }
    }

    return timeString;
  };

  renderDateTime = () => {
    const time = this.props.time;
    const moment = LocaleHelper.dateAndTime;
    const isToday = moment(time) && moment(time).isSame(new Date(), 'd');
    const parsedDate = !isToday && DateHelper.getDate(time);
    const formattedDate = !isToday && FormatHelper.formatDate(parsedDate, 'short');

    // check if the time is a valid Date
    // If date is today's date just return the time
    // Else return the Formatted date.
    const timeString = isToday ? moment(time).format('LT') : formattedDate;
    return timeString || '';
  };

  render() {
    const { url, time, className, hasDateTime, initials, text } = this.props;
    const newFeedItemClass = 'newsFeedItem';

    return (
      <div className={classNames(className, newFeedItemClass)}>
        {(url || initials) &&
          <div className={'newsFeedItem--avatar'}>
            <Avatar initials={initials} size={'sm'} url={url} />
          </div>
        }
        <div>
          <div className="newsFeedItem--time">{hasDateTime ? this.renderDateTime() : this.renderTime(time)}</div>
          <div className="newsFeedItem--text" onClick={this.handleClick}>{text}</div>
        </div>
      </div >
    );
  }
}
