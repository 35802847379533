export class TableUtils {
  static getScrollbarThickness() {
    const body = document.getElementsByTagName('body')[0];

    if (body) {
      const parent = document.createElement('div');
      parent.style.width = '50px';
      parent.style.height = '50px';
      parent.style.overflow = 'hidden';
      parent.style.position = 'absolute';
      parent.style.top = '-200px';
      parent.style.left = '-200px';

      const child = document.createElement('div');
      child.style.height = '100px';

      parent.appendChild(child);
      body.appendChild(parent);

      const widthOne = parent.clientWidth;
      parent.style.overflowY = 'scroll';
      const widthTwo = parent.clientWidth;

      body.removeChild(parent);

      return widthOne - widthTwo;
    }
    else {
      return 17; // Default size for Windows since most clients use Windows.
    }
  }
}
