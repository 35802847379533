import React, { useState } from 'react';
import classNames from 'classnames';
import { DropdownButton } from '@synerg/vdl-react-components';
import { SmallCard } from '../SmallCard/SmallCard';
import { SmallCardContent, SmallCardMiddle } from '../SmallCard/SmallCardMiddle';
import { MDFButton2 } from '../MDFButton2';
import { IRuleCard, RuleCard, RuleCardAlert, RuleCardBottom, RuleCardFooter, RuleCardHeader, RuleCardRows } from './RuleCard';
import { ILabelValuePair, IRuleColumn } from './RuleCell';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { RuleCardContext } from '../MDFContext';
import { RulesetActions } from './RuleSet';
import { cloneDeep } from 'lodash';
import { SdfFocusPane, SdfIcon } from '@waypoint/react-components';

export interface IRuleGroup {
  data: (IRuleCard | IRuleGroup)[];
  setJoinOperator: string;
  groupIndex?: number;
  isValid: boolean;
  validationMessage?: string;
}

export interface IRuleGroupProps extends IRuleGroup {
  // A CSS classname to override the styles of this component.
  className?: string;

  // The title text for this component.
  title?: string;

  // To enable/disable the component
  disabled?: boolean;

  // Number of max allowed rows per rule card.
  maxAllowedRows?: number;

  // The items to display in the field Names dropdown.
  fieldNames?: IRuleColumn[];

  // The items to display in the join dropdown.
  joinOperatorOptions?: ILabelValuePair<string>[];

  // options to display or hide join operator on the header
  showHeaderJoinOperator?: boolean;

  // to render join operator dropdownlist on the card
  renderJoinOperator: boolean;

  canMoveUp: boolean;

  canMoveDown: boolean;

  // to disable the copy button on the card
  canCopy: boolean;

  // to disable delete button on the card
  isSingleSet: boolean;

  // Called when component changes any data to update the state of rule set component.
  onChange?: (cardIndex: number, card: IRuleCard, changeType: string) => void;

  // passed down event handler to child component (Async function call to load dynamic data for the value field (MDFSelectBox))
  onFetchValueOptions: (fieldName: any, inputValue: string, prevOptions: any) => Promise<any>;
}

const handleSetJoinOperatorChange = (e, props) => {
  const setJoinOperator = e ? e.value : undefined;

  if (props.onChange) {
    props.onChange(props.groupIndex, { setJoinOperator: setJoinOperator }, RulesetActions.BETWEEN_SET_JOIN_OPERATOR_FROM_GROUP);
  }
};

const onAddRuleSet = (e: any, props) => {
  if (props.onChange) {
    if (e.value === 'ADD_NEW_SET') {
      props.onChange(props.groupIndex,
        {
          ruleRows: [
            {
              field: undefined,
              operator: undefined,
              values: [
                {
                  value: undefined,
                  label: undefined
                }
              ]
            }
          ],
          rowJoinOperator: 'and',
          setJoinOperator: 'and',
          isValid: false,
          validationMessage: ''
        },
        RulesetActions.ADD_NEW_SET_FROM_GROUP);
    }
    else {
      props.onChange(props.groupIndex,
        {
          data: [
            {
              ruleRows: [
                {
                  field: undefined,
                  operator: undefined,
                  values: [
                    {
                      value: undefined,
                      label: undefined
                    }
                  ]
                }
              ],
              rowJoinOperator: 'and',
              setJoinOperator: 'and',
              isValid: false,
              validationMessage: ''
            }
          ],
          setJoinOperator: 'and',
          isValid: false, validationMessage: ''
        },
        RulesetActions.ADD_NEW_GROUP_FROM_GROUP);
    }
  }
};

const onMove = (props, changeType) => {
  if (props.onChange) {
    props.onChange(props.groupIndex, null, changeType);
  }
};

const onCopyCard = (props) => {
  const card = {
    data: cloneDeep(props.data),
    setJoinOperator: props.setJoinOperator,
    isValid: false,
    validationMessage: ''
  };

  if (props.onChange) {
    props.onChange(props.groupIndex, card, RulesetActions.COPY_SET_FROM_GROUP);
  }
};

const RuleGroupTitleHeader = (props: IRuleGroupProps) => {
  const [showModal, setShowModal] = useState(false);
  const title = props.title ?? `${FormatHelper.formatMessage('@@Group')} ${(props.groupIndex ?? 1)}`;

  const deleteRuleGroup = () => {
    let card = null;

    if (props.isSingleSet) {
      card = {
        data: [{ ruleRows: [{ field: undefined, operator: undefined, values: [{ value: undefined, label: undefined }] }], rowJoinOperator: 'and', setJoinOperator: 'and', isValid: false, validationMessage: '' }],
        setJoinOperator: 'or',
        isValid: false, validationMessage: ''
      };
    }

    if (props.onChange) {
      props.onChange(props.groupIndex, card, 'DELETE_SET_FROM_GROUP');
    }

    resetModal();
  };

  const onDeleteRuleGroup = () => {
    setShowModal(true);
  };

  const resetModal = () => {
    setShowModal(false);
  };

  return (
    <div className="rule-group-titleheader">
      <div className="rule-group-titleheader-row"><h2 className="rule-group-titleheader-row-medium">{title}</h2></div>
      <div className="rule-group-titleheader-row-end">
        {props.canMoveUp &&
          <>
            <MDFButton2
              className="rule-group-button"
              buttonStyle="link"
              iconClass="nav-move-up"
              disabled={props.disabled}
              onClick={() => {
                onMove(props, RulesetActions.MOVE_UP_FROM_GROUP);
              }}
            >
              {FormatHelper.formatMessage('@@MoveUp')}
            </MDFButton2>
            <div className="rule-titleheader-row-divider" />
          </>
        }
        {props.canMoveDown &&
          <>
            <MDFButton2
              className="rule-group-button"
              buttonStyle="link"
              disabled={props.disabled}
              iconClass="nav-move-down"
              onClick={() => {
                onMove(props, RulesetActions.MOVE_DOWN_FROM_GROUP);
              }}
            >
              {FormatHelper.formatMessage('@@MoveDown')}
            </MDFButton2>
            <div className="rule-titleheader-row-divider" />
          </>
        }
        <MDFButton2
          className="rule-group-button"
          disabled={!props.canCopy || !!props.disabled}
          buttonStyle="link"
          iconClass="action-copy"
          onClick={() => {
            onCopyCard(props);
          }}
        >
          {FormatHelper.formatMessage('@@Copy')}
        </MDFButton2>
        <div className="rule-titleheader-row-divider" />
        <MDFButton2
          className="rule-group-button"
          disabled={props.disabled}
          buttonStyle="link"
          iconClass="action-delete"
          onClick={onDeleteRuleGroup}
        >
          {FormatHelper.formatMessage('@@Delete')}
        </MDFButton2>
        {showModal &&
          <SdfFocusPane
            pane-type="floating"
            accept-label={FormatHelper.formatMessage('@@Yes')}
            dismiss-label={FormatHelper.formatMessage('@@No')}
            heading={FormatHelper.formatMessage('@@Delete_Rule_Group_Modal_Header')}
            spacing="normal"
            closeable={true}
            visible={showModal}
            onSdfAccept={deleteRuleGroup}
            onSdfDismiss={resetModal}>
            {FormatHelper.formatMessage('@@Delete_Rule_Group_Modal_Body')}
          </SdfFocusPane>
        }
      </div>
    </div>
  );
};

export const RuleGroup = (props: IRuleGroupProps) => {
  const addSetOptions = [
    {
      label: FormatHelper.formatMessage('@@add_new_set'),
      value: 'ADD_NEW_SET'
    },
    {
      label: FormatHelper.formatMessage('@@add_new_group'),
      value: 'ADD_NEW_GROUP'
    }
  ];

  return (
    <React.Fragment>
      <RuleCardContext.Provider value={{
        ruleRows: [],
        onChange: props.onChange,
        onFetchValueOptions: props.onFetchValueOptions
      }}>
        <div className="rule-group">
          <SmallCard cardStyle={'shadow'} className={classNames(props.className)}>
            <SmallCardMiddle>
              <SmallCardContent>
                <div id={`rule-group-container-${props.groupIndex}`} className="rule-container">
                  <RuleGroupTitleHeader {...props} />
                  <div id={`rule-group-container-${props.groupIndex}`} className="rule-group-container">
                    <React.Fragment>
                      {
                        props.data.map((cardOrGroup, idx) => {
                          if (cardOrGroup.hasOwnProperty('data')) {
                            const item: IRuleGroup = cardOrGroup as IRuleGroup;

                            return (
                              <RuleGroup
                                key={idx}
                                groupIndex={item.groupIndex}
                                canMoveUp={idx !== 0}
                                data={item.data}
                                disabled={props.disabled}
                                isValid={item.isValid}
                                validationMessage={item.validationMessage}
                                fieldNames={props.fieldNames}
                                joinOperatorOptions={props.joinOperatorOptions}
                                showHeaderJoinOperator={props.showHeaderJoinOperator}
                                renderJoinOperator={idx !== props.data?.length - 1}
                                isSingleSet={props.data?.length <= 1}
                                setJoinOperator={item.setJoinOperator}
                                onChange={props.onChange}
                                onFetchValueOptions={props.onFetchValueOptions}
                              />
                            );
                          }
                          else {
                            const item: IRuleCard = cardOrGroup as IRuleCard;

                            return (
                              <RuleCard
                                key={idx}
                                cardIndex={item.cardIndex}
                                canMoveUp={idx !== 0}
                                disabled={props.disabled}
                                maxAllowedRows={props.maxAllowedRows}
                                ruleRows={item.ruleRows || [{ field: null, operator: null, values: [{ value: null, label: null }] }]}
                                rowJoinOperator={item.rowJoinOperator}
                                isValid={item.isValid}
                                validationMessage={item.validationMessage}
                                fieldNames={props.fieldNames}
                                joinOperatorOptions={props.joinOperatorOptions}
                                showHeaderJoinOperator={props.showHeaderJoinOperator}
                                setJoinOperator={item.setJoinOperator}
                                showAlert={item.showAlert}
                                alertTitle={item.alertTitle}
                                alertContent={item.alertContent}
                                alertType={item.alertType}
                                onChange={props.onChange}
                                onFetchValueOptions={props.onFetchValueOptions}
                              >
                                <RuleCardHeader canMoveUp={idx !== 0} isSingleSet={props.data?.length <= 1} />
                                <RuleCardAlert showAlert={item.showAlert} alertTitle={item.alertTitle} alertContent={item.alertContent} alertType={item.alertType} />
                                <RuleCardRows showJoinOperator={true} />
                                <RuleCardFooter />
                                <RuleCardBottom canAddNewGroup={true} renderJoinOperator={idx !== props.data?.length - 1} />
                              </RuleCard>
                            );
                          }
                        })
                      }
                    </React.Fragment>
                  </div>
                </div>
              </SmallCardContent>
            </SmallCardMiddle>
          </SmallCard>
        </div>
        <div className="rule-operator">
          {props.renderJoinOperator &&
            <DropdownButton
              buttonStyle="link"
              className="rule-operator-button"
              disabled={props.disabled}
              data={props.joinOperatorOptions}
              valueField="value"
              textField="valueLabel"
              onSelect={(e) => {
                handleSetJoinOperatorChange(e, props);
              }}
            >
              {props.joinOperatorOptions?.find((item: ILabelValuePair<string>) => item.value === props.setJoinOperator)?.valueLabel || ''}
            </DropdownButton>
          }
          <div className="rule-seperator">
            <DropdownButton
              buttonStyle="secondary"
              className="rule-seperator-popup"
              disabled={props.disabled}
              data={addSetOptions}
              valueField="value"
              textField="label"
              onSelect={(e) => {
                onAddRuleSet(e, props);
              }}
            >
              <SdfIcon icon="plus" /> {FormatHelper.formatMessage('@@Add')}
            </DropdownButton>
          </div>
        </div>
      </RuleCardContext.Provider>
    </React.Fragment>
  );
};

RuleGroup.defaultProps = {
  canMoveDown: true,
  canMoveUp: true,
  canCopy: true
};

RuleGroup.displayName = 'RuleGroup';
