import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import vdlPrefix from '../util/vdlPrefix';
import { omit } from 'lodash';
import { ITreeItem, MDFTreeItem } from './MDFTreeItem';
import { ILeftNavItem } from '@synerg/vdl-react-components';

export { ITreeItem, ILeftNavItem };

const NavItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  viewName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      viewName: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          viewName: PropTypes.string
        })
      ),
      expanded: PropTypes.bool
    })
  ),
  expanded: PropTypes.bool
});

export type ITreeVariant = 'flat' | 'hierarchy' | 'tree' | 'accordion';

export interface ITreeProps {
  // Left navigation variant
  variant?: ITreeVariant;

  // An array of left nav item data objects
  data: ITreeItem[];

  // view name
  viewItem?: string;

  // Selected data item for the entire left nav
  selectedItem?: ITreeItem;

  // Callback fired when a nav item is selected
  onSelect?: (item: ITreeItem) => void;

  // Callback fired when a nav item is clicked
  onClick?: (item: ITreeItem) => void;

  // Callback fired when a nav item is expanded (expanded = true) or collapsed (expanded = false)
  onExpand: (item: ITreeItem, expanded: boolean) => void;

  // Root element custom CSS class
  className?: string;
  isTree?: boolean;
}

export class MDFTree extends React.Component<ITreeProps, {}> {
  static propTypes = {
    variant: PropTypes.oneOf(['flat', 'hierarchy', 'tree', 'accordion']),
    data: PropTypes.arrayOf(NavItemPropType).isRequired,
    selectedItem: NavItemPropType,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    viewItem: PropTypes.string,
    className: PropTypes.string,
    isTree: PropTypes.bool
  };

  static defaultProps: ITreeProps = {
    variant: 'tree',
    isTree: true,
    data: [],
    onExpand: (_i, _e) => undefined
  };

  handleSelect = (item) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(item);
    }
  };

  getAccordionId = (item) => {
    if (item.items) {
      return item.label.replace(/\s/g, '');
    }
  };

  render() {
    const { className, variant, data, selectedItem } = this.props;
    const customProps: any = omit(this.props, Object.keys(MDFTree.propTypes));

    const rootClasses = {
      [vdlPrefix('left-nav')]: true,
      [vdlPrefix(`left-nav--${variant}`)]: true
    };

    return (
      <div
        {...customProps}
        className={classNames(className, rootClasses)}
      >
        {
          data.map((item, index) => (
            <MDFTreeItem
              key={index}
              item={item}
              onClick={this.props.onClick}
              onExpand={this.props.onExpand}
              onSelect={this.handleSelect}
              selectedItem={selectedItem}
              viewItem={this.props.viewItem}
              isTree={this.props.isTree}
              accordionId={this.getAccordionId(item)}
            >
            </MDFTreeItem>
          ))
        }
      </div>
    );
  }
}
