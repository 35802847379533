import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FeatureFlags } from '@adp-wfn/mdf-core';
import { SdfBox } from '@waypoint/react-components';
import { BoxSpacing, BoxVariant } from '@waypoint/web-components/types/utils/box-builder';
import { SmallCardMiddle } from './SmallCardMiddle';
import { SmallCardBottom } from './SmallCardBottom';
import { SmallCardTop } from './SmallCardTop';
import { SmallCardContext } from '../MDFContext';
import { colorStyles, VDLColors, waypointColorStyles } from '../../util/colorStyles';

const newCardStyle: Record<string, BoxVariant> = {
  blank: 'clear',
  dash: 'bordered',
  flat: 'bordered',
  shadow: 'shadowed',
  underlined: 'underlined'
};

export interface ISmallCardProps {
  // The background color for the card. Defaults to 'white'.
  cardColor?: VDLColors;
  // The border/box shadow style for the card. Defaults to 'shadow'. Replaces the 'background' property.
  cardStyle?: 'shadow' | 'flat' | 'dash' | 'blank';
  // Child nodes to render within the SmallCard
  children: any;
  // Application className to apply for SmallCards
  className?: string;
  // Id for each cards
  id?: string;
  // use the Waypoint version of the layout styles, will be ignored once the feature flag is removed
  isWaypoint?: boolean;
  // Indicates if the card is selectable.
  // Selectable card do not support CardBottom
  // Selectable card only supports checkbox and radiobutton in action corner.
  selectable?: boolean;
}

export const SmallCard = (props: ISmallCardProps) => {
  let top = null;
  let middle = null;
  let bottom = null;
  const { className, cardStyle, id } = props;
  const hasOldShell = (window as any).isLegacyAppShell || (window as any).isClassicShell;
  const hasWaypointCards = FeatureFlags.hasFeature('waypointCards') && !hasOldShell;
  const isWaypoint = props.isWaypoint || hasWaypointCards;
  const baseClass = isWaypoint ? 'small-card2' : 'small-card';
  const cardColor = props.cardColor || 'white';
  const colorClasses = isWaypoint ? waypointColorStyles(cardColor) : colorStyles(cardColor);
  const BoxComponent = isWaypoint ? SdfBox : 'div';

  const boxProps: any = {
    id
  };

  if (isWaypoint) {
    boxProps.className = classNames(`${baseClass} flex flex-col ${colorClasses} mdf-applyBorderLine`, className);
    boxProps.spacing = 'none' as BoxSpacing;
    boxProps.variant = newCardStyle[cardStyle ?? 'flat'];
  }
  else {
    boxProps.className = classNames(`small-card small-card--${cardStyle ?? 'flat'} ${colorClasses} mdf-applyBorderLine`, className);
  }

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === SmallCardTop) {
        return top = <SmallCardTop id={child.id || props.id} {...child.props}></SmallCardTop>;
      }
      else if (child.type === SmallCardMiddle) {
        middle = child;
      }
      else if (child.type === SmallCardBottom && props.selectable) {
        console.error('Selectable cards are not allowed to contain a SmallCardBottom');
      }
      else if (child.type === SmallCardBottom && !props.selectable) {
        bottom = child;
      }
      else {
        console.error('The SmallCard children must be at most one of each of these three components: SmallCardTop, SmallCardMiddle, or SmallCardBottom');
      }
    }
    else {
      return null;
    }
  });

  return (
    <SmallCardContext.Provider value={{ selectable: props.selectable }}>
      <BoxComponent {...boxProps}>
        {top}
        {middle}
        {bottom}
      </BoxComponent>
    </SmallCardContext.Provider>
  );
};

SmallCard.propTypes = {
  cardStyle: PropTypes.oneOf(['shadow', 'flat', 'dash', 'blank']),
  className: PropTypes.string,
  id: PropTypes.string,
  isWaypoint: PropTypes.bool,
  selectable: PropTypes.bool
};
