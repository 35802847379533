import React from 'react';
import { get } from 'lodash';
import { GridHelper } from '../../util/GridHelper';
import classNames from 'classnames';

export interface IGridCellProps {
  columnWidths?: any;
  rows: any;
  lockedColumns?: number;
  rowIndex?: number;
  columnIndex?: number;
  value?: string;
  column: object;
  onScrollAnchorChange?: (index: number, offset: number) => void;
  onClick?: () => void;
  setRowHeights?: (rowIndex: number, columnIndex: number, height: number) => void;
}

export class Cell extends React.Component<React.PropsWithChildren<IGridCellProps>, any> {
  private cellElement;
  private height;

  componentDidMount() {
    this.height = this.cellElement.getBoundingClientRect().height;

    if (this.props.setRowHeights && (this.props.lockedColumns || this.props.onScrollAnchorChange) && this.height) {
      this.props.setRowHeights(this.props.rowIndex, this.props.columnIndex, this.height);
    }
  }

  render() {
    const style: React.CSSProperties = GridHelper.getFlexStyles(this.props.column);
    const columnHasSetWidth = (this.props.columnWidths && this.props.columnWidths[this.props.columnIndex]);
    const alignColumn = (this.props.column && this.props.column['alignColumn']) ? this.props.column['alignColumn'] : '';

    if (columnHasSetWidth) {
      style.width = this.props.columnWidths[this.props.columnIndex];
    }

    const classes = classNames(
      {
        'vdl-col-xs': true,
        'mdf-grid-cell': true,
        'mdf-grid-locked-column-min-width': !!this.props.lockedColumns,
        'mdf-grid-column-with-set-width': columnHasSetWidth,
        'vdl-text-right': alignColumn === 'right',
        'vdl-text-center': alignColumn === 'center'
      },
      get(this.props, 'className', ''),
      ('column-' + this.props.columnIndex)
    );

    return (
      <div style={style} className={classes} ref={(el) => this.cellElement = el} onClick={this.props.onClick}>
        {this.props.value}
        {this.props.children}
      </div>
    );
  }
}
