import { booleanAttributes, ComponentManager, ModelHelper, ReduxHelper } from '@adp-wfn/mdf-core';
import { CellFocusedEvent, ICellEditor, ICellEditorComp, ICellEditorParams, ICellRendererComp, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { get } from 'lodash';

interface IADPCellRendererComponent extends ICellRendererComp {
  focusIn?: () => void;
}

interface IADPCellEditorComponent extends ICellEditorComp {
  editorUI?: Record<string, {
    data: any
  }>;
}

export class AgGridManager {
  private static htmlIteratorRenderer({ viewName, view, model }) {
    const items = ModelHelper.resolve(view.items, model, viewName);
    const itemContent = view.content;
    const itemVar: string = view.itemVar || 'item';

    const renderedIterator = items && items.map((item: any, itemIndex: number) => {
      const itemModel = {
        ...model,
        [itemVar]: item,
        [itemVar + 'Index']: itemIndex
      };

      const renderedItem = AgGridManager.renderContent(itemContent, itemModel, viewName);

      if (Array.isArray(renderedItem) && renderedItem.length === 1) {
        // renderContent may return an array, if it does and it's 1 item, take it out of the array.
        return renderedItem[0];
      }
      else {
        return renderedItem;
      }
    });

    return renderedIterator;
  }

  private static htmlRenderer({ viewName, view, model }) {
    const viewProperties = ModelHelper.resolve(view.properties, model, viewName) || [];

    const viewContent = AgGridManager.renderContent(view.content, model, viewName);
    const viewChildren = Array.isArray(viewContent) ? viewContent : [viewContent];
    let viewEvents = [];

    if (view.events) {
      viewEvents = ModelHelper.resolve(view.events, model, viewName);
      viewEvents = Array.isArray(viewEvents) ? viewEvents : [viewEvents];
    }

    // do not pass analyticsCategory, analyticsAction, analyticsLabel properties down (clone before modifications)
    const properties = { ...(viewProperties || {}) };

    delete properties.analyticsCategory;
    delete properties.analyticsAction;
    delete properties.analyticsLabel;

    // filter out boolean HTML attributes that are not `truthy` when rendering OneUX web components.
    // when dealing with Stencil-based web components, their React wrapper does not handle them correctly,
    // so any value that is not 'undefined' will become 'true' inside the Stencil component.
    // in an odd turn, the displayName property is attached to the render function in React-wrapped Stencil components.
    Object.keys(properties).forEach((prop) => {
      if (booleanAttributes.includes(prop.toLowerCase()) && !properties[prop]) {
        delete properties[prop];
      }
    });

    const element = document.createElement(view.type);

    Object.keys(viewProperties).forEach((prop) => {
      const value = viewProperties[prop];

      // Don't set a property if there is no value.
      if (!value) {
        return;
      }

      if (typeof value === 'object' || Array.isArray(value)) {
        element[prop] = value;
        return;
      }
      else if (typeof value === 'boolean') {
        if (value) {
          element.setAttribute(prop, String(value));
        }

        return;
      }
      else if (typeof value === 'function') {
        // Skip functions
        return;
      }
      else {
        element.setAttribute(prop, value);
        return;
      }
    });

    viewEvents.forEach((viewEvent: any) => {
      element.addEventListener(viewEvent.from, (event) => {
        // If view.dispatch is still a string, try to look it up in the model. Otherwise, hope it's a function.
        const eventHandler = typeof viewEvent.dispatch === 'string' ? model[viewEvent.dispatch] : viewEvent.dispatch;

        if (typeof eventHandler === 'function') {
          if (viewEvent.params !== undefined) {
            if (Array.isArray(viewEvent.params)) {
              // Use { ...model, event } to mix the data passed in when the view was created with
              // the DOM event so that the application event handler has access to all the data.
              return eventHandler(...viewEvent.params, { ...model, event });
            }
            else {
              return eventHandler(viewEvent.params, { ...model, event });
            }
          }
          else {
            return eventHandler({ ...model, event });
          }
        }
        else {
          console.log(`The model has no function matching the name ${viewEvent.dispatch} for event ${viewEvent.from}`);
          return undefined;
        }
      });
    });

    viewChildren
      .filter((child) => !!child)
      .forEach((child) => element.append(child));

    return element;
  }

  private static renderView(view: any, model: any, viewName?: string) {
    const properties = view.properties;

    // Resolve the render property to determine if this node needs to be rendered
    if (properties && properties.hasOwnProperty('render')) {
      const shouldRender = ModelHelper.resolve(properties.render, model, viewName);

      if (shouldRender === false) {
        return null;
      }
    }

    // Resolve the ready property to determine if we need to show a busy indicator
    // instead of the actually rendering this node
    if (properties && properties.hasOwnProperty('ready')) {
      const isReady = ModelHelper.resolve(properties.ready, model, viewName);

      if (isReady === false) {
        // const busyProps = ModelHelper.resolve(properties.busyProperties, model, viewName);
        // return this.renderBusyIndicator(busyProps);
      }
    }

    // Save the original view.type value for logging later.
    const originalViewType = view.type;

    // View type could be a variable so resolving it, if view.type does not start with '::' resolver will return it as is.
    const viewType = ModelHelper.resolve(view.type, model);

    if (!viewType) {
      // If there's no view to render, then don't render anything.
      console.warn(`renderView: While rendering view [${viewName}], type [${originalViewType}] resolved to [${viewType}]!`);
      return null;
    }

    // Hack to add Iterator support to the AgGrid custom renderers.
    // A better solution will be to match the renderers to the view technology, but
    // that will require more work than we have time available to make it work and
    // make it backward compatible.
    let renderer;

    switch (viewType) {
      case 'Iterator':
        renderer = AgGridManager.htmlIteratorRenderer;
        break;
      default:
        renderer = AgGridManager.htmlRenderer;
    }

    // do not pass internal properties down (clone before modifications)
    const viewProperties = { ...(properties || {}) };
    delete viewProperties.render;
    delete viewProperties.ready;
    delete viewProperties.busyProperties;
    delete viewProperties.analyticsName;

    // We need to disable components that initiate data changes when in user impersonation modes.
    // This code checks with the WFNShell to see if we are in a user impersonation mode and then uses
    // a property named 'savesChanges' to mark that component as needing to be disabled during impersonation.
    const globalContextObject: any = window['WFNShell']?.globalContextObject;
    const isImpersonatingUser = !!globalContextObject?.getSessionContext()?.isUaImpersonateModeUser();
    const isPartnerPractitionerWithoutEditPrivilege = !!globalContextObject?.getSessionContext()?.isPartnerPractitionerSupport?.() && !globalContextObject?.isFeatureAvailable('78071600');

    if ((isImpersonatingUser || isPartnerPractitionerWithoutEditPrivilege) && viewProperties.hasOwnProperty('savesChanges')) {
      // Resolve the savesChanges property to determine if this component is disabled in View Site as User mode.
      const savesChanges = ModelHelper.resolve(viewProperties.savesChanges, model, viewName);

      // savesChanges needs to be exactly the boolean value true
      if (savesChanges === true) {
        console.log('renderView(): View Site As User enabled - disabling component.');
        viewProperties.disabled = true;
      }
    }

    // publicView is view with internal properties removed
    const publicView = { ...view, properties: viewProperties };
    const renderedView = renderer({ viewName, view: publicView, model });

    return renderedView;
  }

  static renderContent(content: any, model: any, viewName?: string): any {
    let renderedContent;

    // The content can either be an array of components (which might be strings), a single component, or just a scalar value.
    // If it's none of those types, this method returns undefined.
    if (Array.isArray(content)) {
      renderedContent = content.map((child: any) => {
        // If it's a content item, render each item passing in the same model.
        return AgGridManager.renderContent(child, model, viewName);
      });
    }
    else if (typeof content === 'string') {
      // The content is a string, so just return the string (after model lookup)
      renderedContent = ModelHelper.resolve(content, model, viewName);
    }
    else if (content && (typeof content === 'object')) {
      // The content exists and is a single object, so render that object and return it.
      renderedContent = AgGridManager.renderView(content, model, viewName);
    }
    else {
      // The content exists and is some scalar other than a string or object, so just return it.
      renderedContent = content;
    }

    return renderedContent;
  }

  static createAgGridEditor = (viewName: string, viewJson: any, selector?: string) => {
    // Find all the data references in the view (::propertyName) and
    // collect them into an object which we can reference later.
    const viewPropsList = ModelHelper.findProps(viewJson);
    const viewPropsHash = {};
    viewPropsList.forEach((item) => viewPropsHash[item] = true);

    // Make an ag-grid cell renderer out of the view.
    const ViewComponent: any = class implements ICellEditor {
      changeEvent: string;
      editorUI: HTMLElement;
      finishedEditing = false;
      model: any;
      navigateTo: string;
      originalValue: any;
      params: ICellEditorParams;
      shouldLog = false;
      stayInCell = false;
      value: any;
      view = JSON.parse(JSON.stringify(viewJson));
      viewProps = viewPropsHash;

      constructor() {
        this.init = this.init.bind(this);
        this.afterGuiAttached = this.afterGuiAttached.bind(this);
        this.destroy = this.destroy.bind(this);
        this.getGui = this.getGui.bind(this);
        this.getCallback = this.getCallback.bind(this);
        this.getId = this.getId.bind(this);
        this.focusIn = this.focusIn.bind(this);
        this.focusOut = this.focusOut.bind(this);
        this.getPopupPosition = this.getPopupPosition.bind(this);
        this.getValue = this.getValue.bind(this);
        this.isCancelAfterEnd = this.isCancelAfterEnd.bind(this);
        this.isCancelBeforeStart = this.isCancelBeforeStart.bind(this);
        this.isPopup = this.isPopup.bind(this);
      }

      private finishCell = () => {
        console.log(`${viewName}.finishCell(): finishedEditing = ${this.finishedEditing}, navigateTo = ${this.navigateTo}`);

        if (this.finishedEditing) {
          this.finishedEditing = false;

          // Go to the next cell
          switch (this.navigateTo) {
            case 'previous':
              this.params.api.tabToPreviousCell();
              break;

            case 'next':
              this.params.api.tabToNextCell();
              break;

            case 'stay':
              this.stayInCell = true;
              this.params.api.stopEditing();
              break;
          }

          this.navigateTo = '';
        }
      };

      // The AgGrid used to have a method to get the grid's node in its API,
      // but that appears to have gone missing in AgGrid 32, so we have to
      // brute force it.
      private findGridBody = () => {
        let node: Node = this.params.eGridCell;

        // The main body of the AgGrid has the custom attribute grid-id, so this is how
        // we will locate that node. Of course, the AgGrid folks could change this in
        // the future as well. The node type doesn't have the getAttribute method. It is
        // part of the Element type, and nodes can be types other than Element.
        while (node && (node instanceof Element ? !node.getAttribute('grid-id') : true)) {
          node = node.parentNode;
        }

        return node;
      };

      clickHandler = (event) => {
        if (this.shouldLog) {
          console.log(`${viewName}.clickHandler(): Entering.`, event);
        }

        // Get the grid's body viewport from the grid
        const gridViewport = this.findGridBody();

        if (this.shouldLog) {
          console.log(`${viewName}.clickHandler(): gridViewport =`, gridViewport);
        }

        // Check whether the click is inside or outside the viewport,
        // making sure that the event target isn't the sdf-floating-pane,
        // which is used as the popup for various other components.
        const clickIsOutsideGrid = !gridViewport?.contains(event.target);
        const targetIsNotFloatingPane = (event.target as HTMLElement).offsetParent?.tagName !== 'SDF-FLOATING-PANE';

        if (clickIsOutsideGrid && targetIsNotFloatingPane) {
          this.params.api.stopEditing();
        }
      };

      private blurHandler = (event) => {
        if (this.shouldLog) {
          console.log(`${viewName}.blurHandler(): Entering.`, event);
        }

        // On blur, call back to the application's onBlur callback
        const colDef: any = this.params.colDef;
        colDef.onBlur?.(this.params.colDef, this.params.rowIndex, this.params.api, event);
        this.finishCell();
      };

      private changeHandler = (event) => {
        if (this.shouldLog) {
          console.log(`${viewName}.changeHandler(): Entering.`, event);
        }

        // On change, send the change and potentially move the editor since
        // we delay keyboard navigation until AFTER the change event fires.
        if (event?.detail !== undefined) {
          // The web component event data is in the detail field of the event.
          // Or at least we hope so - hasOwnProperty() returns false for the detail
          // field, so it's hard to tell whether it really exists or not, so we're
          // hoping that when a user empties out a field, that we're getting an empty
          // string or null instead of undefined.
          this.value = event.detail;
        }
        else {
          // Otherwise, get the value of what might be an HTML input
          this.value = event.target.value;
        }

        this.finishCell();
      };

      private keyDownHandler = (event) => {
        // The ag-grid keyboard handling can cause the editor to get destroyed
        // BEFORE the change event happens, causing the cell to lose value.
        // By stopping the propagation of the navigation keys, and then setting
        // data in the editor, we can delay the navigation until the cell is ready.
        if (this.shouldLog) {
          console.log(`${viewName}.keyDownHandler(): Entering.`, event);
        }

        if (event.key === 'Tab') {
          // Delay navigation when the tab key is pressed. Make sure we preserve
          // the state of the shift key so that we navigate the right direction.
          event.stopPropagation();
          this.finishedEditing = true;

          if (event.shiftKey) {
            this.navigateTo = 'previous';
          }
          else {
            this.navigateTo = 'next';
          }
        }
        else if (event.key === 'Enter') {
          // Delay turning off the editor when the enter key is pressed.
          event.stopPropagation();
          this.finishedEditing = true;
          this.navigateTo = 'stay';

          // Finish editing if the value hasn't changed and the user clicks enter
          // since there won't be an onChange event coming.
          if (event.target.value === this.value) {
            this.finishCell();
          }
        }
        else if (event.key === 'Escape') {
          // Delay turning off the editor when the escape key is pressed.
          // Pressing escape will abandon any changes and there will not be
          // an onChange event, so we need to turn off the editor.
          event.stopPropagation();
          this.finishedEditing = true;
          this.value = this.originalValue;
          this.navigateTo = 'stay';
          this.finishCell();
        }
      };

      private keyUpHandler = (event) => {
        if (this.shouldLog) {
          console.log(`${viewName}.keyUpHandler(): Entering.`, event);
        }

        if (event.key === 'Tab') {
          // Don't let keyUp on Tab cause the grid to navigate
          event.stopPropagation();
        }

        // As Suggested by AgGrid Team, getValue() is always called earlier to blur
        // To store the value we need to save it on keyUp
        this.value = event.target.value;
      };

      init(params) {
        let mappedState = {};
        let mappedFunctions = {};

        if (params.context.store && params.context.dispatch) {
          const viewConnector = ReduxHelper.getConnector(viewName);
          const store = params.context.store;

          if (viewConnector) {
            mappedState = viewConnector.mapStateToModel(store.getState());
            mappedFunctions = viewConnector.mapDispatchToModel(store.dispatch);
          }
          else {
            mappedState = selector ? get(store.getState(), selector, undefined) : store.getState();
          }
        }
        else {
          console.log(`${viewName}.init(): The grid using this view has no context.`, params);
        }

        this.model = { ...mappedState, ...mappedFunctions, ...params };

        if (this.view?.properties?.hasOwnProperty('debug')) {
          this.shouldLog = ModelHelper.resolve(this.view.properties.debug, this.model, viewName);
        }

        if (this.shouldLog) {
          console.log(`${viewName}.init(): Entering. params =`, params);
          console.log(`${viewName}.init(): Entering. model =`, this.model);
        }

        this.params = params;
        this.value = params.value;
        this.originalValue = params.value;
        this.editorUI = AgGridManager.renderView(this.view, { ...this.model }, viewName);

        this.changeEvent = (this.params.colDef as any).changeEvent ?? 'change';

        // Add all the event handlers
        document.addEventListener('click', this.clickHandler, true);
        this.editorUI.addEventListener('blur', this.blurHandler);
        this.editorUI.addEventListener(this.changeEvent, this.changeHandler);
        this.editorUI.addEventListener('keydown', this.keyDownHandler);
        this.editorUI.addEventListener('keyup', this.keyUpHandler);
      }

      afterGuiAttached() {
        if (this.shouldLog) {
          console.log(`${viewName}.afterGuiAttached(): Entering.`);
        }

        const focusNode = this.findFocusNode(this.view);

        if (focusNode) {
          if (focusNode.tagName.includes('-')) {
            // Web component tag names will always have a "-" in them.
            // Assume that our web components have a setFocus method.
            focusNode.setFocus();

            // Assume that our web components (input types) have a select method.
            if (focusNode.select) {
              focusNode.select();
            }
          }
          else {
            // Use the DOM focus method for standard tags.
            focusNode.focus();
          }
        }
        else {
          if (this.shouldLog) {
            console.log(`${viewName}.afterGuiAttached(): Could not find a node with takesFocus and an id in the view.`, this.editorUI);
          }
        }
      }

      destroy() {
        if (this.shouldLog) {
          console.log(`${viewName}.destroy(): Entering. Removing event listeners`);
        }

        // Remove all the event listeners
        document.removeEventListener('click', this.clickHandler, true);
        this.editorUI.removeEventListener('blur', this.blurHandler);
        this.editorUI.removeEventListener(this.changeEvent, this.changeHandler);
        this.editorUI.removeEventListener('keydown', this.keyDownHandler);
        this.editorUI.removeEventListener('keyup', this.keyUpHandler);

        if (this.stayInCell) {
          // Keep this cell as the focused cell
          this.params.api.setFocusedCell(this.params.rowIndex, this.params.column);
          this.stayInCell = false;
        }

        // Remove references to everything to make sure memory doesn't leak.
        this.params = undefined;
        this.model = undefined;
        this.originalValue = undefined;
        this.value = undefined;
        this.editorUI = undefined;
        this.viewProps = undefined;
        this.view = undefined;
      }

      getGui() {
        return this.editorUI;
      }

      findFocusNode = (view: any) => {
        if (!this.editorUI) {
          if (this.shouldLog) {
            console.log(`${viewName}.findFocusNode(): this.editorUI was undefined`);
          }

          return undefined;
        }

        if (view.properties?.takesFocus && view.properties?.id) {
          const id = ModelHelper.resolve(view.properties.id, this.model, viewName);

          if (this.shouldLog) {
            console.log(`${viewName}.findFocusNode(): Found item in the view with id ${id}`);
          }

          // If the query doesn't start with the parent, then it won't find the editorUI node
          // should it be the node that matches the query.
          return this.editorUI.parentElement?.querySelector(`#${id}`);
        }
        else if (view.content) {
          if (Array.isArray(view.content)) {
            return view.content.reduce((previousValue, subView) => {
              if (!previousValue) {
                return this.findFocusNode(subView);
              }
              else {
                return previousValue;
              }
            }, undefined);
          }
          else {
            return this.findFocusNode(view.content);
          }
        }
        else {
          return undefined;
        }
      };

      getCallback(name) {
        if (this.view.properties?.[name]) {
          if (typeof this.view.properties[name] === 'function') {
            return this.view.properties[name];
          }
          else if (typeof this.view.properties[name] === 'string') {
            return ModelHelper.resolve(this.view.properties[name], this.model, viewName) || undefined;
          }
        }
        else if (this.params.colDef[name] && typeof this.params.colDef[name] === 'function') {
          return this.params.colDef[name];
        }
        else {
          return undefined;
        }
      }

      getId() {
        if (this.view.properties?.id) {
          return ModelHelper.resolve(this.view.properties.id, this.model, viewName);
        }
        else {
          return '';
        }
      }

      // The next 7 methods are AG Grid cell editor methods. This class forwards the calls back to the application.
      focusIn() {
        // If doing full row edit, then gets called when tabbing into the cell.
        const callback = this.getCallback('focusIn');

        if (this.shouldLog) {
          console.log(`${viewName}.focusIn(): Calling props.focusIn, if it exists, otherwise returning undefined.`, callback);
        }

        return callback?.(this.model);
      }

      focusOut() {
        // If doing full row edit, then gets called when tabbing out of the cell.
        const callback = this.getCallback('focusOut');

        if (this.shouldLog) {
          console.log(`${viewName}.focusOut(): Calling props.focusOut, if it exists, otherwise returning undefined.`, callback);
        }

        return callback?.(this.model);
      }

      getPopupPosition() {
        // Gets called once, only if isPopup() returns true. Return "over" if the
        // popup should cover the cell, or "under" if it should be positioned below
        // leaving the cell value visible. If this method is not present, the
        // default is "over".
        const callback = this.getCallback('getPopupPosition');

        if (this.shouldLog) {
          console.log(`${viewName}.getPopupPosition(): Calling props.getPopupPosition, if it exists, otherwise returning 'over'.`, callback);
        }

        return callback?.(this.model) || 'over';
      }

      getValue() {
        if (this.shouldLog) {
          console.log(`${viewName}.getValue(): Entering. value =`, this.value);
        }

        return this.value;
      }

      isCancelBeforeStart() {
        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        const callback = this.getCallback('isCancelBeforeStart');

        if (this.shouldLog) {
          console.log(`${viewName}.isCancelBeforeStart(): Calling props.isCancelBeforeStart, if it exists, otherwise returning false.`, callback);
        }

        return callback?.(this.model) || false;
      }

      isCancelAfterEnd() {
        // Gets called once when editing is finished (e.g. if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        const callback = this.getCallback('isCancelAfterEnd');

        if (this.shouldLog) {
          console.log(`${viewName}.isCancelAfterEnd(): Calling props.isCancelAfterEnd, if it exists, otherwise returning false.`, callback);
        }

        return callback?.(this.model) || false;
      }

      isPopup() {
        // Gets called once after initialized.
        // If you return true, the editor will appear in a popup.
        const callback = this.getCallback('isPopup');

        if (this.shouldLog) {
          console.log(`${viewName}.isPopup(): Calling props.isPopup, if it exists, otherwise returning false.`, callback);
        }

        return callback?.(this.model) || false;
      }
    };

    // Register the component
    ComponentManager.registerComponent(viewName, ViewComponent);

    return ViewComponent;
  };

  static createAgGridRenderer = (viewName: string, viewJson: any, selector?: string) => {
    // Find all the data references in the view (::propertyName) and
    // collect them into an object so we can reference them later.
    const viewPropsList = ModelHelper.findProps(viewJson);
    const viewPropsHash = {};
    viewPropsList.forEach((item) => viewPropsHash[item] = true);

    // Make an ag-grid cell renderer out of the view.
    const ViewComponent: any = class implements IADPCellRendererComponent {
      params: ICellRendererParams;
      model: any;
      value: any;
      rendererUI: HTMLElement;
      shouldLog = false;
      viewProps = viewPropsHash;
      view = JSON.parse(JSON.stringify(viewJson));

      getAllFocusableElementsOf = (el) => {
        return [
          ...el.querySelectorAll(
            '[takesFocus]'
          )];
      };

      constructor() {
        this.init = this.init.bind(this);
        this.destroy = this.destroy.bind(this);
        this.getGui = this.getGui.bind(this);
        this.refresh = this.refresh.bind(this);
      }

      init(params) {
        let mappedState = {};
        let mappedFunctions = {};

        if (params.column?.colDef?.suppressHeaderKeyboardEvent === 'suppress') {
          params.column.colDef.suppressHeaderKeyboardEvent = (param) => {
            const key = param.event.key;
            const shiftKey = param.event.shiftKey;
            const isTabbingForward = key === 'Tab' && shiftKey === false;
            const isTabbingBackWards = key === 'Tab' && shiftKey === true;

            if (isTabbingForward || isTabbingBackWards) {
              const eGridCell = param.event.composedPath().find((el) => {
                if (el.classList === undefined) {
                  return false;
                }

                return el.classList.contains('ag-header-cell');
              });

              const focusableChildrenElements = this.getAllFocusableElementsOf(eGridCell);
              const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1];
              const firstCellChildEl = focusableChildrenElements[0];

              if (isTabbingForward && focusableChildrenElements.length > 0) {
                const isLastChildFocused = lastCellChildEl && (document.activeElement === lastCellChildEl || document.activeElement.parentElement === lastCellChildEl);

                if (isLastChildFocused === false) {
                  return true;
                }
              }

              if (isTabbingBackWards && focusableChildrenElements.length > 0) {
                const cellHasFocusedChildren = eGridCell.contains(document.activeElement) && eGridCell !== document.activeElement;

                if (!cellHasFocusedChildren) {
                  param.event.preventDefault();
                  lastCellChildEl.focus();
                }

                const isFirstChildFocused = firstCellChildEl && document.activeElement === firstCellChildEl;

                if (isFirstChildFocused === false) {
                  return true;
                }
              }
            }

            return false;
          };
        }

        if (params.context.store && params.context.dispatch) {
          const viewConnector = ReduxHelper.getConnector(viewName);
          const store = params.context.store;

          if (viewConnector) {
            mappedState = viewConnector.mapStateToModel(store.getState());
            mappedFunctions = viewConnector.mapDispatchToModel(store.dispatch);
          }
          else {
            mappedState = selector ? get(store.getState(), selector, undefined) : store.getState();
          }
        }
        else {
          console.log(`${viewName}.init(): The grid using this view has no context.`, params);
        }

        this.model = { ...mappedState, ...mappedFunctions, ...params };

        if (this.view?.properties?.hasOwnProperty('debug')) {
          this.shouldLog = ModelHelper.resolve(this.view.properties.debug, this.model, viewName);
        }

        if (this.shouldLog) {
          console.log(`${viewName}.init(): Entering. params =`, params);
          console.log(`${viewName}.init(): Entering. model =`, this.model);
        }

        this.params = params;
        this.value = params.value;
        this.rendererUI = AgGridManager.renderView(this.view, { ...this.model }, viewName);
      }

      destroy() {
        if (this.shouldLog) {
          console.log(`${viewName}.destroy(): Entering. Removing event listeners`);
        }

        // Remove references to everything to make sure memory doesn't leak.
        this.params = undefined;
        this.model = undefined;
        this.value = undefined;
        this.rendererUI = undefined;
        this.viewProps = undefined;
        this.view = undefined;
      }

      getGui() {
        return this.rendererUI;
      }

      refresh() {
        // Tell the grid to destroy and recreate this renderer when the grid is refreshed.
        return false;
      }

      isCellWrapperFocused() {
        return document.activeElement === this.params.eGridCell;
      }

      focusIn() {
        // Called by the focus helper when this cell receives focus
        if (this.shouldLog) {
          console.log(`${viewName}.focusIn(): Entering. Cell received focus`);
        }

        if (this.rendererUI) {
          const focusNode: any = this.rendererUI.querySelector('[takesFocus]');

          if (this.shouldLog) {
            console.log(`${viewName}.focusIn(): focusNode =`, focusNode);
          }

          if (focusNode && focusNode.tagName.includes('-')) {
            if (!this.isCellWrapperFocused()) {
              // Check to fix a bug when navigating from last cell in detail grid to next master row.
              return;
            }
            void focusNode.setFocus();
          }
        }
      }
    };

    // Register the component
    ComponentManager.registerComponent(viewName, ViewComponent);

    return ViewComponent;
  };

  static createAgGridEditorBundle = (viewBundle: any, selector?: string) => {
    if (viewBundle) {
      Object.keys(viewBundle).forEach((key) => {
        this.createAgGridEditor(key, viewBundle[key], selector);
      });
    }
  };

  static createAgGridRendererBundle = (viewBundle: any, selector?: string) => {
    if (viewBundle) {
      Object.keys(viewBundle).forEach((key) => {
        this.createAgGridRenderer(key, viewBundle[key], selector);
      });
    }
  };

  static cellFocusHandler = (params: CellFocusedEvent) => {
    let rowNode: IRowNode;

    // Check for rowPinned property and use the corresponding method to get the rowNode.
    if (params.rowPinned === 'bottom') {
      rowNode = params.api.getPinnedBottomRow(params.rowIndex);
    }
    else if (params.rowPinned === 'top') {
      rowNode = params.api.getPinnedTopRow(params.rowIndex);
    }
    else {
      rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
    }

    const rendererInstanceParams = {
      columns: [ params.column ],
      rowNodes: [ rowNode ]
    };

    // Find the custom cell renderer for the cell that just received focus.
    const cellRenderer: IADPCellRendererComponent = params.api.getCellRendererInstances(rendererInstanceParams)?.[0] as IADPCellRendererComponent;
    // We expect that the custom cell renderer was created with AgGridManager.createAgGridRenderer().
    // Call the focusIn() method on the renderer to set focus to the node that has takesFocus set.
    cellRenderer?.focusIn?.();
  };

  static asyncSelectOptionSetter = (params: any, options: any) => {
    // This function sets the options for sdf-select-simple component when 'filter-async' property is used.
    if (!options) {
      console.error('User must pass an array of options to the component.');
    }

    const rowNode = params?.api.getDisplayedRowAtIndex(params.rowIndex);
    const editorInstanceParams = {
      columns: [ params.column ],
      rowNodes: [ rowNode ]
    };

    // Find the custom cellEditor for the cell that is being edited.
    const cellEditor: IADPCellEditorComponent = params?.api.getCellEditorInstances(editorInstanceParams)?.[0] as IADPCellEditorComponent;
    // Set the options to the select component's items property.
    cellEditor.editorUI.items = options;
  };
}
