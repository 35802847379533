import React from 'react';
import { FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
export class MDFFlag extends React.Component<any, any> {
  private flagsPath = '/mas/flag-icons/6.6.3/flags/4x3/';

  constructor(props) {
    super(props);
    const currentPage = document.location.href;
    const isProduction = currentPage.includes('workforcenow.adp.com') || currentPage.includes('wfn-prod-test.adp.com') || currentPage.includes('workforcenow.cloud.adp.com') || currentPage.includes('static.adp.com') || currentPage.includes('static.workforcenow.adp.com');

    if (isProduction) {
      this.flagsPath = '//static.workforcenow.adp.com' + this.flagsPath;
    }
    else {
      this.flagsPath = '//static.wfn-iat.adp.com' + this.flagsPath;
    }
  }

  render() {
    const countryCode = this.props.countryCode || this.props.country;
    // Added userLocale to get the user's locale.
    const locale = LocaleHelper.getUserLocale();
    const regionNames = new Intl.DisplayNames([locale.substring(0, 2)], { type: 'region' });
    const getCountryCode = regionNames.of(countryCode);
    const altText = FormatHelper.formatMessage(getCountryCode);
    const flagSrc = this.flagsPath + countryCode.toLowerCase() + '.svg';
    // Avoid using the wrapper div as the new phone version already comes with this wrapper div.
    const image = <img alt={altText} title={altText} className="react-phone-number-input__icon-image" src={flagSrc} />;

    return <React.Fragment> {this.props.flagsPath ? image : (this.props.phoneFlag ? <React.Fragment>{image}</React.Fragment> : <div className="react-phone-number-input__icon">{image}</div>)}</React.Fragment>;
  }
}
