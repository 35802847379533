import React from 'react';

function renderRecursive(render, remaining, cascadeProps?) {
  cascadeProps = cascadeProps || {};
  const component = remaining[0];

  // Once components is exhausted, we can render out the cascadeProps array.
  if (!component) {
    return render(cascadeProps);
  }

  // Continue recursion for remaining items.
  function nextRender(wrapperProps) {
    // compose wrapper component properties into one single object
    cascadeProps = Object.assign({}, cascadeProps, wrapperProps);

    return renderRecursive(render, remaining.slice(1), cascadeProps);
  }

  // Each component is either an element or function
  return typeof component === 'function'
    ? // When it is a function, produce an element by invoking it with props passed in
    component({ cascadeProps, render: nextRender })
    : // When it is an element, pass in render prop and all previous props.
    // The signature to React.cloneElement may have changed in React 18. Added these to let the compiler go while we figure it out.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.cloneElement(component, cascadeProps, nextRender);
}

export function Composer(props) {
  return renderRecursive(props.children, props.components);
}
