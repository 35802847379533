import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MDFCore } from '@adp-wfn/mdf-core';

export function SnackBar(props: any) {
  const [ needsPortal, setNeedsPortal ] = useState(false);
  const ref = useRef<HTMLDivElement>(undefined);
  const focusPane = useRef<HTMLElement>(undefined);
  const snackBarProps: any = {
    className: 'mdf-snackbar'
  };

  useEffect(() => {
    let parent = ref.current?.parentElement;

    while (parent) {
      if (parent?.tagName.includes('WFN-FOCUS-PANE') || parent?.tagName.includes('SDF-FOCUS-PANE')) {
        setNeedsPortal(true);
        focusPane.current = parent;
        return;
      }
      else {
        parent = parent.parentElement;
        focusPane.current = undefined;
      }
    }
  }, [ref.current]);

  if (props.snackBarWidth) {
    snackBarProps.style = {
      width: props.snackBarWidth + 'px'
    };
  }

  if (props.slotted) {
    // Don't style the component or add an extra <div> if it's going to be slotted.
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  else if (needsPortal) {
    // The SnackBar is in the content part of some Waypoint modal component and needs to move
    // to the custom-buttons slot of that component.
    if (MDFCore.shouldLog()) {
      console.log('SnackBar(): Found focus pane parent. Using createPortal.');
    }
    (focusPane.current as any).hideFooter = false;
    return createPortal(<div ref={ref} slot="custom-buttons" className="w-full">{props.children}</div>, focusPane.current);
  }
  else {
    return <div ref={ref} {...snackBarProps}>{props.children}</div>;
  }
}
