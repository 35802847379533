import React from 'react';
import ReactDOM from 'react-dom';
import { ITextboxProps } from '@synerg/vdl-react-components';
import { MDFTextbox } from './MDFTextbox';
import { AsyncScriptLoader, generateId, LocaleHelper, MDFCore } from '@adp-wfn/mdf-core';

// The documentation for Google's Places API response object can be found here: https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/js?';
const streetNumberLast = ['CL', 'CR', 'DK', 'GT', 'HU', 'IL', 'SV'];

export interface IAutoCompleteAddress {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  county?: string;
  provinceName?: string;
  provinceCode?: string;
  postalCode?: string;
  countryName?: string;
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  utcOffset?: number;
  formattedAddress?: string;
  name?: string;
}

interface IAutoCompleteAddressProps extends ITextboxProps {
  country?: string;
  onAddressSelect: (address: IAutoCompleteAddress, providedByGoogle?: boolean) => void;
  googleMapsKey?: string;
}

// An address component item from the Google Places API
interface IAddressDetail {
  long_name: string;
  short_name: string;
  types: string[];
}

// The address pieces we use from the Google Places API
interface IAddressComponents {
  country?: IAddressDetail;
  postal_code?: IAddressDetail;
  administrative_area_level_1?: IAddressDetail;
  administrative_area_level_2?: IAddressDetail;
  administrative_area_level_3?: IAddressDetail;
  postal_town?: IAddressDetail;
  locality?: IAddressDetail;
  sublocality_level_1?: IAddressDetail;
  sublocality_level_2?: IAddressDetail;
  neighborhood?: IAddressDetail;
  route?: IAddressDetail;
  street_number?: IAddressDetail;
  subpremise?: IAddressDetail;
}

export class AutoCompleteAddress extends React.Component<IAutoCompleteAddressProps, any> {
  // Local reference to google autocomplete object
  autoComplete: any = null;

  // Local reference to the text box that prompt user to type the address
  textInput: MDFTextbox = null;

  private static googleMapsPromise;
  private emptyFocusDivId: any;

  constructor(props: IAutoCompleteAddressProps) {
    super(props);

    this.state = { isInitializing: true };
    this.emptyFocusDivId = generateId('emptyFocusDivId');

    // if onAddressSelect is missing, then log an error to pass the valid function
    if (!props.onAddressSelect) {
      console.error('onAddressSelect is missing in the props. Please pass valid onAddressSelect function');
      return;
    }

    const google = window['google'];

    // Load Google Maps object when it's available
    if (!google) {
      // Note - Google doesn't support multiple maps object initialization. If a view has two or more autocompleteaddress component, then it should be loaded only on once.
      // Initialize the promise only when it is null. If promise available, use the promise method to register component.
      if (!AutoCompleteAddress.googleMapsPromise) {
        // when googleMapsKey property is not provided route it to use WFN keys
        AutoCompleteAddress.googleMapsPromise = AsyncScriptLoader.initialize({
          url: `${GOOGLE_MAPS_URL}${this.getGoogleAddressLookupKey()}`,
          name: 'google',
          callbackName: '__googleMapsApiOnLoadCallback'
        });
      }

      AutoCompleteAddress.googleMapsPromise
        .then((/* googleMaps */) => {
          // If component is already mounted, then add listener
          if (this.textInput) {
            this.registerAutoCompleteListener();
          }
        })
        .catch((err) => {
          console.error('Unable to load Google maps ', err);

          // googleMapsPromise sometimes create an empty google object in window, hence removing it here
          window['google'] = null;

          // If component is already mounted, then use setState to trigger render
          // See https://reactjs.org/docs/state-and-lifecycle.html#using-state-correctly
          if (this.textInput) {
            // removing the initializing prompt, so that this component is editable by user.
            this.setState({ isInitializing: false });
          }
          else {
            // if the component is not mounted, we are still in the constructor.
            // removing the initializing prompt, so that this component is editable by user.
            this.state = { isInitializing: false };
          }
        });
    }

    // this global method calls when there is an authentication failure if you supply an invalid google maps key
    window['gm_authFailure'] = () => {
      const GOOGLE_ERROR_CLASS = 'gm-err-autocomplete';
      // clear all error styles from autocomplete component to enable the field
      const autoComplete = document.getElementsByClassName(GOOGLE_ERROR_CLASS);

      if (autoComplete && autoComplete.length === 1) {
        autoComplete[0].removeAttribute('style');
        autoComplete[0].removeAttribute('disabled');
        autoComplete[0].removeAttribute('placeholder');
        autoComplete[0].setAttribute('placeholder', props.placeholder || '');
        autoComplete[0].className = autoComplete[0].className.replace(GOOGLE_ERROR_CLASS, '');
      }
    };
  }

  componentDidMount() {
    const google = window['google'];

    if (google) {
      this.registerAutoCompleteListener();
    }
  }

  componentDidUpdate(oldProps) {
    if ((!oldProps || !oldProps.country) || (oldProps && oldProps.country && this.props && this.props.country && this.props.country !== oldProps.country)) {
      this.autoComplete?.setComponentRestrictions({ country: this.props.country });
    }
  }

  // When googleMapsKey property is not provided then use ADP supported keys
  getGoogleAddressLookupKey = () => {
    let options: any = {};

    if (this.props.googleMapsKey) {
      options = { key: this.props.googleMapsKey };
    }
    else {
      options = { key: MDFCore.getGoogleMapsKey() };
    }

    options.language = LocaleHelper.getUserLocale();

    const params = [];

    Object.keys(options).forEach(function(key) {
      if (options[key]) {
        params.push(key + '=' + options[key]);
      }
    });

    params.push('libraries=places');
    return params.join('&');
  };

  // Registering the google auto complete object to textInput
  registerAutoCompleteListener = () => {
    const google = window['google'];
    // The autocomplete address is restricted by country. Use the country if it is available in props, else use the user's locale country
    const country = this.props.country || LocaleHelper.getCountryCode();

    // Clear any existing listeners registered in the input textbox
    google.maps.event.clearInstanceListeners(this.textInput.textBox._input);

    this.autoComplete = new google.maps.places.Autocomplete(this.textInput.textBox._input, { types: ['geocode'], componentRestrictions: { country: country } });
    // Limit what is returned to the basic fields we use to reduce costs.
    this.autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'place_id', 'utc_offset_minutes']);
    // Registering a listener to handle when the address is selected from google autoaddress list
    this.autoComplete.addListener('place_changed', this.handleAddress.bind(this));

    this.setState({ isInitializing: false });
  };

  transformPlace = (place: any): IAddressComponents => {
    const newPlace: IAddressComponents = {};

    place?.address_components.forEach((element: IAddressDetail) => {
      element.types?.forEach((elementType) => {
        newPlace[elementType] = element;
      });
    });

    return newPlace;
  };

  // This method get the selected address from Google results list and invoke the onAddressSelect
  handleAddress = () => {
    const address: IAutoCompleteAddress = {};
    const place = this.autoComplete.getPlace();

    address.utcOffset = place.utc_offset_minutes;
    address.formattedAddress = place.formatted_address;
    address.name = place.name;

    if (place.geometry) {
      address.latitude = place.geometry.location.lat();
      address.longitude = place.geometry.location.lng();
    }

    if (place.address_components) {
      const addressComponents: IAddressComponents = this.transformPlace(place);

      // Country - some bad addresses won't have country data
      address.countryName = addressComponents.country?.long_name;
      address.countryCode = addressComponents.country?.short_name ?? this.props.country;

      if (address.countryCode === 'AS' || address.countryCode === 'GU' || address.countryCode === 'MP' || address.countryCode === 'PR' || address.countryCode === 'UM' || address.countryCode === 'VI') {
        // US territories addresses will always use their respective US territories as the province/state.
        address.provinceName = address.countryName;
        address.provinceCode = address.countryCode;
      }

      // Postal Code
      if (addressComponents.postal_code) {
        address.postalCode = addressComponents.postal_code.long_name;
      }

      // Administrative Area Level 1 - Usually the "state" or "province".
      if (addressComponents.administrative_area_level_1) {
        if (address.countryCode === 'TR' || address.countryCode === 'TW' || address.countryCode === 'VN') {
          // Turkey, Taiwan, and Vietnam addresses use this for the city
          address.city = addressComponents.administrative_area_level_1.long_name;
        }
        else if (address.countryCode !== 'PR') {
          // For everyone other than Puerto Rico
          address.provinceName = addressComponents.administrative_area_level_1.long_name;
          address.provinceCode = addressComponents.administrative_area_level_1.short_name;
        }
      }

      // Administrative Area Level 2 - Usually the "county"
      if (addressComponents.administrative_area_level_2) {
        if (address.countryCode === 'ID' || address.countryCode === 'LB') {
          // In Indonesia and Lebanon, it appears to be the "city".
          address.city = addressComponents.administrative_area_level_2.long_name;
        }
        else if (address.countryCode === 'VN') {
          // Vietnam uses this for the "district" (which we put in address line 3)
          address.address3 = addressComponents.administrative_area_level_2.long_name;
        }
        else {
          address.county = addressComponents.administrative_area_level_2.long_name;
        }
      }

      // Administrative Area Level 3
      if (addressComponents.administrative_area_level_3) {
        if (address.countryCode === 'TW') {
          // Taiwan addresses use this for the "district" (which we put in the "county" field)
          address.county = addressComponents.administrative_area_level_3.long_name;
        }
        else if (address.countryCode === 'VN') {
          // Vietnam uses this for the "ward" (which we put in address line 2)
          address.address2 = addressComponents.administrative_area_level_3.long_name;
        }
      }

      // The city can be in locality, postal_town, or sublocality_level_1.
      // Postal Town
      if (addressComponents.postal_town) {
        // If present, it is always the city.
        address.city = addressComponents.postal_town.long_name;
      }

      // Locality
      if (addressComponents.locality && !address.city) {
        address.city = addressComponents.locality.long_name;
      }

      // Sublocality Level 1
      if (addressComponents.sublocality_level_1) {
        if (!address.city) {
          // This is the city in some cases, but don't override locality or postal_town.
          address.city = addressComponents.sublocality_level_1.long_name;
        }
        else {
          // When we already have a city, then this should be address2.
          address.address2 = addressComponents.sublocality_level_1.long_name;
        }
      }

      // Sublocality Level 2
      if (addressComponents.sublocality_level_2) {
        if (!address.city) {
          // This is the city in some cases, but don't override locality or postal_town.
          address.city = addressComponents.sublocality_level_2.long_name;
        }
        else if (!address.address2) {
          // When we already have a city, then this should be address2.
          address.address2 = addressComponents.sublocality_level_2.long_name;
        }
        else {
          // When we already have address2, then this should be address3.
          address.address3 = addressComponents.sublocality_level_2.long_name;
        }
      }

      // Neighborhood
      if (addressComponents.neighborhood) {
        if (address.countryCode === 'HK') {
          // Hong Kong uses 'neighborhoods' instead of cities.
          address.city = addressComponents.neighborhood.long_name;
        }
        else if (address.countryCode === 'SG') {
          // Singapore uses 'neighborhoods' instead of states (districts).
          address.provinceName = addressComponents.neighborhood.long_name;
          address.provinceCode = addressComponents.neighborhood.short_name;
        }
      }

      // Subpremise
      if (addressComponents.subpremise) {
        // Canadian addresses use this for apartment numbers. Maybe others do as well.
        // We will add it to address line 2 or 3, whichever is open
        if (!address.address2) {
          // When we already have a city, then this should be address2.
          address.address2 = addressComponents.subpremise.long_name;
        }
        else {
          // When we already have address2, then this should be address3.
          address.address3 = (`${address.address3 ?? ''} ${addressComponents.subpremise.long_name}`).trim();
        }
      }

      // Street Number and Route
      if (addressComponents.route) {
        // Some countries put the street number AFTER the street name. Some addresses don't have street numbers.
        if (streetNumberLast.includes(address.countryCode)) {
          address.address1 = `${addressComponents.route.long_name} ${(addressComponents.street_number?.long_name || '')}`;
        }
        else {
          address.address1 = `${(addressComponents.street_number?.long_name || '')} ${addressComponents.route.long_name}`;
        }

        address.address1 = address.address1.trim();
      }

      // if onAddressSelect is available, then pass the address object to the client app
      if (this.props.onAddressSelect) {
        this.props.onAddressSelect(address, true);

        // Once the address is selected the focus should stay in address1 text box to preserve tabbing.
        // setting the focus opens the dropdown again.
        // Found a way to add a dummy div after the address1 text box and force the focus to that div
        // add the focus style to the address1 text box using previousElementsSibling to indicate the address1 is currently focused.
        // had to use inline style to take precedence over the text box style.
        const addressDiv = document.getElementById(this.emptyFocusDivId);
        addressDiv.focus();
        const getPreviousSibling = addressDiv && addressDiv.previousElementSibling;
        getPreviousSibling?.setAttribute('style', 'border: 2px solid #3a9ade; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);outline: 0;');
      }
    }
  };

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onFocus = () => {
    const textboxNode = ReactDOM.findDOMNode(this.textInput) as Element;

    textboxNode.setAttribute('autocomplete', 'address-line1');
  };

  // Remove the style attribute added to show focus.
  removeAddress1FocusStyle = (event) => {
    const getPreviousSibling = event.target.previousElementSibling;
    getPreviousSibling?.removeAttribute('style');
  };

  render() {
    const { country, onAddressSelect, googleMapsKey, readOnly, ...rest } = this.props;
    const isReadOnly = (readOnly || this.state.isInitializing);
    const placeHolder = (isReadOnly ? 'Initializing...' : this.props.placeholder);

    return (
      <React.Fragment>
        <MDFTextbox
          ref={(input) => this.textInput = input}
          {...rest}
          readOnly={isReadOnly}
          onChange={this.onChange}
          placeholder={placeHolder}
          onFocus={this.onFocus}
        />
        <div id={this.emptyFocusDivId} onBlur={this.removeAddress1FocusStyle} tabIndex={-1}/>
      </React.Fragment>
    );
  }
}
