import { LocaleHelper } from '@adp-wfn/mdf-core';

declare let require: any;

const meta = require('../components/data/static.json');

export const getStateList = (countryCode: string, isSelectBox?: boolean) => {
  const data = [];

  if (countryCode) {
    const countryJson = meta.countries[countryCode.toUpperCase()] ? meta.countries[countryCode.toUpperCase()] : meta.countries['DEFAULT'];

    if (countryJson && countryJson.fields) {
      const stateField = countryJson.fields.find((fieldObj) => fieldObj.id === 'state');

      if (stateField && stateField.list) {
        const stateList = stateField.list;
        const locale: any = LocaleHelper.getUserLocale().split('-')[0];
        const count: number = stateList.length;

        for (let i = 0; i < count; i++) {
          const option = stateList[i];
          let labelObj = option.labels[0];
          labelObj = option.labels.find((myObj) => myObj.locale === locale);

          if (!labelObj) {
            labelObj = option.labels[0];
          }

          // Push different data object for DropdownList and MDFSelectbox
          if (isSelectBox) {
            data.push({
              value: option.value,
              label: labelObj.value
            });
          }
          else {
            data.push({
              id: option.value,
              name: labelObj.value
            });
          }
        }
      }
    }
  }

  return data;
};
