import React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Card } from '@synerg/vdl-react-components';
import { SdfIcon } from '@waypoint/react-components';

export interface IMDFCardProps {
  // Render this MDFCard as disabled.
  disabled?: boolean;
  // Can this MDFCard be dragged.
  draggable?: boolean;
  // The index of this instance of the Card.
  index?: number;
  // Is this MDFCard selected.
  selected?: boolean;
  // Called when the MDFCard is clicked
  onClick?: (selected: boolean) => void;
}

export class MDFCard extends React.Component<React.PropsWithChildren<IMDFCardProps>, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selected: this.props.selected
    };
  }

  // Look for any update from the props
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.selected, prevState.selected) && !nextProps.disabled) {
      return { selected: nextProps.selected };
    }
  }

  // pass the selected property back to the application to always get the updated value for selected property
  handleClick = (event) => {
    const { onClick } = this.props;

    event.stopPropagation();
    event.nativeEvent?.stopImmediatePropagation();

    onClick?.(!this.state.selected);

    this.setState({
      selected: !this.state.selected
    });
  };

  render() {
    const { selected, disabled, children, onClick, ...passDownProps } = this.props;
    const isADPUnified = !window['isLegacyAppShell'];

    const cardClasses = classNames({
      'mdf-card': true,
      'mdf-card__selected': this.state.selected && !this.props.disabled
    });

    const triangleClasses = classNames({
      triangle: true,
      triangle__selected: this.state.selected,
      triangle__disabled: this.props.disabled,
      triangle__disabled__selected: this.props.disabled && this.state.selected
    });

    const checkClasses = classNames({
      'fa fa-check': !isADPUnified && this.state.selected,
      check: !isADPUnified && this.state.selected,
      'check-waypoint': isADPUnified && this.state.selected,
      hidden: isADPUnified && !this.state.selected,
      check__disabled__selected: this.props.disabled && this.state.selected
    });

    const mdfCardWrapperClasses = {
      'mdf-card__wrapper__disabled': this.props.disabled,
      'mdf-card__disabled__selected': this.props.disabled && this.state.selected
    };

    const mdfCardContent = 'mdf-card-content';

    return (
      <div onClick={!this.props.disabled && this.handleClick} draggable={this.props.draggable}>
        <Card {...passDownProps} className={classNames(cardClasses, mdfCardWrapperClasses)}>
          <div className={triangleClasses} />
          {isADPUnified ? <SdfIcon icon="action-confirm" className={checkClasses}/> : <i className={checkClasses} />}
          <div className={mdfCardContent}>
            {children}
          </div>
        </Card>
      </div>
    );
  }
}
