import React from 'react';
import stickyfill from 'stickyfilljs';
import { GridHelper } from '../../util/GridHelper';

export interface IWindowScrollProps {
  fetchItems?: any;
  pageSize?: any;
  stickyHeader?: boolean;
  children?: any;
  fireScrollFunctions?: any;
  addEventListener?: any;
  enableScroll?: boolean;
}

export default class WindowScroll extends React.Component<IWindowScrollProps, any> {
  private headers;
  private bodies;
  private unlockedHeaderInitialOffsetTop;
  private isHeaderSticky;

  constructor(props) {
    super(props);

    this.state = {
      items: props.items || [],
      isLoadingItems: false,
      enableScroll: this.props.enableScroll || false
    };
  }

  componentDidMount() {
    if (this.props.fetchItems) {
      const startIndex = 0;
      const numberOfRowsToLoad = this.props.pageSize;
      this.props.fetchItems(startIndex, numberOfRowsToLoad);
    }

    // enable scroll true means it is grid scroll we need to call the window scroll when enablescroll is false
    if (!this.state.enableScroll) {
      // Attaching scroll event listener in capturing phase.
      // Scroll events won't bubble up. So if the listener has been attached in bubbling phase then it won't be triggered on MDFSlideIn/MDFSidePanel.
      window.addEventListener('scroll', this.handleWindowScroll, true);
    }

    this.headers = Array.from(GridHelper.getElementsByClassName('vdl-row mdf-grid-header', this));
    this.bodies = Array.from(GridHelper.getElementsByClassName('mdf-grid-scroll-pane', this));
  }

  componentWillUnmount() {
    console.log('WindowScroll.componentWillUnmount(): Removing the handleWindowScroll event listener.');
    window.removeEventListener('scroll', this.handleWindowScroll, true);
  }

  handleWindowScroll = (event) => {
    const grid = GridHelper.getElementsByClassName('mdf-grid-flex', this)[0] as HTMLElement;
    const gridBottom = grid && grid.offsetHeight;

    // default the scrolltop to pick from event.target
    let scrollTop = event.target && event.target.scrollTop;

    // event.target is refering to #document when the grid is not inside the slide-in
    // IE does not support scrollingElement hence using the scrollTop from document.body.
    if (GridHelper.isIE()) {
      // quirks || standard mode
      // get document scrollTop only when the event.target.scrollTop is not available.
      scrollTop = event.target && !scrollTop && (document.body.scrollTop || document.documentElement.scrollTop) || scrollTop;
    }
    else if (event && event.target && event.target.scrollingElement && !scrollTop) {
      scrollTop = event.target.scrollingElement.scrollTop;
    }

    if (this.props.stickyHeader && !this.state.enableScroll) {
      if (!this.unlockedHeaderInitialOffsetTop) {
        this.unlockedHeaderInitialOffsetTop = this.headers[0].offsetTop;
      }

      const heightOfHeader = this.headers[0].offsetHeight;

      if (!this.isHeaderSticky) {
        const topOfHeader = this.headers[0].offsetTop;

        const widthsOfHeaders = this.headers.map((head) => head.offsetWidth);

        if (scrollTop >= topOfHeader) {
          this.headers.forEach((head, index) => {
            head.classList.add('mdf-grid-sticky-header');
            head.style['width'] = widthsOfHeaders[index] + 'px';
            stickyfill.add(head);
          });

          this.isHeaderSticky = true;
        }
      }
      else {
        const topsOfBodies = this.bodies.map((body) => body.getBoundingClientRect().top);

        if (topsOfBodies[0] > heightOfHeader) {
          this.headers.forEach((head) => {
            head.classList.remove('mdf-grid-sticky-header');
            stickyfill.remove(head);
          });

          this.isHeaderSticky = false;
        }
        else {
          const topPixels = scrollTop.toString();

          if (GridHelper.isIE()) {
            this.headers.forEach((header) => {
              header.style['top'] = 0;
            });
          }
          else {
            // The container having `overflow-x: scroll` means the `top` styles need to be different.
            this.headers.forEach((header, index) => {
              header.style['top'] = index ? (topPixels - this.unlockedHeaderInitialOffsetTop) + 'px' : topPixels + 'px';
            });
          }
        }
      }
    }

    if (this.props.fireScrollFunctions) {
      this.props.fireScrollFunctions(scrollTop, gridBottom);
    }
  };

  render() {
    const windowScrollProps = {};

    return (
      <div className={'mdf-grid-window-scroll-container'}>
        {this.props.children(windowScrollProps)}
      </div>
    );
  }
}
