import React, { Children, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MDFCore } from '@adp-wfn/mdf-core';
import { createPortal } from 'react-dom';
import { MDFHelpIcon } from './MDFHelpIcon';
import { MDFVersoView } from './MDFVersoView';
import { WfnFocusPaneClone } from '@adp-wfn/mdf-wc-react';
// import { SdfFocusPane } from '@waypoint/react-components';

export interface IMDFSlideIn2Props {
  // animation?: boolean; // Unused
  // 'aria-label'?: string;
  // 'aria-labelledby'?: string;
  // autoFocus?: boolean; // Unused
  backdrop?: true | false | 'static';
  // backdropClassName?: string; // Unused
  // bsPrefix?: string; // Unused
  // centered?: boolean; // Unused
  children?: React.ReactElement;
  className?: string;
  closeable?: boolean;
  closeButtonLabel?: React.ReactNode;
  // container?: DOMContainer; // Unused
  containerClassName?: string;
  dialogClassName?: string;
  // enforceFocus?: boolean; // Unused
  helpUrl?: string;
  id?: string;
  // keyboard?: boolean; // Unused
  // manager?: ModalManager;
  // onBackdropClick?: (e: React.SyntheticEvent) => void; // Unused
  onClose?: () => void;
  // onEnter?: (node: HTMLElement, isAppearing: boolean) => any; // Unused
  onEntered?: (node: HTMLElement, isAppearing: boolean) => any;
  // onEntering?: (node: HTMLElement, isAppearing: boolean) => any; // Unused
  // onEscapeKeyDown?: (e: KeyboardEvent) => void; // Unused
  // onExit?: (node: HTMLElement) => any; // Unused
  onExited?: (node: HTMLElement) => any;
  // onExiting?: (node: HTMLElement) => any; // Unused
  onHide?: () => void;
  onScrollToTop?: () => void;
  // onShow?: () => void; // Unused
  portalEnabled?: boolean;
  // restoreFocus?: boolean; // Unused
  restoreFocusNodeId?: string;
  // restoreFocusOptions?: { // Unused
  //   preventScroll: boolean;
  // };
  // scrollable?: boolean; // Unused
  scrollToTop?: boolean;
  show?: boolean;
  showHelpIcon?: boolean;
  showSnackBar?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  snackBar?: React.ReactNode;
  // style?: React.CSSProperties; // Unused
  title?: string;
}

export const MDFSlideIn2 = (props: IMDFSlideIn2Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(props.show);
  const [trigger, setTrigger] = useState(null);
  const [counter, setCounter] = useState(0);
  let showFooter = !!(props.snackBar && props.showSnackBar !== false);
  const hasHeading = typeof props.title === 'string';
  const showTitle = !!(props.title && typeof props.title === 'object');
  const focusPane = useRef<HTMLWfnFocusPaneCloneElement>(null);
  const slideInBody = useRef<HTMLDivElement>(null);

  if (MDFCore.shouldLog()) {
    console.warn(`MDFSlideIn2(): ${props.id} Entering. props =`, props);
  }

  const updateCounter = () => {
    setCounter(Date.now());
  };

  const mutationCallback = useCallback((mutationList, _observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        if (MDFCore.shouldLog()) {
          console.warn(`MDFSlideIn2(): ${props.id} Slide-in content updated.`);
        }

        updateCounter();
      }
    }
  }, []);

  const afterOpened = useCallback((event) => {
    props.onEntered?.(event, true);
  }, []);

  const afterClosed = useCallback((event) => {
    setIsShowing(false);
    props.onExited?.(event);

    // Accessibility: Use restoreFocusNodeId property to place focus on a custom element after the slide in closes.
    if (props.restoreFocusNodeId) {
      const id = props.restoreFocusNodeId;
      const customFocusElement = document.getElementById(id) as any;

      if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
        customFocusElement.setFocus?.();
      }
      else if (customFocusElement) {
        customFocusElement.focus?.();
      }
    }
  }, []);

  const dismissed = useCallback(() => {
    props.onHide?.();
  }, []);

  // Check for a MDFVersoView child and snackbars
  let hasVersoView = false;

  Children.forEach(props.children, (child: any) => {
    if (child?.type === MDFVersoView) {
      hasVersoView = true;
    }
  });

  // Passing 'closeSlideIn' property to child views
  const childrenWithProps = Children.map(props.children, (child: any): any => (child ? React.cloneElement(child, { closeSlideIn: props.onClose }) : child));

  const focusPaneProps: any = {
    'back-label': props.closeButtonLabel,
    className: (`mdf ${props.containerClassName ?? ''} ${props.dialogClassName ?? ''}`).trim(),
    closeable: props.closeable,
    'dismiss-on-click-outside': props.backdrop,
    heading: hasHeading ? props.title : undefined,
    'hide-accept-button': true,
    'hide-dismiss-button': true,
    'hide-footer': !showFooter,
    'pane-type': 'anchored',
    size: props.size ?? 'lg',
    onSdfAfterOpen: afterOpened,
    onSdfAfterClose: afterClosed,
    onSdfDismiss: dismissed
  };

  if (props.id) {
    focusPaneProps.id = props.id;
  }

  if (hasVersoView) {
    focusPaneProps.spacing = 'none';
    focusPane['hide-footer'] = true;
    showFooter = false;
  }

  // When a slide-in has dynamic content consisting of MDF views, we cannot
  // detect a SnackBar in the body of the slide-in until after the dynamic
  // content renders. So, we first need to know when the slideInBody is rendered,
  // and then wait a tick for the actual content of the slide-in to render.
  useEffect(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFSlideIn2(): ${props.id} slideInBody.current changed`, slideInBody.current);
    }

    const observer = new MutationObserver(mutationCallback);

    if (slideInBody.current) {
      observer.observe(slideInBody.current, {
        childList: true,
        subtree: true
      });
    }

    updateCounter();

    // Clean up the observer
    return () => observer.disconnect();
  }, [slideInBody.current]);

  useEffect(() => {
    setIsShowing(props.show);
  }, [props.show]);

  useLayoutEffect(() => {
    // The open/close methods need to be run before repainting the screen.
    if (isShowing) {
      // Focus management: Identify element that opens the slide in.
      setTrigger(document.activeElement.id);
      void focusPane.current?.open();
      setIsOpen(true);
    }
    else {
      void focusPane.current?.close();
      setIsOpen(false);

      // Handles focus management upon slide in closing
      if (props.restoreFocusNodeId || trigger) {
        // Checks if a custom focus element (restoreFocusNodeId property) is specified first. If a
        // focus element is not specified, then focus will be moved back to the original trigger element.
        const focusNodeId = props.restoreFocusNodeId ? props.restoreFocusNodeId : trigger;
        const customFocusElement = document.getElementById(focusNodeId) as any;

        if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
          customFocusElement.setFocus?.();
        }
        else if (customFocusElement) {
          customFocusElement.focus?.();
        }
      }
    }
  }, [isShowing]);

  useEffect(() => {
    if (props.scrollToTop) {
      const scrollToContent = slideInBody.current?.parentElement?.parentElement;

      if (scrollToContent) {
        scrollToContent.scrollTop = 0;
        props.onScrollToTop?.();
      }
    }
  }, [props.scrollToTop]);

  function getFooter() {
    if (showFooter) {
      return <div slot="custom-buttons" className="w-full">{props.snackBar}</div>;
    }
    else {
      return null;
    }
  }

  function getHeader() {
    if (showTitle) {
      return <span slot="title">{props.title} {props.showHelpIcon && <MDFHelpIcon helpUrl={props.helpUrl} />}</span>;
    }
    else if (props.showHelpIcon) {
      return <span slot="title">{props.showHelpIcon && <MDFHelpIcon helpUrl={props.helpUrl} />}</span>;
    }
    else {
      return null;
    }
  }

  function renderContent() {
    if (props.show && isOpen) {
      return (
        <React.Fragment>
          {childrenWithProps}
        </React.Fragment>
      );
    }
    else if (!(props.show && isOpen)) {
      return null;
    }
  }

  const renderBody = () => {
    return (
      <React.Fragment>
        {getHeader()}
        <div ref={slideInBody} className={(`mdf-slide-in-body ${hasVersoView ? 'mdf-verso-view-mods' : 'px-4 pb-4'}`).trim()}>{renderContent()}</div>
        {getFooter()}
      </React.Fragment>
    );
  };

  if (MDFCore.shouldLog()) {
    console.warn(`MDFSlideIn2(): ${props.id} current state =`, {
      counter,
      focusPaneProps,
      isOpen,
      isShowing,
      hasHeading,
      hasVersoView,
      showFooter,
      showTitle
    });
  }

  if (props.show || isShowing) {
    if (props.portalEnabled) {
      return createPortal(<WfnFocusPaneClone {...focusPaneProps} ref={focusPane}>{renderBody()}</WfnFocusPaneClone>, document.body);
    }
    else {
      return <WfnFocusPaneClone {...focusPaneProps} ref={focusPane}>{renderBody()}</WfnFocusPaneClone>;
    }
  }
  else {
    return null;
  }
};
