import React from 'react';
import { IWFNMockShellChange } from '../WFNMockShell';
import { Button } from '@synerg/vdl-react-components';

export interface IWFNMockShellMMFeaturesSelectedDetailProps {
  totalChildren?: number;
  selectedChildren?: number;
  renderEditOptions?: boolean;
  renderFeatureSelection?: boolean;
  onEditOptionsClick?: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string;
  featuresSelectedLabel: string;
}

export const WFNMockShellMMFeaturesSelectedDetail = ({ totalChildren, selectedChildren, renderEditOptions, renderFeatureSelection, onEditOptionsClick, editOptionsLabel, featuresSelectedLabel }: IWFNMockShellMMFeaturesSelectedDetailProps) => {
  const featuresSelectedText = renderFeatureSelection ? `${selectedChildren}/${totalChildren} ${featuresSelectedLabel}` : '';

  return (
    <div className="wfnmockshell-features-selected-detail">
      {renderEditOptions && <Button onClick={onEditOptionsClick} buttonStyle={'link'}>{editOptionsLabel}</Button>}
      {renderFeatureSelection && <div className="wfnmockshell-features-selected" title={featuresSelectedText}>{renderEditOptions && <div className="wfnmockshell-features-selected-divisor">|</div>}<div className="wfnmockshell-features-selected-label">{featuresSelectedText}</div></div>}
    </div>
  );
};

WFNMockShellMMFeaturesSelectedDetail.displayName = 'WFNMockShellMMFeaturesSelectedDetail';
