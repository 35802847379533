import { AutoCompleteAddress } from './AutoCompleteAddress';
import { Country } from './Country';
import { DatePicker } from './DatePicker';
import { MDFDropdownList } from './MDFDropdownList';
import { MDFLabeledField } from './MDFLabeledField';
import { MDFNumberBox } from './MDFNumberBox';
import { MDFSelectBox } from './MDFSelectBox';
import { MDFTextarea } from './MDFTextarea';
import { MDFTextbox } from './MDFTextbox';

export const MDFLabeledAutoCompleteAddress = MDFLabeledField(AutoCompleteAddress);
export const MDFLabeledCountry = MDFLabeledField(Country);
export const MDFLabeledDatePicker = MDFLabeledField(DatePicker);
export const MDFLabeledDropdownList = MDFLabeledField(MDFDropdownList);
export const MDFLabeledNumberBox = MDFLabeledField(MDFNumberBox);
export const MDFLabeledSelectBox = MDFLabeledField(MDFSelectBox);
export const MDFLabeledTextarea = MDFLabeledField(MDFTextarea);
export const MDFLabeledTextbox = MDFLabeledField(MDFTextbox);
