import React from 'react';

export interface IMDFProgressTrackerProps {
  // an array of step objects defined by IStep interface
  steps?: IStep[];

  // the title to be used left of the verticle bar
  title: string;

  // function called when user clicks on a step
  onStepClick: (index: number, step: object) => void;

  // id property for ProgressTracker
  id?: string;
}

interface IStep {
  // step title displayed underneath the icon
  title: string;

  // font awesome icon name
  icon?: string;

  // font awesome icon name when step is active
  activeIcon?: string;

  // if this step is currently being viewed by the user
  active?: boolean;

  // if this step has already been seen by the user
  seen?: boolean;

  // causes step to be greyed out
  disabled?: boolean;

  // id property to identify each step/pendo support
  id?: string;
}

export class MDFProgressTracker extends React.Component<IMDFProgressTrackerProps, any> {
  onStepClick = (index, step) => {
    if (!step.disabled && this.props.onStepClick) {
      this.props.onStepClick(index, step);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent, index, step) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();

      this.onStepClick(index, step);
    }
  };

  render() {
    return (
      <div className="progress-tracker-wrapper">
        <div className="pt-title-container">
          <span className="pt-title font-size-h1">{this.props.title}</span>
        </div>
        <div className="pt-steps-wrapper">
          {
            this.props.steps && this.props.steps.map((step, index) => {
              const color = (step.disabled ? 'neutral' : 'accent-0');
              const isFirstStep = (index === 0);
              const isNotLastStep = (index !== this.props.steps.length - 1);
              let icon = step.icon;

              if (step.seen) {
                icon = 'fa-check';
              }

              if (step.active) {
                icon = step.activeIcon || 'fa-eye';
              }

              return (
                <div key={`pt-step-${index}`} className="pt-container">
                  <div className={'pt-step-container' + (step.disabled ? ' disabled' : '')} id={this.props.id && this.props.id + step.id || step.id} onClick={() => this.onStepClick(index, step)} role="button" aria-label={step.title}>
                    {
                      (index > 1) &&
                      <div className={'pt-step-navigation__step-line before ' + color}></div>
                    }
                    <div className={'pt-step-navigation__step-indicator ' + color + (step.active ? ' active' : '') } tabIndex={step.disabled ? -1 : 0} onKeyDown={(e) => this.handleKeyDown(e, index, step)}>
                      <i className={'fa ' + icon + (step.active ? ' accent-0-darker' : ' accent-0-dark')}></i>
                    </div>
                    {isNotLastStep && <div className={'pt-step-navigation__step-line after' + (this.props.steps[index + 1].disabled ? ' neutral' : ' accent-0')}></div>}
                    {isFirstStep && <i className="fa fa-angle-right accent-0 pt-step-arrow"></i>}
                  </div>
                  <span className={'pt-step-title font-size-h5 font-weight-semibold ' + color + (step.disabled ? '' : '-dark') + (step.active ? 'er' : '')}>{step.title}</span>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}
