import React from 'react';
import { MDFLabel } from './MDFLabel';

export function MDFFormValidatedLabel(props: any) {
  const { hasHtml, helpMessage, htmlFor, id, labelText, required } = props;
  let { labelClassName } = props;

  // Class is added to label to sync styles with synergy oneUX web Components
  labelClassName = labelClassName ? labelClassName : '' + ' mdf-form-control-label';

  return <MDFLabel
    className={labelClassName}
    hasHtml={hasHtml}
    htmlFor={htmlFor ? htmlFor : id}
    labelText={labelText}
    required={required}
    placement="top"
    helpMessage={helpMessage}>
  </MDFLabel>;
}
