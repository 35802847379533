import React from 'react';
import PropTypes from 'prop-types';

export interface IWFNXTitleBarProps {
  className?: string;
  children?: React.ReactNode;
}

export class WFNXTitleBar extends React.Component<IWFNXTitleBarProps, any> {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const { className, children } = this.props;
    const classnames = `wfnx-titleBar ${className}`;

    return (
      <div className={classnames}>{children}</div>
    );
  }
}
