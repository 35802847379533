import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';
import { repeater } from '../util/repeater';
import { number as numberLocalizer } from '@synerg/vdl-react-components/lib/util/localizers';
import { MDFNumberInput } from './MDFNumberInput';
import { SdfIcon } from '@waypoint/react-components';
import { mdfRound } from '../util/mdfRound';
import { notify } from '@synerg/vdl-react-components/lib/util/widget-helpers';
import vdlPrefix from '../util/vdlPrefix';
import MinusIcon from 'adp-react-icons/lib/fa/minus';
import PlusIcon from 'adp-react-icons/lib/fa/plus';
import { generateId } from '@adp-wfn/mdf-core';
import { TimeoutManager } from '@synerg/vdl-react-components/lib/util/timeout-manager';
import { handleBlur, handleFocus, IFocusMixinProps } from '@synerg/vdl-react-components/lib/util/focus-mixin';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

const directions = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  UP: 'UP',
  DOWN: 'DOWN'
};

const format = (props) => numberLocalizer.getFormat('default', props.format);

export type INumberSpinnerDirection = 'LEFT' | 'RIGHT' | 'UP' | 'DOWN';

export interface INumberSpinnerMessages {
  increment?: React.ReactNode;
  decrement?: React.ReactNode;
}

export interface INumberSpinnerProps {
  id?: string;
  value?: number;
  onChange?: (value?: number) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
  culture?: string;
  format?: any;
  name?: string;
  parse?: (str: string, culture?: string) => number;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  messages?: INumberSpinnerMessages;
  placeholder?: string;
  onKeyDown?: (event) => void;
  onKeyPress?: (event) => void;
  onKeyUp?: (event) => void;
  tabIndex?: number;
  className?: string;
  immediate?: boolean;
  ariaLabel?: string;
  'aria-label'?: string;
}

export interface INumberSpinnerState {
  focused?: boolean;
  active?: INumberSpinnerDirection | boolean;
}

const isADPUnified = !window['isLegacyAppShell'];

export class MDFNumberSpinner extends React.PureComponent<INumberSpinnerProps, INumberSpinnerState> {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    precision: PropTypes.number,
    culture: PropTypes.string,
    format: PropTypes.any,
    name: PropTypes.string,
    parse: PropTypes.func,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    messages: PropTypes.shape({
      increment: PropTypes.node,
      decrement: PropTypes.node
    }),
    placeholder: PropTypes.string,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    tabIndex: PropTypes.number,
    immediate: PropTypes.bool,
    ariaLabel: PropTypes.string
  };

  _cancelRepeater?: () => void;
  _input: MDFNumberInput;
  _element: HTMLElement;
  _isMounted = false;
  _timeoutManager = new TimeoutManager();

  static defaultProps: INumberSpinnerProps = {
    min: -Infinity,
    max: Infinity,
    step: 1,
    precision: 0,
    messages: {
      increment: isADPUnified ? <SdfIcon icon="plus" /> : <PlusIcon />,
      decrement: isADPUnified ? <SdfIcon icon="minus" /> : <MinusIcon />
    },
    immediate: true
  };

  state: INumberSpinnerState = {
    focused: false,
    active: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._timeoutManager.clearAllTimeouts();
  }

  setTimeout = (key, callback, duration) => {
    if (this._isMounted) {
      return this._timeoutManager.setTimeout(key, callback, duration);
    }
  };

  validate = (strVal: string) => {
    // get the decimalSeperator for the locale
    const delimChar = MDFNumberInput.getlocaleChar(this.props);

    // When decimals other "." is entered replace the decimal character before parsing the value.
    strVal = strVal.replace(delimChar, '.');

    // Parsing string to number before sending it to onChange.
    const numValue = this.constrainValue(parseFloat(strVal));

    if (this.props.onChange) {
      this.props.onChange(numValue);
    }
  };

  handleBlur = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleBlur(focusMixinProps, event);
  };

  handleFocus = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    handleFocus(focusMixinProps, event, undefined, (_focused) => {
      this.focus();
    });
  };

  // allow for styling, focus stealing keeping me from the normal what have you
  private mouseDown = (dir: INumberSpinnerDirection) => {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    const method = (dir === directions.UP) ? this.increment : this.decrement;

    this.setState({ active: dir });

    const val = method.call(this);

    if (!((dir === directions.UP && val >= this.props.max) || (dir === directions.DOWN && val <= this.props.min))) {
      if (!this._cancelRepeater) {
        this._cancelRepeater = repeater(this.mouseDown.bind(null, dir));
      }
    }
    else {
      this.mouseUp();
    }
  };

  private mouseUp = () => {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    this.setState({ active: false });
    this._cancelRepeater?.();
    this._cancelRepeater = undefined;
  };

  private keyDown = (e) => {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    const key = e.key;

    notify(this.props.onKeyDown, [e]);

    if (e.defaultPrevented) {
      return;
    }

    if (key === 'End' && isFinite(this.props.max)) {
      e.preventDefault();
      this.change(this.props.max);
    }
    else if (key === 'Home' && isFinite(this.props.min)) {
      e.preventDefault();
      this.change(this.props.min);
    }
    else if (key === 'PageUp') {
      e.preventDefault();
      this.incrementFive();
    }
    else if (key === 'PageDown') {
      e.preventDefault();
      this.decrementFive();
    }
    else if (key === 'ArrowDown') {
      e.preventDefault();
      this.decrement();
    }
    else if (key === 'ArrowUp') {
      e.preventDefault();
      this.increment();
    }
  };

  private focus = () => {
    (ReactDOM.findDOMNode(this._input) as HTMLElement).focus();
  };

  private incrementFive = () => this.step(this.props.step * 5);

  private decrementFive = () => this.step(-this.props.step * 5);

  private increment = () => this.step(this.props.step);

  private decrement = () => this.step(-this.props.step);

  private step = (amount) => {
    const value = (this.props.value || 0) + amount;
    const decimals = (this.props.precision != null) ? this.props.precision : numberLocalizer.precision(format(this.props));

    this.change((decimals != null) ? mdfRound(value, decimals) : value);
    return value;
  };

  private change = (val) => {
    this.validate(val.toString());
  };

  private constrainValue = (value) => {
    const max = this.props.max == null ? Infinity : this.props.max;
    const min = this.props.min == null ? -Infinity : this.props.min;

    if (value === undefined || value === null || value === '') {
      return undefined;
    }

    return Math.max(Math.min(value, max), min);
  };

  render() {
    const {
      className,
      onKeyPress,
      onKeyUp,
      disabled,
      readOnly,
      tabIndex
    } = this.props;

    const messages = this.props.messages;
    const customProps = omit(this.props, Object.keys(MDFNumberSpinner.propTypes), 'aria-labelledby', 'aria-label');

    const val = this.constrainValue(this.props.value);
    const id = this.props.id || generateId('number-spinner');

    const numberSpinnerClasses = {
      [vdlPrefix('number-spinner')]: true,
      [vdlPrefix('number-spinner--focus')]: this.state.focused,
      [vdlPrefix('number-spinner--disabled')]: disabled,
      [vdlPrefix('number-spinner--readonly')]: readOnly
    };

    const numberInputClasses = [
      vdlPrefix('number-spinner__input')
    ];

    const decrementButtonClasses = {
      [vdlPrefix('number-spinner__button')]: true,
      [vdlPrefix('number-spinner__button--decrement')]: true,
      [vdlPrefix('number-spinner__button--active')]: this.state.active === directions.DOWN
    };

    const incrementButtonClasses = {
      [vdlPrefix('number-spinner__button')]: true,
      [vdlPrefix('number-spinner__button--increment')]: true,
      [vdlPrefix('number-spinner__button--active')]: this.state.active === directions.UP
    };

    const ariaLabel = resolveAriaProperty('MDFNumberSpinner', 'aria-label', 'ariaLabel', this.props);

    return (
      <div
        {...customProps as any}
        ref={(c) => this._element = c}
        onKeyDown={this.keyDown}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        tabIndex={-1}
        role="group"
        className={classNames(className, numberSpinnerClasses)}
      >
        <button
          id={`${id}_decrement`}
          style={{ outline: 'none' }}
          tabIndex={-1}
          aria-hidden={true}
          type="button"
          className={classNames(decrementButtonClasses)}
          onMouseDown={this.mouseDown.bind(null, directions.DOWN)}
          onMouseUp={this.mouseUp.bind(null, directions.DOWN)}
          onMouseLeave={this.mouseUp.bind(null, directions.DOWN)}
          onClick={this.handleFocus}
          disabled={val === this.props.min || this.props.disabled}
          aria-disabled={val === this.props.min || this.props.disabled}
        >
          {messages.decrement}
        </button>
        <MDFNumberInput
          ref={(c) => this._input = c}
          id={id}
          className={classNames(numberInputClasses)}
          tabIndex={disabled || readOnly ? -1 : tabIndex || 0}
          placeholder={this.props.placeholder}
          value={val}
          autoFocus={this.props.autoFocus}
          autoFormat={true}
          editing={this.state.focused}
          format={this.props.format}
          culture={this.props.culture}
          parse={this.props.parse}
          decimal={this.props.precision}
          name={this.props.name}
          role="spinbutton"
          min={this.props.min}
          aria-valuenow={val}
          aria-valuemin={isFinite(this.props.min) ? this.props.min : null}
          aria-valuemax={isFinite(this.props.max) ? this.props.max : null}
          aria-disabled={this.props.disabled}
          aria-readonly={this.props.readOnly}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          aria-label={ariaLabel}
          aria-labelledby={this.props['aria-labelledby']}
          onChange={this.change}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          immediate={this.props.immediate}
        />
        <button
          style={{ outline: 'none' }}
          id={`${id}_increment`}
          tabIndex={-1}
          aria-hidden={true}
          type="button"
          className={classNames(incrementButtonClasses)}
          onMouseDown={this.mouseDown.bind(null, directions.UP)}
          onMouseUp={this.mouseUp.bind(null, directions.UP)}
          onMouseLeave={this.mouseUp.bind(null, directions.UP)}
          onClick={this.handleFocus}
          disabled={val === this.props.max || this.props.disabled}
          aria-disabled={val === this.props.max || this.props.disabled}
        >
          {messages.increment}
        </button>
      </div>
    );
  }
}
