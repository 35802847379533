import React from 'react';
import { IWFNMockShellMMLevel3Item } from '../types';
import { Checkbox } from '@synerg/vdl-react-components';
import { IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellMMFeaturesSelectedDetail } from './WFNMockShellMMFeaturesSelectedDetail';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellMMLevel3ItemProps extends IWFNMockShellMMLevel3Item {
  handleCheck?: any;
  extended?: boolean;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string
}

export const WFNMockShellMMLevel3Item = ({ name, oid, extended, renderCheckbox, isSelected, disableCheckbox, onWFNShellChange, totalChildren, selectedChildren, renderEditOptions, renderFeatureSelection, editOptionsLabel }: IWFNMockShellMMLevel3ItemProps) => {
  const handleLevel3ItemChange = (type: 'menuLevel3Link' | 'menuLevel3Checkbox' | 'menuLevel3EditOptions', value?: boolean) => {
    onWFNShellChange({
      item: {
        oid,
        name
      },
      type,
      value
    });
  };

  const level3ItemDetailClassName = (!renderEditOptions && !renderFeatureSelection) ? 'wfnmockshell-level3-item-detail single-line' : 'wfnmockshell-level3-item-detail';

  return (
    <React.Fragment>
      <div className={level3ItemDetailClassName}>
        {renderCheckbox && <Checkbox onChange={handleLevel3ItemChange.bind(null, 'menuLevel3Checkbox')} checked={isSelected} disabled={disableCheckbox} />}
        <div className="wfnmockshell-level3-item-detail-text">
          <button title={name} type="button" className="wfnmockshell-level3-item-detail--button" onClick={handleLevel3ItemChange.bind(null, 'menuLevel3Link')}>{name}</button>
          <WFNMockShellMMFeaturesSelectedDetail totalChildren={totalChildren} selectedChildren={selectedChildren} renderEditOptions={renderEditOptions} renderFeatureSelection={renderFeatureSelection} onEditOptionsClick={handleLevel3ItemChange.bind(null, 'menuLevel3EditOptions')} editOptionsLabel={editOptionsLabel} featuresSelectedLabel={FormatHelper.formatMessage('@@label_wysiwyg_selected')} />
        </div>
      </div>
      {extended && <li style={{ display: 'none' }} title={name}/>}
    </React.Fragment>
  );
};

WFNMockShellMMLevel3Item.displayName = 'WFNMockShellMMLevel3Item';
