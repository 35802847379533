import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';
import warning from 'warning';
import vdlPrefix from '../util/vdlPrefix';
import { Validations } from './Validations';
import { FormatHelper } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

let didWarnValueDefaultValue = false;

export interface ITextboxValidationObject {
  valid: boolean;
  message: string;
}

export interface ITextboxProps {
  ariaInvalid?: any;
  'aria-invalid'?: any;
  ariaRequired?: boolean;
  'aria-required'?: boolean;
  ariaLabel?: string;
  'aria-label'?: string;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
  errorId?: string;
  type?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean | 'selectAll';
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  onBlur?: (value?) => void;
  onFocus?: () => void;
  required?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  autoComplete?: string;
  immediate?: boolean;
  takeFocus?: boolean;
  tabIndex?: number;
  selectAllOnFocus?: boolean;
  limitRegExp?: RegExp;
  validationObject?: ITextboxValidationObject;
}

export interface ITextboxState {
  initialValue?: string;
  value?: string;
  hasFocus?: boolean;
  isTouched?: boolean;
  takeFocus?: boolean;
}

export class Textbox extends React.PureComponent<ITextboxProps, ITextboxState> {
  static propTypes = {
    ariaInvalid: PropTypes.bool,
    ariaRequired: PropTypes.bool,
    ariaDescribedby: PropTypes.string,
    ariaLabel: PropTypes.string,
    errorId: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.oneOf([true, false, 'selectAll']),
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    autoComplete: PropTypes.string,
    tabIndex: PropTypes.number,
    immediate: PropTypes.bool,
    takeFocus: PropTypes.bool,
    selectAllOnFocus: PropTypes.bool,
    validationObject: PropTypes.shape({
      valid: PropTypes.bool,
      message: PropTypes.string
    })
  };

  static defaultProps: ITextboxProps = {
    type: 'text',
    autoFocus: false
  };

  state: ITextboxState = {
    hasFocus: false,
    isTouched: false,
    takeFocus: false
  };

  _input: HTMLInputElement;

  componentDidUpdate() {
    if (this.state.takeFocus) {
      this.takeFocus();
    }
  }

  static getDerivedStateFromProps(nextProps: ITextboxProps, currentState: ITextboxState) {
    const nextState: ITextboxState = {};

    if ((nextProps.value !== undefined) && (nextProps.defaultValue !== undefined) && !didWarnValueDefaultValue) {
      warning(false,
        'Input elements must be either controlled or uncontrolled ' +
        '(specify either the value prop, or the defaultValue prop, but not ' +
        'both). Decide between using a controlled or uncontrolled input ' +
        'element and remove one of these props. More info: ' +
        'https://fb.me/react-controlled-components'
      );

      didWarnValueDefaultValue = true;
    }

    if (currentState.initialValue === undefined) {
      nextState.initialValue = nextProps.value;
      nextState.value = nextProps.value;
    }

    if (nextProps.immediate) {
      // If nextProps.immediate is true, then onChange fires on every change, so the new value should reflect the current state.
      if (nextProps.value !== currentState.value) {
        nextState.value = nextProps.value;
      }
    }
    else {
      // If nextProps.immediate is not true, then onChange fires only on blur, so nextProps.value will not match the current state,
      // so we need to ignore the reflected value, unless it is different from the initial value, which shows that the app updated
      // the value property instead of the user.
      if (nextProps.value !== currentState.initialValue || !currentState.hasFocus) {
        // If the app somehow changed the value, then we need to reflect that.
        nextState.initialValue = nextProps.value;
        nextState.value = nextProps.value;
      }
      else {
        // Otherwise, make sure the current state stays the current state.
        nextState.value = currentState.value;
      }
    }

    if (nextProps.takeFocus !== undefined && nextProps.takeFocus !== currentState.takeFocus) {
      nextState.takeFocus = nextProps.takeFocus;
    }

    if (Object.keys(nextState).length > 0) {
      return nextState;
    }
    else {
      return null;
    }
  }

  getValue = () => this.state.value;

  hasFocus = () => this.state.hasFocus;

  isTouched = () => this.state.isTouched;

  private handleChange = (event) => {
    // There are several React bugs in IE,
    // where the `input`'s `onChange` event is
    // fired even when the value didn't change.
    // https://github.com/facebook/react/issues/2185
    // https://github.com/facebook/react/issues/3377

    const newValue = event.target.value;
    let isAllowedValue = true;

    if (this.props.limitRegExp) {
      isAllowedValue = (new RegExp(this.props.limitRegExp)).test(newValue) || newValue === '';
    }

    if (isAllowedValue && newValue !== this.state.value) {
      this.setState({
        value: newValue,
        isTouched: true
      });

      if (this.props.onChange && this.props.immediate) {
        this.props.onChange(newValue);
      }
    }
  };

  blur = () => {
    if (this._input) {
      this._input.blur();
    }
  };

  private handleBlur = () => {
    this.setState({
      hasFocus: false,
      isTouched: true
    });

    if (this.props.immediate && this.props.onBlur) {
      this.props.onBlur(this.state.value);
    }
    else if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };

  private handleFocus = () => {
    this.setState(
      {
        hasFocus: true,
        isTouched: true
      },
      () => {
        if (this.props.selectAllOnFocus) {
          this.takeFocus();
        }

        if (this.props.onFocus) {
          this.props.onFocus();
        }
      }
    );
  };

  private onInputMounted = (input) => {
    this._input = input;

    if (!input) {
      return;
    }

    if (this.state.takeFocus) {
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }

    if (this.props.autoFocus === 'selectAll') {
      try {
        input.setSelectionRange(0, input.value.length);
      }
      catch (ex) {
        // ignore
      }
    }
  };

  private handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  private takeFocus = () => {
    if (this._input) {
      this._input.focus();
      this._input.setSelectionRange(0, this._input.value.length);
    }
  };

  renderAriaLabel = (value) => {
    let label;
    const regex = /-[1-9][0-9]*/g;

    // Convert number to string
    const stringVal = (typeof value !== 'string') ? value.toString() : value;
    const ariaLabel = resolveAriaProperty('Textbox', 'aria-label', 'ariaLabel', this.props);

    // The aria-label may show a negative number accessibility message and/or optional ariaLabel props.
    if (stringVal?.match(regex) && ariaLabel) {
      label = FormatHelper.formatMessage('@@OneOrMoreNegativeNumbers') + ' ' + ariaLabel;
    }
    else if (stringVal?.match(regex) && !ariaLabel) {
      label = FormatHelper.formatMessage('@@OneOrMoreNegativeNumbers');
    }
    else if (ariaLabel && !stringVal?.match(regex)) {
      label = ariaLabel;
    }

    return label;
  };

  render() {
    const {
      errorId,
      className,
      disabled,
      readOnly,
      required,
      autoFocus,
      placeholder,
      minLength,
      maxLength,
      type,
      name,
      autoComplete
    } = this.props;
    const ariaInvalid = resolveAriaProperty('Textbox', 'aria-invalid', 'ariaInvalid', this.props);
    const ariaRequired = resolveAriaProperty('Textbox', 'aria-required', 'ariaRequired', this.props);

    // Convert null to an empty string for display purposes.
    const value = Validations.isExisty(this.state.value) ? this.state.value : '';

    const textBoxClasses = {
      [vdlPrefix('textbox')]: true,
      [vdlPrefix('textbox--touched')]: this.state.isTouched
    };

    const customProps = omit(this.props, Object.keys(Textbox.propTypes));
    const ariaDescribedby = resolveAriaProperty('Textbox', 'aria-describedby', 'ariaDescribedby', this.props);

    return (
      <input
        aria-invalid={ariaInvalid}
        aria-required={ariaRequired || required}
        aria-describedby={ariaDescribedby || errorId}
        aria-label={this.renderAriaLabel(value)}
        {...customProps}
        className={classNames(className, textBoxClasses)}
        ref={this.onInputMounted}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={this.handleChange}
        readOnly={readOnly}
        minLength={minLength}
        maxLength={maxLength}
        autoFocus={!!autoFocus}
        autoComplete={autoComplete}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        placeholder={placeholder}
        onClick={this.handleClick}
        tabIndex={this.props.tabIndex}
      />
    );
  }
}
