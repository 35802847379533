import React from 'react';

export interface IMDFTableProps {
  // Accessibility feature used to indicate the table contents. Screen readers announce this descriptive content to help users decide if they want to read it. Some screen readers use captions as the primary mechanism to identify tables.
  caption?: string;
  // CSS class name used to override styling.
  className?: string;
  // Array of strings for header cells.
  header?: string[];
  // Array of arrays representing the rows for the body.
  body: string[][];
  // Array of strings for footer cells.
  footer?: string[];
}

export const MDFTable = (props: IMDFTableProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const direction = event.keyCode;
    const arrowLeft = 37;
    const arrowUp = 38;
    const arrowRight = 39;
    const arrowDown = 40;

    if (direction === arrowLeft || direction === arrowUp || direction === arrowRight || direction === arrowDown) {
      const target = event.currentTarget;
      const column = target.getAttribute('data-column');
      const nextCell = target.nextSibling;
      const previousCell = target.previousSibling;
      const nextRow = target.parentElement.nextSibling;
      const previousRow = target.parentElement.previousSibling;
      const nextTableSection = target.parentElement.parentElement.nextElementSibling;
      const previousTableSection = target.parentElement.parentElement.previousElementSibling;
      let nextElement;

      const moveFocus = (nextItem) => {
        nextItem.focus();
      };

      if (direction === arrowRight) {
        if (nextCell) {
          nextElement = nextCell;
        }
        else if (nextRow) {
          nextElement = nextRow.firstChild;
        }
        else if (nextTableSection) {
          nextElement = nextTableSection.firstChild.firstChild;
        }
      }

      if (direction === arrowLeft) {
        if (previousCell) {
          nextElement = previousCell;
        }
        else if (previousRow) {
          nextElement = previousRow.lastChild;
        }
        else if (previousTableSection) {
          nextElement = previousTableSection.lastChild.lastChild;
        }
      }

      if (direction === arrowDown) {
        if (nextRow) {
          nextElement = nextRow.childNodes[column];
        }
        else if (nextTableSection) {
          nextElement = nextTableSection.firstChild.childNodes[column];
        }
      }

      if (direction === arrowUp) {
        if (previousRow) {
          nextElement = previousRow.childNodes[column];
        }
        else if (previousTableSection) {
          nextElement = previousTableSection.lastChild.childNodes[column];
        }
      }

      if (nextElement) {
        moveFocus(nextElement);
      }
    }
  };

  const renderHeader = () => {
    return (props.header &&
      <thead>
        <tr>
          {
            props.header.map((item, index) => {
              return <th key={index} scope="col" tabIndex={0} data-column={index} onKeyDown={(event) => handleKeyDown(event)}>{item}</th>;
            })
          }
        </tr>
      </thead>
    );
  };

  const renderRow = (cells) => {
    return (
      cells.map((item, index) => {
        return <td key={index} tabIndex={0} data-column={index} onKeyDown={(event) => handleKeyDown(event)}>{item}</td>;
      })
    );
  };

  const renderBody = () => {
    return (
      <tbody>
        {
          props.body.map((item, index) => {
            return <tr key={index}>{renderRow(item)}</tr>;
          })
        }
      </tbody>
    );
  };

  const renderFooter = () => {
    return (props.footer &&
      <tfoot>
        <tr>
          {
            props.footer.map((item, index) => {
              return <td key={index} tabIndex={0} data-column={index} onKeyDown={(event) => handleKeyDown(event)}>{item}</td>;
            })
          }
        </tr>
      </tfoot>
    );
  };

  const classname = props.className ? 'mdf-table ' + props.className : 'mdf-table';
  const { caption } = props;

  return (
    <div className={classname}>
      <table>
        <caption className="vdl-invisible">{caption}</caption>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </table>
    </div>
  );
};

MDFTable.displayName = 'MDFTable';
