import React from 'react';
import PropTypes from 'prop-types';
import { IOverlayTriggerProps, IPopoverProps, OverlayTrigger, Popover } from '@synerg/vdl-react-components';
import { generateId } from '@adp-wfn/mdf-core';

export type IStatusType = 'success' | 'info' | 'warning' | 'error' | 'default';

export interface IOverlayPopover extends IOverlayTriggerProps {
  disabled: boolean;
  className: string;
  children: any[];
  type: IStatusType;
  useOverlayContent?: boolean;
}

export interface IMDFPopoverProps extends IPopoverProps {
  containerClassName?: string;
}

export class MDFPopoverContent extends React.Component<IMDFPopoverProps, any> {
  static propTypes = Object.assign({}, Popover.propTypes, { containerClassName: PropTypes.string });

  render() {
    return <div>{this.props.children}</div>;
  }
}

export class MDFOverlayContent extends React.Component<any, any> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export class MDFOverlayPopoverComponent extends React.Component<IOverlayPopover, any> {
  actionOverlay: any;
  popoverId: any;

  constructor(props: IOverlayPopover) {
    super(props);
    this.popoverId = generateId('MDFPopover');
  }

  popoverToggle = (event) => {
    if (this.props.onToggle) {
      this.props.onToggle(event);
    }
  };

  popoverClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  // close the popup if the target has a className of popup-closer
  closeOverlay = (e) => {
    const target = e.target;
    const parentTarget = e.target.offsetParent;

    if (target && (target.classList.contains('popup-closer') || parentTarget.classList.contains('popup-closer'))) {
      this.actionOverlay.hide();
    }
  };

  render() {
    let popover: MDFPopoverContent;
    let overlay: MDFOverlayContent;

    React.Children.forEach(this.props.children, (child: any): any => {
      if (child.type === MDFPopoverContent) {
        popover = child;
      }
      else if (child.type === MDFOverlayContent) {
        overlay = child;
      }
    });

    if (!popover || !overlay) {
      return null;
    }

    const { children, ...rest } = this.props;

    // remove the containerClassName property before passing it to Popover component
    const { containerClassName, id, ...customProps } = popover.props;
    const popoverId = id || this.popoverId;

    return (
      <div className={`mdf-overlay-popover ${this.props.className || ''}`}>
        {!this.props.disabled &&
          <OverlayTrigger
            {...rest}
            overlay={
              <Popover {...customProps} id={popoverId} className={`mdf-overlay-popover ${containerClassName || ''} vdl-popover--${this.props.type || ''}`}>
                <div onClick={this.closeOverlay}>
                  {popover.props.children}
                </div>
              </Popover>
            }
            ref={(actionOverlay) => this.actionOverlay = actionOverlay}
            onClick={this.popoverClick}
            onToggle={this.popoverToggle}
            hideOnWindowResize
            flip={true}
          >
            {this.props.useOverlayContent ? overlay : (overlay.props.children?.length ? overlay.props.children[0] : overlay.props.children)}
          </OverlayTrigger>
        }
        {this.props.disabled && overlay.props.children}
      </div>
    );
  }
}
