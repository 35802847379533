import React from 'react';
import { ComponentManager } from '@adp-wfn/mdf-core';
import { Accordion } from '@synerg/vdl-react-components';

export class JourneyMap extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { items, mainView, viewMap } = this.props;
    const myViewMap = viewMap[mainView];
    const HeadingView = ComponentManager.getComponent(myViewMap.heading);
    const ItemView = ComponentManager.getComponent(myViewMap.items);
    const itemViewMap = myViewMap.viewMap;

    return (
      <>
        <HeadingView/>
        <Accordion className={'journeyItemAccordion'}>
          {items && items.map((item, index) => {
            // Transitional code until the JSON file is fully updated.
            const itemProps = item.properties || item;
            return <ItemView key={`ji${index}`} eventKey={`ji${index}`} viewMap={itemViewMap} {...itemProps}/>;
          })}
        </Accordion>
      </>
    );
  }
}
