import React from 'react';

import { InfiniteList } from './InfiniteList';
import { SearchBox } from './SearchBox';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IInfiniteSearchProps {
  tabIndex?: number;
  pageSize: number;
  placeHolder: string;
  fetchItems: (startIndex: number, count: number, query: string, resolve: (items: any[], updatedItemCount: number) => void) => void;
  contentModel?: (index: number, item: any) => any;
  renderItem: (index, item) => React.ReactNode;
  rowCount?: number;
  ariaLabel?: string;
  'aria-label'?: string;
}

interface IInfiniteSearchState {
  query: string;
}

export class InfiniteSearch extends React.Component<IInfiniteSearchProps, IInfiniteSearchState> {
  private infiniteList: InfiniteList;

  state: IInfiniteSearchState = {
    query: ''
  };

  constructor(props: IInfiniteSearchProps) {
    super(props);
  }

  private setInfiniteList = (infiniteList: InfiniteList) => {
    this.infiniteList = infiniteList;
  };

  private queryChanged = (query: string) => {
    this.setState({ query }, () => this.infiniteList.reset());
  };

  private fetchItems = (startIndex: number, count: number, resolve: (items: any[], updatedItemCount: number) => void) => {
    this.props.fetchItems(startIndex, count, this.state.query, resolve);
  };

  render() {
    const ariaLabel = resolveAriaProperty('InfiniteSearch', 'aria-label', 'ariaLabel', this.props);

    return (
      <div className="mdf-infinitesearch-container">
        <div className="mdf-infinitesearch-searchbox">
          <SearchBox aria-label={ariaLabel} tabIndex={this.props.tabIndex} placeHolder={this.props.placeHolder} query={this.state.query} onQueryChange={this.queryChanged} />
        </div>
        <div className="mdf-infinitesearch-list">
          <InfiniteList tabIndex={this.props.tabIndex} ref={this.setInfiniteList} rowCount={this.props.rowCount} pageSize={this.props.pageSize} fetchItems={this.fetchItems} contentModel={this.props.contentModel} renderItem={this.props.renderItem} />
        </div>
      </div>
    );
  }
}
