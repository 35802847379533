import React from 'react';
import PropTypes from 'prop-types';
import { ITextareaProps, Textarea } from '@synerg/vdl-react-components';
import { FormatHelper } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IMDFTextareaProps extends ITextareaProps {
  ariaLabel?: string;
  'aria-label'?: string;
  height?: string;
  immediate?: boolean;
  labelText?: string;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
}

export class MDFTextarea extends React.Component<IMDFTextareaProps, any> {
  static propTypes = {
    ariaLabel: PropTypes.string,
    ariaDescribedby: PropTypes.string,
    height: PropTypes.string,
    immediate: PropTypes.bool,
    labelText: PropTypes.string
  };

  static displayName = 'MDFTextarea';

  private textarea: any;

  private setTextarea = (textarea: any) => {
    this.textarea = textarea;
  };

  onBlur = () => {
    if (this.props.immediate && this.props.onBlur) {
      const value = this.textarea.getValue();
      this.props.onBlur(value);
    }
    else if (this.props.onChange) {
      const value = this.textarea.getValue();
      this.props.onChange(value);
    }
  };

  onChange = (value) => {
    if (this.props.immediate && this.props.onChange) {
      this.props.onChange(value);
    }
  };

  renderAriaLabel = (value) => {
    let label;
    const regex = /-[1-9][0-9]*/g;

    // Convert number to string
    const stringVal = (typeof value !== 'string') ? value?.toString() : value;

    const ariaLabel = resolveAriaProperty('MDFTextarea', 'aria-label', 'ariaLabel', this.props);

    // The aria-label may show a negative number accessibility message and/or optional ariaLabel props.
    if (stringVal?.match(regex) && ariaLabel) {
      label = FormatHelper.formatMessage('@@OneOrMoreNegativeNumbers') + ' ' + ariaLabel;
    }
    else if (stringVal?.match(regex) && !ariaLabel) {
      label = FormatHelper.formatMessage('@@OneOrMoreNegativeNumbers');
    }
    else if (ariaLabel && !stringVal?.match(regex)) {
      label = ariaLabel;
    }

    return label;
  };

  render() {
    const { immediate, ariaLabel, ...passDownProps } = this.props;
    const ariaDescribedby = resolveAriaProperty('MDFTextarea', 'aria-describedby', 'ariaDescribedby', this.props);

    return (
      <Textarea
        {...passDownProps}
        aria-label={this.renderAriaLabel(this.props.value)}
        aria-describedby={ariaDescribedby}
        ref={this.setTextarea}
        onBlur={this.onBlur}
        onChange={this.onChange}
      />
    );
  }
}
