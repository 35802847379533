import React, { useContext, useEffect, useRef, useState } from 'react';
import { IWFNMockShellRoot } from '../types';
import { IWFNMockShellActivePopover, IWFNMockShellChange, PopoverExemptionContext } from '../WFNMockShell';
import { Checkbox } from '@synerg/vdl-react-components';
import { WFNMockShellDisabledOverlay } from '../WFNMockShellDisabledOverlay';
import { WFNMockShellToolbarPopoverNav } from '../Toolbar/WFNMockShellToolbarPopoverNav';
import { WFNMockShellPopoverDetail } from '../Toolbar/WFNMockShellPopoverDetail';
import { WFNMockShellPopoverInstructionalText } from '../Toolbar/WFNMockShellPopoverInstructionalText';
import { MDFPopupDialog } from '../../MDFPopupDialog';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNMockShellMMSearchbarProps {
  shellData: IWFNMockShellRoot;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  activePopover: IWFNMockShellActivePopover;
  megaMenuActive: boolean;
}

export const WFNMockShellMMSearchbar = ({ shellData, activePopover, onWFNShellChange, megaMenuActive }: IWFNMockShellMMSearchbarProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverClassName = showPopover ? 'wfnmockshell-wrapper wfnmockshell-popover searchbar-popover' : 'wfnmockshell-wrapper wfnmockshell-popover searchbar-popover popover-hidden'; // hide popover while it unmounts

  const [target, setTarget] = useState() as any;
  const containerRef = useRef();
  const targetRef = useRef();
  const popoverExemptionChange = useContext(PopoverExemptionContext).popoverExemptionChange;
  const handlePopoverClose = useContext(PopoverExemptionContext).handlePopoverClose;

  // Set refs after initial rendering.
  useEffect(() => {
    setTarget(targetRef.current);

    popoverExemptionChange([containerRef.current]);
    return () => popoverExemptionChange([containerRef.current], true);
  }, []);

  useEffect(() => {
    setShowPopover(!megaMenuActive && activePopover.type === 'searchbar');
  }, [activePopover, megaMenuActive]);

  const handleSearchboxCheckboxChange = (value) => {
    onWFNShellChange({
      item: {
        oid: shellData.search.oid,
        name: shellData.search.name
      },
      type: 'searchBoxCheckbox',
      value
    });
  };

  const handleOverlayTriggerClick = (event) => {
    const clickedNode = event.target;
    const containerNode = containerRef.current as any;

    if (clickedNode === containerNode || containerNode?.contains(clickedNode)) {
      activePopover.handlePopoverClick({
        ...activePopover,
        type: 'searchbar',
        renderNextBtn: false,
        renderPrevBtn: true
      });
    }
  };

  const popover = (
    <MDFPopupDialog title={shellData.search.name} onClose={() => handlePopoverClose()} className={popoverClassName} isOpen={showPopover} targetId={shellData.search.oid} targetRef={target}>
      <WFNMockShellPopoverInstructionalText instructionalText={shellData.search.instructionalText} />
      <WFNMockShellPopoverDetail popoverType="searchBar" childList={shellData.search.childList} onWFNShellChange={onWFNShellChange} />
      <WFNMockShellToolbarPopoverNav type="searchbar" activePopover={activePopover} />
    </MDFPopupDialog>
  );

  return (
    shellData.search.renderSearchBar ?
      <div ref={containerRef} className="wfnmockshell-search" onClick={(event) => handleOverlayTriggerClick(event)}>
        <WFNMockShellDisabledOverlay disabled={shellData.search.disabled} />
        {(shellData.search.renderCheckbox && !shellData.search.disabled) && <Checkbox checked={shellData.search.isSelected} onChange={handleSearchboxCheckboxChange} disabled={shellData.search.disableCheckbox} />}
        <input ref={targetRef} id={shellData.search.oid} type="text" placeholder={FormatHelper.formatMessage('@@label_SearchWorkforceNow') + ':'} readOnly={true} />
        <button id="magnifyingGlass"><i className="fa fa-search" /></button>
        {popover}
      </div> :
      null
  );
};

WFNMockShellMMSearchbar.displayName = 'WFNMockShellMMSearchbar';
