import React, { useEffect, useRef, useState } from 'react';
import { Placement } from '@synerg/vdl-react-components';
import { MDFSimpleHelpIcon } from './MDFSimpleHelpIcon';
import { MDFLegacyHelpIcon } from './MDFLegacyHelpIcon';

export interface IMDFLabelProps {
  // The value used for the data-adp-field-id attribute required by the Field Grabber.
  adpFieldId?: string;
  // Deprecated for accessibility. Aria-label for the help icon.
  'aria-label'?: string;
  // A CSS classname to override the styles of this component.
  className?: string;
  // Child nodes to add inside this component.
  children?: React.ReactNode;
  // Whether the label contains html.
  hasHtml?: boolean;
  // helpMessage
  helpMessage?: any;
  // Set the htmlFor attribute.
  htmlFor?: string;
  // Set an ID attribute.
  id?: string;
  // The text for the label.
  labelText?: string;
  // help icon placement
  placement?: Placement;
  // Display the required field indicator.
  required?: boolean;
}

export const MDFLabel = ({ adpFieldId, className, children, hasHtml, helpMessage, htmlFor, labelText, id, placement, required, ...props }: IMDFLabelProps) => {
  const [isAriaLive, setAriaLive] = useState(false);
  const previousRef = useRef(labelText);
  const HelpIcon = window['isLegacyAppShell'] ? MDFLegacyHelpIcon : MDFSimpleHelpIcon;

  // add aria-live polite only when label values changes.
  useEffect(() => {
    if (labelText === previousRef.current) {
      setAriaLive(false);
    }
    else if (previousRef.current && (previousRef.current !== labelText)) {
      setAriaLive(true);
    }
  }, [labelText]);

  const labelProps: any = {
    className,
    htmlFor,
    id
  };

  if (isAriaLive) {
    labelProps['aria-live'] = 'polite';
  }

  if (adpFieldId) {
    labelProps['data-adp-field-id'] = adpFieldId;
  }

  return (
    <div className="mdf-label mb-2 flex items-center">
      <label {...labelProps}>
        {hasHtml ? <span dangerouslySetInnerHTML={{ __html: labelText }} /> : labelText}
        {required && <span className="mdf-required-indicator">*</span>}
        {children}
      </label>
      {helpMessage &&
        <HelpIcon
          portalEnabled={true}
          placement={placement || 'top-start'}
          aria-label={props['aria-label'] ? props['aria-label'] : labelText }
          helpMessage={helpMessage}
        />
      }
    </div>
  );
};

MDFLabel.displayName = 'MDFLabel';
