import React from 'react';
import CountrySelect from './CountrySelect';
import 'react-phone-number-input/style.css';
import Phone from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

// A <NativePhone/> with Mobile support for countrySelect
import NativePhone from 'react-phone-number-input/mobile';
import parsePhoneNumber, { AsYouType, isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { DeviceHelper, FormatHelper, MDFCore } from '@adp-wfn/mdf-core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MDFFlag } from './MDFFlag';

export class MDFPhone extends React.Component<any, any> {
  private useDevicePicker = false;

  static propTypes = Object.assign({
    country: PropTypes.string,
    isValid: PropTypes.bool,
    adaptive: PropTypes.bool,
    phone: PropTypes.string,
    isExt: PropTypes.bool,
    ext: PropTypes.any,
    showCountrySelect: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool
  });

  static defaultProps = {
    showCountrySelect: true
  };

  constructor(props: any) {
    super(props);

    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();

    this.state = {
      country: props.country ? props.country : 'US',
      phone: (typeof props.phone === 'object' ? props.phone.phone : props.phone) || '',
      ext: (typeof props.phone === 'object' ? props.phone.ext : props.ext) || '',
      formattedPhone: (typeof props.phone === 'object' ? props.phone.formattedPhone : props.formattedPhone) || ''
    };
  }

  static displayName = 'MDFPhone';

  // Since we are fetching the isValid and validationMessage from the field in MDFValidatedField - moved it out of state to match the numberbox and datepicker.
  isValid = true;
  // We treat all "possible" numbers as valid, but we will return the information from isValidPhoneNumber, even though it's possible the strict validation is out of date.
  isStrictlyValid = true;
  validationMessage = '';

  componentDidMount() {
    this.validate(this.state.phone);
  }

  validate = (value) => {
    let phone = value;

    if (phone === undefined) {
      phone = this.state.phone;
    }

    if (phone) {
      const isValid = isValidPhoneNumber(phone, this.state.country);
      const isPossible = isPossiblePhoneNumber(phone, this.state.country);

      if (MDFCore.shouldLog()) {
        console.log(`MDFPhone.validate(): phone = ${phone}, isValid = ${isValid}, isPossible = ${isPossible}`);
      }

      // Treat all "possible" numbers as valid, but provide the stricter validation
      // result so applications can decide whether or not to use it. "Possible" numbers
      // make sure that changes that haven't made it into the validation library are
      // still valid entries while waiting for the library to catch up. For example,
      // the library doesn't understand the 861 US area code and some numbers in the
      // 505 US area code.
      this.isValid = isPossible;
      this.isStrictlyValid = isValid;

      // Reject the change if the country is Cuba, Iran, North Korea, Russia, Sudan, South Sudan, and Syria
      if (['CU', 'IR', 'KP', 'RU', 'SD', 'SS', 'SY'].includes(this.state.country)) {
        this.isValid = false;
        this.isStrictlyValid = false;
      }

      if (this.isValid) {
        this.validationMessage = '';
      }
      else {
        this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfInvalidData')}`;

        this.setState(() => {
          this.handleChange();
        });
      }
    }
    else {
      this.isValid = true;
    }
  };

  isValidComponent = () => (
    {
      isValid: this.isValid,
      isStrictlyValid: this.isStrictlyValid,
      validationMessage: this.validationMessage,
      value: this.state.phone
    }
  );

  // Update the phone State when new props is updated also validate the data and format the same.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UNSAFE_componentWillReceiveProps(nextProps) {
    const phone = (typeof nextProps.phone === 'object' ? nextProps.phone.phone : nextProps.phone) || '';
    const ext = (typeof nextProps.phone === 'object' ? nextProps.phone.ext : nextProps.ext) || '';

    if (this.state.phone !== phone) {
      // Validate the component only after the phone state is updated
      this.validate(phone);
      this.setState({ phone: phone });
    }

    if (this.state.ext !== ext) {
      this.setState({ ext: ext });
    }

    if (nextProps.country && this.state.country !== nextProps.country) {
      this.setState({ country: nextProps.country });
    }
  }

  // Onload when a value is set and onBlur isValidPhoneNumber checks for the country code (+1)
  // Do not validate when phone number is empty
  handleBlur = () => {
    this.validate(this.state.phone);
  };

  handleChange = () => {
    if (this.props.onChange) {
      this.props.onChange({ ...this.state }, this.isValid, this.validationMessage);
    }
  };

  // This is called on blur of phone and extension input, excluding the flag country select button onBlur(which is unnecessary).
  wrapperOnBlur = (event) => {
    if (event.target.tagName === 'INPUT') {
      let formattedPhone;
      let phoneInfo;
      let country = this.state.country || '';

      try {
        phoneInfo = parsePhoneNumber(this.state.phone, this.state.country);
        formattedPhone = phoneInfo.formatInternational();
        country = this.state.country || phoneInfo.country;
      }
      catch (e) {
        // catch the error
      }

      if (MDFCore.shouldLog()) {
        console.log(`MDFPhone.wrapperOnBlur(): formattedPhone = ${formattedPhone}, phoneInfo =`, phoneInfo);
      }

      const newState = {
        formattedPhone: formattedPhone,
        country: country
      };

      this.setState(newState, () => {
        const phoneObject = Object.assign({}, { ...this.state }, { isValid: this.isValid, isStrictlyValid: this.isStrictlyValid, validationMessage: this.validationMessage });

        if (this.props.onBlur) {
          this.props.onBlur(phoneObject);
        }

        this.handleChange();
      });
    }
  };

  // Extension on Change
  private extOnChange = (e) => {
    const val = e.target.value;

    if (val === '' || this.isNumber(val)) {
      this.setState({ ext: val });
    }
  };

  // Check to see if the value entered is a number
  private isNumber = (val) => {
    const re = /^[0-9\b]*$/;
    return re.test(val);
  };

  countryChange = (country) => {
    this.setState({ country: country });
  };

  formatPhoneNumber = (value) => {
    try {
      const countryVal = this.props.country ? this.props.country : 'US';
      const parsedNumber = parsePhoneNumber(value, countryVal);
      return (new AsYouType(parsedNumber.country).input(parsedNumber.number));
    }
    catch (e) {
      // AsYouType will throw an exception if the phone number is invalid. return the original text if that happens.
      return value;
    }
  };

  render() {
    const {
      className,
      disabled,
      readOnly,
      required,
      autoFocus,
      placeholder,
      extPlaceholder,
      country,
      ext,
      isExt,
      title,
      phone,
      showCountrySelect,
      'aria-labelledby': ariaLabelledby,
      ...customProps
    } = this.props;
    let extension;

    // is Readonly
    const isReadOnly = readOnly || false;

    // readOnly text "Ext" .
    const extPlaceholderText = this.props.extPlaceholder ? this.props.extPlaceholder : `${FormatHelper.formatMessage('@@label_ei9_enroll_phone_ext')}. `;

    // Render Extension input
    if (isExt) {
      extension = (
        <div className={'mdf-Phone ext'}>
          <input
            className="vdl-textbox"
            type={'tel'}
            placeholder={extPlaceholderText}
            value={this.state.ext}
            disabled={this.props.disabled}
            maxLength={7}
            onChange={this.extOnChange}
            aria-label={extPlaceholderText}
          />
        </div>
      );
    }

    return (
      <div role="group" aria-labelledby={this.props['aria-labelledby']} className={classNames(className)} tabIndex={-1} onBlur={this.wrapperOnBlur}>
        {isReadOnly &&
          <div className="mdf-Phone-readOnly">
            <span className="mdf-Phone-icon"><i className="fa fa-mobile" /></span>
            <span className="mdf-Phone-input">{this.formatPhoneNumber(this.state.phone)}</span>
            {isExt &&
              <span className="mdf-Phone-input">{extPlaceholderText}{this.state.ext}</span>
            }
          </div>
        }
        {!isReadOnly &&
          <div className={(!disabled && !this.isValid) ? 'mdf-Phone dial vdl-validation-error' : 'mdf-Phone dial'}>
            {this.useDevicePicker ?
              <NativePhone
                {...customProps}
                placeholder={this.props.placeholder ? this.props.placeholder : `${FormatHelper.formatMessage('@@PhoneNumber')}`}
                aria-label={this.props['aria-label'] ? this.props['aria-label'] : FormatHelper.formatMessage('@@PhoneNumber')}
                defaultCountry={this.state.country}
                onCountryChange={this.countryChange}
                value={this.state.phone}
                nativeCountrySelect={true}
                disabled={disabled}
                addInternationalOption={false}
                onChange={(phoneComponent) => this.setState({ phone: phoneComponent })}
                onBlur={this.handleBlur}
                flagComponent={(countryFlag) => <MDFFlag phoneFlag={true} country={countryFlag && countryFlag.country} />}
              /> :
              showCountrySelect &&
              <Phone
                {...customProps}
                placeholder={this.props.placeholder ? this.props.placeholder : `${FormatHelper.formatMessage('@@PhoneNumber')}`}
                aria-label={this.props['aria-label'] ? this.props['aria-label'] : FormatHelper.formatMessage('@@PhoneNumber')}
                defaultCountry={this.state.country}
                countrySelectComponent={CountrySelect}
                onCountryChange={this.countryChange}
                autoFocus={this.props.autoFocus}
                value={this.state.phone}
                disabled={disabled}
                addInternationalOption={false}
                onChange={(value) => this.setState({ phone: value })}
                onBlur={this.handleBlur}
                flagComponent={(countryFlag) => <MDFFlag phoneFlag={true} country={countryFlag && countryFlag.country} />}
              /> ||
              <Input
                {...customProps}
                className={'PhoneInputInput'}
                placeholder={this.props.placeholder ? this.props.placeholder : `${FormatHelper.formatMessage('@@PhoneNumber')}`}
                aria-label={this.props['aria-label'] ? this.props['aria-label'] : FormatHelper.formatMessage('@@PhoneNumber')}
                defaultCountry={this.state.country}
                value={this.state.phone}
                disabled={disabled}
                autoFocus={this.props.autoFocus}
                onChange={(value) => this.setState({ phone: value })}
                onBlur={this.handleBlur} />
            }
          </div>
        }
        {!isReadOnly && extension}
      </div>
    );
  }
}
