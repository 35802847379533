import React from 'react';
import classNames from 'classnames';
import { StatusIcon } from './StatusIcon';
import { MDFPopover } from './MDFPopover';
import { OverlayTrigger, Popover } from '@synerg/vdl-react-components';

export interface IStatusOverlay {
  // Size of the statusIcon
  size?: 'sm' | 'md';

  // className to support styles
  className?: any[];

  // support popover children from the application
  children?: any;

  // class to be applied for the icons
  iconClass?: string;

  // choose one of the statusType to show the overlay.
  statusType?: 'success' | 'info' | 'warning' | 'error';
}

export function MDFStatusOverlay(props: any) {
  const renderPopover = () => {
    let popover: any;

    React.Children.forEach(props.children, (child: any): any => {
      if (child.type === MDFPopover) {
        popover = child;
      }
    });

    return (
      popover ?
        <Popover id={'popoverOverlay'} {...popover.props} className={`mdf-overlay-popover vdl-popover--${props.statusType || ''}`}>
          {popover.props.children && popover.props.children}
        </Popover> : null
    );
  };

  const renderCircleIcon = () => <StatusIcon size={props.size} className={classNames(props.className)} statusType={props.statusType} />;

  // wrap circleIcon with the div to render the popover on the div
  // When no popover child is passed render only the circleIcon
  return (
    renderPopover() ?
      <OverlayTrigger
        trigger={['hover', 'focus', 'click']}
        placement={'bottom'}
        overlay={renderPopover()}
      >
        <div tabIndex={0}>
          {renderCircleIcon()}
        </div>
      </OverlayTrigger> :
      <div tabIndex={0}>
        {renderCircleIcon()}
      </div>
  );
}
