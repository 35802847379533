import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@synerg/vdl-react-components';
import classNames from 'classnames';
import { MDFSimpleHelpIcon } from './MDFSimpleHelpIcon';

export interface ICheckboxSetProps {
  className?: string;
  items?: { id: any; label: string; checked: boolean; disabled: boolean; helpMessage?: string }[];
  onChange: (option, checked) => void;
  isHorizontal?: boolean;
}

export class CheckboxSet extends React.Component<ICheckboxSetProps, any> {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func,
    isHorizontal: PropTypes.bool
  };

  checkboxOnChange = (option, itemChecked) => {
    // set the checked value to option before sending it to the application
    // Copy the object before modifying the object property
    const optionData = option && option.hasOwnProperty('checked') && Object.assign({}, { ...option, checked: itemChecked });
    this.props.onChange(optionData, itemChecked);
  };

  renderCheckbox = () => (this.props.items && this.props.items.map((option, i) => (
    <div className={'mdf-checkbox'} key={option.id}>
      <Checkbox className={this.props.className ? this.props.className : ''} onChange={(checked) => this.checkboxOnChange(option, checked)} checked={this.props.items && this.props.items[i].checked} disabled={this.props.items && this.props.items[i].disabled}>
        <span>{option.label}{option.helpMessage && <MDFSimpleHelpIcon placement={'bottom'} helpMessage={option.helpMessage} aria-label={option.label}/>}</span>
      </Checkbox>
    </div>
  )));

  render() {
    const { className, isHorizontal } = this.props;

    return (
      <div className={classNames(`mdf-checkboxSet${isHorizontal ? '--horizontal' : ''}`, className)}>
        {this.renderCheckbox()}
      </div>
    );
  }
}
