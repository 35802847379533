import React from 'react';
import PropTypes from 'prop-types';
import { ComponentManager } from '@adp-wfn/mdf-core';
import classNames from 'classnames';
import vdlPrefix from '../util/vdlPrefix';
import CaretIcon from 'adp-react-icons/lib/fa/caret-left';
import { omit } from 'lodash';

export interface ISidebarItemProps {
  // The label to use in the sidebar item.
  label?: React.ReactNode;
  // The icon to display in the sidebar item.
  icon?: React.ReactNode;
  // The id to appear in the HTML list item element (<li></li>)
  id?: string;
  // Is this item active.
  active?: boolean;
  // Called when a user selects this item.
  onSelect: () => void;
  // A CSS class name for overriding styles.
  className?: string;
  // The view to load when this item is selected.
  customItem?: string;
}

export class MDFCustomSidebarItem extends React.Component<ISidebarItemProps, {} > {
  static propTypes = {
    label: PropTypes.node,
    icon: PropTypes.node,
    id: PropTypes.string,
    active: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
    customItem: PropTypes.string
  };

  render() {
    const { active } = this.props;
    const sidebarItemClasses = {
      [vdlPrefix('sidebar-item')]: true,
      [vdlPrefix('sidebar-item--active')]: active
    };

    const customProps = omit(this.props, Object.keys(MDFCustomSidebarItem.propTypes));
    const CustomView = ComponentManager.getComponent(this.props.customItem);

    return (
      <li {...customProps} className={classNames(sidebarItemClasses)}>
        <CustomView />
        {active && <CaretIcon className={vdlPrefix('sidebar-item__active-indicator')}/> }
      </li>
    );
  }
}
