import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { MDFButton } from '../MDFButton';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IWFNXFilterProps {
  className?: string;
  filters?: IFilterCategory[];
  onCategory?: (categoryName: string, filters: IFilterCategory[]) => void;
  onCategoryItem?: (categoryName: string, itemName: string, filters: IFilterCategory[]) => void;
  onClearAll?: (filters: IFilterCategory[]) => void;
  onCollapse?: () => void;
  onExpand?: () => void;
  collapsed?: boolean;
}

export interface IFilterItem {
  name: string;
  value: boolean;
}

export interface IFilterCategory {
  name: string;
  expanded: boolean;
  items: IFilterItem[];
}

export class WFNXFilter extends React.Component<IWFNXFilterProps, any> {
  static propTypes = {
    className: PropTypes.string,
    filters: PropTypes.array,
    onCategory: PropTypes.func,
    onCategoryItem: PropTypes.func,
    onClearAll: PropTypes.func,
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func,
    collapsed: PropTypes.bool
  };

  state = {
    collapsed: this.props.collapsed,
    filters: this.props.filters,
    prevAppFilters: this.props.filters
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.filters, state.prevAppFilters)) {
      return {
        filters: props.filters,
        prevAppFilters: props.filters
      };
    }
    return null;
  }

  handleCollapseClick = (event: any) => {
    event.stopPropagation();

    this.setState({
      collapsed: !this.state.collapsed
    });

    if (this.state.collapsed) {
      if (this.props.onCollapse) {
        this.props.onCollapse();
      }
    }
    else {
      if (this.props.onExpand) {
        this.props.onExpand();
      }
    }
  };

  handleCategoryClick = (event: any, name: string) => {
    event.stopPropagation();

    this.toggleCategory(name);

    if (this.props.onCategory) {
      this.props.onCategory(name, this.state.filters);
    }
  };

  handleCategoryItemClick = (event: any, category: string, item: string) => {
    event.stopPropagation();

    this.toggleCategoryItem(category, item);

    if (this.props.onCategoryItem) {
      this.props.onCategoryItem(category, item, this.state.filters);
    }
  };

  handleClearAllClick = (event: any) => {
    event.stopPropagation();

    this.clearAllItems();

    if (this.props.onClearAll) {
      this.props.onClearAll(this.state.filters);
    }
  };

  clearAllItems = () => {
    const filters = this.state.filters;

    for (const filter of filters) {
      for (const item of filter.items) {
        item.value = false;
      }
    }

    this.setState({ filters: filters });
  };

  toggleCategory = (categoryName: string) => {
    const filters = this.state.filters;

    for (const f of filters) {
      if (f.name === categoryName) {
        f.expanded = !f.expanded;
      }
    }

    this.setState({ filters: filters });
  };

  toggleCategoryItem = (categoryName: string, itemName: string) => {
    const filters = this.state.filters;

    for (const filter of filters) {
      if (filter.name === categoryName) {
        for (const item of filter.items) {
          if (item.name === itemName) {
            item.value = !item.value;
          }
        }
      }
    }

    this.setState({ filters: filters });
  };

  selectedItemNames = (): string[] => {
    const selected: string[] = [];
    const filters = this.state.filters;

    for (const filter of filters) {
      for (const item of filter.items) {
        if (item.value) {
          // Note - Storing the selected items here in the format of 'category name' : 'item name'
          // This makes it easy for the applied filters to be turned off when clicked.
          selected.push(filter.name + ':' + item.name);
        }
      }
    }

    return selected;
  };

  renderItems = (category: string, items: any): any => (
    items.map((item, index) => (
      <li key={index} onClick={(event) => this.handleCategoryItemClick(event, category, item.name)} title={item.name}>
        <div className="list-item-content">
          <i className={item.value ? 'fa fa-check-square' : 'fa fa-square-o'}></i>
          <div className="text">{item.name}</div>
        </div>
      </li>
    ))
  );

  renderCollapseControl = () => {
    if (!this.state.collapsed) {
      return <div className="collapse-control-open" onClick={this.handleCollapseClick}><i className="fa fa-chevron-left"/></div>;
    }
    else {
      return <div className="collapse-control-closed" onClick={this.handleCollapseClick}><i className="fa fa-chevron-right"/><span>{FormatHelper.formatMessage('@@Retropay_Input_Slider_Filters') || 'filters'}</span></div>;
    }
  };

  renderAppliedFilterContainer = () => {
    const selectedItems = this.selectedItemNames();
    const style: CSSProperties = { left: this.state.collapsed ? '125px' : '290px' };

    if (selectedItems.length) {
      return <div className="applied-filters-container" style={style}>{this.renderAppliedFilterItems()}</div>;
    }
  };

  renderAppliedFilterItems = () => {
    const selectedItems: string[] = this.selectedItemNames();
    let items: string[] = [];
    let remaining = 0;

    if (selectedItems.length > 4) {
      items = selectedItems.slice(0, 4);
      remaining = selectedItems.length - items.length;
    }
    else {
      items = selectedItems;
    }

    const filters = items.map((name, index) => {
      // Note - Pairs array below will have category name in position 0 and item name in position 1.
      // See selectedItemNames() above.
      const pairs: string[] = name.split(':');
      return <div key={index} className="applied-filter">{pairs[1]}<i className="fa fa-times" onClick={(event) => this.handleCategoryItemClick(event, pairs[0], pairs[1])}/></div>;
    });

    if (remaining > 0) {
      filters.push(<div className="remaining-filters">{remaining} {FormatHelper.formatMessage('@@More') || 'more'}</div>);
    }

    return filters;
  };

  render() {
    const { className } = this.props;

    const sections: any = this.state.filters.map((item, index) => (
      <div key={index}>
        <div className="category-container" onClick={(event) => this.handleCategoryClick(event, item.name)}>
          <div className="icon"><i className={item.expanded ? 'fa fa-caret-down' : 'fa fa-caret-right'}></i></div>
          <div className="name">{item.name}</div>
        </div>
        {item.expanded ? (<ul>{this.renderItems(item.name, item.items)}</ul>) : null}
        <div className="separator"/>
      </div>
    ));

    return (
      <div className={classNames('wfnx-filter', className)}>
        {this.renderCollapseControl()}
        {this.renderAppliedFilterContainer()}
        <div className={this.state.collapsed ? 'wfnx-filter filter-collapsed' : 'wfnx-filter filter-expand'}>
          <div className="filter-content">
            <div className="title-wrapper">
              <div className="title-container">{FormatHelper.formatMessage('@@Retropay_Input_Slider_Filters') || 'filters'}</div>
              <div className="button-container">
                <MDFButton buttonStyle="secondary" onClick={this.handleClearAllClick}>{FormatHelper.formatMessage('@@SODClearAll') || 'clear all'}</MDFButton>
              </div>
            </div>
            <div className="separator"/>
            {sections}
          </div>
        </div>
      </div>
    );
  }
}
