import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IDateComponentProps {
  children: string | Date;
  format: string;
  hidden?: boolean;
  locale?: string;
}

export const DateComponent = (props: IDateComponentProps) => {
  const { format, children, locale, ...rest } = props;
  let formattedValue;

  if (typeof children === 'string' || children instanceof Date) {
    formattedValue = FormatHelper.formatDate(children, format, locale);
  }

  return (!props.hidden && <span {...rest} >{formattedValue || ''}</span>);
};

DateComponent.displayName = 'Date';
