import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormatHelper, LocaleHelper, MDFCore } from '@adp-wfn/mdf-core';
import { Checkbox } from '@synerg/vdl-react-components';
import { cloneDeep, get, isEqual } from 'lodash';
import { MDFLabel } from './MDFLabel';
import { IMDFFieldState } from './FieldState';
import { MDFLabeledDatePicker, MDFLabeledSelectBox, MDFLabeledTextbox } from './MDFLabeledComponents';
import { GenericValidator } from '../validators/GenericValidator';
import { ValidationContainerContext } from './MDFContext';

declare let require: any;

export enum LayoutType {
  Column,
  Row
}

export interface ITaxTypeConfig {
  label?: string;
  value?: string;
  country?: string;
  regExp?: string;
  format?: string;
  notSpecified?: boolean;
  appliedFor?: boolean;
  expireDate?: boolean;
}

export interface ITaxConfig {
  type?: string;
  id?: string;
  reEnterId?: string;
  notSpecified?: boolean;
  appliedFor?: boolean;
  expireDate?: Date;
  nid?: string;
}

export interface ITaxIdProps {
  name?: string;
  country?: string;
  required?: boolean;
  isNewTaxId?: boolean;
  disabled?: boolean;
  disableTaxType?: boolean;
  layoutType?: LayoutType;
  hideAppliedFor?: boolean;
  hideNotSpecified?: boolean;
  hideNationalIdentifier?: boolean;
  additionalTypes?: string[];
  restrictedTypes?: string[];
  taxDetails?: ITaxConfig;
  validateOnRender?: boolean;
  onTaxChange: (value) => void;
  fetchTaxId?: () => void;
  autoFocus?: boolean;
  adaptive?: boolean;
}

export interface ITaxIdState {
  types?: any[];
  country?: string;
  isNewTaxId?: boolean;
  disabled?: boolean;
  disableTaxType?: boolean;
  hideAppliedFor?: boolean;
  hideNotSpecified?: boolean;
  hideNationalIdentifier?: boolean;
  additionalTypes?: string[];
  restrictedTypes?: string[];
  taxDetails?: ITaxConfig;
  validationMessages?: any;
  isValid?: boolean;
}

export const LABEL_KEYS = {
  TAXID_TYPE: '@@TaxIdType',
  TAXID: '@@TaxId',
  APPLIED_FOR: '@@AppliedFor',
  NOT_PROVIDED: '@@NotProvided',
  SIN_EXPIRYDATE: '@@SINExpiryDate',
  REENTERTAXIDLABEL: '@@ReEnterTaxIdLabel',
  NATIONAL_IDENTIFIER: '@@NationalIdentifier',
  NATIONAL_IDENTIFIER_HELP: '@@NationalIdentifier_Help'
};

const AUTO_FORMAT_TAXIDS = {
  SIN: GenericValidator.formatSIN,
  EIN: GenericValidator.formatEIN,
  ITIN: GenericValidator.formatITIN,
  SSN: GenericValidator.formatSSN
};

export class TaxId extends React.Component<ITaxIdProps, IMDFFieldState> {
  declare context: React.ContextType<typeof ValidationContainerContext>;
  static contextType = ValidationContainerContext;

  static propTypes = {
    country: PropTypes.string,
    required: PropTypes.bool,
    taxDetails: PropTypes.any,
    onTaxChange: PropTypes.func,
    fetchTaxId: PropTypes.func,
    validationMessages: PropTypes.any
  };

  state: IMDFFieldState = {
    value: null,
    isValid: true,
    validationMessage: '',
    showPopover: false
  };

  static taxIds: any = require('./data/taxid.json');
  static countryTaxIdsMapping: any = require('./data/countrytaxmapping.json');
  static countryList: any = require('./data/countries.json');
  private readonly countries: any = [];
  private readonly nidCountries: any = [];
  private currentTaxConfig: any;

  constructor(props) {
    super(props);
    this.countries = this.getCountries();
    // DE562928 - United States should not be in National Identifier drop list if Tax ID Type is National Identifier
    this.nidCountries = this.countries.filter((item) => item.value !== 'US');
    let taxTypes = TaxId.getCountryTaxTypes(props.country);

    if (props.additionalTypes && props.additionalTypes.length > 0) {
      taxTypes = TaxId.getAdditionalTaxTypes(props.additionalTypes)
        .reduce((taxAcc, currentItem) => {
          if (taxAcc.filter((item) => item.value === currentItem.value).length === 0) {
            taxAcc.push(currentItem);
          }

          return taxAcc;
        }, taxTypes);
    }

    if (props.restrictedTypes && props.restrictedTypes.length > 0) {
      taxTypes = taxTypes?.filter((item) => !props.restrictedTypes.includes(item.value));
    }

    let taxConfig: ITaxConfig;

    if (props.taxDetails) {
      taxConfig = cloneDeep(props.taxDetails);
    }
    else {
      taxConfig = {
        type: taxTypes[0].value,
        id: '',
        reEnterId: '',
        expireDate: null,
        notSpecified: false,
        appliedFor: false,
        nid: ''
      };
    }

    const initialValue = {
      isNewTaxId: (props.isNewTaxId !== false),
      disabled: !!props.disabled,
      disableTaxType: !!props.disableTaxType,
      hideAppliedFor: !!props.hideAppliedFor,
      hideNotSpecified: !!props.hideNotSpecified,
      hideNationalIdentifier: !!props.hideNationalIdentifier,
      hideLabels: !!props.hideLabels,
      additionalTypes: props.additionalTypes || [],
      restrictedTypes: props.restrictedTypes || [],
      country: props.country,
      types: taxTypes,
      taxDetails: taxConfig,
      validationMessages: {},
      isValid: (props.isNewTaxId === false),
      required: !!props.required
    };

    initialValue.isValid = this.validateComponent(initialValue, props.required, props.validateOnRender);

    this.state = {
      value: initialValue,
      isValid: props.isValid === false ? props.isValid : initialValue.isValid,
      validationMessage: props.serverMessage || '',
      showPopover: false
    };

  }

  componentWillReceiveProps(nextProps) {
    let propsHasChanged = false;
    const isNewTaxId = (nextProps.isNewTaxId !== false);
    const disabled = !!nextProps.disabled;
    const disableTaxType = !!nextProps.disableTaxType;
    const hideAppliedFor = !!nextProps.hideAppliedFor;
    const hideNotSpecified = !!nextProps.hideNotSpecified;
    const hideNationalIdentifier = !!nextProps.hideNationalIdentifier;
    const additionalTypes = nextProps.additionalTypes || [];
    const restrictedTypes = nextProps.restrictedTypes || [];
    const fieldState: any = { ...this.state };
    const nextState: any = { ...fieldState.value };

    if (nextState.isNewTaxId !== isNewTaxId) {
      propsHasChanged = true;
      nextState.isNewTaxId = isNewTaxId;
    }

    if (nextState.disabled !== disabled) {
      propsHasChanged = true;
      nextState.disabled = disabled;
    }

    if (nextState.disableTaxType !== disableTaxType) {
      propsHasChanged = true;
      nextState.disableTaxType = disableTaxType;
    }

    if (nextState.hideAppliedFor !== hideAppliedFor) {
      propsHasChanged = true;
      nextState.hideAppliedFor = hideAppliedFor;
    }

    if (nextState.hideNotSpecified !== hideNotSpecified) {
      propsHasChanged = true;
      nextState.hideNotSpecified = hideNotSpecified;
    }

    if (nextState.hideNationalIdentifier !== hideNationalIdentifier) {
      propsHasChanged = true;
      nextState.hideNationalIdentifier = hideNationalIdentifier;
    }

    if (nextState.country !== nextProps.country) {
      propsHasChanged = true;
      nextState.country = nextProps.country;
    }

    if (nextState.required !== nextProps.required) {
      propsHasChanged = true;
      nextState.required = nextProps.required;
    }

    nextState.types = TaxId.getCountryTaxTypes(nextProps.country);

    if (!isEqual(nextState.additionalTypes, additionalTypes)) {
      propsHasChanged = true;
      nextState.additionalTypes = additionalTypes;
    }

    if (additionalTypes.length > 0) {
      nextState.types = TaxId.getAdditionalTaxTypes(additionalTypes)
        .reduce((taxAcc, currentItem) => {
          if (taxAcc.filter((item) => item.value === currentItem.value).length === 0) {
            taxAcc.push(currentItem);
          }

          return taxAcc;
        }, nextState.types);
    }

    if (!isEqual(nextState.restrictedTypes, restrictedTypes)) {
      propsHasChanged = true;
      nextState.restrictedTypes = restrictedTypes;
    }

    if (restrictedTypes.length > 0) {
      nextState.types = nextState.types?.filter((item) => !restrictedTypes.includes(item.value));
    }

    if (!isEqual(nextState.taxDetails, nextProps.taxDetails)) {
      propsHasChanged = true;
      nextState.taxDetails = cloneDeep(nextProps.taxDetails);
      nextState.validationMessages = {};
    }

    if (propsHasChanged) {
      fieldState.isValid = nextState.isValid = this.validateComponent(nextState, nextProps.required);
      fieldState.value = nextState;
      this.updateField(nextState, fieldState.isValid, fieldState.validationMessage);
    }
  }

  componentDidMount() {
    if (this.context.registerField) {
      if (!this.props.name) {
        console.error('Validated fields must have a name attribute!');
      }

      // Attaching the component to the form
      this.context.registerField(this);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      // Detaching when unmounted
      this.context.unregisterField(this);
    }
  }

  componentDidUpdate() {
    if (this.context.updateFieldMeta) {
      this.context.updateFieldMeta(this);
    }
  }

  private setField = () => {
    if (this.props.validateOnRender && (this.state.isValid === false)) {
      this.updateField(this.state.value, this.state.isValid, this.state.validationMessage);
    }
  };

  updateField = (value, isValid = null, validationMessage = null) => {
    const validState = this.validate(value, isValid, validationMessage);

    if (this.context.updateFieldState) {
      this.context.updateFieldState(this.props.name, validState, this.updateState);
    }

    this.updateState(validState);

    if (this.props.onTaxChange) {
      this.props.onTaxChange(validState.value);
    }
  };

  updateState = (newState: any) => {
    this.setState(() => ({ ...newState }));
  };

  validate = (value, isValid, validationMessage, showPopover?): IMDFFieldState => (
    // Validation stops if the isValid is already false
    {
      value,
      isValid: isValid !== false,
      validationMessage: validationMessage ? validationMessage : null,
      showPopover: showPopover
    }
  );

  getCountries = () => {
    const locale = LocaleHelper.getUserLocale().split('-')[0];

    return TaxId.countryList.options.map((country) => (
      {
        value: country.value,
        label: country.labels.find((item) => item.locale === locale).value
      }
    ));
  };

  getTaxTypes = (taxTypes) => {
    const locale = LocaleHelper.getUserLocale().split('-')[0];

    return taxTypes.map((taxType) => (
      {
        value: taxType.value,
        label: taxType.labels.find((item) => item.locale === locale).value,
        ...taxType
      }
    ));
  };

  getCountryLabel = (country: string) => this.countries.find((item) => item.value === country).label;

  static getCountryTaxTypes = (country: string) => {
    let currentTaxMappings: any;

    if (country) {
      if (TaxId.countryTaxIdsMapping.hasOwnProperty(country.toUpperCase())) {
        currentTaxMappings = TaxId.countryTaxIdsMapping[country.toUpperCase()];
      }
      else {
        currentTaxMappings = TaxId.countryTaxIdsMapping['DEFAULT'];
      }
    }
    else {
      currentTaxMappings = TaxId.countryTaxIdsMapping['ALL'];
    }

    return currentTaxMappings.map((item) => TaxId.taxIds[item]);
  };

  static getAdditionalTaxTypes = (additionalTypes: string) => {
    const currentTaxMappings: any = additionalTypes;

    return currentTaxMappings.map((item) => TaxId.taxIds[item]);
  };

  onTaxTypeChange = (taxConfig: ITaxTypeConfig) => {
    const fieldState = { ...this.state };
    const newState = { ...fieldState.value };

    if (taxConfig.value !== undefined) {
      newState.taxDetails.type = taxConfig.value;
      newState.taxDetails.id = '';
      newState.taxDetails.reEnterId = '';
      newState.taxDetails.expireDate = null;
      newState.taxDetails.nid = taxConfig.country;
      newState.taxDetails.notSpecified = false;
      newState.taxDetails.appliedFor = false;
      newState.validationMessages['TAXTYPE'] = '';
      newState.validationMessages['TAXID'] = '';
      newState.validationMessages['REENTERTAXIDLABEL'] = '';
      newState.validationMessages['EXPIRYDATE'] = '';
      newState.validationMessages['NID'] = '';
    }

    fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.validateTaxType('type', 'TAXTYPE', this.props.required, newState);
    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  onIDValueChange = (taxId: string) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    newState.taxDetails.id = taxId;

    if (taxId && !taxId.startsWith('9')) {
      newState.taxDetails.expireDate = undefined;
    }

    fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.validateTaxId('id', 'TAXID', this.props.required, newState);

    if (newState.taxDetails.reEnterId !== '') {
      this.validateTaxId('reEnterId', 'REENTERTAXIDLABEL', this.props.required, newState);
      this.validateReenterTaxId('REENTERTAXIDLABEL', newState);
    }

    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  onReEnterIDValueChange = (reEnterTaxId: string) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    newState.taxDetails.reEnterId = reEnterTaxId;
    fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.validateTaxId('reEnterId', 'REENTERTAXIDLABEL', this.props.required, newState);
    this.validateReenterTaxId('REENTERTAXIDLABEL', newState);
    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  onReEnterIDPaste = (e: any) => {
    e.preventDefault();
  };

  onAppliedForChange = (checked: boolean) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    newState.taxDetails.appliedFor = checked;
    newState.validationMessages['TAXID'] = '';
    newState.validationMessages['REENTERTAXIDLABEL'] = '';

    if (checked && this.props.fetchTaxId) {
      newState.taxDetails.id = '';
      this.setState(() => ({ ...fieldState }));
      this.props.fetchTaxId();
    }
    else {
      fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
      this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
    }
  };

  onNotSpecifiedChange = (checked: boolean) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    if (checked) {
      newState.taxDetails.id = '';
      newState.taxDetails.expireDate = null;
      newState.taxDetails.notSpecified = checked;
    }
    else {
      newState.taxDetails.notSpecified = checked;
    }

    fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  onExpireDateBlur = () => {
    this.onExpireDateChange(this.state.value.taxDetails.expireDate);
  };

  onExpireDateChange = (value: Date) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    newState.taxDetails.expireDate = value;
    fieldState.isValid = newState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.validateTaxType('expireDate', 'EXPIRYDATE', this.props.required, newState);
    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  onNIDChange = (nid: any) => {
    const fieldState = { ...this.state };
    const newState = { ...this.state.value };

    if (nid.value !== undefined) {
      newState.taxDetails.nid = nid.value;
    }

    fieldState.isValid = newState.isValid = this.validateComponent(newState, this.props.required);
    this.validateTaxType('nid', 'NID', this.props.required, newState);
    this.updateField(newState, fieldState.isValid, fieldState.validationMessage);
  };

  idPrefix = (name: string) => get(this.props, 'id', '').concat('_', name);

  public static checkRequiredness = (labelKey, messageKey, fieldValue, newState) => {
    if (!GenericValidator.isNotNullOrEmpty(fieldValue)) {
      newState.validationMessages[labelKey] = FormatHelper.formatMessage(messageKey);
    }

    return newState;
  };

  public validateComponent = (newState, isRequired, validateOnRender?) => {
    const clearMessages = !validateOnRender;
    let isValid = true;

    // Validate type
    if (!newState.taxDetails.type) {
      newState.taxDetails.type = '';
    }

    let taxConfig = newState.types.find((item) => item.value === newState.taxDetails.type);

    if (!taxConfig) {
      taxConfig = newState.types[0];
    }

    // validate type
    if (!this.validateTaxType('type', 'TAXTYPE', isRequired, newState, clearMessages)) {
      isValid = false;
    }

    // validate tax
    if ((isValid || validateOnRender) && (!newState.taxDetails.notSpecified) && (!this.validateTaxId('id', 'TAXID', isRequired, newState, clearMessages))) {
      isValid = false;
    }

    // validate Re-Enter TaxID
    if ((isValid || validateOnRender) && (newState.taxDetails.type === 'SSN') && (!newState.taxDetails.appliedFor) && !(this.validateTaxId('reEnterId', 'REENTERTAXIDLABEL', isRequired, newState) && this.validateReenterTaxId('REENTERTAXIDLABEL', newState, clearMessages))) {
      isValid = false;
    }

    // validate expireDate
    if ((isValid || validateOnRender) && taxConfig.expireDate && (newState.taxDetails.id.charAt(0) === '9')) {
      if (!this.validateTaxType('expireDate', 'EXPIRYDATE', isRequired, newState, clearMessages)) {
        isValid = false;
      }
    }

    // validate National Identifier
    if ((isValid || validateOnRender) && !this.validateTaxType('nid', 'NID', isRequired, newState, clearMessages)) {
      isValid = false;
    }

    return isValid;
  };

  public validateTaxType = (fieldName, fieldLabelId, isRequired, newState, clearMessages = false) => {
    const previousTaxTypeErrorState = newState ? newState.validationMessages[fieldLabelId] : false;
    newState.validationMessages[fieldLabelId] = '';

    if (!newState.taxDetails[fieldName] && isRequired) {
      newState = TaxId.checkRequiredness(fieldLabelId, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newState.taxDetails[fieldName], newState);
    }

    const isValidTax = newState.validationMessages[fieldLabelId] === '';

    if (clearMessages && !previousTaxTypeErrorState) {
      newState.validationMessages[fieldLabelId] = '';
    }

    return isValidTax;
  };

  public validateTaxId = (fieldName, fieldLabelId, isRequired, newState, clearMessages = false) => {
    const taxConfig = newState.types.find((item) => item.value === newState.taxDetails.type) || TaxId.taxIds['DEFAULT'];
    const previousTaxIdErrorState = newState ? newState.validationMessages[fieldLabelId] : false;

    newState.validationMessages[fieldLabelId] = '';

    if (!newState.taxDetails[fieldName] && isRequired) {
      newState = TaxId.checkRequiredness(fieldLabelId, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newState.taxDetails[fieldName], newState);
    }

    // For appliedFor = true , don't validate the regular expression as random value comes from fetchTaxId method and this may not be a valid taxid
    if ((!newState.validationMessages[fieldLabelId]) && (!newState.taxDetails.appliedFor) && (!GenericValidator.isValidPattern(newState.taxDetails[fieldName], new RegExp(taxConfig.regExp)))) {
      newState.validationMessages[fieldLabelId] = ' ' + FormatHelper.formatMessage('@@mdfInvalidData');
    }

    if (!newState.validationMessages[fieldLabelId] && AUTO_FORMAT_TAXIDS.hasOwnProperty(taxConfig.value)) {
      newState.taxDetails[fieldName] = AUTO_FORMAT_TAXIDS[taxConfig.value](newState.taxDetails[fieldName]);
    }

    const isValidTax = newState.validationMessages[fieldLabelId] === '';

    if (clearMessages && !previousTaxIdErrorState) {
      newState.validationMessages[fieldLabelId] = '';
    }

    return isValidTax;
  };

  public validateReenterTaxId = (fieldLabelId, newState, clearMessages = false) => {
    if ((!newState.validationMessages[fieldLabelId]) && (newState.taxDetails.reEnterId !== newState.taxDetails.id)) {
      newState.validationMessages[fieldLabelId] = ' ' + FormatHelper.formatMessage('@@mdfMismatchField');
    }

    const isValidReenterTax: boolean = newState.validationMessages[fieldLabelId] === '';

    if (clearMessages) {
      newState.validationMessages[fieldLabelId] = '';
    }

    return isValidReenterTax;
  };

  renderTaxTypes = () => {
    const { adaptive, required } = this.props;
    const { taxDetails, validationMessages } = this.state.value;
    const hideLabels = this.props.layoutType === LayoutType.Row;

    return (
      <div>
        <div className="mdf-taxId-cell vdl-dropdown-list-nowrap">
          <MDFLabeledSelectBox
            adaptive={adaptive}
            id={this.idPrefix('taxidtype')}
            name={this.idPrefix('taxidtype')}
            autoFocus={this.props.autoFocus}
            labelText={hideLabels ? '' : FormatHelper.formatMessage(LABEL_KEYS.TAXID_TYPE)}
            aria-label={FormatHelper.formatMessage(LABEL_KEYS.TAXID_TYPE)}
            placeholder={hideLabels ? FormatHelper.formatMessage('@@enterTaxType') : ' '}
            value={taxDetails.type}
            isValid={!validationMessages['TAXTYPE']}
            validationMessage={validationMessages['TAXTYPE']}
            optionsList={this.getTaxTypes(this.state.value.types)}
            required={required}
            isClearable={false}
            onBlur={this.onTaxTypeChange}
            onChange={this.onTaxTypeChange}
            disabled={this.state.value.disabled || this.state.value.disableTaxType || taxDetails.appliedFor}
            alwaysShowValidation={this.props.validateOnRender}
          />
        </div>
      </div>
    );
  };

  renderTaxID = () => {
    const { required } = this.props;
    const { taxDetails, validationMessages } = this.state.value;
    const labelText = FormatHelper.formatMessage(LABEL_KEYS.TAXID);
    const hideLabels = this.props.layoutType === LayoutType.Row;

    const notSpecifiedClasses = classNames({
      'mdf-taxId-applied-cell': !taxDetails.notSpecified,
      'mdf-taxId-notSpecified-cell': taxDetails.notSpecified
    });

    return (
      <div className="render-taxId" >
        {!taxDetails.notSpecified &&
          <div className="mdf-taxId-cell">
            <MDFLabeledTextbox
              labelText={hideLabels ? '' : labelText}
              id={this.idPrefix('taxid')}
              name={this.idPrefix('taxid')}
              helpMessage={this.currentTaxConfig.format}
              placeholder={hideLabels ? FormatHelper.formatMessage('@@enterTaxId') : ' '}
              value={taxDetails.id}
              isValid={!validationMessages['TAXID']}
              validationMessage={validationMessages['TAXID']}
              required={required}
              onBlur={this.onIDValueChange}
              onChange={this.onIDValueChange}
              disabled={this.state.value.disabled || taxDetails.appliedFor}
              alwaysShowValidation={this.props.validateOnRender}
            />
          </div>
        }
        {this.currentTaxConfig.notSpecified && !this.state.value.hideNotSpecified &&
          <div className={notSpecifiedClasses}>
            <Checkbox
              checked={taxDetails.notSpecified}
              disabled={this.state.value.disabled}
              onChange={this.onNotSpecifiedChange}>
              {FormatHelper.formatMessage(LABEL_KEYS.NOT_PROVIDED)}
            </Checkbox>
          </div>
        }
        {this.currentTaxConfig.appliedFor && !this.state.value.hideAppliedFor &&
          <div className="mdf-taxId-applied-cell">
            <Checkbox
              checked={taxDetails.appliedFor}
              disabled={this.state.value.disabled || (!this.state.value.isNewTaxId)}
              onChange={this.onAppliedForChange}>
              {FormatHelper.formatMessage(LABEL_KEYS.APPLIED_FOR)}
            </Checkbox>
          </div>
        }
      </div>
    );
  };

  renderExpiryDate = () => {
    const { required } = this.props;
    const { taxDetails, validationMessages } = this.state.value;
    const hideLabels = this.props.layoutType === LayoutType.Row;

    return (
      this.currentTaxConfig.expireDate && (taxDetails.id.charAt(0) === '9') &&
      <div>
        <div className="mdf-taxId-cell">
          <MDFLabeledDatePicker
            id={this.idPrefix('expiryDate')}
            name={this.idPrefix('expiryDate')}
            labelText={hideLabels ? '' : FormatHelper.formatMessage(LABEL_KEYS.SIN_EXPIRYDATE)}
            disabled={this.state.value.disabled}
            selectedDate={taxDetails.expireDate}
            isValid={!validationMessages['EXPIRYDATE']}
            validationMessage={validationMessages['EXPIRYDATE']}
            onBlur={this.onExpireDateBlur}
            onChange={this.onExpireDateChange}
            required={required}
            alwaysShowValidation={this.props.validateOnRender}
          />
        </div>
      </div>
    );
  };

  renderReEnterTaxID = () => {
    const { required } = this.props;
    const { taxDetails, validationMessages } = this.state.value;
    const hideLabels = this.props.layoutType === LayoutType.Row;

    return (
      (taxDetails.type === 'SSN' && !taxDetails.appliedFor) && <div>
        <div className="mdf-taxId-cell">
          <MDFLabeledTextbox
            labelText={hideLabels ? '' : FormatHelper.formatMessage(LABEL_KEYS.REENTERTAXIDLABEL)}
            id={this.idPrefix('reentertaxid')}
            name={this.idPrefix('reentertaxid')}
            disabled={this.state.value.disabled}
            helpMessage={this.currentTaxConfig.format}
            placeholder={hideLabels ? FormatHelper.formatMessage('@@reEnterTaxId') : ' '}
            value={taxDetails.reEnterId}
            isValid={!validationMessages['REENTERTAXIDLABEL']}
            validationMessage={validationMessages['REENTERTAXIDLABEL']}
            required={required}
            onPaste={this.onReEnterIDPaste}
            onBlur={this.onReEnterIDValueChange}
            onChange={this.onReEnterIDValueChange}
            alwaysShowValidation={this.props.validateOnRender}
          />
        </div>
      </div>
    );
  };

  renderNationalIdentifier = () => {
    const { adaptive, required } = this.props;
    const { taxDetails, validationMessages } = this.state.value;
    const hideLabels = this.props.layoutType === LayoutType.Row;

    return (
      <div>
        <div className="mdf-taxId-cell nationalidentifier">
          {this.currentTaxConfig.country && !hideLabels &&
            <div className="mdf-validated-field">
              <MDFLabel
                htmlFor={this.idPrefix('nationalidentifier')}
                labelText={FormatHelper.formatMessage(LABEL_KEYS.NATIONAL_IDENTIFIER)}
                required={required}
                placement="top"
                helpMessage={FormatHelper.formatMessage(LABEL_KEYS.NATIONAL_IDENTIFIER_HELP)}>
              </MDFLabel>
            </div>
          }
          {this.currentTaxConfig.country &&
            <div className="mdf-validated-field">
              <MDFLabel labelText={this.getCountryLabel(this.currentTaxConfig.country)} />
            </div>
          }
          {!this.currentTaxConfig.country &&
            <div className="vdl-dropdown-list-nowrap">
              <MDFLabeledSelectBox
                adaptive={adaptive}
                id={this.idPrefix('nationalidentifier')}
                name={this.idPrefix('nationalidentifier')}
                labelText={hideLabels ? '' : FormatHelper.formatMessage(LABEL_KEYS.NATIONAL_IDENTIFIER)}
                helpMessage={FormatHelper.formatMessage(LABEL_KEYS.NATIONAL_IDENTIFIER_HELP)}
                disabled={this.state.value.disabled}
                isValid={!validationMessages['NID']}
                validationMessage={validationMessages['NID']}
                placeholder={' '}
                value={taxDetails.nid}
                optionsList={this.nidCountries}
                isClearable={false}
                required={required}
                onBlur={this.onNIDChange}
                onChange={this.onNIDChange}
                filter={'startsWith'}
                alwaysShowValidation={this.props.validateOnRender}
              />
            </div>
          }
        </div>
      </div>
    );
  };

  render() {
    const { taxDetails, disabled, hideNationalIdentifier } = this.state.value;
    this.currentTaxConfig = this.state.value.types.find((item) => item.value === taxDetails.type);

    const taxidClasses = classNames({
      'mdf-taxId': true,
      column: !(this.props.layoutType === LayoutType.Row),
      row: this.props.layoutType === LayoutType.Row
    });

    if (!this.currentTaxConfig) {
      this.currentTaxConfig = this.state.value.types[0];
    }

    if (MDFCore.shouldLog()) {
      console.log('TaxId.render(): props =', this.props);
      console.log('TaxId.render(): state =', this.state);
      console.log('TaxId.render(): currentTaxConfig =', this.currentTaxConfig);
    }

    return (
      <div className={taxidClasses} ref={this.setField}>
        {this.renderTaxTypes()}
        {this.renderTaxID()}
        {this.renderExpiryDate()}
        {!disabled && this.renderReEnterTaxID()}
        {!hideNationalIdentifier && this.renderNationalIdentifier()}
      </div >
    );
  }
}
