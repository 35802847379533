import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FAChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FAChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export default class Action extends React.Component<any, any> {
  static propTypes = {
    id: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    isMoveAll: PropTypes.bool,
    handleKeyboardSelection: PropTypes.func.isRequired
  };

  static defaultProps = {
    isMoveAll: false
  };

  /**
   * @returns {void}
   */
  constructor(props) {
    super(props);
  }

  /**
   * @returns {void}
   */
  onClick = () => {
    const { direction, isMoveAll, onClick } = this.props;

    onClick({
      direction,
      isMoveAll
    });
  };

  /**
   * @param {string} direction
   *
   * @returns {string}
   */
  getIconClass = (direction) => {
    if (direction === 'right') {
      return 'fa fa-chevron-right';
    }

    return 'fa fa-chevron-left';
  };

  getLabel = (direction, isMoveAll) => {
    const allText = isMoveAll ? ' all' : '';

    return `Move${allText} ${direction}`;
  };

  /**
   * @param {string} iconClass
   * @param {boolean} isMoveAll
   *
   * @returns {*}
   */
  renderIcons = (iconClass, isMoveAll) => {
    if (iconClass === 'fa fa-chevron-right') {
      return (isMoveAll ? (
        <>
          <FAChevronRight />
          <FAChevronRight />
        </>
      ) : <FAChevronRight />);
    }
    else {
      return (isMoveAll ? (
        <>
          <FAChevronLeft />
          <FAChevronLeft />
        </>
      ) : <FAChevronLeft />);
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const { handleKeyboardSelection, id } = this.props;

    // Accessibility feature: select items using keyboard navigation
    if (event.key === 'Enter' || event.key === ' ') {
      setTimeout(() => {
        this.onClick();
        handleKeyboardSelection(event, id);
      }, 0);
    }
  };

  /**
   * @returns {React.Component}
   */
  render() {
    const { direction, disabled, isMoveAll, id } = this.props;
    const ariaDescribedby = resolveAriaProperty('Action', 'aria-describedby', 'ariaDescribedby', this.props);
    const iconClass = this.getIconClass(direction);
    const label = this.getLabel(direction, isMoveAll);
    const className = classNames(this.props.className);

    return (
      <button
        id={id || null}
        aria-describedby={ariaDescribedby}
        aria-label={label}
        className={className}
        disabled={disabled}
        title={label}
        tabIndex={0}
        type="button"
        onClick={this.onClick}
        onKeyDown={this.handleKeyDown}
      >
        {this.renderIcons(iconClass, isMoveAll)}
      </button>
    );
  }
}
