import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SmallCard } from './SmallCard';
import { SmallCardContent, SmallCardMiddle } from './SmallCardMiddle';
import { IColumnHeader, MDFListHeader } from '../MDFListHeader';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IMDFListCardProps {
  columns: IColumnHeader[];
  onSort?: (columnId) => any;
  defaultSortable?: boolean;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  cardClass?: string;
  className?: string;
  items: object[];
  noDataMessage?: string;
}

export class MDFListCard extends React.Component<React.PropsWithChildren<IMDFListCardProps>, any> {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };

  renderCard = (item, idx) => {
    const cardClass = `small-card--list-card ${this.props.cardClass || ''}`;

    return (
      <SmallCard cardStyle={'flat'} className={cardClass} key={idx}>
        <SmallCardMiddle>
          <SmallCardContent>
            {this.props.columns.map((column, index) => <div key={column.id ?? index} className={classNames('small-card--list-card-column', column.className)}>{item[column.id]}</div>)}
          </SmallCardContent>
        </SmallCardMiddle>
      </SmallCard>
    );
  };

  renderNoDataMessage = () => (
    <div className={'mdf-grid-noDataMessage'}>
      {this.props.noDataMessage ? this.props.noDataMessage : ` ${FormatHelper.formatMessage('@@NoRowsFound')}`}
    </div>
  );

  render() {
    const { className, children, cardClass, items, columns, ...headerProps } = this.props;

    return (
      <div className={classNames(className)}>
        <MDFListHeader columnHeaders={columns} {...headerProps} />
        {items?.length > 0 ? items.map(this.renderCard) : this.renderNoDataMessage()}
      </div>
    );
  }
}
