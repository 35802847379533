import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { elementType } from 'prop-types-extra';
import vdlPrefix from '../../util/vdlPrefix';
import { ITabOrientation, Tab } from './tab';
import { result } from '@synerg/vdl-react-components/src/util/filter';
import { generateId, notify } from '@synerg/vdl-react-components/src/util/widget-helpers';
import { contains, isDisabledItem } from '@synerg/vdl-react-components/src/util/interaction';
import { dataId, dataIndexOf, dataText, dataValue, valueMatcher } from '@synerg/vdl-react-components/src/util/data-helpers';
import { message as messageLocalizer } from '@synerg/vdl-react-components/src/util/localizers';

const optionId = (id, idx) => `${id}__tab__${idx}`;

const tabContentId = (id, idx) => `${id}__tabs__content__${idx}`;

function localizedMessage(messageKey: string, locale?: string) {
  return messageLocalizer.formatMessage('adp-react-components/tabs/' + messageKey, undefined, locale);
}

function msgs(tabsMsgs?: ITabsMessages, locale?: string) {
  return Object.assign(
    {
      emptyTabs: localizedMessage('EMPTY_TABS', locale)
    },
    tabsMsgs
  );
}

export type ITabValueAccessor = (item: any) => string;

export interface ITabsMessages {
  emptyTabs?: React.ReactNode;
}

export interface ITabsProps {
  value?: any;
  onChange?: (value: any) => void;
  data: any[];
  tabComponent?: React.ClassType<any, any, any>;
  itemComponent?: React.ClassType<any, any, any>;
  orientation?: ITabOrientation;
  valueField?: string | ITabValueAccessor;
  textField?: string | ITabValueAccessor;
  idField?: string | ITabValueAccessor;
  children?: React.ReactNode;
  className?: string;
  role?: string;
  messages?: ITabsMessages;
  disabled?: boolean;
  disabledItems?: any[];
  lastTabComponent?: React.ClassType<any, any, any>;
  // Keyboard accessibility: if true, this property removes the built-in tab stop inside the tabpanel.
  // This built-in tab stop is needed to enter the tabpanel, if no interactive elements are available inside it.
  // If true, the tabpanel is expected to have an interactive element available in the tabbing order.
  disableTabPanelTabStop?: boolean;
}

export const Tabs = (props: ITabsProps) => {
  const { children,
    orientation,
    value,
    data,
    textField,
    valueField,
    idField,
    disabled,
    disableTabPanelTabStop,
    tabComponent: TabComponent,
    itemComponent: ItemComponent,
    lastTabComponent: LastTabComponent
  } = props;

  const initialIdx = dataIndexOf(data, value, valueField);
  const messages = msgs(props.messages);
  const id = generateId(props);
  const [selectedItem, setSelectedItem] = useState(data[initialIdx] || data[0]);

  useEffect(() => {
    const idx = dataIndexOf(data, value, valueField);
    setSelectedItem(data[idx]);
  });

  const onChange = (item: any) => {
    if (!valueMatcher(item, props.value, props.valueField)) {
      notify(props.onChange, dataValue(item, valueField));
      setSelectedItem(item);
    }
  };

  const onNavigate = (direction: string) => {
    const mappedSteps = data.map((item) => {
      if (props.disabledItems?.includes(dataValue(item, valueField))) {
        return undefined;
      }
      else {
        return item;
      }
    });

    const currentIndex = dataIndexOf(data, value, valueField);

    switch (direction) {
      case 'previous': {
        let newIndex = currentIndex;

        while (newIndex > 0) {
          if (mappedSteps[newIndex - 1]) {
            newIndex = newIndex - 1;
            notify(props.onChange, dataValue(data[newIndex], valueField));
            setSelectedItem(data[newIndex]);
            return;
          }
          else {
            newIndex = newIndex - 1;
          }
        }

        break;
      }

      case 'next': {
        let newIndex = currentIndex;

        while (newIndex < mappedSteps.length) {
          if (mappedSteps[newIndex + 1]) {
            newIndex = newIndex + 1;
            notify(props.onChange, dataValue(data[newIndex], valueField));
            setSelectedItem(data[newIndex]);
            return;
          }
          else {
            newIndex = newIndex + 1;
          }
        }

        break;
      }
    }
  };

  const tabsClasses = {
    [vdlPrefix('tabs')]: true,
    [vdlPrefix('tabs--' + orientation)]: true
  };

  const tabsContainerClasses = {
    [vdlPrefix('tabs__container')]: true,
    [vdlPrefix('tabs__container--' + orientation)]: true
  };

  const tabToolbarClasses = {
    [vdlPrefix('tabs__toolbar')]: true,
    [vdlPrefix('tabs__toolbar--' + orientation)]: true
  };

  const toolbarContainerClasses = {
    [vdlPrefix('tabs__toolbar__container')]: true,
    [vdlPrefix('tabs__toolbar__container--' + orientation)]: true
  };

  const tabsContentClasses = {
    [vdlPrefix('tabs__content')]: true,
    [vdlPrefix('tabs__content--' + orientation)]: true
  };

  const tabs = !data.length ?
    (
      [<TabComponent
        key={'tab_0'}
        empty
        disabled={disabled}
        role="tab"
        aria-controls={tabContentId(id, 0)}
      >
        {result(messages.emptyTabs, props)}
      </TabComponent>]
    )
    : data.map(
      (item, idx) => {
        const currentId = optionId(id, idx);
        const isDisabled = isDisabledItem(item, props);

        return (
          <TabComponent
            key={'tab_' + idx}
            id={dataId(item, idField) || currentId}
            index={idx}
            dataItem={item}
            disabled={isDisabled}
            orientation={orientation}
            selected={contains(item, selectedItem, valueField)}
            onNavigate={onNavigate}
            onSelect={onChange}
            role="tab"
            aria-controls={tabContentId(id, idx)}
          >
            {
              ItemComponent ?
                (
                  <ItemComponent
                    item={item}
                    disabled={isDisabled}
                  />
                )
                : dataText(item, textField)
            }
          </TabComponent>
        );
      }
    );

  if (props.lastTabComponent && orientation === 'horizontal') {
    tabs.push(<LastTabComponent />);
  }

  const selectedIndex = dataIndexOf(data, selectedItem, valueField);

  return (
    <div className={classNames(tabsClasses)}>
      <div className={classNames(tabsContainerClasses)} >
        <div className={classNames(tabToolbarClasses)} >
          <ul className={classNames(toolbarContainerClasses)} role="tablist">
            {tabs}
          </ul>
        </div>
        <div className={classNames(tabsContentClasses)} role="tabpanel" tabIndex={disableTabPanelTabStop ? -1 : 0} id={tabContentId(id, selectedIndex)}>
          {children}
        </div>
      </div>
    </div>
  );
};

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  data: PropTypes.array,
  tabComponent: elementType,
  itemComponent: elementType,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  valueField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  textField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  idField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  role: PropTypes.string,
  messages: PropTypes.shape({
    emptyTabs: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }),
  disabled: PropTypes.bool,
  disabledItems: PropTypes.array,
  lastTabComponent: elementType
};

Tabs.defaultProps = {
  tabComponent: Tab,
  orientation: 'horizontal',
  data: [],
  role: 'tablist',
  disabled: false
};
