import React, { useEffect, useRef, useState } from 'react';

// Notes
// multiple items - user needs to click on the checkboxes or send isSelected in order to drag the item
// single time - one can drag a single item without checking the box and we send a callback function to the application with itemId
export interface IMDFDragggableProps {
  // to set IsSelected for each item.
  isSelected?: boolean;
  // set Id for each item.
  itemId?: string;
  // The children to render within this component.
  children: any;
  // classname to overwrite dragImage
  dragImageClass?: string;
  // className to overWrite each DragItem
  className?: string;
  // notify when the item is dragged and not marked as selected.
  itemDragged?: (itemId) => void;
  // notify when the item(s) are dropped outside drop target.
  onDragEnd?: () => void;
}

const draggingStyle = {
  opacity: 0.25
};

export const MDFDraggable = (props: IMDFDragggableProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isSelected, setIsSelected] = useState(props.isSelected ?? false);

  const drag = useRef(null);

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);

  let elementsCount = 0;

  const updateSelectedStyle = () => {
    // What happens if there are more than one draggable container? At one time only one container can be selected to start the dragging process.
    const elements = document.querySelectorAll('[data-mdf-drag-select = true]');

    elementsCount = elements.length;

    for (let i = 0; i < elementsCount; i++) {
      elements?.[i].classList.add('mdf-drag-item-borderStyle');
    }
  };

  // When single item is dragged without selecting. Notify the application with the callback and send the itemId
  const itemDragged = () => {
    props.itemDragged?.(props.itemId);
  };

  const startDrag = (ev) => {
    setIsDragging(true);
    setIsSelected(true);

    // Call the callback only when the item is not marked selected to notify the application
    if (!isSelected) {
      itemDragged();
    }

    // get the count of selected Items to send it for the dragImage
    updateSelectedStyle();

    // Creating ghost image element for drag.
    // Adding data transfer to the event object to read at the target.
    const dragImgEl = document.createElement('div');
    dragImgEl.id = 'mdf-drag-image';

    // using optional Chaining here changes the text to red in chrome browser console.
    dragImgEl.className = `mdf-drag-imageStyle ${props.dragImageClass && props.dragImageClass || ''}`;

    // Set an image for the items, need to verify with UX..
    dragImgEl.innerHTML = `Move ${isSelected ? elementsCount : (elementsCount + 1)} selected item(s)`;

    document.body.appendChild(dragImgEl);

    if ('setDragImage' in ev.dataTransfer) {
      ev.dataTransfer.setDragImage(dragImgEl, 0, 0);
    }

    // Drag and drop won't work in IE11 if we pass custom name instead of 'Text' as format.
    // https://github.com/akserg/ng2-dnd/issues/8
    // https://stackoverflow.com/questions/26213011/html5-dragdrop-issue-in-internet-explorer-datatransfer-property-access-not-pos
    ev.dataTransfer.setData('Text', ev.target.id);
  };

  const dragEnd = () => {
    // Deleting ghost image element on user dropped item outside drop target.
    const dragImage = document.getElementById('mdf-drag-image');

    if (dragImage && dragImage.parentNode) {
      dragImage.parentNode.removeChild(dragImage);
    }

    setIsDragging(false);
    props.onDragEnd?.();
  };

  return (
    <div id={props.itemId} ref={drag} data-mdf-drag-select={isSelected} className={`${props.className ?? ''} mdf-drag-item${props.isSelected && '--selected' || ''}`} style={isDragging ? draggingStyle : {}} draggable onDragStart={startDrag} onDragEnd={dragEnd}>
      {props.children}
    </div>
  );
};

MDFDraggable.displayName = 'MDFDraggable';
