/*
This component accepts as input a data structure indicating the ordering of a set of tiles, which includes indicating how
many columns to create, and which tiles appear in each column, and in what order. To put it more simply, the format of this
input property is an array of arrays of objects, where each object has the following properties:

name: string to indicate the viewID (Must be unique).
label: string to be used below label in compressed edit mode.
label: string to be passed as the label prop to the Tile component - gets displayed above the linebreak in the Tile.
tileClass: the css class to be applied to this specific tile and this tile only.
visible: boolean used to determine if it should be visible by default.

After rendering the tiles, this component enables drag & drop of the tiles via the dragula library. Tiles can be dragged
from any column into any column (including the same column), and into any position within a column. Dragula's default behavior
must be overridden to avoid mutating the DOM without React being aware of the change.

See the IProps interface below for a description of each supported property.
*/

import React from 'react';
import ReactDOM from 'react-dom';
import dragula from 'react-dragula';
import autoScroll from 'dom-autoscroller';
import 'dragula/dist/dragula.min.css';
import { ComponentManager, FormatHelper } from '@adp-wfn/mdf-core';
import { Tile, ToggleSwitch } from '@synerg/vdl-react-components';
import { cloneDeep, isEqual } from 'lodash';
import classNames from 'classnames';

export interface IProps {
  // Style(s) attached to the entire dashboard.
  dashboardClassName?: string;
  // Style(s) attached to each column.
  columnClassName?: string;
  // Style(s) attached to each tile.
  tileClassName?: string;
  // Style(s) attached to the `span` containing the label text.
  labelClassName?: string;
  // Used to toggle into and out of Edit Mode.
  editMode?: boolean;
  // Whether or not to compress tiles in Edit Mode.  Requires 'label' present on each tile object in dashboardLayout.
  compressTilesInEditMode?: boolean;
  // Whether or not to allow drag and drop when in read only mode.
  draggableInReadOnlyMode?: boolean;
  // Indicates ordering of tiles - each object must have unique 'name' property.
  dashboardLayout: object[][];
  // Invoked after every tile drop or visibility toggle; receives parameter indicating new ordering.
  onChange?: (tileOrdering: object[][]) => void;
  // Whether or not to scroll the list of tiles as the user drags a tile.
  autoScroll: boolean;
}

export class MDFDashboard extends React.Component<IProps, any> {
  containers: HTMLElement[];
  private inputRef: HTMLElement;
  private drakeRef;
  private autoScrollRef;
  private autoScrollProps = {
    margin: 20,
    maxSpeed: 5,
    scrollWhenOutside: true,
    autoScroll: () => {
      // Only scroll when the pointer is down, and there is a child being dragged.
      return this.autoScrollRef.down && this.drakeRef.dragging;
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      dashboardLayout: this.props.dashboardLayout
    };

    this.containers = [];
  }

  toggleVisibility = (tile) => {
    const dashboardLayout = this.state.dashboardLayout.map((row) => row.map((item) => (item === tile ? { ...cloneDeep(item), visible: !item.visible } : cloneDeep(item))));

    this.setState(
      () => ({ dashboardLayout }),
      () => this.props.onChange?.(this.state.dashboardLayout)
    );
  };

  removeElementFromLayout = (dashboardLayout, sourceColumnIndex, tileIndex) => dashboardLayout[sourceColumnIndex].splice(tileIndex, 1);

  insertElementInLayout = (dashboardLayout, targetColumnIndex, targetTileIndex, tile) => dashboardLayout[targetColumnIndex].splice(targetTileIndex, 0, tile);

  updateDashboardLayout = (el, target, source, sibling) => (
    (state) => {
      const currentTileIndex = el.getAttribute('data-tile-index');
      const sourceColumnIndex = source.getAttribute('data-column-index');
      const targetColumnIndex = target.getAttribute('data-column-index');
      let targetTileIndex;
      const droppingInSameColumn = sourceColumnIndex === targetColumnIndex;

      if (sibling) {
        targetTileIndex = sibling.getAttribute('data-tile-index');

        if (droppingInSameColumn && (currentTileIndex < targetTileIndex)) {
          targetTileIndex--;
        }
      }
      else if (target.children.length === 2 && droppingInSameColumn) {
        targetTileIndex = 1;
      }
      else {
        targetTileIndex = target.children.length;
      }

      const dashboardLayout = JSON.parse(JSON.stringify(state.dashboardLayout));

      const tile = this.removeElementFromLayout(dashboardLayout, sourceColumnIndex, currentTileIndex);

      this.insertElementInLayout(dashboardLayout, targetColumnIndex, targetTileIndex, tile[0]);

      return { dashboardLayout };
    }
  );

  componentDidMount() {
    const draggable = (el) => !el.classList.contains('no-dnd');

    // Instantiate dragula, tell it which containers hold draggable tiles, and which tiles are draggable
    this.drakeRef = dragula(this.containers, {
      accepts: draggable,
      moves: draggable
    });

    if (this.props.autoScroll) {
      this.autoScrollRef = autoScroll([window, ReactDOM.findDOMNode(this.inputRef)], this.autoScrollProps);
    }

    this.drakeRef.on('drop', (el, target, source, sibling) => {
      // Ensure nothing happens to the DOM that react doesn't know about.
      this.drakeRef.cancel(true);

      // Functional setState to avoid setting state with an out of date object
      this.setState(this.updateDashboardLayout(el, target, source, sibling), () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.dashboardLayout);
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.dashboardLayout, nextProps.dashboardLayout)) {
      this.setState((_prevState, props) => (
        { dashboardLayout: props.dashboardLayout }
      ));
    }
  }

  componentDidUpdate() {
    if (this.props.autoScroll && !this.autoScrollRef) {
      this.autoScrollRef = autoScroll([window, ReactDOM.findDOMNode(this.inputRef)], this.autoScrollProps);
    }
    else if (!this.props.autoScroll && this.autoScrollRef) {
      this.autoScrollRef?.destroy();
      this.autoScrollRef = null;
    }
  }

  componentWillUnmount() {
    this.autoScrollRef?.destroy();
    this.drakeRef?.destroy();
  }

  collectContainers = (container: HTMLElement) => {
    this.containers.push(container);
  };

  renderEditMode = (tile, TileComponent) => {
    const greyedOut = { opacity: 0.5 };

    return (
      <div style={tile.visible ? null : greyedOut}>
        <Tile
          title={FormatHelper.formatMessage(tile.label) ? null : tile.title}
          subTitle={tile.subTitle}
        >
          <ToggleSwitch
            onChange={(_value) => {
              this.toggleVisibility(tile);
            }}
            checked={tile.visible}
            disabled={tile.customizable === false}
          >
            {FormatHelper.formatMessage('@@Show')}
          </ToggleSwitch>
          {this.props.compressTilesInEditMode ? FormatHelper.formatMessage(tile.label) :
            ((typeof TileComponent === 'object') && React.isValidElement(TileComponent)) ? TileComponent : <TileComponent />}
        </Tile>
      </div>
    );
  };

  renderReadOnly = (tile, TileComponent) => {
    if (tile.visible) {
      return (
        <Tile
          title={tile.title}
          subTitle={tile.subTitle}
        >
          {((typeof TileComponent === 'object') && React.isValidElement(TileComponent)) ? TileComponent : <TileComponent />}
        </Tile>
      );
    }
    return null;
  };

  renderColumns = (column, columnIndex) => (
    <div ref={this.collectContainers} key={columnIndex} data-column-index={columnIndex} className={this.props.columnClassName} style={this.containerStyle}>
      {
        column.map((tile, tileIndex) => {
          let TileComponent = ComponentManager.getComponent(tile.name);

          if (tile.properties) {
            TileComponent = React.cloneElement(<TileComponent />, tile.properties);
          }

          const classes = classNames(
            'mdf-draggable',
            this.props.tileClassName,
            tile.tileClass,
            { 'no-dnd ': !this.props.draggableInReadOnlyMode && !this.props.editMode }
          );

          return (
            <div key={tileIndex} data-tile-index={tileIndex} className={classes} id={tile.id ? tile.id : `${tile.name}-${tileIndex}`}>
              {this.props.editMode ? this.renderEditMode(tile, TileComponent) : this.renderReadOnly(tile, TileComponent)}
            </div>
          );
        })
      }
    </div>
  );

  containerStyle = { width: 100 / this.props.dashboardLayout.length + '%' };

  render() {
    return (
      <div ref={(c) => this.inputRef = c} className={'mdfDashboard ' + (this.props.dashboardClassName || '')}>
        {this.state.dashboardLayout.map((column, columnIndex) => this.renderColumns(column, columnIndex))}
      </div>
    );
  }
}
