export default {
  eq: (a, b) => a === b,
  neq: (a, b) => a !== b,
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,

  contains: (a, b) => a.indexOf(b) !== -1,

  startsWith: (a, b) => a.lastIndexOf(b, 0) === 0,

  endsWith: (a, b) => {
    const pos = a.length - b.length;
    const lastIndex = a.indexOf(b, pos);

    return lastIndex !== -1 && lastIndex === pos;
  }
};
