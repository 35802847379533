import React from 'react';
import { IMDFLabelProps, MDFLabel } from './MDFLabel';
import { generateId } from '@adp-wfn/mdf-core';

// Notes
// The labelText is a simple component that wraps label and value.
export interface ILabeledTextProps extends IMDFLabelProps {
  // The text for the label
  label: string;
  // The text for the Value
  value: any;
}

export const LabeledText = ({ label, value, ...otherLabelProps }: ILabeledTextProps) => {
  const labelId = generateId('labeled_text_label');
  const spanId = generateId('labeled_text_text');

  return (
    <React.Fragment>
      <MDFLabel id={labelId} htmlFor={spanId} labelText={label} {...otherLabelProps} />
      <span id={spanId}>{value ? value : '—'}</span>
    </React.Fragment>
  );
};

LabeledText.displayName = 'LabeledText';
