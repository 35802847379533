import React from 'react';
import dragula from 'react-dragula';
import 'dragula/dist/dragula.min.css';
import { isEqual } from 'lodash';

export interface IDraggableGridProps {
  children: any;
  items: any;
  onChange: any;
  draggable: any;
}

export default class DraggableGrid extends React.Component<IDraggableGridProps, any> {
  private containers: HTMLElement[];
  private drake;

  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items || []
    };

    this.containers = [];
  }

  componentDidMount() {
    if (this.props.draggable) {
      this.initDraggable();
    }
  }

  componentWillUnmount() {
    if (this.drake) {
      this.drake.destroy();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnNextState = false;
    const nextState: any = {};

    if (!isEqual(nextProps.items, prevState.items)) {
      nextState.items = nextProps.items;
      returnNextState = true;
    }

    return returnNextState ? nextState : null;
  }

  initDraggable = () => {
    const draggable = (el) => !el.classList.contains('no-dnd');

    // Instantiate dragula, tell it which containers hold draggable rows, and which rows are draggable
    this.drake = dragula(this.containers, {
      accepts: draggable,
      moves: draggable
    });

    this.drake.on('drop', (el, target, source, sibling) => {
      // Ensure nothing happens to the DOM that react doesn't know about.
      this.drake.cancel(true);

      // Functional setState to avoid setting state with an out of date object
      this.setState(this.updateGridLayout(el, target, source, sibling));
    });
  };

  collectContainers = (container: HTMLElement) => {
    this.containers.push(container);
  };

  removeElementFromLayout = (items, gridIndex, rowIndex) => {
    if (items[0].hasOwnProperty('subheader')) {
      return items[gridIndex].rows.splice(rowIndex, 1);
    }

    return items.splice(rowIndex, 1);
  };

  insertElementInLayout = (items, targetRowIndex, targetGridIndex, item) => {
    if (items[0].hasOwnProperty('subheader')) {
      return items[targetGridIndex].rows.splice(targetRowIndex, 0, item);
    }

    return items.splice(targetRowIndex, 0, item);
  };

  updateGridLayout = (el, target, source, sibling) => (
    () => {
      const currentRowIndex = el.getAttribute('data-index');
      const currentGridIndex = source.getAttribute('data-grid-index');
      const targetGridIndex = target.getAttribute('data-grid-index');
      let targetRowIndex = target.childNodes.length;

      if (sibling) {
        targetRowIndex = sibling.getAttribute('data-index');

        if (currentRowIndex < targetRowIndex) {
          targetRowIndex--;
        }
      }

      const items = this.state.items.map((r) => ({ ...r }));
      const item = this.removeElementFromLayout(items, currentGridIndex, currentRowIndex)[0];
      this.insertElementInLayout(items, targetRowIndex, targetGridIndex, item);

      if (this.props.onChange) {
        this.props.onChange(items);
      }

      return { items };
    }
  );

  render() {
    const draggableProps = Object.assign({}, this.props, { collectContainers: this.collectContainers }, this.state.items);

    return (
      <div>
        {this.props.children(draggableProps)}
      </div>
    );
  }
}
