import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MDFCircleIcon } from '../MDFCirlceIcon';
import { MDFIcon } from '../MDFIcon';
import { MDFPopover } from '../MDFPopover';
import { MDFStatusOverlay } from '../MDFStatusOverlay';
import { SmallCardContext } from '../MDFContext';
import { Avatar, OverlayTrigger, Popover } from '@synerg/vdl-react-components';
import classNames from 'classnames';
import { colorNames, colorValue, VDLColors } from '../../util/colorStyles';

export interface ISmallCardCircleProps {
  // Assign className for SmallCardCircle
  className?: string;
  // One of the following 'high' | 'low'
  circlePosition?: 'high' | 'low';
  // One of the following 'none' | 'status' | 'icon' | 'avatar' | 'initials'
  circleType: 'none' | 'status' | 'icon' | 'avatar' | 'initials';
  // apply one of the following 'sm' | 'md'
  size: 'sm' | 'md';
  // The class name of the icon to be displayed in the circle
  iconClass?: string;
  // The initials to be displayed in the circle
  initials?: string;
  // The avatar url to be displayed in the circle
  avatarUrl?: string;
  // One of the following 'success' | 'info' | 'warning' | 'error' | 'delegate' | 'notStarted' | 'inProgress' | 'submitted' | 'inReview' | 'approved' | 'accepted' | 'completed' | 'inComplete' | 'archived' | 'generalLiability' | 'default';
  statusType?: 'success' | 'info' | 'warning' | 'error' | 'delegate' | 'notStarted' | 'inProgress' | 'submitted' | 'inReview' | 'approved' | 'accepted' | 'completed' | 'inComplete' | 'archived' | 'generalLiability' | 'default';
  // The color to use for the half of the SmallCardCircle that touches the SmallCardMiddle
  middleBackgroundColor?: VDLColors;
  // The color to use for the half of the SmallCardCircle that touches either the SmallCardTop (if cirlcePosition is 'high') or SmallCardBottom
  outerBackgroundColor?: VDLColors;
  children?: any;
}

// Find the popover child of the SmallCardCircle
const getPopoverChild = (children) => {
  let result = null;
  let count = 0;

  React.Children.forEach(children, (child: any) => {
    if (!result && child?.type === MDFPopover) {
      result = (
        <Popover id={'popoverOverlay'} {...child.props} className={'mdf-overlay-popover'}>
          {child.props.children}
        </Popover>
      );

      count++;
    }
  });

  if (count > 1) {
    console.error('The SmallCardCircle can have only 1 Popover child.');
  }

  return result;
};

// The only child of the SmallCardCircle would be a popover.
// If one exists, create the overlay trigger and associate the popover with it.
// Otherwise, we render the circle and it's box.
const renderPopover = (children, circle) => {
  const popover = getPopoverChild(children);

  if (popover) {
    return (
      <OverlayTrigger
        trigger={children.props.trigger || ['hover', 'focus', 'click']}
        defaultOverlayShown={children.props.defaultOverlayShown}
        placement={'bottom'}
        overlay={popover}
      >
        <div tabIndex={0}>
          {circle}
        </div>
      </OverlayTrigger>
    );
  }
  else {
    return circle;
  }
};

const renderCircleIcon = (size, classes, mdfIconClass, children?) => {
  const circleIcon = (
    <MDFCircleIcon size={size} className={classNames(classes)}>
      <MDFIcon className={classNames(mdfIconClass)}/>
    </MDFCircleIcon>
  );

  return renderPopover(children, circleIcon);
};

const renderAvatar = (size, initials, avatarUrl, iconClass, children?) => {
  const avatar = (
    <Avatar url={avatarUrl} size={size} className={'mdf-avatar'} initials={initials}>
      <i className={iconClass}/>
    </Avatar>
  );

  return renderPopover(children, avatar);
};

const renderStatus = (props: ISmallCardCircleProps, classes) => {
  if (props.statusType) {
    return <MDFStatusOverlay size={props.size} statusType={props.statusType} iconClass={props.iconClass} className={classes.join(' ')}>{props.children}</MDFStatusOverlay>;
  }
  else {
    return null;
  }
};

export const SmallCardCircle = (props: ISmallCardCircleProps) => {
  const smallCardContext: any = useContext(SmallCardContext);
  const classes = [
    `small-card-middle--${props.circleType || ''}--${(props.circleType === 'status' && props.statusType) || ''}`,
    props.className || ''
  ];

  let circle;

  switch (props.circleType) {
    case 'status':
      circle = renderStatus(props, classes);
      break;

    case 'icon': {
      const iconClass = `${props.iconClass} cardIcon`;
      circle = renderCircleIcon(props.size, classes, iconClass, props.children);
      break;
    }

    case 'avatar':
    case 'initials': {
      const initials = props.circleType === 'initials' ? props.initials : undefined;
      circle = renderAvatar(props.size, initials, props.avatarUrl, props.iconClass, props.children);
      break;
    }

    default:
      circle = null;
  }

  const isSelected = !!smallCardContext?.selectable;

  // Position the circle to low when it is **not** a selectable card and circleType is set to STATUS
  const circlePosition = !isSelected && props.circleType === 'status' && !props.circlePosition ? 'low' : 'high';

  // Adjust the style for the circle based on the circle size property.
  const circleSizeStyle = (props.size === 'md' && circlePosition === 'low') ? 'circleBottomStyle' : '';

  // Match the middle and outer background colors, if provided
  const middleBackgroundColor = props.middleBackgroundColor ? colorValue(props.middleBackgroundColor) : colorValue('neutral-lightest');
  const outerBackgroundColor = props.outerBackgroundColor ? colorValue(props.outerBackgroundColor) : 'transparent';
  let circleStyle;

  if (circlePosition === 'high') {
    circleStyle = { background: `linear-gradient(to top, ${middleBackgroundColor} 50%, ${outerBackgroundColor} 50%)` };
  }
  else {
    circleStyle = { background: `linear-gradient(to bottom, ${middleBackgroundColor} 50%, ${outerBackgroundColor} 50%)` };
  }

  return (
    <div className={classNames(`small-card-circle ${circleSizeStyle}`)} style={circleStyle}>
      {circle}
    </div>
  );
};

SmallCardCircle.propTypes = {
  className: PropTypes.string,
  circlePosition: PropTypes.oneOf([
    'high',
    'low'
  ]),
  circleType: PropTypes.oneOf([
    'none',
    'status',
    'icon',
    'avatar',
    'initials'
  ]),
  size: PropTypes.oneOf([
    'sm',
    'md'
  ]),
  iconClass: PropTypes.string,
  initials: PropTypes.string,
  avatarUrl: PropTypes.string,
  statusType: PropTypes.oneOf(['success', 'info', 'warning', 'error', 'delegate', 'notStarted', 'inProgress', 'submitted', 'inReview', 'approved', 'accepted', 'completed', 'inComplete', 'archived', 'generalLiability', 'default']),
  middleBackgroundColor: PropTypes.oneOf(colorNames()),
  outerBackgroundColor: PropTypes.oneOf(colorNames())
};

SmallCardCircle.displayName = 'SmallCardCircle';
