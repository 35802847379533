import React from 'react';
import PropTypes from 'prop-types';

export interface ISegmentFillerProps {
  fill: number;
  id?: string;
  segments: number;
  'aria-labelledby'?: string;
}

export const SegmentFiller = (props: ISegmentFillerProps) => {
  const { fill, segments } = props;
  const segmentChunks = [];

  for (let i = 0; i < segments; i++) {
    const chunkClass = i < fill ? 'vdl-segment-filler__filled' : 'vdl-segment-filler__empty';
    segmentChunks.push(<div className={chunkClass} role="presentation"/>);
  }

  return (
    <div className="vdl-segment-filler__container" role="progressbar" aria-valuenow={fill} aria-valuemax={segments} aria-labelledby={props['aria-labelledby']}>
      {segmentChunks}
    </div>
  );
};

SegmentFiller.displayName = 'SegmentFiller';

SegmentFiller.propTypes = {
  fill: PropTypes.number,
  id: PropTypes.string,
  segments: PropTypes.number
};

SegmentFiller.defaultProps = {
  fill: 0,
  segments: 1
};
