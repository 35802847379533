import React, { forwardRef } from 'react';
import { IMDFSpreadSheetSortParams } from './IMDFSpreadSheetSortParams';
import { MDFSpreadSheet } from './MDFSpreadSheet';

// Notes
// - 1) Locked columns have to be at the beginning and adjacent to each other in the columns array.<br/>
// - 2) All columns must specify a width and in the case of compound columns each child must each specify a width. Child widths must add up to the parents width.<br/>
// - 3) Users need to wrap the adp components in a div in their view definition to avoid potential performance hit on Spreadsheet when rendering them.<br/>
// - 4) Keyboard navigation support is added for headers & body. Footers are considered static columns of text for now so will add tabbing through footer cells in future to support accessibility as well.<br/>
// - 5) Parameters that any view in the column object are index (row number), column (key of the column) and item object (represents each row in the data)
// - 6) Row Selection: 'selectRow' is the callback function which is required to enable row selection on MDFGrid. When selectRow is configured MDFGrid supports multiple row selection by default. If users want to limit the selection to single row then they need to set enableSingleRowSelect property on the MDFGrid.
//      When each row is selected components add isSelected key on particular data object for the row and assigns it with a true value. If the row is already selected and you are deselecting the row then isSelected key on data/row object will be set to false.
//      selectRow call back function passes the row index parameter back to application. Selected rows are highlighted with bluish background color for entire selected row as per UX.

export interface IMDFGridColumn {
  // 'alignHeader [accepts values center, right] - to align header cell'
  alignHeader?: 'center' | 'right';

  // 'alignColumn [accepts values center, right] - to align data cells'
  alignColumn?: 'center' | 'right';

  // A string to apply custom class to required column
  className?: string;

  // Determines if the column is locked or unlocked
  locked: boolean;

  // Used to map actual data in row object to a column
  key: string;

  // Display field for header
  headerLabel: string;

  // Optional field to read the actual data from objects in case of columns with validation components as editors
  valueField?: string;

  // An optional property set on columns and accepts a json view as component definition. The cell with editor looks like a text field, click the cell to see the actual editor component. Example components could be used as editors Dropdowns, DatePicker, TimePicker, TextBox, NumberBox, Validated TextBox, Validated NumberBox etc (components which requires user input).
  editor?: object;

  // Sets width of column
  width: number;

  // Field is used for compound columns, accepts an array of column objects. It requires array of size 2. (limitation: Only two columns can be used as child columns for a compound column)
  children: IMDFGridColumn[];

  // An optional property set on columns and accepts a json view as component definition. Example for view are button, radio button, link, icon, static customized text, checkbox, toggle button etc..(View does not need user to click on it to see, it renders whatever is configured in the view).
  view?: object;

  // An optional property set on columns of MDFGrid to resize columns by mouse handler drag. Default value is false.
  resize?: boolean;

  // An optional property set on columns of MDFGrid to enable sorting on data. Default value is false.
  sortable?: boolean;

  // Optional property can be set when sorting is enabled on column. Acceptable values for this field are "asc" / "desc".
  sortDirection?: 'asc' | 'desc';

  // Optional property can be set along with sort direction when sorting is enabled on column. This field is use ful when user tries to do multi column sorting.
  sortOrder?: number;

  // Property to hide / show a column in MDF Grid. Default is assumed to be false.
  hidden?: boolean;
}

export interface IMDFGridRows {
  // Accepts view name (json view)
  subheader?: string;

  // Accepts a object with all the props that is expected to be applied to subheader view. Ex: class name
  subHeaderData?: object;

  // Accepts array of objects, each object contains a set of key value pairs to render Spreadsheet
  rows: object[];

  // Accepts array of objects, each object contains a set of key value pairs to render Spreadsheet. Used for rendering the tree structure with in the Grid
  children?: object[];
}

export interface IMDFGridProps {
  // An id to assign to the MDFGrid.
  componentId?: string;

  // If set to table rows get transparent background, set the value as list to get white background for rows (indicating list style)
  tableStyle?: 'table' | 'list';

  // Required accessibility feature: Associates a data table caption with the data table. Captions help users to find a data table and understand what it’s about and decide if they want to read it.
  caption: string;

  // Set on MDFGrid, to let users drag and drop the rows in MDFGrid.
  draggable?: boolean;

  // Defines the columns in the MDFGrid, accepts an array of column objects
  columns: IMDFGridColumn[];

  // Enables the selection of only 1 row at a time (multiple selections not allowed) when set to true.
  enableSingleRowSelect?: boolean;

  // Application defined function accepts the index of the selected row, select row function is a required property in order to highlight selected rows in the MDFGrid (applicable for both single row selection and multi row selection).
  selectRow?: (rowIndex: number, groupNumber?: number, componentId?: string) => void;

  // Application defined function accepts the index of the selected column
  selectColumn?: (columnIndex: number, componentId?: string) => void;

  // The row number to display when the MDFGrid is initially displayed.
  initialRow?: number;

  model?: any;

  // A message to display when there are no rows.
  noDataMessage?: string;

  // A function to call when the user scrolls to the last row, it requires two parameters startIndex and count.
  onFetchItems?: (startIndex: number, count: number, componentId?: string) => void;

  // A function to call as the user scrolls notifying the application of the row at the top of the MDFGrid to be used with initialRow  property so the application can return the user to where he left off after a navigation.
  onInitialRow?: (row: number, componentId?: string) => any;

  // A function to call when the user drags and drops the row, an object with the below properties will be sent to the applciation ('currentIndex', 'currentSubGroupIndex', 'targetSubGroupIndex', 'targetIndex')
  onChange?: (row: object, componentId?: string) => any;

  // A function to call when the user clicks on a column header. Callback recieves an array of IMDFSpreadSheetSortParams (index => index of the column, direction => asc | desc, order => sorting order (primary or secondary columns indicated with 1, 2, ...))
  onSort?: (meta: IMDFSpreadSheetSortParams[], componentId?: string) => void;

  // The event handler called when a tree item is clicked (triggers expand/collapse)
  onExpand?: (item: object, rowIndex: number, isExpanded: boolean, componentId?: string) => void;

  // An array of arrays. Inner array contains an object with a cell's data.
  rows: IMDFGridRows[];

  // Identifier used to store widths of columns in local storage to reapply them when they revisit the page
  storageName?: string;

  // If application configures onFetchItems then this pageSize prop will be used as second parameter in that function i.e. count the number of records to fetch.
  pageSize?: number;

  // Optional property to set configure row height
  rowHeight?: number;

  // Optional property to set configure subheader row height
  subHeaderRowHeight?: number;

  // If true, the grid body will return to top scroll position on update
  scrollToTop: boolean;

  // If true enalbes scroll on the window / body of the page, default value is false.
  enableWindowScroll?: boolean;

  // The number of rows that should be visible to the user.We use this rather than specifying a css height.
  visibleRows?: number;

  // Optional property to enable all editors on the MDFGrid if it is set to true.
  areEditorsVisible?: boolean;

  // If true, the grid body will scroll to newly added row to bring it to visiblity
  scrollToNewRow?: boolean;

  // Index of the newly added row to scroll to it when scrollToNewRow is set to true.
  newRowIndex?: { group: null, row: null };
}

export const MDFGrid = forwardRef((props: IMDFGridProps, ref) => {
  return (
    <MDFSpreadSheet {...props} ref={ref} isGrid={true}></MDFSpreadSheet>
  );
});

MDFGrid.displayName = 'MDFGrid';
