import React from 'react';

interface IRowProps {
  className: string;
  children?: React.ReactNode;
  onClick?: (event) => void;
  onMouseEnter?: (event) => void;
  onMouseLeave?: (event) => void;
  row?: number;
  subHeaderIndex?: number;
  width?: number;
  draggable?: boolean;
  height?: number;
  ariaRowIndex?: number;
  expanded?: boolean
  isHeaderRow?: boolean;
}

export const Row = (props: IRowProps) => (
  <div
    unselectable={'on'} // Required for IE11
    className={props.className}
    style={{ width: props.width }}
    data-group={props.subHeaderIndex}
    data-row={props.row}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    draggable={props.draggable}
    role={'row'}
    aria-rowindex={props.ariaRowIndex ? props.ariaRowIndex : undefined}
  >
    {props.children}
  </div>
);

Row.displayName = 'Row';
