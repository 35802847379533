import React from 'react';

export interface IWFNMockShellDisabledOverlayProps {
  disabled: boolean;
}

export const WFNMockShellDisabledOverlay = ({ disabled }: IWFNMockShellDisabledOverlayProps) => {

  return disabled ? <div className="wfnmockshell-disabled-overlay" /> : null;
};
