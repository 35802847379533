import React, { createContext, useContext, useEffect, useState } from 'react';
import { WFNMockShellToolbar } from './Toolbar/WFNMockShellToolbar';
import { WFNMockShellFooter } from './Footer/WFNMockShellFooter';
import { WFNMockShellMegaMenu } from './MegaMenu/WFNMockShellMegaMenu';
import { IWFNMockShellRoot } from './types';

export interface IWFNMockShellProps {
  shellData: IWFNMockShellRoot;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  editOptionsLabel: string
}

export const PopoverExemptionContext = createContext({
  popoverExemptionChange: null,
  handlePopoverClose: null,
  popoverClickExemptions: []
});

export const WFNMockShell = (props: IWFNMockShellProps) => {
  const [selectedTopItem, setSelectedTopItem] = useState<string>();
  const [activePopover, setActivePopover] = useState<IWFNMockShellActivePopover>({
    handlePopoverClick,
    handlePopoverNav,
    type: null,
    activeToolbarItemIndex: 0,
    renderNextBtn: true,
    renderPrevBtn: false
  });
  const THEME_NAME = (window['isADPUnified'] === undefined) ? 'wfnmockshell-wrapper' : 'wfnmockshell-wrapper mockShell-unified-shell';

  // handle click events for popover closing
  const popoverContext = useContext(PopoverExemptionContext);
  const popoverExemptionChange = (elems: any[], remove?: boolean) => {
    let newExemptions = [...popoverContext.popoverClickExemptions];

    if (remove) {
      newExemptions = newExemptions.filter((elem) => {
        for (const elemToRemove of elems) {
          if (elem === elemToRemove) {
            return false;
          }
        }

        return true;
      });
    }
    else {
      newExemptions = newExemptions.concat(elems);
    }

    popoverContext.popoverClickExemptions = newExemptions;
  };

  const handlePopoverClose = () => {
    activePopover.handlePopoverClick({
      ...activePopover,
      type: null
    });
  };

  useEffect(() => {
    const topMegaMenuItemOid = !props.shellData.menu.disabled ? props.shellData.menu.resources[0]?.oid : null;
    const popoverType = !topMegaMenuItemOid ? 'toolbar' : null;
    setActivePopover({ ...activePopover, type: popoverType });
    setSelectedTopItem(topMegaMenuItemOid);
  }, []);

  function handlePopoverClick(popover: IWFNMockShellActivePopover) {
    setSelectedTopItem(null);
    setActivePopover(popover);
  }

  function handleMegaMenuTopItemClick(oid: string) {
    setSelectedTopItem(oid);

    if (oid) {
      setActivePopover({ ...activePopover, type: null });
    }
  }

  function handlePopoverNav(popoverAction: IWFNMockShellActivePopoverAction) {
    const nextActivePopover: IWFNMockShellActivePopover = { ...activePopover };

    switch (popoverAction.type) {
      case 'toolbar': {
        nextActivePopover.activeToolbarItemIndex = popoverAction.action === 'next' ? popoverAction.index + 1 : popoverAction.index - 1;
        // beggining of toolbar
        nextActivePopover.renderPrevBtn = nextActivePopover.activeToolbarItemIndex !== 0;
        nextActivePopover.type = 'toolbar';

        // Last toolbar item
        if (nextActivePopover.activeToolbarItemIndex === props.shellData.toolbar.childList?.length - 1) {
          nextActivePopover.renderNextBtn = !props.shellData.headerMenu.disabled || props.shellData.search.renderSearchBar;
        }

        // moving from end of toolbar
        if (nextActivePopover.activeToolbarItemIndex > props.shellData.toolbar.childList?.length - 1) {
          if (!props.shellData.headerMenu.disabled) {
            nextActivePopover.type = 'usermenu';
            nextActivePopover.renderNextBtn = props.shellData.search.renderSearchBar;
          }
          else {
            nextActivePopover.type = 'searchbar';
            nextActivePopover.renderNextBtn = false;
          }
        }

        break;
      }
      case 'usermenu': {
        // moving back to toolbar
        if (popoverAction.action === 'prev') {
          nextActivePopover.type = 'toolbar';
          nextActivePopover.activeToolbarItemIndex = props.shellData.toolbar.childList.length - 1;
          nextActivePopover.renderPrevBtn = nextActivePopover.activeToolbarItemIndex !== 0;
          break;
        }

        // moving to searchbar
        nextActivePopover.type = 'searchbar';
        nextActivePopover.renderNextBtn = false;
        nextActivePopover.renderPrevBtn = true;
        break;
      }
      case 'searchbar': {
        // move to usermenu
        if (!props.shellData.headerMenu.disabled) {
          nextActivePopover.type = 'usermenu';
          nextActivePopover.renderPrevBtn = props.shellData.toolbar.childList?.length > 0;
          break;
        }

        // moving back to toolbar
        nextActivePopover.type = 'toolbar';
        nextActivePopover.activeToolbarItemIndex = props.shellData.toolbar.childList.length - 1;
        nextActivePopover.renderPrevBtn = nextActivePopover.activeToolbarItemIndex !== 0;
        break;
      }
    }

    setActivePopover(nextActivePopover);
  }

  return (
    <PopoverExemptionContext.Provider value={{ popoverExemptionChange, handlePopoverClose, popoverClickExemptions: popoverContext.popoverClickExemptions }} >
      <div className={THEME_NAME}>
        <header>
          <WFNMockShellToolbar activePopover={activePopover} megaMenuActive={!!selectedTopItem} {...props} />
          <WFNMockShellMegaMenu activePopover={activePopover} selectedTopItem={selectedTopItem} setSelectedTopItem={handleMegaMenuTopItemClick} megaMenuActive={!!selectedTopItem} editOptionsLabel {...props} />
        </header>
        <main onClick={() => setSelectedTopItem(null)}/>
        <WFNMockShellFooter setSelectedTopItem={setSelectedTopItem} {...props} />
      </div>
    </PopoverExemptionContext.Provider>
  );
};

WFNMockShell.displayName = 'WFNMockShell';

// componet data change types
export interface IWFNMockShellChange {
  item: IWFNMockShellChangeItem;
  value: boolean;
  type: WFNMockShellChangeType;
}

interface IWFNMockShellChangeItem {
  oid: string,
  name: string
}

export type WFNMockShellChangeType =
  | 'menuLevel1Checkbox'
  | 'menuLevel2Checkbox'
  | 'menuLevel3Checkbox'
  | 'menuLevel3Link'
  | 'menuLevel3EditOptions'
  | 'menuLevel2SelectAll'
  | 'menuLevel2UnselectAll'
  | 'toolbarItemCheckbox'
  | 'toolbarItemSubItemCheckbox'
  | 'toolbarItemNestedSubItemCheckbox'
  | 'searchBarItemSubItemCheckbox'
  | 'searchBarItemNestedSubItemCheckbox'
  | 'userMenuItemSubItemCheckbox'
  | 'userMenuItemNestedSubItemCheckbox'
  | 'userMenuItemLevel3temCheckbox'
  | 'userMenuItemLevel4ItemCheckbox'
  | 'searchBoxCheckbox'
  | 'footerItemCheckbox'
  | 'headerMenuChange'
  | 'userMenuCheckbox';

export type WFNMockShellActivePopoverType = 'toolbar' | 'usermenu' | 'searchbar';

export interface IWFNMockShellActivePopoverAction {
  index?: number;
  type: WFNMockShellActivePopoverType;
  action: 'next' | 'prev';
}

export interface IWFNMockShellActivePopover {
  handlePopoverClick: (activePopover: IWFNMockShellActivePopover) => any;
  handlePopoverNav: (popoverAction: IWFNMockShellActivePopoverAction) => void;
  type: WFNMockShellActivePopoverType;
  activeToolbarItemIndex: number;
  renderNextBtn: boolean;
  renderPrevBtn: boolean;
}
