import React from 'react';
import { IWFNMockShellFooterItem, IWFNMockShellRoot } from '../types';
import { IWFNMockShellChange } from '../WFNMockShell';
import { WFNMockShellDisabledOverlay } from '../WFNMockShellDisabledOverlay';
import { WFNMockShellFooterItem } from './WFNMockShellFooterItem';

export interface IWFNMockShellFooterProps {
  shellData: IWFNMockShellRoot;
  onWFNShellChange: (wfnShellChange: IWFNMockShellChange) => void;
  setSelectedTopItem: (oid: string) => void;
}

export const WFNMockShellFooter = ({ shellData, onWFNShellChange, setSelectedTopItem }: IWFNMockShellFooterProps) => {
  const renderFooterItem = (footerItem: IWFNMockShellFooterItem) => {
    return <WFNMockShellFooterItem key={footerItem.oid} onWFNShellChange={onWFNShellChange} footerDisabled={shellData.footer.disabled} {...footerItem} />;
  };

  return (
    <footer className="wfnmockshell-footer" onClick={() => setSelectedTopItem(null)}>
      <WFNMockShellDisabledOverlay disabled={shellData.footer.disabled} />
      <nav className="wfn-footer-nav">
        {shellData.footer.childList?.map(renderFooterItem)}
      </nav>
    </footer>
  );
};

WFNMockShellFooter.displayName = 'WFNMockShellFooter';
