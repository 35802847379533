import React from 'react';
import PropTypes from 'prop-types';
import { IObfuscationProps, IPopoverProps, Obfuscation } from '@synerg/vdl-react-components';
import { MDFOverlayContent } from './MDFOverlayPopover';

export interface IMDFObfuscationProps extends IObfuscationProps {
  revealText?: string;
}

export class MDFMaskContent extends React.Component<IPopoverProps, any> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export const MDFObfuscationComponent = (props: IMDFObfuscationProps) => {
  let maskContent: MDFMaskContent = null;
  let overlay: MDFOverlayContent = null;

  React.Children.forEach(props.children, (child: any): any => {
    if (child.type === MDFMaskContent) {
      maskContent = child;
    }
    else if (child.type === MDFOverlayContent) {
      overlay = child;
    }
  });

  if (!maskContent || !overlay) {
    return null;
  }

  const { revealText, ...rest } = props;

  return (
    <div className="mdf-obfuscation">
      <Obfuscation mask={maskContent.props.children as any} value={overlay.props.children} {...rest}>
      </Obfuscation>
      <span className="mdf-obfuscation__reveal-text">{revealText}</span>
    </div>
  );
};

MDFObfuscationComponent.propTypes = {
  mask: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any,
  defaultVisible: PropTypes.bool,
  revealText: PropTypes.string
};
