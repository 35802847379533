import { ActionMenu as Menu } from '@synerg/vdl-react-components';
import React, { useState } from 'react';
import { generateId } from '@adp-wfn/mdf-core';
import classNames from 'classnames';
import { SdfIcon } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';

export function ActionMenu(props) {
  const [actionId] = useState(generateId('mdf-ActionMenu-Container'));
  const { className, menuLabel, ...otherProps } = props;
  const isADPUnified = !window['isLegacyAppShell'];

  // Switch the default action menu icon to the Waypoint version
  if (!otherProps.iconClass && isADPUnified) {
    if (menuLabel) {
      otherProps.icon = <SdfIcon icon="action-menu-open" />;
    }
    else {
      otherProps.icon = <SdfIcon icon="action-more-horiz" />;
    }
  }

  const renderMDFActionMenu = () => {
    const { iconClass, buttonClassName, position, ...menuProps } = otherProps;

    if (iconClass?.includes('fa-') || iconClass?.includes('icon-')) {
      menuProps.icon = <div className="mdf-action-menu__button">{menuLabel} <MDFIcon className={iconClass} /></div>;
    }
    else {
      menuProps.icon = <div className="mdf-action-menu__button">{menuLabel} {otherProps.icon ?? <SdfIcon icon={iconClass} />}</div>;
    }

    return (
      <Menu position={position || 'bottom-start'} className={classNames('mdf', className)} buttonClassName={classNames('mdf-action-menu', buttonClassName)} {...menuProps} />
    );
  };

  return (
    <div id={actionId}>
      {menuLabel ? renderMDFActionMenu() : <Menu className={classNames('mdf', className)} {...otherProps} />}
    </div>
  );
}
