import React from 'react';

import { Body } from './GridBody';
import { Row } from './GridRow';
import { cloneDeep, isEqual } from 'lodash';
import { GridSortDirectionKeys } from './GridHeadCell';
import { GridContext } from '../MDFContext';

export interface IGridProps {
  model?: any;
  // Enables the selection of only 1 row at a time (multiple selections not allowed)
  // This property must be added manually to the JSON view
  enableSingleRowSelect?: boolean;
  enableScroll?: boolean;
  horizontalScroll?: boolean;
  displayHeader?: boolean;
  draggable?: boolean;
  scrollToTop?: boolean;
  stickyHeader?: boolean;
  pageSize?: number;
  lockedColumns?: number;
  noDataMessage?: string;
  subHeader?: string;
  itemVar?: string;
  items?: object[];
  columns?: object[];
  rows?: object[];
  scrollAnchor?: { index: number; offset: number };
  collectContainers?: (container: HTMLElement) => void;
  // Application defined function accepts the index of the selected column
  selectColumn?: (columnIndex: number) => void;
  // Application defined function accepts the index of the selected row
  selectRow?: (rowIndex: number) => void;
  onSort?: (sortObject: object) => void;
  collectRows?: (row) => void;
  onScrollAnchorChange?: (index: number, offset: number) => void;
  fetchItems?: (
    startIndex: number,
    count: number
  ) => object[];
}

export class Grid extends React.Component<IGridProps, any> {
  private rowHeightTimeout: any;
  private rowHeightBuffer: any[] = [];
  private lockedGrid: any;
  private unlockedGrid: any;
  private panes: any[] = [];
  private scrollBarWidth;
  private scrollAnchorTimer;

  constructor(props: any) {
    super(props);

    this.state = {
      columns: this.props.columns,
      columnWidths: {},
      applicationRows: cloneDeep(this.props.rows),
      rows: cloneDeep(this.props.rows || []),
      headerHeight: 40,
      items: this.props.items || [],
      scrollToTop: false,
      enableScroll: this.props.enableScroll || false,
      sortInfo: this.getColumnSortInfo(this.props.columns)
    };
  }

  getColumnSortInfo(columns) {
    const sortInfo = columns.reduce((acc, item, index) => {
      if (item.sortDirection) {
        acc.index = index;
        acc.sortDirection = item.sortDirection;
      }

      return acc;
    }, {});

    return {
      previousSelectedColumn: sortInfo.index ? sortInfo.index : -1,
      sortDirection: sortInfo.sortDirection ? sortInfo.sortDirection : GridSortDirectionKeys.ASCENDING
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnNextState = false;
    const nextState: any = {};

    if (!isEqual(prevState.columns, nextProps.columns)) {
      nextState.columns = nextProps.columns;
      returnNextState = true;
    }

    if (!isEqual(nextProps.items, prevState.items)) {
      nextState.items = nextProps.items;
      returnNextState = true;
    }

    if (!isEqual(nextProps.rows, prevState.applicationRows)) {
      nextState.applicationRows = cloneDeep(nextProps.rows);
      nextState.rows = cloneDeep(nextProps.rows);
      returnNextState = true;
    }

    if (!isEqual(nextProps.scrollToTop, prevState.scrollToTop)) {
      nextState.scrollToTop = nextProps.scrollToTop;
      returnNextState = true;
    }

    if (!isEqual(nextProps.enableScroll, prevState.enableScroll)) {
      nextState.enableScroll = nextProps.enableScroll;
      returnNextState = true;
    }

    return returnNextState ? nextState : null;
  }

  componentDidMount() {
    if (this.props.scrollAnchor) {
      this.setScrollAnchor();
    }
  }

  componentDidUpdate() {
    if (this.state.scrollToTop) {
      this.setScrollTops(0);
    }
  }

  setScrollAnchor = () => {
    if (this.state.rows && this.props.scrollAnchor && (this.state.rows.length >= this.props.scrollAnchor.index)) {
      this.setScrollTops(this.getScrollPositionFromScrollAnchor(this.props.scrollAnchor));
    }
    else {
      clearTimeout(this.scrollAnchorTimer);
      this.scrollAnchorTimer = setTimeout(this.setScrollAnchor, 0);
    }
  };

  setSortDirection = (selectedIndex, DefaultDirection) => {
    const { sortInfo } = this.state;
    const { previousSelectedColumn, sortDirection } = sortInfo;

    let newDirection = '';

    if (previousSelectedColumn === -1 && DefaultDirection) {
      newDirection = (DefaultDirection === GridSortDirectionKeys.ASCENDING) ? GridSortDirectionKeys.DESCENDING : GridSortDirectionKeys.ASCENDING;
    }
    else {
      const flipSortIcon = (sortDirection === GridSortDirectionKeys.ASCENDING) ? GridSortDirectionKeys.DESCENDING : GridSortDirectionKeys.ASCENDING;
      newDirection = (selectedIndex === previousSelectedColumn) ? flipSortIcon : GridSortDirectionKeys.ASCENDING;
    }

    return newDirection;
  };

  sortHandler = (sortChanges) => {
    const { columnIndex, DefaultDirection } = sortChanges;

    const sortInfo = {
      sortDirection: this.setSortDirection(columnIndex, DefaultDirection),
      previousSelectedColumn: columnIndex
    };

    this.setState({ sortInfo },
      () => {
        const { sortDirection } = this.state.sortInfo;
        const { onSort } = this.props;

        onSort?.(Object.assign({}, sortChanges, { sortDirection }));
      }
    );
  };

  getScrollPositionFromScrollAnchor = (scrollAnchor) => {
    const { index, offset } = scrollAnchor;

    return this.state.rows.slice(0, index).reduce((acc, curr) => (curr >= 40 ? (acc + curr) : (acc + 40)), 0) + offset;
  };

  handleColumnResize = (columnIndex, columnWidth, isLockedGrid) => {
    this.setState((state: any) => {
      const newColumns = state.columnWidths || {};
      newColumns[columnIndex] = columnWidth;

      return {
        columns: newColumns,
        isResizingLockedGrid: isLockedGrid
      };
    });
  };

  setRowHeights = (rowIndex, columnIndex, height) => {
    if (rowIndex === 'header') {
      this.setState((state) => {
        if (height > state.headerHeight) {
          return { headerHeight: height };
        }
      });
    }

    this.rowHeightBuffer[rowIndex] = this.rowHeightBuffer[rowIndex] || {};
    this.rowHeightBuffer[rowIndex].height = Math.max(this.rowHeightBuffer[rowIndex].height || 0, height);

    if (this.rowHeightTimeout) {
      clearTimeout(this.rowHeightTimeout);
      this.rowHeightTimeout = null;
    }

    this.rowHeightTimeout = setTimeout(() => {
      let needsUpdate = false;

      this.setState((state) => {
        this.rowHeightBuffer.forEach((item, index) => {
          if (item) {
            state.rows[index] = state.rows[index] || {};
            state.rows[index].cellHeights = state.rows[index].cellHeights || [];

            if (state.rows[index].cellHeights[columnIndex] !== item.height) {
              state.rows[index].cellHeights[columnIndex] = item.height;
              state.rows[index].height = state.rows[index].cellHeights.reduce((acc, curr) => {
                if (curr > acc) {
                  return curr;
                }
                else {
                  return acc;
                }
              }, 0);

              needsUpdate = true;
            }
          }
        });

        this.rowHeightBuffer = [];
        this.rowHeightTimeout = null;

        if (needsUpdate) {
          return { rows: state.rows };
        }
        else {
          return null;
        }
      });
    }, 100);
  };

  handleHover = (rowIndex, isHovering) => {
    // If the grid contains locked columns and this is the locked portion
    if (this.lockedGrid) {
      this.lockedGrid.getRows()[rowIndex].updateHoverStyle(isHovering);
    }

    // If the grid contains locked columns and this is NOT the locked portion
    if (this.unlockedGrid) {
      this.unlockedGrid.getRows()[rowIndex].updateHoverStyle(isHovering);
    }
  };

  handleRowClick = (rowIndex) => {
    // Application defined function
    if (this.props.selectRow) {
      this.setState((state) => {
        state.rows[rowIndex] = state.rows[rowIndex] || {};

        // If only one row selection is allowed at a time, then deselect the previously selected row
        // this.props.enableSingleRowSelect is not included in the component by default
        if (this.props.enableSingleRowSelect) {
          state.rows.forEach((item, index) => {
            if (item) {
              state.rows[index].isSelected = false;
            }
          });
        }

        // Selects the row
        if (!state.rows[rowIndex].isSelected) {
          state.rows[rowIndex].isSelected = true;
          return { rows: state.rows };
        }
        // Deslects the row if the row was previously selected, and then clicked
        else {
          state.rows[rowIndex].isSelected = false;
          return { rows: state.rows };
        }
      });
    }
  };

  getContext() {
    return {
      registerScrollPane: this.registerScrollPane,
      unregisterScrollPane: this.unregisterScrollPane
    };
  }

  registerScrollPane = (node) => {
    if (!this.findPane(node)) {
      this.addEvents(node);
      this.panes.push(node);
    }
  };

  unregisterScrollPane = (node) => {
    if (!this.findPane(node)) {
      this.addEvents(node);
      this.panes.splice(this.panes.indexOf(node), 1);
    }
  };

  setScrollTops = (scrollTop) => {
    this.panes.forEach((pane) => {
      pane.scrollTop = scrollTop;

      // leaving this here b/c I'm just not sure if removing it is going to break something or not.
      // if (pane.classList.contains('mdf-grid-sticky-header')) {
      //   pane.style['top'] = scrollTop.toString() + 'px';
      // }
    });
  };

  handleScroll = (event) => {
    let scrollTop;

    if (event && event.target) {
      scrollTop = event.target.scrollTop;
    }

    this.setScrollTops(scrollTop);
  };

  addEvents = (node) => {
    node.onscroll = this.handleScroll;
  };

  removeEvents = (node) => {
    node.onscroll = null;
  };

  findPane = (node): any => {
    this.panes.find((pane) => pane === node);
  };

  gridBodyElement = (grid, locked) => {
    this.lockedGrid = locked ? grid : this.lockedGrid;
    this.unlockedGrid = !locked ? grid : this.unlockedGrid;
  };

  renderGrid = (locked) => {
    const displayHeader = !(this.props.displayHeader === false);
    const subHeader = this.props.subHeader;

    return (
      <div className={'mdf-grid-wrapper scroll-container'}>
        {displayHeader && !this.state.enableScroll &&
          <Row
            head={true}
            setRowHeights={this.setRowHeights}
            height={this.state.headerHeight}
            columns={this.state.columns}
            columnWidths={this.state.columnWidths}
            onSort={this.sortHandler}
            sortInfo={this.state.sortInfo}
            selectColumn={this.props.selectColumn}
            lockedGrid={locked}
            lockedColumns={this.props.lockedColumns}
            handleResize={this.handleColumnResize}
            fetchItems={this.props.fetchItems}
            scrollBarWidth={this.scrollBarWidth}
          />
        }
        {subHeader && <Row key={0} rowIndex={0} subheader={true} columns={[subHeader]} item={subHeader} />}
        <Body
          ref={(grid) => this.gridBodyElement(grid, locked)}
          collectContainers={this.props.collectContainers}
          draggable={this.props.draggable}
          fetchItems={this.props.fetchItems}
          items={this.state.items}
          columns={this.state.columns}
          columnWidths={this.state.columnWidths}
          collectRows={this.props.collectRows}
          rows={this.state.rows}
          lockedColumns={this.props.lockedColumns}
          lockedGrid={locked}
          sortInfo={this.state.sortInfo}
          setRowHeights={this.setRowHeights}
          selectRow={this.props.selectRow}
          selectColumn={this.props.selectColumn}
          handleHover={this.handleHover}
          handleScroll={this.handleScroll}
          handleRowClick={this.handleRowClick}
          enableScroll={this.state.enableScroll}
          model={this.props.model}
          itemVar={this.props.itemVar}
          noDataMessage={this.props.noDataMessage}
          onScrollAnchorChange={this.props.onScrollAnchorChange}
          onSort={this.sortHandler}
          handleResize={this.handleColumnResize}
          headerHeight={this.state.headerHeight}
        />
      </div>
    );
  };

  render() {
    // If the grid contains only unlocked columns, render a single grid
    if (!this.props.lockedColumns) {
      return (
        <GridContext.Provider value={this.getContext()}>
          <div className={'vdl-container-fluid mdf-grid-flex' + (this.props.horizontalScroll ? ' mdf-grid-scroll' : '') + (this.props.enableScroll && !this.props.fetchItems ? ' mdf-grid-enable-scroll-container' : '')}>
            {this.renderGrid(false)}
          </div>
        </GridContext.Provider>
      );
    }

    // If the grid contains locked and unlocked columns, render two grids (one for each type)
    return (
      <GridContext.Provider value={this.getContext()}>
        <div className={'vdl-container-fluid mdf-grid-flex' + (this.props.enableScroll && !this.props.fetchItems ? ' mdf-grid-enable-scroll-container' : '')}>
          <div className={'mdf-grid-locked-columns' + (this.state.isResizingLockedGrid ? ' mdf-grid-resizing' : '')}>
            {this.renderGrid(true)}
          </div>
          <div className={'mdf-grid-unlocked-columns'} onScroll={this.handleScroll}>
            {this.renderGrid(false)}
          </div>
        </div>
      </GridContext.Provider>
    );
  }
}
