import React from 'react';
import { isEqual } from 'lodash';
import { GridHelper } from '../../util/GridHelper';

const GRID_DEFAULT_PAGE_SIZE = 100;

export interface IInfiniteGridProps {
  enableScroll?: boolean;
  stickyHeader?: boolean;
  pageSize?: number;
  lockedColumns?: number;
  rows?: object[];
  scrollAnchor?: { index: number; offset: number };
  handleScroll?: () => void;
  addEventListener?: any;
  onScrollAnchorChange?: (index: number, offset: number) => void;
  fetchItems?: (startIndex: number, count: number) => object[];
  children?: any;
}

export default class InfiniteGrid extends React.Component<IInfiniteGridProps, any> {
  private headers;
  private rows = [];
  private isScrolling;
  private infiniteGridContainer: HTMLElement;
  private scrollAnchor;

  constructor(props) {
    super(props);

    this.state = {
      items: props.items || [],
      isLoadingItems: false,
      enableScroll: this.props.enableScroll || false
    };
  }

  componentDidMount() {
    if (this.props.fetchItems) {
      const startIndex = 0;
      const numberOfRowsToLoad = this.props.pageSize || GRID_DEFAULT_PAGE_SIZE;
      this.props.fetchItems(startIndex, numberOfRowsToLoad);
    }

    this.headers = Array.prototype.slice.call(GridHelper.getElementsByClassName('vdl-row mdf-grid-header', this));
  }

  componentWillUnmount() {
    if (this.scrollAnchor) {
      this.props.onScrollAnchorChange(this.scrollAnchor.index, this.scrollAnchor.offset);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnNextState = false;
    const nextState: any = {};

    if (!isEqual(nextProps.items, prevState.items)) {
      // Make sure we get an empty array and not null/undefined.
      nextState.items = nextProps.items || [];
      nextState.isLoadingItems = false;
      returnNextState = true;
    }

    if (!isEqual(nextProps.enableScroll, prevState.enableScroll)) {
      nextState.enableScroll = nextProps.enableScroll;
      returnNextState = true;
    }

    return returnNextState ? nextState : null;
  }

  collectRows = (row) => {
    this.rows.push(row);
  };

  shouldLoadMoreRows = (scrollTop, gridBottom) => {
    const viewHeight = window.innerHeight;
    const grid = GridHelper.getElementsByClassName('mdf-grid-flex', this)[0] as HTMLElement;
    const gridHeight = grid && grid.offsetHeight;

    if (!this.props.fetchItems || this.state.isLoadingItems) {
      return false;
    }

    if (this.state.enableScroll) {
      return (gridBottom - scrollTop) < gridHeight;
    }

    return (gridBottom - scrollTop) < viewHeight;
  };

  fireScrollFunctions = (scrollTop, gridBottom) => {
    const pageSize = this.props.pageSize || GRID_DEFAULT_PAGE_SIZE;
    const startIndex = this.state.items?.length || 0;

    if (this.shouldLoadMoreRows(scrollTop, gridBottom)) {
      this.setState({ isLoadingItems: true }, () => {
        this.props.fetchItems(startIndex, pageSize);
      });

      // add focus to the infiniteGrid container anytime the scrollevent triggers to load more items
      // this is to trigger the onChange for any children to focus away from a field.
      this.infiniteGridContainer.focus();
    }

    if (this.props.lockedColumns && this.props.handleScroll) {
      this.props.handleScroll();
    }
  };

  getRowPositions = (rows) => rows.map((row) => row.getBoundingClientRect().top);

  getTopRowIndex = (rowPositions, headerBottom) => rowPositions.findIndex((elem, index) => ((elem <= headerBottom) && (rowPositions[index + 1] >= headerBottom)));

  getScrollAnchor = (rowPositions, topRowIndex, headerBottom) => (
    {
      index: topRowIndex,
      offset: (headerBottom - rowPositions[topRowIndex])
    }
  );

  onScrollStop = (cb) => {
    window.clearTimeout(this.isScrolling);

    this.isScrolling = setTimeout(function() {
      if (cb) {
        return cb();
      }
    }, 100);
  };

  handleScroll = (event) => {
    const gridClassNames = ['mdf-grid-scroll-pane', 'mdf-grid-unlocked-columns', 'mdf-grid-unlocked-infinite-scroll'];
    const isScrollEventFromGrid = gridClassNames.some((className) => event.target.classList.contains(className));

    // Fetch Items only on vertical scroll and only when scroll event originates from grid itself.
    if (event.target.scrollTop && isScrollEventFromGrid) {
      const scrollTop = event.target.scrollTop;
      // height of the grid component
      const gridBottom = event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;
      const currentScroll = scrollHeight - scrollTop;

      this.fireScrollFunctions(gridBottom, currentScroll);

      if (this.props.onScrollAnchorChange) {
        this.onScrollStop(() => {
          const rowPositions = this.getRowPositions(this.rows);
          const headerBottom = this.headers[0].getBoundingClientRect().bottom;
          const topRowIndex = this.getTopRowIndex(rowPositions, headerBottom);
          this.scrollAnchor = this.getScrollAnchor(rowPositions, topRowIndex, headerBottom);
        });
      }
    }

  };

  render() {
    const InfiniteGridProps = Object.assign({}, this.props, { items: this.state.items, collectRows: this.collectRows, fireScrollFunctions: this.fireScrollFunctions });

    return (
      <div onScrollCapture={this.handleScroll}
        ref={(el) => this.infiniteGridContainer = el}
        className={'mdf-grid-infinite-grid-container'}>
        {this.props.children(InfiniteGridProps)}
      </div>
    );
  }
}
