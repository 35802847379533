import React, { useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { generateId } from '@adp-wfn/mdf-core';

export interface IMDFInfiniteListProps {
  // Called when the user scrolls to the end of the list of items. Signals the application to load more items into the items array.
  onFetchItems?: () => void;
  // A CSS classname to override the styles of this component.
  className?: string;
  children?: any;
  role?: string;
  id?: string;
  // Accessibility message for component
  'aria-label'?: any;
  'aria-activedescendant'?: any;
}

export const MDFInfiniteList = (props: IMDFInfiniteListProps) => {
  const lastScrollPosition = useRef(0);
  let scrollingDirection = 'down';
  const lastDiff = useRef(null);
  const listId = useRef(props.id || generateId('mdf-infinite-list'));

  const triggerOnFetchItems = useCallback(() => {
    props.onFetchItems?.();
  }, []);

  const debouncedOnFetchItems = useRef(debounce(triggerOnFetchItems, 300)).current;

  // Calls onFetchItems when the user scrolls to the end.
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // stopping propagation of scrolling to parent containers
    e.stopPropagation();
    e.preventDefault();

    if (props.onFetchItems) {
      if (e.currentTarget.scrollTop >= lastScrollPosition.current) {
        scrollingDirection = 'down';
      }
      else {
        scrollingDirection = 'up';
      }

      const height = e.currentTarget.getBoundingClientRect().height;
      const diff = (e.currentTarget.scrollHeight - e.currentTarget.scrollTop) - height;

      // threshold if the user scrolls within 10px of the bottom of the scrolling div
      if ((diff <= 10) && scrollingDirection === 'down' && lastDiff.current !== diff) {
        debouncedOnFetchItems();
      }

      lastScrollPosition.current = e.currentTarget.scrollTop;
      lastDiff.current = diff;
    }
  };

  // do not call handleScroll when there are no fetchitems
  return (
    <div data-list-header-id={'mdfInfiniteList'} id={listId.current} role={props.role} aria-activedescendant={props['aria-activedescendant']} className={props.className || 'mdf-infiniteList'} onScroll={(e) => handleScroll(e)} aria-label={props['aria-label']}>{props.children}</div>
  );
};

MDFInfiniteList.defaultProps = {
  role: 'listbox'
};

MDFInfiniteList.displayName = 'MDFInfiniteList';
