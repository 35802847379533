import { ComponentManager } from '@adp-wfn/mdf-core';
import { createReactWrapper } from './createReactWrapper';
import { ExternalComponents } from './ExternalComponents';

export const registerCEHComponent = (tagName: string, tagVersion = ''): Promise<any> => {
  tagVersion = tagVersion && '-' + tagVersion;
  const realTagName = `${tagName}${tagVersion}`;
  // TODO: Replace bam-dit.oneadp.com with the permanent home for the CEH web components.
  const host = ExternalComponents.getHost('ceh') || 'bam.oneadp.com';
  const componentURL = `//${host}/build/es6/Polymer3/${tagName}/1.0.0/${realTagName}.js`;
  const scriptContent = `import("${componentURL}").then(() => { window.mdfWCCallback("${realTagName}"); });`;

  return new Promise<any>((resolve) => {
    // This function is always redefined here so that we make sure the right
    // function gets called no matter what version of the MDF is being used.
    (window as any).mdfWCCallback = (wcTagName: string) => {
      const component = createReactWrapper(wcTagName);
      resolve(ComponentManager.registerComponent(wcTagName, component));
      console.log(`${wcTagName} registered with the MDF.`);
    };

    if (!customElements.get(`${realTagName}`)) {
      console.log(`Loading ${realTagName}`);
      const newScript = document.createElement('script');
      newScript.type = 'module';
      newScript.appendChild(document.createTextNode(scriptContent));
      document.body.appendChild(newScript);
      console.log('Script tag injected');
    }
  });
};
